<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="details-view-results-head">
    <div class="flex justify-end">
      <app-button
        text="Assign Site"
        faIcon="fas fa-plus"
        href="/trial/{{ trialId }}/site/assign"
        [size]="ButtonSize.Sm"
        [margin]="ButtonMargin.Lg"
        [style]="ButtonStyle.Info"
        [visible]="authService.hasPermission(Permissions.TrialSiteAssign)" />
    </div>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr>
      <th style="width: 10%;">Site Number</th>
      <th>Site Name</th>
      <th>Site Address</th>
      <th style="width: 10%;">Site Status</th>
      <th style="width: 20%;"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let site of results.results">
      <td class="site-id"> {{ site.trials[0].siteNumber }} <span class="badge badge-info" *ngIf="site.apiConsumerId">{{ site.apiConsumerName }}</span></td>
      <td> {{ site.name }}</td>
      <td> {{ site.getFullAddress() }}</td>
      <td> {{ this.mapStatus(site.trials[0].approvalStatus) }}</td>
      <td class="text-right">
        <div class="flex justify-end gap-2">
          <app-button
            text="Edit"
            href="/trial/{{ trialId }}/site/{{ site.id }}/edit"
            [size]="ButtonSize.Sm"
            [style]="ButtonStyle.Secondary"
            [visible]="authService.hasPermission(Permissions.TrialSiteEdit)" />
          <app-button
            text="Unassign"
            (clicked)="onUnassignSite(site.id)"
            [size]="ButtonSize.Sm"
            [style]="ButtonStyle.Info"
            [visible]="authService.hasPermission(Permissions.TrialSiteUnassign) && !apiEnabled" />
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="no-results inside" *ngIf="results.results.length == 0">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no sites assigned to this trial.</p>
      </div>
    </div>
  </div>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
    (pageSelected)="loadSites($event)"></app-pagination>
</div>

<!-- Start: Unassign site modal -->
<app-modal #unassignSiteModel customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Unassign Site?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to unassign this site?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <app-button
          text="Cancel"
          (clicked)="unassignSiteModel.hide()"
          [style]="ButtonStyle.Secondary"
          [isBusy]="siteIsUnassigning"
          [width]="ButtonWidth.Fill" />
      </div>

      <div class="col-6">
        <app-button
          text="Yes, unassign"
          (clicked)="onConfirmUnassignSite()"
          [isBusy]="siteIsUnassigning"
          [width]="ButtonWidth.Fill" />
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign sites modal -->
