<div *ngIf="dataLoaded" class="expense-actions">

  <div class="secondary-buttons" *ngIf="authService.hasPermission(Permissions.ExpenseWrite)">
    <app-button
      text="Requires Investigation"
      (clicked)="startInvestigation.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [style]="ButtonStyle.Secondary"
      *ngIf="!expense.underInvestigation && expense.state === ExpenseClaimState.Pending" />

    <app-button
      text="Close Investigationn"
      (clicked)="closeInvestigation.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [style]="ButtonStyle.Secondary"
      *ngIf="expense.underInvestigation && expense.state === ExpenseClaimState.Pending" />

    <app-button
      text="Edit"
      (clicked)="edit.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [style]="ButtonStyle.Secondary"
      [disabled]="expense.overBudgetRequestRaised && expense.state === ExpenseClaimState.Pending" />
  </div>

  <app-expense-auto-approval-result *ngIf="selectedTab === ExpenseClaimState.Pending"
    [result]="expense.approvalSuggestion" [expenseClaimId]="expense.id"
    (resultUpdated)="propagateResult()"></app-expense-auto-approval-result>

  <div class="primary-buttons">
    <app-button
      text="Check"
      (clicked)="check.emit(expense)"
      [size]="ButtonSize.Sm"
      *ngIf="expense.state === ExpenseClaimState.Pending" />
      
    <app-button
      text="Clone"
      (clicked)="clone.emit(expense)"
      [size]="ButtonSize.Sm"
      *ngIf="expense.state === ExpenseClaimState.Pending" />

    <app-button
      text="Approve"
      (clicked)="approve.emit(expense)"
      [size]="ButtonSize.Sm"
      [disabled]="processingRequest"
      [isBusy]="processingRequest"
      *ngIf="expense.state == ExpenseClaimState.Pending &&
                    !expense.underInvestigation &&
                    authService.hasPermission(Permissions.ExpenseWrite)" />

    <app-button
      text="Revert to Pending"
      (clicked)="revertToPending.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [disabled]="processingRequest"
      [isBusy]="processingRequest"
      *ngIf="expense.state == ExpenseClaimState.Approved && authService.hasPermission(Permissions.ExpenseWrite)" />

    <app-button
      text="Mark as Paid"
      (clicked)="markAsPaid.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [disabled]="processingRequest"
      [isBusy]="processingRequest"
      *ngIf="expense.state == ExpenseClaimState.Approved && authService.hasPermission(Permissions.ExpenseWrite)" />

    <app-button
      text="Reject"
      (clicked)="reject.emit(expense.id)"
      [size]="ButtonSize.Sm"
      [style]="ButtonStyle.Info"
      [disabled]="expense.overBudgetRequestRaised"
      [isBusy]="processingRequest"
      *ngIf="expense.state == ExpenseClaimState.Pending && authService.hasPermission(Permissions.ExpenseWrite)" />
  </div>

  <div *ngIf="expense.state == ExpenseClaimState.Paid">&nbsp;</div>
</div>

<div *ngIf="!dataLoaded" class="expense-actions">
  <div class="loaders">
    <div class="security-buttons-loader" *ngIf="authService.hasPermission(Permissions.ExpenseWrite)">
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 50%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader style="width: 15%;"></ngx-skeleton-loader>
    </div>
    <div class="auto-approval-suggestion-loader" *ngIf="selectedTab === ExpenseClaimState.Pending">
      <ngx-skeleton-loader style="width: 40%;" class="label-loader"></ngx-skeleton-loader>
      <ngx-skeleton-loader class="approval-loader"></ngx-skeleton-loader>
    </div>
    <div class="primary-buttons-loader">
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Pending" style="width: 25%;"></ngx-skeleton-loader>
      <ngx-skeleton-loader *ngIf="selectedTab === ExpenseClaimState.Approved" class="approved-page-button-loader"
        style="width: 30%;"></ngx-skeleton-loader>
    </div>
  </div>
</div>
