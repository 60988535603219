<section class="over-budget-request-items">
  <div class="over-budget-request-items-header">
    <div class="travel-budget-button-wrapper">
      <app-button
        text="Travel budget info"
        [style]="ButtonStyle.Secondary"
        [size]="ButtonSize.Sm"
        (clicked)="onClickShowPatientDetails()"
      />
    </div>

    <div class="sponsor-checkbox">
      <div class="checkbox-wrap last">
        <div class="form-group">
          <label class="checkbox">
            <input type="checkbox" value="true" [checked]="sponsorEmailed" (change)="onToggleSponsorEmailed()" [disabled]="overBudgetRequest.status !== OverBudgetRequestStatus.Open">
            <span></span>
          </label>
          <label class="checkbox-label">Sponsor Emailed</label>
        </div>
      </div>
    </div>

    <app-button
      text="Refer back"
      [style]="ButtonStyle.Secondary"
      [size]="ButtonSize.Sm"
      (clicked)="referBackRequest.emit()"
      *ngIf="authService.hasPermission(Permissions.OverBudgetRequestReferBack)"
    />

    <app-button
      text="View history"
      [style]="ButtonStyle.Info"
      [size]="ButtonSize.Sm"
      (clicked)="showHistoryModal()"
      [isBusy]="historyLoading"
      *ngIf="authService.hasPermission(Permissions.OverBudgetRequestReferBack)"
    />

    <app-button
      text="Approve all"
      [style]="ButtonStyle.Success"
      [size]="ButtonSize.Sm"
      (clicked)="showApproveAllModal()"
      [disabled]="approveAllDisabled"
      *ngIf="overBudgetRequestCategory === OverBudgetRequestCategory.Trip && authService.hasPermission(Permissions.OverBudgetRequestApprove)"
    />

    <app-button
      text="Delete request"
      [size]="ButtonSize.Sm"
      (clicked)="deleteRequest.emit()"
      *ngIf="authService.hasPermission(Permissions.OverBudgetRequestDelete)"
    />
  </div>
  <div *ngFor="let items of overBudgetRequestItemsViewModel" class="over-budget-request-items-body">
    <div class="items-details" style="gap:0;">

      <div class="flex gap-0">
        <div class="ob-panel">
          <label>Caregiver Travelling</label>
          <span>{{items.careGiver ? 'Yes' : 'No'}}</span>
        </div>

        <div class="ob-panel" *ngIf="items.category === OverBudgetRequestCategory.Expense">
          <label>Blanket Receipt Approval</label>
          <span>{{items.blanketReceiptApproval ? 'Yes' : 'No'}}</span>
        </div>

        <div class="ob-panel" *ngIf="items.category === OverBudgetRequestCategory.Expense">
          <label>Rule</label>
          <span>{{items.ruleName}}</span>
        </div>

        <div class="ob-panel" *ngIf="items.category === OverBudgetRequestCategory.Expense">
          <label>Limit Per</label>
          <span>{{items.rulePer | enumToText: ExpenseAutoApprovalRuleType }}</span>
        </div>

        <div class="ob-panel" style="min-width: 320px; max-width: 320px; white-space: pre-line">
          <label>Additional Details</label>
          <span>{{items.additionalDetails }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <div class="items-list">
          <table>
            <thead>
            <tr>
              <th>Type</th>
              <th>Status</th>
              <th>Date closed</th>
              <th>Actual cost</th>
              <th>Actual Cost Policy Currency</th>
              <th *ngIf="items.category === OverBudgetRequestCategory.Expense">Missing receipt</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of items.items"
                [class.approved]="item.status !== 'Rejected' && item.status !== 'Pending'"
                [class.rejected]="item.status === 'Rejected'">
              <td>{{item.type}}</td>
              <td>{{item.status}}</td>
              <td>{{item.date | date: 'dd/MM/yy'}}</td>
              <td>{{item.actualCost}} {{item.actualCostCurrency}}</td>
              <td>{{item.actualCostInBaseCurrency}} {{( item.actualCostInBaseCurrency ? item.baseCurrency : '')}}</td>
              <td *ngIf="items.category === OverBudgetRequestCategory.Expense">{{item.missingReceipt ? 'Yes' : 'No'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-3">
        <div class="items-summary">
          <div class="cost">
            <div class="total-cost">
              <span class="total-cost-label">Policy Budget Limit</span>
              <span *ngIf="items.budgetCost" class="total-cost-value">{{items.budgetCost}} {{items.currency}}
              <ng-container *ngIf="items.accommodationBudgetType"> per {{items.accommodationBudgetType | enumToText: AccommodationBudgetType}}</ng-container>
              </span>
            </div>
            <div class="total-cost">
              <span class="total-cost-label">Total Actual Cost</span> <span
              class="total-cost-value">{{items.totalActualCost}} {{items.currency}}</span>
            </div>
          </div>

          <div class="status-container" *ngIf="items.status === OverBudgetRequestDetailsStatus.Approved || items.status === OverBudgetRequestDetailsStatus.Rejected">
            <app-badge [badgeStyle]="BadgeStyle.Success" [size]="BadgeSize.Lg" *ngIf="items.status === OverBudgetRequestDetailsStatus.Approved">Paid {{items.amountPaid}}{{items.currency}}</app-badge>
            <app-badge [badgeStyle]="BadgeStyle.Danger" [size]="BadgeSize.Lg" *ngIf="items.status === OverBudgetRequestDetailsStatus.Rejected">Rejected</app-badge>
          </div>

          <div class="button-container">
            <div class="row">
              <div class="col-6">
                <app-button
                  text="Reject"
                  (clicked)="showRejectModal(items)"
                  [width]="ButtonWidth.Fill"
                  [style]="ButtonStyle.Primary"
                  *ngIf="items.status === null && authService.hasPermission(Permissions.OverBudgetRequestReject)"
                />
              </div>

              <div class="col-6">
                <app-button
                  text="Approve total"
                  (clicked)="showApproveTotalModal(items)"
                  [width]="ButtonWidth.Fill"
                  [style]="ButtonStyle.Success"
                  *ngIf="items.status === null && authService.hasPermission(Permissions.OverBudgetRequestApprove)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="other-over-budget-request-container" *ngIf="items.newOverBudgetRequestExists">
      <p>New Over budget request for an additional
        {{items.newOverBudgetRequestAmount}}{{items.newOverBudgetRequestCurrency}} has been raised.</p>
    </div>
    <div class="other-over-budget-request-container" *ngIf="items.existingOverBudgetRequestExists">
      <p>Previous Over budget request for {{items.existingOverBudgetRequestAmount}}{{items.existingOverBudgetRequestCurrency}}
        <span *ngIf="items.existingOverBudgetRequestStatus === null">is still outstanding</span>
        <span *ngIf="items.existingOverBudgetRequestStatus === OverBudgetRequestDetailsStatus.Rejected">of this {{items.totalActualCost}}{{items.currency}} has been rejected</span>
        <span *ngIf="items.existingOverBudgetRequestStatus === OverBudgetRequestDetailsStatus.Approved">of this {{items.totalActualCost}}{{items.currency}} has been approved</span>. This is included in the summary above.
      </p>
    </div>
  </div>
</section>

<app-modal #approveTotalModal class="approve-total-modal">
  <div class="modal-header">
    <h2 class="modal-title">Approve</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="approveTotalForm">
      <div class="form-group" *ngIf="overBudgetItemToApprove?.category === OverBudgetRequestCategory.Expense">
        <label>Amount payable</label>
        <input class="form-control" formControlName="amountPayable" type="text">
        <span class="currency">{{overBudgetItemToApprove?.currency}}</span>
      </div>
      <div class="trip-types" *ngIf="overBudgetItemToApprove?.category === OverBudgetRequestCategory.Trip">
        <span *ngFor="let item of overBudgetItemToApprove.items" class="trip-type">
          {{item.type}}<span class="trip-type-value"> {{item.actualCost}}{{item.actualCostCurrency}}</span>
        </span>
      </div>
      <div class="form-group">
        <label>Approved by</label>
        <input class="form-control" formControlName="approvedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Approved</label>
        <app-datepickerv2-input [parentForm]="approveTotalForm" controlName="approvedAt"></app-datepickerv2-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="approveTotalModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onApproveTotal()" [disabled]="obSubmitBtnDisabled">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #approveAllModal class="approve-total-modal">
  <div class="modal-header">
    <h2 class="modal-title">Approve all requests</h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="approveAllForm">
      <div class="trip-types mb-3">
        <span *ngFor="let item of overBudgetRequestItemsViewModel" class="trip-types">
          <span *ngFor="let trip of item.items" class="trip-type">
            {{trip.type}}<span class="trip-type-value"> {{trip.actualCost}}{{trip.actualCostCurrency}}</span>
          </span>
        </span>
      </div>
      <div class="form-group">
        <label>Approved by</label>
        <input class="form-control" formControlName="approvedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Approved</label>
        <app-datepickerv2-input [parentForm]="approveAllForm" controlName="approvedAt"></app-datepickerv2-input>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="approveAllModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" (click)="onApproveAll()">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #rejectOptionsModal class="reject-options-modal">
  <div class="modal-header">
    <h2 class="modal-title">Reject</h2>
  </div>
  <div class="modal-body">
    <button class="btn btn-outline" (click)="showExpenseRejectModal(false)">Reject full amount</button>
    <button class="btn btn-primary" (click)="showExpenseRejectModal(true)">Reject and pay partial amount</button>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="rejectOptionsModal.hide()">Cancel</button>
  </div>
</app-modal>

<app-modal #rejectModal class="reject-modal">
  <div class="modal-header">
    <h2 class="modal-title">Reject <ng-container *ngIf="rejectPartial">and pay partial</ng-container></h2>
  </div>
  <div class="modal-body">
    <form [formGroup]="rejectForm">
      <div class="form-group" *ngIf="rejectPartial">
        <label>Amount to be paid</label>
        <input class="form-control" formControlName="amountToBePaid" type="number">
        <span class="currency">{{overBudgetItemToReject.currency}}</span>
      </div>
      <div class="form-group">
        <label>Rejected by</label>
        <input class="form-control" formControlName="rejectedBy" type="text">
      </div>
      <div class="form-group">
        <label>Date Rejected</label>
        <app-datepickerv2-input [parentForm]="rejectForm" controlName="rejectedAt"></app-datepickerv2-input>
      </div>
      <div class="form-group">
        <label>Notes</label>
        <textarea class="form-control" formControlName="notes" type="text"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="rejectModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" [disabled]="loading || rejectForm.invalid" (click)="onReject()">
          <span *ngIf="loading">
            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          </span>
          <span *ngIf="!loading">{{ rejectPartial ? 'Submit' : 'Reject' }}</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-over-budget-request-history-modal #viewHistoryModal></app-over-budget-request-history-modal>
<app-visit-patient-detail-modal #visitPatientDetailModal></app-visit-patient-detail-modal>
