import { SearchPage } from "./search-page.model";
import { DateHelper } from "../helpers/date-helper";
import { ExpenseImage } from "./expense-image.model";
import { ExpenseClaimPaidBy, ReasonForEdit, VisitAttendance } from "./expense.model";
import { OverBudgetRequestDetailsStatus } from "./project-management.model";

export class PatientExpenseListItem {
  public id: string;
  public state: string;
  public visitTitle: string;
  public categoryName: string;
  public categoryType: string;
  public amount: number;
  public currency: string;
  public distanceAmount: number;
  public distanceUnit: string;
  public imageUrls: ExpenseImage[] = [];
  public rejectionReason: string;
  public notes: string;
  public policyReminders: string;
  public created: Date;
  public dateApproved: Date;
  public dateRejected: Date;
  public datePaid: Date;
  public type: string;
  public underInvestigation: boolean;
  public reasonForInvestigation: string;
  public addedByPatient: boolean;
  public paidBy: ExpenseClaimPaidBy;
  public subCategoryName: string;
  public visitType: string;
  public visitAttendance?: VisitAttendance;
  public reasonsForEdit: ReasonForEdit[] = [];
  public overspendApproved: boolean;
  public fixedFeePerVisit: boolean;
  public bankFeesIncurred: boolean;
  public approvalNoReceipts: boolean;
  public overspendTravel: boolean;
  public overspendMileage: boolean;
  public overBudgetRequestRaised: boolean;
  public overBudgetRequestStatus?: OverBudgetRequestDetailsStatus;
  public overBudgetPartialApproval: boolean;
  public referredBack: boolean;
  public fullyReconciled: boolean;

  public static fromObject(obj: any) {
    let expense = new PatientExpenseListItem();
    expense.id = obj.id;
    expense.state = obj.state;
    expense.visitTitle = obj.visitTitle;
    expense.categoryName = obj.categoryName;
    expense.categoryType = obj.categoryType;
    expense.amount = obj.amount > 0 ? obj.amount / 100 : null;
    expense.currency = obj.currency;
    expense.distanceAmount = obj.distanceAmount;
    expense.distanceUnit = obj.distanceUnit;
    expense.imageUrls = obj.imageUrls;
    expense.rejectionReason = obj.rejectionReason;
    expense.notes = obj.notes;
    expense.policyReminders = obj.policyReminders;
    expense.created = DateHelper.dateUTCToLocal(obj.created);
    expense.dateApproved = DateHelper.dateUTCToLocal(obj.dateApproved);
    expense.dateRejected = DateHelper.dateUTCToLocal(obj.dateRejected);
    expense.datePaid = DateHelper.dateUTCToLocal(obj.datePaid);
    expense.type = expense.distanceAmount > 0 ? 'Distance' : 'Currency';
    expense.underInvestigation = obj.underInvestigation;
    expense.reasonForInvestigation = obj.reasonForInvestigation;
    expense.addedByPatient = obj.addedByPatient;
    expense.paidBy = obj.paidBy;
    expense.visitAttendance = obj.visitAttendance;
    expense.subCategoryName = obj.subCategoryName;
    expense.reasonsForEdit = obj.reasonsForEdit !== null ? obj.reasonsForEdit : [];
    expense.overspendTravel = obj.overspendTravel ?? false;
    expense.overspendMileage = obj.overspendMileage ?? false;
    expense.overspendApproved = obj.overspendApproved ?? false;
    expense.fixedFeePerVisit = obj.fixedFeePerVisit ?? false;
    expense.bankFeesIncurred = obj.bankFeesIncurred ?? false;
    expense.approvalNoReceipts = obj.approvalNoReceipts ?? false;
    expense.overBudgetRequestRaised = obj.overBudgetRequestRaised;
    expense.overBudgetRequestStatus = obj.overBudgetRequestStatus;
    expense.referredBack = obj.referredBack;
    expense.fullyReconciled = obj.fullyReconciled;

    switch (obj.visitType) {
      case 'Home':
        expense.visitType = 'Home';
        break;
      case 'OnSite':
        expense.visitType = 'On-Site';
        break;
      case 'Virtual':
        expense.visitType = 'Virtual';
        break;
      case 'Telephone':
        expense.visitType = 'Telephone';
        break;
    }

    return expense;
  }
}

export class PatientExpensesList extends SearchPage {
  results: PatientExpenseListItem[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(PatientExpenseListItem.fromObject(result));
    }

    return this;
  }
}
