export class RoleNames {
  public static readonly Pending: string = "Pending";
  public static readonly Patient: string = "Patient";
  public static readonly SiteContact: string = "SiteContact";
  public static readonly SystemAdministrator: string = "SystemAdministrator";
  public static readonly ProductAdministrator: string = "ProductAdministrator";
  public static readonly ProjectCoordinatorExpenses: string = "ProjectCoordinatorExpenses";
  public static readonly ProjectCoordinatorTravel: string = "ProjectCoordinatorTravel";
  public static readonly StartupCoordinator: string = "StartupCoordinator";
  public static readonly ProjectManager: string = "ProjectManager";
  public static readonly CardManager: string = "CardManager";
}
