<button
    [type]="buttonType()"
    [class]="classNames()"
    (click)="onClick()"
    [disabled]="disabled"
    [class.w-full]="width === ButtonWidth.Fill"
    [class.align-right]="textAlign === ButtonTextAlign.Right"
    [class.align-left]="textAlign === ButtonTextAlign.Left"
    [class.is-busy]="isBusy"
    [title]="title"
    *ngIf="visible"
>
  <i
    class="fa-icon {{faIcon}}"
    [class.order-2]="iconPosition == ButtonIconPosition.Right"
    *ngIf="faIcon"
  ></i>
  <span class="text" *ngIf="!iconOnly || confirmationActive">
          {{ text }}
      </span>

  <span class="spinner" *ngIf="isBusy">
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </span>

</button>
