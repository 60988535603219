<app-modal-v2 #modal class="add-visit-modal" [viewPort]="viewPort" [tabulated]="true">
  <div class="modal-header">
    <h2 class="modal-title">Edit Trip</h2>

    <div class="modal-actions" style="min-width: 455px;" *ngIf="!muvRideFormVisible">
      <button class="btn btn-secondary" (click)="onClickShowPatientDetails()">Patient/Budget Info</button>
      <button class="btn btn-outline" (click)="onDeleteTrip(trip)">Delete</button>

      <button class="btn btn-primary" [disabled]="form.invalid" (click)="onFormSubmit()">
        <span *ngIf="form.get('processing').value">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!form.get('processing').value">Save</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" (mousemove)="onMouseEnterForm()">

      <app-tabulated-content #tabulatedContent [inModal]="true" [tabs]="tabs" [showNavButtons]="true">

        <!-- Start: Trip Information -->
        <section [formGroup]="tripTabForm">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="form-group">
                    <label>Trip Type <sup *ngIf="isRequired(tripTabForm.get('tripType'))">*</sup></label>
                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ getTripTypeText() }}</div>
                    <div [class.visibility-hidden]="tripHasObRequest">
                      <app-input-select [parentForm]="tripTabForm" controlName="tripType" [items]="tripTypeOptions"
                                        inputPlaceholder="Select Trip Type..."></app-input-select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group" *ngIf="tripTabForm.get('tripType').value === '0'">
                    <label>To/From <sup *ngIf="bookingTabForm.get('bookingStatus').value === 'Booked'">*</sup></label>
                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ getGroundTransportTypeText() }}</div>
                    <div [class.visibility-hidden]="tripHasObRequest">
                    <app-input-select #groundTransportTravelTypeSelect panelClass="hide-checkbox"
                      [parentForm]="tripTabForm" controlName="groundTransportTravelType"
                      [items]="groundTransportTravelTypeOptions"
                      inputPlaceholder="Select Ground Transport Travel Type..."></app-input-select>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-3">
                  <div class="form-group"
                    *ngIf="tripTabForm.get('tripType').value === '0' || tripTabForm.get('tripType').value === '1' || tripTabForm.get('tripType').value === '2'">
                    <label>Direction</label>
                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ getDirectionText() }}</div>
                    <div [class.visibility-hidden]="tripHasObRequest">
                      <app-input-select panelClass="hide-checkbox" [parentForm]="tripTabForm"
                        controlName="tripDirection" [items]="tripDirectionOptions"
                        inputPlaceholder="Select trip direction..."></app-input-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row"
            [ngClass]="{'visibility-hidden': visitType !== 'Travel' && visitType !== 'Accommodation' && visitType !== 'Rental Car' }">
            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>{{ getDepartureDateLabel(visitType)}} <sup
                        *ngIf="isRequired(tripTabForm.get('departureDate'))">*</sup></label>
                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ this.trip.departureDate | date: 'dd/MM/yyyy' }}</div>

                    <div [class.visibility-hidden]="tripHasObRequest">
                      <input type="text" class="date white-bg" data-toggle="datepicker" #departureDate
                        formControlName="departureDate" placeholder="Select Date...">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group type-time">
                    <label>{{ getDepartureTimeLabel(visitType)}} <sup
                        *ngIf="isRequired(tripTabForm.get('departureTime'))">*</sup></label>

                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ this.tripTabForm.get('departureTime').value }}</div>

                    <div [class.visibility-hidden]="tripHasObRequest">
                      <mat-form-field>
                        <input type="time" matInput formControlName="departureTime">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>{{ getArrivalDateLabel(visitType) }} <sup
                        *ngIf="isRequired(tripTabForm.get('arrivalDate'))">*</sup></label>

                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ this.trip.arrivalDate | date: 'dd/MM/yyyy' }}</div>

                    <div [class.visibility-hidden]="tripHasObRequest">
                      <input type="text" class="date white-bg" data-toggle="datepicker" #arrivalDate
                        formControlName="arrivalDate" placeholder="Select Date...">
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group type-time">
                    <label>{{ getArrivalTimeLabel(visitType) }} <sup
                        *ngIf="isRequired(tripTabForm.get('arrivalTime'))">*</sup></label>

                    <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ this.tripTabForm.get('arrivalTime').value }}</div>

                    <div [class.visibility-hidden]="tripHasObRequest">
                      <mat-form-field>
                        <input type="time" matInput formControlName="arrivalTime">
                      </mat-form-field>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row" [ngClass]="{'visibility-hidden': visitType !== 'Accommodation'}">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>Accommodation Location <sup
                    *ngIf="isRequired(tripTabForm.get('accommodationLocation'))">*</sup></label>

                <div class="fake-textarea disabled" *ngIf="tripHasObRequest">{{ this.trip.accommodationLocation }}</div>

                <div [class.visibility-hidden]="tripHasObRequest">
                  <input type="text" formControlName="accommodationLocation">
                </div>
              </div>
            </div>
          </div>

          <div class="row" [ngClass]="{'visibility-hidden': visitType !== 'Travel' && visitType !== 'Rental Car'}">
            <div class="col-12 col-lg-6">
              <div class="trip-info-container">
                <div class="form-group">
                  <label>{{ visitType !== 'Rental Car' ? 'Departure Location' : 'Pick-up Location'}} <sup
                      *ngIf="isRequired(tripTabForm.get('departureLocation'))">*</sup></label>

                  <div class="fake-textarea disabled" *ngIf="tripHasObRequest">{{ this.trip.departureLocation }}</div>

                  <div [class.visibility-hidden]="tripHasObRequest">
                    <textarea class="form-control" formControlName="departureLocation"></textarea>
                    </div>
                </div>
              </div>
            </div>


            <div class="col-12 col-lg-6">
              <div class="trip-info-container">
                <div class="form-group">
                  <label>{{ visitType !== 'Rental Car' ? 'Arrival Location' : 'Drop-off Location'}} <sup
                      *ngIf="isRequired(tripTabForm.get('arrivalLocation'))">*</sup></label>

                  <div class="fake-textarea disabled" *ngIf="tripHasObRequest">{{ this.trip.arrivalLocation }}</div>

                  <div [class.visibility-hidden]="tripHasObRequest">
                    <textarea class="form-control" formControlName="arrivalLocation"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- End: Trip Information -->

        <!-- Start: Booking Information -->
        <section [formGroup]="bookingTabForm">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Booking Status</label>

                <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ getBookingStatusText() }}</div>

                <div [class.visibility-hidden]="tripHasObRequest">
                  <app-input-select panelClass="hide-checkbox" [parentForm]="bookingTabForm" controlName="bookingStatus"
                    [items]="tripBookingStatusOptions" inputPlaceholder="Select Book Type..."></app-input-select>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Carrier</label>
                <app-list-autocomplete #carrierList [parentForm]="bookingTabForm" controlName="carrier"
                  listType="Carrier"></app-list-autocomplete>
              </div>
            </div>

            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Booked Through</label>
                <app-list-autocomplete #bookedThroughList [parentForm]="bookingTabForm" controlName="bookingProvider"
                  listType="BookingProvider"></app-list-autocomplete>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>Reference</label>
                <input type="text" formControlName="reference">
              </div>
            </div>

            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>Amount Quoted</label>

                <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ getAmountAsText(this.trip.quotedAmount) }} {{ trip.quotedCurrency }}</div>

                <div [class.visibility-hidden]="tripHasObRequest">
                  <app-input-currency #amountQuotedControl [parentForm]="bookingTabForm" placeholder="0.00"
                    amountControlName="quotedAmount" currencyControlName="quotedCurrency">
                  </app-input-currency>
                </div>
              </div>
              <div class="form-group over-budget-checkbox" [class.visibility-hidden]="tripHasObRequest">
                <label class="checkbox">
                  <input type="checkbox" id="overBudget" formControlName="overBudget" class="over-budget-checkbox">
                  <span></span>
                </label>
                <label class="checkbox-label" for="overBudget">Over Budget</label>
              </div>
            </div>

            <div class="col-12 col-lg-2 show-fx-rate-wrapper" [class.visibility-hidden]="tripHasObRequest">
              <label>&nbsp;</label>
              <button class="btn btn-primary" (click)="onShowTransformedFxRate()">
                <span *ngIf="transformedCurrencyProcessing">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
                </span>
                <span *ngIf="!transformedCurrencyProcessing">FX</span>
              </button>
            </div>

            <div *ngIf="!showFxTransformError" class="col-12 col-lg-4">
              <div class="form-group">
                <label>Amount Quoted (BC)</label>

                <div class="fake-field disabled" *ngIf="tripHasObRequest">{{ transformedFxRateForm.get('transformedAmount').value }} {{ transformedFxRateForm.get('transformedCurrency').value }}</div>

                <div [class.visibility-hidden]="tripHasObRequest">
                  <app-input-currency #amountQuotedBCControl [parentForm]="transformedFxRateForm" placeholder="0.00"
                    amountControlName="transformedAmount" currencyControlName="transformedCurrency">
                  </app-input-currency>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div *ngIf="showFxTransformError && !canTransformRate"
              class="col-12 col-lg-12 quoted-amount-error-message-wrapper">
              <span class="quoted-amount-error-message">The quoted amount, quoted currency and base currency on
                connected trial cannot be blank..</span>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>Payment Card Used</label>
                <app-list-autocomplete #paymentCardUsedList [parentForm]="bookingTabForm" controlName="paymentCardUsed"
                  listType="TripPaymentCard"></app-list-autocomplete>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>Invoice Number</label>
                <input type="text" formControlName="invoiceNo">
              </div>
            </div>
          </div>

          <div class="row" [ngClass]="{'visibility-hidden': visitType !== 'Travel'}">
            <div class="col-12">
              <div class="form-group">
                <label>Ticket/Confirmation Number(s)</label>
                <textarea class="form-control" formControlName="ticketConfirmationNo"></textarea>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <app-manage-trip-tickets #ticketManager (changed)="onTicketsChanged($event)"></app-manage-trip-tickets>
          </div>
        </section>
        <!-- End: Booking Information -->

        <!-- Start: Notes -->
        <section [formGroup]="noteTabForm">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>Patient Notes <sup *ngIf="isRequired(noteTabForm.get('notes'))">*</sup></label>
                <textarea class="form-control" formControlName="notes"></textarea>
              </div>

              <div class="form-group">
                <label>Internal Notes <sup *ngIf="isRequired(noteTabForm.get('internalNotes'))">*</sup></label>
                <textarea class="form-control" formControlName="internalNotes"></textarea>
              </div>
            </div>
          </div>
        </section>
        <!-- End: Trip Information -->

        <!-- Start: RideHealth -->
        <section *ngIf="trip !== undefined">
          <app-custom-fields [form]="form" [customFields]="trip.customDataFields"></app-custom-fields>
        </section>
        <!-- End: RideHealth -->

        <!-- Start: MUV Rides -->
        <section *ngIf="trip !== undefined && trip.trialMuvEnabled">
          <app-trip-muv-rides #muvRides [patientTrip]="trip !== undefined ? trip : null"
            [rides]="trip !== undefined && trip.muvRides"
            (muvRideFormVisible)="updateMuvRideFormVisibility($event)"></app-trip-muv-rides>
        </section>
        <!-- End: MUV Rides -->

      </app-tabulated-content>
    </form>
  </div>
  <div class="modal-footer">

  </div>
</app-modal-v2>

<!-- Start: Delete trip modal -->
<app-modal #deleteTripModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Delete Trip?</h2>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to delete this this?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="deleteTripModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onConfirmDeleteTrip()" class="btn btn-primary" [ngClass]="{'disabled': isDeleteProcessing}">
            <span *ngIf="isDeleteProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isDeleteProcessing">Delete</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Delete trip modal -->

<app-modal-v2 #recalculateAmountBcModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Recalculate BC amount ?</h2>
    </div>
    <div class="modal-body text-center">
      <p>Trip quoted amount or currency changed, do you want to recalculate amount in BC ?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <button (click)="onFormSubmit(false)" class="btn btn-secondary">
            <span *ngIf="isUpdateProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isUpdateProcessing">No</span>
          </button>
        </div>

        <div class="col-6">
          <button (click)="onFormSubmit(true)" class="btn btn-primary">
            <span *ngIf="isUpdateProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isUpdateProcessing">Yes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>

<app-visit-patient-detail-modal #visitPatientDetailModal></app-visit-patient-detail-modal>
