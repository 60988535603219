<app-modal-v2 #modal class="add-patient-modal" [viewPort]="viewPort" [tabulated]="true">
  <div class="modal-header">
    <h2 class="modal-title">Add Patient</h2>

    <div class="modal-actions">
      <button class="btn btn-primary" [disabled]="form.invalid" (click)="onFormSubmit()">
        <span *ngIf="form.get('processing').value">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!form.get('processing').value">Create Patient</span>
      </button>
    </div>
  </div>

  <div class="modal-body">
    <form [formGroup]="form">
      <app-tabulated-content #tabulatedContent [showNavButtons]="true" [inModal]="true" [tabs]="tabs" (onTabChange)="onHandleTabChanged($event)">
        <section [formGroup]="trialInfoForm">
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label>Public Trial Code <sup *ngIf="isRequired(trialInfoForm.get('trialCode'))">*</sup></label>
                <app-trial-autocomplete #trialAutocomplete [form]="trialInfoForm" controlName="trialCode" [liveTrials]="true" [showApiEnabledTrials]="true"
                                        placeholder="Select a public trial code..."></app-trial-autocomplete>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Site <sup *ngIf="isRequired(trialInfoForm.get('site'))">*</sup></label>
                <app-site-autocomplete #siteAutocomplete [form]="trialInfoForm" controlName="site"
                                       [allowAllSites]="false"
                                       [showApprovedOnly]="true"
                                       [showSiteNumbers]="true"
                                       [showConsumerSites]="apiEnabled"
                                       [trialFilter]="trialInfoForm.get('trialId').value"
                                       placeholder="Select site..."></app-site-autocomplete>
              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <label>Patient ID <sup *ngIf="isRequired(trialInfoForm.get('patientCode'))">*</sup></label>
                <input class="form-control" formControlName="patientCode" id="patientCode" maxlength="16"
                       type="text">
              </div>
            </div>
          </div>
        </section>

        <section [formGroup]="patientInfoForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>First Name <sup *ngIf="isRequired(patientInfoForm.get('firstname'))">*</sup></label>
                <input type="text" class="form-control" id="firstname" formControlName="firstname">
              </div>

              <div class="form-group">
                <label>Last Name <sup *ngIf="isRequired(patientInfoForm.get('lastname'))">*</sup></label>
                <input type="text" class="form-control" id="lastname" formControlName="lastname">
              </div>

              <div class="form-group">
                <label>Email Address <sup *ngIf="isRequired(patientInfoForm.get('email'))">*</sup></label>
                <input type="text" class="form-control" id="email" formControlName="email">
              </div>

              <div class="form-group">
                <label>Language <sup *ngIf="isRequired(patientInfoForm.get('culture'))">*</sup></label>
                <app-input-select #languageSelect [parentForm]="patientInfoForm" controlName="culture" [items]="languageOptions" inputPlaceholder="Select Language..."></app-input-select>
              </div>
            </div>

            <div class="col-6">
              <label style="margin-left: 16px;">Home Address <sup *ngIf="isRequired(patientInfoForm.get('homeAddress'))">*</sup></label>
              <app-input-address #homeAddress [parentForm]="patientInfoForm" controlName="homeAddress"></app-input-address>
            </div>
          </div>
        </section>

        <section [formGroup]="extraInfoForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Important Information <sup *ngIf="isRequired(extraInfoForm.get('notes'))">*</sup></label>
                <textarea class="form-control" formControlName="notes" id="notes"></textarea>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label>Expense Policy Reminders <sup *ngIf="isRequired(extraInfoForm.get('policyReminders'))">*</sup></label>
                <textarea class="form-control" formControlName="policyReminders" id="policyReminders"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group" style="margin-left: -16px;">
                <label class="checkbox">
                  <input type="checkbox" id="patient-consent" formControlName="patientConsent">
                  <span></span>
                </label>
                <label class="checkbox-label" for="patient-consent">I confirm that I have seen and verified patient
                  consent</label>
              </div>

              <div class="form-group" style="margin-left: -16px;">
                <label class="checkbox">
                  <input formControlName="patientNotOnApp" id="patientNotOnApp" type="checkbox">
                  <span></span>
                </label>
                <label class="checkbox-label" for="patientNotOnApp">Patient is not on the app (Will not receive
                  notifications)</label>
              </div>
            </div>
          </div>
        </section>
      </app-tabulated-content>
    </form>
  </div>
</app-modal-v2>

<app-create-site-modal #createSiteModal (createdSite)="onNewSiteCreated($event)"
                       [trialId]="this.trialInfoForm.get('trialId').value"></app-create-site-modal>

<!-- Start: Send Invitation Modal -->
<app-modal #sendInviteModal>
  <div class="modal-header">
    <h2 class="modal-title">Send Invitation Email?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Would you like to email the patient an App Invitation?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="onDoNotSendInvitation()" class="btn btn-secondary" data-dismiss="modal">No</a>
      </div>

      <div class="col-6">
        <a (click)="onSendPatientInvitation()" class="btn btn-primary">
          <span *ngIf="sendingInvite">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!sendingInvite">Yes</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End:Send Invitation Modal -->
