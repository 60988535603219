<div class="filter-toggle-container" [class.is-open]="isOpen" (clickOutside)="close()">
  <div class="filter-toggle flex justify-between items-center" (click)="open()">
    <i class="fas fa-filter"></i>
    <span class="selected-count" [class.clear-enabled]="getSelectedCount() > 0">
      <span class="count">{{ getSelectedCount() }}</span>
      <span class="clear" *ngIf="getSelectedCount() > 0" (click)="clear($event)"><i class="fas fa-times"></i></span>
    </span>
  </div>

  <div class="filter-toggle-options">
    <ul>
      <ng-container *ngFor="let option of options">
        <li *ngIf="option.visible">
          <div class="checkbox-wrap">
            <label class="checkbox">
              <input type="checkbox" [id]="option.id" value="true" [checked]="option.checked" (change)="onToggle(option)">
              <span></span>
            </label>
            <label class="checkbox-label" [for]="option.id">{{ option.text }}</label>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
