import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../modal/modal.component";

@Component({
  selector: 'app-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrl: './confirm-action-modal.component.scss'
})
export class ConfirmActionModalComponent {
  @ViewChild('modal') modal: ModalComponent;

  @Output('confirmed') confirmed = new EventEmitter<string>();

@Input('entityId') entityId: string;
  @Input('title') title: string = 'Confirm Action?';
  @Input('message') message: string = 'Are you sure you want to perform this action?';
  @Input('confirmBtnText') confirmBtnText: string = 'Yes, confirm';


  showConfirmation(entityId: string, title: string = null, message: string = null, confirmBtnText: string = null) {
    this.entityId = entityId;

    if (title)
      this.title = title;

    if (message)
      this.message = message;

    if (confirmBtnText)
      this.confirmBtnText = confirmBtnText;

    this.modal.show();
  }

  onConfirmed() {
    this.confirmed.emit(this.entityId);
    this.hide();
  }

  hide() {
    this.entityId = undefined;
    this.modal.hide();
  }
}
