import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";
import { TrialService } from "../../../../core/services/trial.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { VisitScheduleViewModel } from "../trial-visit-schedule.model";
import { Currencies } from "../../../../core/constants/currency";
import { KeyVisitPreloadPaymentsRequest, PreloadPaymentType } from "../trial-policy.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectOption } from 'app/core/models/select-option.model';
import { enumToText } from 'app/core/helpers/enum-to-text.function';

@Component({
  selector: 'app-visit-preload-payments',
  templateUrl: './visit-preload-payments.component.html',
  styleUrl: './visit-preload-payments.component.scss'
})
export class VisitPreloadPaymentsComponent implements OnInit, AfterViewInit {
  @ViewChild('currencySelect') currencySelect: DropdownInputComponent;
  @ViewChild('autoPaymentTypeSelect') autoPaymentTypeSelect: DropdownInputComponent;
  @ViewChild('preloadPaymentTypeSelect') preloadPaymentTypeSelect: DropdownInputComponent;

  @Input() trialId: string | undefined;
  @Input() policyId: string | undefined;

  private readonly _trialService = inject(TrialService);
  private readonly _alertService = inject(AlertService);

  visits: VisitScheduleViewModel[] = [];
  visitPreloadPaymentsForm = new FormGroup({
    isBusy: new FormControl<boolean>(false),
    preloadBeforeVisitDays: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(99)]),
    preloadPaymentCurrency: new FormControl(null, Validators.required),
    preloadType: new FormControl(null, Validators.required),
  });

  currencies: { value: string, text: string }[] = [];
  preloadTypes: SelectOption[] = [];

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initiatePreloadTypes();
    this.initiateCurrencies();
    this.loadPreloadPayments();
  }

  initiatePreloadTypes() {
    this.preloadTypes.push({ value: PreloadPaymentType.Visit.toString(), text: enumToText(PreloadPaymentType, PreloadPaymentType.Visit) });
    this.preloadTypes.push({ value: PreloadPaymentType.Day.toString(), text: enumToText(PreloadPaymentType, PreloadPaymentType.Day) });
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  loadPreloadPayments() {
    this._trialService.getKeyVisitsPreloadPayment(this.trialId, this.policyId).subscribe({
      next: response => {
        this.visits = response.visits;
        this.visitPreloadPaymentsForm.get("preloadBeforeVisitDays").setValue(response.preloadBeforeVisitDays)
        this.visitPreloadPaymentsForm.get('preloadPaymentCurrency').setValue(response.preloadPaymentCurrency)
        this.visitPreloadPaymentsForm.get('preloadType').setValue(response.preloadType)
        this.currencySelect.setValue(response.preloadPaymentCurrency);
        this.preloadPaymentTypeSelect.setValue(response.preloadType.toString());
      }
    });
  }

  onKeyVisitChecked(visit: VisitScheduleViewModel, value: any) {
    visit.keyVisit = value.target.checked
  }

  onAmountSet(visit: VisitScheduleViewModel, value: string) {
    visit.amount = +value;
    if (visit.amount === 0) {
      visit.amount = null;
    }
  }

  onCaregiverAmountSet(visit: VisitScheduleViewModel, value: string) {
    visit.caregiverAmount = +value;
    if (visit.caregiverAmount === 0) {
      visit.caregiverAmount = null;
    }
  }

  onSave() {
    this.visitPreloadPaymentsForm.patchValue({ isBusy: true });
    let request = this.visitPreloadPaymentsForm.value as KeyVisitPreloadPaymentsRequest;

    request.keyVisits = this.visits.map(v => ({
      visitTitle: v.title,
      keyVisit: v.keyVisit,
      amount: v.amount,
      caregiverAmount: v.caregiverAmount
    }));

    let isValid = true;
    request.keyVisits.forEach(v => {
      if (v.keyVisit && v.amount === null) {
        isValid = false;
      }
    });

    if (!isValid) {
      this._alertService.showWarningAlert('Please provide patient amount for all key visits.');
      this.visitPreloadPaymentsForm.patchValue({ isBusy: false });
      return;
    }

    this._trialService.updateKeyVisitPreloadPayment(this.trialId, this.policyId, request).subscribe({
      next: () => {
        this.visitPreloadPaymentsForm.patchValue({ isBusy: false });
        this._alertService.showSuccessAlert("Key visits updated successfully.");
      },
      error: error => {
        this.visitPreloadPaymentsForm.patchValue({ isBusy: false });
        this._alertService.showWarningAlert(error.error.title)
        LogHelper.log(error);
      }
    })
  }
}
