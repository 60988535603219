import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { DropdownInputComponent } from '../../../shared/dropdown-input/dropdown-input.component';
import {
  AutosuggestDropdownInputComponent
} from '../../../shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ExpenseClaimDetails } from '../../../core/models/expense-claim-details.model';
import { ExpenseCategoryItem } from '../../../core/models/expense-category-list.model';
import { TemplateService } from '../../../core/services/template.service';
import { TrialService } from '../../../core/services/trial.service';
import { AlertService } from '../../../shared/alert/alert.service';
import { ExpenseService } from '../../../core/services/expense.service';
import { PatientService } from '../../../core/services/patient.service';
import {
  ExpenseClaimPaymentMethod,
  ExpenseClaimPaymentMethodString,
  ExpenseSubCategory,
  UpdateExpenseClaimRequest
} from '../../../core/models/expense.model';
import { LogHelper } from '../../../core/helpers/log.helper';
import { TrialAutocompleteComponent } from '../../../shared/trial-autocomplete/trial-autocomplete.component';
import { TrialAutocomplete } from '../../../shared/trial-autocomplete/trial-autocomplete.model';
import { Currencies } from 'app/core/constants/currency';
import { NumberHelper } from "../../../core/helpers/number.helper";
import { ExchangeRateService } from 'app/core/services/exchange-rate.service';
import { InputCurrencyComponent } from 'app/shared/input-currency/input-currency.component';
import { ExchangeRateRequest } from 'app/core/models/exchange-rate.model';
import { invalidCharactersValidator } from 'app/core/validators/invalid-characters.validator';
import { ModalV2Component } from 'app/shared/modal-v2/modal-v2.component';
import moment from 'moment';
import { TrialDetail } from 'app/core/models/trial-detail.model';

@Component({
  selector: 'app-edit-expense-modal',
  templateUrl: './edit-expense-modal.component.html',
  styleUrls: ['./edit-expense-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditExpenseModalComponent implements OnInit {
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('recalculateAmountBcModal') recalculateAmountBcModal: ModalV2Component;
  @ViewChild('distanceUnitSelect') distanceUnitSelect: DropdownInputComponent;
  @ViewChild('currencySelect') currencySelect: AutosuggestDropdownInputComponent;
  @ViewChild('categorySelect') categorySelect: DropdownInputComponent;
  @ViewChild('visitSelect') visitSelect: DropdownInputComponent;
  @ViewChild('paymentMethodSelect') paymentMethodSelect: DropdownInputComponent;
  @ViewChild('amountQuotedBCControl') amountQuotedBCControl: InputCurrencyComponent;

  @Output() expenseUpdated = new EventEmitter();
  @Output() expenseUpdatedDetail = new EventEmitter<{
    amount: number,
    currency: string,
    categoryName: string,
    subCategory: ExpenseSubCategory,
    distanceAmount: number,
    distanceUnit: string
  }>();

  //FX rate
  transformedFxRateForm: UntypedFormGroup;
  transformedCurrencyProcessing: boolean = false;
  showFxTransformError: boolean = false;
  showTransformedCurrency: boolean = false;
  baseCurrency: string;

  //Expense Claim
  form = new FormGroup({
    trialId: new FormControl('', Validators.required),
    visitId: new FormControl('', Validators.required),
    categoryId: new FormControl('', Validators.required),
    paymentMethod: new FormControl(null, Validators.required),
    subCategoryId: new FormControl(null),
    currency: new FormControl(''),
    amount: new FormControl(null),
    distanceAmount: new FormControl(null),
    distanceUnit: new FormControl(''),
    rejectionReason: new FormControl('', Validators.maxLength(1000)),
    reasonForEdit: new FormControl('', [Validators.required, Validators.maxLength(500), invalidCharactersValidator(['|', '+'])]),
    onHold: new FormControl(false),
    overspendApproved: new FormControl(false),
    notes: new FormControl(),
    claimFormId: new FormControl('', Validators.maxLength(50)),
    claimFormUrl: new FormControl('', Validators.maxLength(200)),
    claimFormDate: new FormControl(null),
    fullyReconciled: new FormControl(false)
  });

  distanceUnitOptions: { value: string, text: string }[] = [];
  currencyOptions: { value: string, text: string }[] = [];
  visitOptions: { value: string, text: string }[] = [];
  categoryOptions: { value: string, text: string }[] = [];
  paymentMethodOptions: { value: string, text: string }[] = [];
  expenseId: string;
  expenseClaimDetails: ExpenseClaimDetails;
  trial: TrialDetail;
  categories: ExpenseCategoryItem[] = [];
  selectedCategory = new ExpenseCategoryItem('', '', '', '', false, null);
  isFormProcessing = false;
  numberHelper = NumberHelper;
  ExpenseSubCategory = ExpenseSubCategory;

  get canTransformRate(): boolean {
    let amount = this.form.get('amount').value;
    let currency = this.form.get('currency').value;
    return this.baseCurrency !== '' && this.baseCurrency !== null && this.baseCurrency !== undefined
      && amount !== '' && amount !== null && amount !== undefined
      && currency !== '' && currency !== null && currency !== undefined;
  }

  constructor(
    private templateService: TemplateService,
    private trialService: TrialService,
    private alertService: AlertService,
    private readonly exchangeRateService: ExchangeRateService,
    private expenseService: ExpenseService,
    private patientService: PatientService) {
  }

  ngOnInit() {
    this.templateService.showHeader();

    // Populate distance units drop down
    this.distanceUnitOptions.push({ value: 'Miles', text: 'Miles' });
    this.distanceUnitOptions.push({ value: 'Kilometers', text: 'Kilometers' });

    // Populate currencies drop down
    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    this.paymentMethodOptions.push({
      value: ExpenseClaimPaymentMethod.ManualBankTransfer.toString(),
      text: ExpenseClaimPaymentMethodString.Values[ExpenseClaimPaymentMethod.ManualBankTransfer]
    });
    this.paymentMethodOptions.push({
      value: ExpenseClaimPaymentMethod.CaxtonCard.toString(),
      text: ExpenseClaimPaymentMethodString.Values[ExpenseClaimPaymentMethod.CaxtonCard]
    });
    this.paymentMethodOptions.push({
      value: ExpenseClaimPaymentMethod.CaxtonAutomatedBankTransfer.toString(),
      text: ExpenseClaimPaymentMethodString.Values[ExpenseClaimPaymentMethod.CaxtonAutomatedBankTransfer]
    });

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({ value: '' }),
      transformedCurrency: new UntypedFormControl({ value: '', disabled: true }),
    });
  }

  resetForm(): void {
    // Using form.reset() breaks the autocomplete
    this.form.patchValue({
      visitId: '',
      categoryId: '',
      subCategoryId: '',
      currency: '',
      amount: '',
      distanceAmount: '',
      distanceUnit: '',
      reasonForEdit: '',
      notes: '',
      claimFormId: '',
      claimFormUrl: '',
      claimFormDate: ''
    });

    this.visitOptions = [];
    this.categoryOptions = [];

    // Reset inner components
    this.trialAutocomplete.reset();
    this.distanceUnitSelect.reset();
    this.currencySelect.reset();
    this.categorySelect.reset();
    this.visitSelect.reset();
    this.paymentMethodSelect.reset();

    this.form.get('reasonForEdit').reset();
    this.form.get('notes').reset();
    this.form.get('claimFormId').reset();
  }

  show(expenseId: string) {
    this.resetForm();
    this.expenseId = expenseId;
    this.loadExpenseClaim();
    this.modal.show();
    this.form.updateValueAndValidity();
  }

  hide(): void {
    this.modal.hide();
  }

  setSelectedCategory(expenseCategoryId: string) {
    for (const category of this.categories) {
      if (category.id === expenseCategoryId) {
        this.selectedCategory = category;
        console.log(category)

        if (category.type === 'Distance') {
          this.form.get('currency').setValidators(null);
          this.form.get('amount').setValidators(null);
          this.form.get('distanceAmount').setValidators(this.amountValidator);
          this.form.get('distanceUnit').setValidators([Validators.required]);
        }

        if (category.type === 'Value') {
          this.form.get('currency').setValidators([Validators.required]);
          this.form.get('amount').setValidators(this.amountValidator);
          this.form.get('distanceAmount').setValidators(null);
          this.form.get('distanceUnit').setValidators(null);
        }

        // Update validation on the form depending on whether 'other' is selected as a category
        if (category.internalType.toLowerCase() === 'other') {
          this.form.patchValue({ subCategoryId: this.expenseClaimDetails.subCategory });
          this.form.get('subCategoryId').setValidators([Validators.required]);
          this.form.get('subCategoryId').setErrors({ required: true });
        } else {
          this.form.get('subCategoryId').setValidators(null);
          this.form.get('subCategoryId').setErrors(null);
          this.form.patchValue({ subCategoryId: null });
        }

        this.form.get('subCategoryId').updateValueAndValidity();
      }
    }
  }

  amountValidator(control: AbstractControl): { [key: string]: any } | null {
    let value = control.value;

    if (value === undefined || value === null || value === '') {
      return { required: true };
    }

    let amount = +control.value;

    if (amount > 0) {
      return null;
    }

    return { invalid: true };
  }

  loadExpenseClaim() {
    this.expenseService.retrieveExpenseClaim(this.expenseId).subscribe(expenseClaimDetails => {
      this.expenseClaimDetails = expenseClaimDetails;
      this.baseCurrency = expenseClaimDetails.trialBaseCurrency;

      this.loadVisits(expenseClaimDetails.trialId).then(() => {
        this.form.patchValue({
          visitId: expenseClaimDetails.visitId
        });
        this.visitSelect.setValue(expenseClaimDetails.visitId);
      });

      this.loadCategories(expenseClaimDetails.patientId, expenseClaimDetails.trialId).then(() => {
        this.form.patchValue({
          categoryId: expenseClaimDetails.expenseCategoryId
        });

        this.categorySelect.setValue(expenseClaimDetails.expenseCategoryId);
        this.setSelectedCategory(expenseClaimDetails.expenseCategoryId);

        this.transformedFxRateForm.patchValue({ transformedAmount: expenseClaimDetails.amountBC });
        if (expenseClaimDetails.trialBaseCurrency !== null && expenseClaimDetails.trialBaseCurrency !== undefined) {
          this.transformedFxRateForm.patchValue({ transformedCurrency: expenseClaimDetails.trialBaseCurrency });
        }
        this.amountQuotedBCControl.setCurrency(expenseClaimDetails.trialBaseCurrency);
        this.showTransformedCurrency = true;

        this.form.patchValue({
          trialId: expenseClaimDetails.trialId,
          currency: expenseClaimDetails.currency,
          amount: expenseClaimDetails.amount !== null ? expenseClaimDetails.amount.toString() : '',
          distanceAmount: expenseClaimDetails.distanceAmount,
          distanceUnit: this.expenseClaimDetails.distanceUnit,
          rejectionReason: expenseClaimDetails.rejectionReason,
          subCategoryId: expenseClaimDetails.subCategory,
          paymentMethod: expenseClaimDetails.paymentMethod,
          onHold: expenseClaimDetails.onHold,
          overspendApproved: expenseClaimDetails.overspendApproved,
          notes: expenseClaimDetails.notes,
          fullyReconciled: expenseClaimDetails.fullyReconciled,
          claimFormId: expenseClaimDetails.claimFormId,
          claimFormUrl: expenseClaimDetails.claimFormUrl,
          claimFormDate: expenseClaimDetails.claimFormDate,
        });

        this.trialAutocomplete.setPatientId(expenseClaimDetails.patientId);

        setTimeout(() => {
          if (expenseClaimDetails.distanceUnit) {
            const distanceIsInKilometers = expenseClaimDetails.distanceUnit?.toLowerCase() === 'kilometers' || expenseClaimDetails.distanceUnit?.toLowerCase() === 'km';
            this.distanceUnitSelect.setValue(distanceIsInKilometers ? 'Kilometers' : 'Miles');
          }

          this.paymentMethodSelect.setValue(expenseClaimDetails.paymentMethod !== null ? expenseClaimDetails.paymentMethod.toString() : '0');
          this.currencySelect.setSelectedOption(expenseClaimDetails.currency);
          this.loadTrial(expenseClaimDetails.trialId);

          this.form.patchValue({ subCategoryId: expenseClaimDetails.subCategory });
        }, 50);
      });
    });
  }

  /**
   * Called when user wants to see amount in transformed FX currency.
   */
  onShowTransformedFxRate() {
    let amount = this.form.get('amount').value;
    let currency = this.form.get('currency').value;
    let toCurrency = this.baseCurrency;

    if (!this.canTransformRate) {
      this.showFxTransformError = true;
      return;
    }
    this.transformedCurrencyProcessing = true;

    let request = {
      fromCurrencyCode: currency,
      toCurrencyCode: toCurrency,
      quotedAmount: amount
    } as ExchangeRateRequest

    this.exchangeRateService.getTransformedFxRate(request).subscribe({
      next: result => {
        this.transformedCurrencyProcessing = false;
        if (result.result !== null) {
          this.transformedFxRateForm.patchValue({ transformedAmount: result.result / 100 });

          if (this.canTransformRate) {
            this.showTransformedCurrency = true;
          }
        }
        else if (result.destinationCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for the base currency set on connected trial (${toCurrency}).`);
        }
        else if (result.sourceCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for this currency (${currency})`);
        }
      },
      error: error => {
        this.transformedCurrencyProcessing = false;
        this.showFxTransformError = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to retrieve transformed FX rate!');
      }
    });
  }

  onTrialChanged(option: TrialAutocomplete): void {
    this.form.patchValue({ trialId: option.id });
    this.loadVisits(option.id).then(() => {
      this.form.patchValue({ visitId: '', categoryId: '', subCategoryId: '' });
    });

    // Load expense categories for the new trial
    this.updateCategoriesForPatientTrial(this.expenseClaimDetails.patientId, option.id);
  }

  /**
   * Loads the trial associated with the expense claim, and also listens for changes to the trial and starts
   * the process of updating visits and categories
   * @param trialId
   */
  loadTrial(trialId: string) {
    this.trialService.retrieveTrial(trialId).subscribe({
      next: trial => {
        this.trial = trial;
        this.trialAutocomplete.setInitialValue(trial.id, trial.code);
      }
    });
  }

  // Load and populate visits select
  loadVisits(trialId: string) {
    this.visitSelect.reset();
    this.visitOptions = [];
    return new Promise((resolve) => {
      this.patientService.retrievePatientVisitsForTrial(this.expenseClaimDetails.patientId, trialId).subscribe(visits => {
        for (const visit of visits) {
          this.visitOptions.push({ value: visit.id, text: visit.title });
        }
        return resolve(null);
      });
    });
  }

  // Load and populate categories select
  loadCategories(patientId: string, trialId: string) {
    this.categoryOptions = [];
    return new Promise((resolve) => {
      this.patientService.getTrialForPatient(patientId, trialId).subscribe({
        next: (trial) => {
          this.categories = trial.categories;

          this.categoryOptions = [];
          for (const category of trial.categories) {
            this.categoryOptions.push({ value: category.id, text: category.name });
          }

          if (trial.categories.length === 0) {
            this.categoryOptions.push({ value: '', text: 'No categories' });
          }

          this.categorySelect.options = this.categoryOptions;
          this.categorySelect.setValue(this.expenseClaimDetails.expenseCategoryId);

          return resolve(null);
        },
        error: (error) => {
          LogHelper.log(error);
        }
      });
    });
  }

  /**
   * Called when the user has selected a different trial to that originally assigned to the expense.
   * It will update the expense categories to match the trial
   * @param patientId
   * @param trialId
   * @private
   */
  private updateCategoriesForPatientTrial(patientId: string, trialId: string) {
    this.categorySelect.reset();
    this.patientService.getTrialForPatient(patientId, trialId).subscribe({
      next: trial => {
        this.categories = trial.categories;

        this.categoryOptions = [];
        for (const category of trial.categories) {
          this.categoryOptions.push({ value: category.id, text: category.name });
        }

        if (trial.categories.length === 0) {
          this.categoryOptions.push({ value: '', text: 'No categories' });
        }
      }
    });
  }

  onSelectChanged(name: string, value: string) {
    // this.form.patchValue({ name: value });

    if (name === 'categoryId') {
      this.setSelectedCategory(value);
    }
  }

  onCancelEditing() {
    this.resetFxFields();
    this.hide();
  }

  resetFxFields() {
    this.transformedCurrencyProcessing = false;
    this.showFxTransformError = false;
    this.showTransformedCurrency = false;

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({ value: '' }),
      transformedCurrency: new UntypedFormControl({ value: '', disabled: true }),
    });
  }

  disableScrollWheel(event: WheelEvent): void {
    event.preventDefault();
  }

  onFormSubmit(recalculateAmountBc?: boolean) {
    if (!this.form.valid) {
      return;
    }

    let updateRequest: UpdateExpenseClaimRequest = {
      trialId: this.form.get('trialId').value,
      visitId: this.visitSelect.selectedValue,
      expenseCategoryId: this.categorySelect.selectedValue,
      currency: this.form.get('currency').value,
      amount: this.convertAmount(this.form.get('amount').value),
      amountBC: this.convertAmount(this.transformedFxRateForm.get('transformedAmount').value),
      distanceUnit: this.distanceUnitSelect.selectedValue,
      distanceAmount: this.form.get('distanceAmount').value > 0 ? Math.round(this.form.get('distanceAmount').value) : null,
      rejectionReason: this.form.get('rejectionReason').value,
      reasonForEdit: this.form.get('reasonForEdit').value,
      subCategory: +this.form.get('subCategoryId').value,
      onHold: this.form.get('onHold').value,
      paymentMethod: +this.paymentMethodSelect.selectedValue,
      overspendApproved: this.form.get('overspendApproved').value,
      notes: this.form.get('notes').value,
      claimFormId: this.form.get('claimFormId').value,
      claimFormUrl: this.form.get('claimFormUrl').value,
      claimFormDate: this.form.get('claimFormDate').value !== null ? moment(this.form.get('claimFormDate').value).format('DD/MMM/yyyy') : null,
      recalculateAmountBc: recalculateAmountBc,
      fullyReconciled: this.form.get('fullyReconciled').value
    };

    this.isFormProcessing = true;

    this.expenseService.updateExpenseClaim(this.expenseClaimDetails.id, updateRequest).subscribe({
      next: result => {
        this.isFormProcessing = false;

        if (!result.saved) {
          this.recalculateAmountBcModal.show();
          return;
        }

        this.alertService.showSuccessAlert('Claim Updated Successfully.');

        this.hide();
        this.recalculateAmountBcModal.hide();
        this.expenseUpdated.emit();
        this.expenseUpdatedDetail.emit({
          amount: this.form.get('amount').value,
          currency: this.form.get('currency').value,
          categoryName: this.selectedCategory.name,
          distanceAmount: this.form.get('distanceAmount').value > 0 ? Math.round(this.form.get('distanceAmount').value) : null,
          distanceUnit: this.distanceUnitSelect.selectedValue,
          subCategory: this.form.get('subCategoryId').value
        });
      }, error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert(error.error?.title ? error.error?.title.split('|')[0] : 'There was a problem updating the claim!');
        this.isFormProcessing = false;
      }
    });
  }

  convertAmount(amount: any): number {
    const amountInt = +amount > 0 ? +amount * 100 : 0;
    return amountInt > 0 ? Math.round(amountInt) : null;
  }
}
