import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TrialService } from "../../../../core/services/trial.service";
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";
import { VisitScheduleViewModel } from "../trial-visit-schedule.model";
import { AutoPaymentType, AutoPaymentTypeString, KeyVisitAutoPaymentsRequest } from "../trial-policy.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AlertService } from "../../../../shared/alert/alert.service";
import { Currencies } from "../../../../core/constants/currency";

@Component({
  selector: 'app-visit-auto-payments',
  templateUrl: './visit-auto-payments.component.html',
  styleUrl: './visit-auto-payments.component.scss'
})
export class VisitAutoPaymentsComponent implements OnInit, AfterViewInit {
  @ViewChild('currencySelect') currencySelect: DropdownInputComponent;
  @ViewChild('autoPaymentTypeSelect') autoPaymentTypeSelect: DropdownInputComponent;

  @Input() trialId: string | undefined;
  @Input() policyId: string | undefined;

  private readonly _trialService = inject(TrialService);
  private readonly _alertService = inject(AlertService);

  visits: VisitScheduleViewModel[] = [];
  visitAutoPaymentsForm: UntypedFormGroup;
  autoPaymentTypes: { value: string, text: string }[] = [];
  currencies: { value: string, text: string }[] = [];

  ngOnInit() {
    this.visitAutoPaymentsForm = new UntypedFormGroup({
      isBusy: new FormControl<boolean>(false),
      autoPaymentType: new UntypedFormControl(null, Validators.required),
      assumeVisitAttendance: new UntypedFormControl(false),
      assumeVisitAttendanceAfterDays: new UntypedFormControl(null, [Validators.min(1), Validators.max(99)]),
      autoPaymentCurrency: new UntypedFormControl(null, Validators.required),
    });
  }

  ngAfterViewInit() {
    this.subscribeAutoPaymentFormChanges();
    this.initiateDefaultPaymentMethods();
    this.initiateCurrencies();
    this.loadAutoPayments();
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  initiateDefaultPaymentMethods() {
    this.autoPaymentTypes.push({ value: AutoPaymentType.AutoStipend.toString(), text: AutoPaymentTypeString.Values[AutoPaymentType.AutoStipend] });
    this.autoPaymentTypes.push({ value: AutoPaymentType.AutoFixedFee.toString(), text: AutoPaymentTypeString.Values[AutoPaymentType.AutoFixedFee] });
  }

  subscribeAutoPaymentFormChanges() {
    this.visitAutoPaymentsForm.get("assumeVisitAttendance").valueChanges.subscribe(value => {
      if (value) {
        this.visitAutoPaymentsForm.get("assumeVisitAttendanceAfterDays").setValidators(Validators.required);
        this.visitAutoPaymentsForm.get('assumeVisitAttendanceAfterDays').enable();
      } else {
        this.visitAutoPaymentsForm.get("assumeVisitAttendanceAfterDays").removeValidators(Validators.required);
        this.visitAutoPaymentsForm.get('assumeVisitAttendanceAfterDays').disable();
      }

      this.visitAutoPaymentsForm.get("assumeVisitAttendanceAfterDays").updateValueAndValidity();
    });
  }

  loadAutoPayments() {
    this._trialService.getKeyVisitsAutoPayment(this.trialId, this.policyId).subscribe({
      next: response => {
        this.visits = response.visits;
        this.visitAutoPaymentsForm.get("autoPaymentType").setValue(response.autoPaymentType)
        this.autoPaymentTypeSelect.setValue(response.autoPaymentType?.toString());
        this.visitAutoPaymentsForm.get("assumeVisitAttendance").setValue(response.assumeVisitAttendance)
        this.visitAutoPaymentsForm.get("assumeVisitAttendanceAfterDays").setValue(response.assumeVisitAttendanceAfterDays)
        this.currencySelect.setValue(response.autoPaymentCurrency);

        if (response.assumeVisitAttendance) {
          this.visitAutoPaymentsForm.get('assumeVisitAttendanceAfterDays').enable();
        } else {
          this.visitAutoPaymentsForm.get('assumeVisitAttendanceAfterDays').disable();
        }
      }
    });
  }

  onKeyVisitChecked(visit: VisitScheduleViewModel, value: any) {
    visit.keyVisit = value.target.checked
  }

  onAmountSet(visit: VisitScheduleViewModel, value: string) {
    visit.amount = +value;
    if (visit.amount === 0) {
      visit.amount = null;
    }
  }

  onCaregiverAmountSet(visit: VisitScheduleViewModel, value: string) {
    visit.caregiverAmount = +value;
    if (visit.caregiverAmount === 0) {
      visit.caregiverAmount = null;
    }
  }

  onSave() {
    this.visitAutoPaymentsForm.patchValue({isBusy: true});
    let request = this.visitAutoPaymentsForm.value as KeyVisitAutoPaymentsRequest;

    request.keyVisits = this.visits.map(v => ({
      visitTitle: v.title,
      keyVisit: v.keyVisit,
      amount: v.amount,
      caregiverAmount: v.caregiverAmount
    }));

    let isValid = true;
    request.keyVisits.forEach(v => {
      if (v.keyVisit && v.amount === null) {
        isValid = false;
      }
    });

    if (!isValid) {
      this.visitAutoPaymentsForm.patchValue({isBusy: false});
      this._alertService.showWarningAlert('Please provide patient amount for all key visits.');
      return;
    }

    this._trialService.updateKeyVisitAutoPayment(this.trialId, this.policyId, request).subscribe({
      next: () => {
        this.visitAutoPaymentsForm.patchValue({isBusy: false});
        this._alertService.showSuccessAlert("Key visits updated successfully.");
      },
      error: error => {
        this.visitAutoPaymentsForm.patchValue({isBusy: false});
        this._alertService.showWarningAlert(error.error.title)
        LogHelper.log(error);
      }
    })
  }
}
