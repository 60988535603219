import { Permissions } from './../../../../core/constants/permissions';
import { AuthService } from './../../../../core/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseClaimPaymentMethod, ExpenseClaimState, ExpenseClaimViewModel, ExpenseCurrencyGroup } from 'app/core/models/expense.model';

@Component({
  selector: 'app-expense-caxton-payment-actions',
  templateUrl: './expense-caxton-payment-actions.component.html',
  styleUrls: ['./expense-caxton-payment-actions.component.scss']
})
export class ExpenseCaxtonPaymentActionsComponent implements OnInit {
  @Input() expenses: ExpenseCurrencyGroup;
  @Input() selectedTab: ExpenseClaimState;
  @Input() processingRequest: boolean;
  @Input() dataLoaded: boolean;
  Permissions = Permissions;
  ExpenseClaimPaymentMethod = ExpenseClaimPaymentMethod;
  ExpenseClaimState = ExpenseClaimState;

  @Output() caxtonFailed: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() sendToFinance: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() payByBank: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() payByCard: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() unloadFromCard: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() investigation: EventEmitter<ExpenseClaimViewModel[]> = new EventEmitter<ExpenseClaimViewModel[]>();
  @Output() transfer: EventEmitter<ExpenseCurrencyGroup> = new EventEmitter<ExpenseCurrencyGroup>();

  constructor(public authService: AuthService) { }

  ngOnInit(): void { }
}
