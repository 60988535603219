<div class="table-inline-cell-amount-currency" [class.editing]="editing" [class.editable]="editable">
  <div class="amount-currency-readonly" *ngIf="!editable">
    <div class="amount-readonly">
      {{ renderAmount(+parentForm.get(this.amountControlName).value) }}
    </div>

    <div class="currency-readonly">
      <div class="inner">
        {{ parentForm.get(this.currencyControlName).value }}
      </div>
    </div>
  </div>

  <span *ngIf="editable" class="editing" [formGroup]="parentForm">
    <app-input-currency (valueChanged)="onBlur()" #currencySelect [parentForm]="parentForm"
      [currencyControlName]="currencyControlName" [amountControlName]="amountControlName"
      placeholder=""></app-input-currency>
  </span>
</div>