export class FilterToggleOption {
  public id: string;
  public text: string;
  public checked: boolean = false;
  public visible: boolean = true;

  constructor(id: string, text: string, checked: boolean = false, visible: boolean = true) {
    this.id = id;
    this.text = text;
    this.checked = checked;
    this.visible = visible;
  }
}
