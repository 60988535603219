import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import moment from "moment";
import { Observable } from 'rxjs';
import { ExpenseClaimsResponsePage } from "../models/search-page.model";
import {
  AddExpenseClaimNoteRequest,
  AppendFileToExpenseClaimRequest,
  ApproveExpenseClaimResult,
  CloseExpenseClaimInvestigationRequest,
  CreateOverBudgetRequestByCategoriesRequest,
  CreateOverBudgetRequestByExpenseClaimRequest,
  ExpenseApprovalSuggestionResult,
  ExpenseClaimViewModel,
  ExpensePatientGroup,
  GetExpenseClaimsRequest,
  MoveToCaxtonFailedRequest,
  NotOnCaxtonRetryRequest,
  OverBudgetRequestDetailsViewModel,
  PayByCaxtonBankRequest,
  PayByCaxtonCardRequest,
  RemoveFileFromExpenseClaimRequest,
  SendCaxtonExpenseClaimsToFinanceRequest,
  SetCaxtonTransferIdRequest,
  StartExpenseClaimInvestigationRequest,
  UnloadFromCaxtonCardRequest,
  UpdateExpenseClaimAmountBcRequest,
  UpdateExpenseClaimAmountRequest,
  UpdateExpenseClaimCurrencyRequest,
  UpdateExpenseClaimRequest,
  UpdateExpenseClaimResult,
  UploadResponse
} from "../models/expense.model";
import {
  ExpenseCategoryItem,
  ExpenseCategoryList,
  ExpenseCategoryTranslation
} from "../models/expense-category-list.model";
import { ExpenseClaimDetails } from "../models/expense-claim-details.model";
import { environment } from "../../../environments/environment";
import { IExpenseCreate } from './interfaces/expense-create.interface';
import { ExpenseCheck } from '../models/expense-check.model';
import { UpdateExpenseClaimCheck } from './interfaces/update-expense-claim-check.interface';
import { ReimbursementAndTravelAmountUpdateResult } from '../models/reimbursement-search.model';
import { ExpenseImage } from '../models/expense-image.model';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  filterKeywords: string = null;
  filterPageNumber: number = 1;

  constructor(private http: HttpClient) {
  }

  /**
   * Updates expense check information
   * @param expenseClaimId
   * @param dto
   */
  updateExpenseCheck(expenseClaimId: string, dto: UpdateExpenseClaimCheck) {
    return this.http.put(environment.apiUrl + '/expense/' + expenseClaimId + '/check', dto);
  }

  /**
   * Retrieves expense information used for the check expense view
   * @param expenseClaimId
   */
  getExpenseCheck(expenseClaimId: string) {
    return this.http.get<ExpenseCheck>(environment.apiUrl + '/expense/' + expenseClaimId + '/check');
  }

  /**
   * Returns a list of expense categories for a given expense claim. It returns only those categories that are
   * valid based on the trial, patient site and patient culture recorded on the expense claim
   * @param expenseClaimId
   */
  getCategoriesForClaim(expenseClaimId: string) {
    return this.http.get<ExpenseCategoryItem[]>(environment.apiUrl + '/expense/' + expenseClaimId + '/categories');
  }

  /**
   * Retrieve an expense category
   * @param id
   */
  retrieveExpenseCategory(id: string) {
    return this.http.get<ExpenseCategoryItem>(environment.apiUrl + '/expense/category/' + id);
  }

  /**
   * Deletes an expense category
   * @param id
   */
  deleteExpenseCategory(id: string) {
    return this.http.delete(environment.apiUrl + '/expense/category/' + id);
  }

  /**
   * Updates an existing expense category and associated translations
   * @param id
   * @param name
   * @param type
   * @param translations
   */
  updateExpenseCategory(id: string, name: string, type: number, translations: ExpenseCategoryTranslation[]) {
    let body = {
      name: name,
      type: type,
      translations: translations
    };

    return this.http.post(environment.apiUrl + '/expense/category/' + id, body);
  }

  /**
   * Creates a new expense category and associated translations
   * @param name
   * @param type
   * @param translations
   */
  createExpenseCategory(name: string, type: number, translations: ExpenseCategoryTranslation[]) {
    let body = {
      name: name,
      type: type,
      translations: translations
    };

    return this.http.post(environment.apiUrl + '/expense/category', body);
  }

  exportExpenses(dateFrom: string, dateTo: string, filterBy: string, trialId: string) {
    let body = {
      dateFrom: dateFrom ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateTo ? moment(dateTo + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      filterBy: filterBy,
      trialId: trialId
    }

    return this.http.post(environment.apiUrl + '/expense/export', body);
  }

  getExpenseClaims(expenseClaimsRequest: GetExpenseClaimsRequest): Observable<ExpenseClaimsResponsePage<ExpenseClaimViewModel>> {
    let params = new HttpParams()
      .set('page', expenseClaimsRequest.page)
      .set('state', expenseClaimsRequest.state)
      .set('onHold', expenseClaimsRequest.onHold)
      .set('preLoads', expenseClaimsRequest.preloads)
      .set('orderBy', expenseClaimsRequest.orderBy)
      .set('investigations', expenseClaimsRequest.investigations);

    if (expenseClaimsRequest.keywords != null) {
      params = params.set('keywords', expenseClaimsRequest.keywords);
    }

    return this.http.get<ExpenseClaimsResponsePage<ExpenseClaimViewModel>>(`${environment.apiUrl}/expense`, { params: params }).pipe(map(rsp => {
      rsp.results = rsp.results.map(x => {
        return ExpenseClaimViewModel.fromObject(x);
      });

      return rsp;
    }));
  }

  getCaxtonExpenseClaims(expenseClaimsRequest: GetExpenseClaimsRequest): Observable<ExpenseClaimsResponsePage<ExpensePatientGroup>> {
    let params = new HttpParams()
      .set('state', expenseClaimsRequest.state)
      .set('page', expenseClaimsRequest.page)
      .set('onHold', expenseClaimsRequest.onHold)
      .set('preloads', expenseClaimsRequest.preloads)
      .set('investigations', expenseClaimsRequest.investigations)
      .set('orderBy', expenseClaimsRequest.orderBy);

    if (expenseClaimsRequest.keywords != null) {
      params = params.set('keywords', expenseClaimsRequest.keywords);
    }

    return this.http.get<ExpenseClaimsResponsePage<ExpensePatientGroup>>(`${environment.apiUrl}/expense/caxton-claims`, { params: params });
  }

  sendCaxtonExpenseClaimsToFinance(request: SendCaxtonExpenseClaimsToFinanceRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/send-to-finance`, request);
  }

  payByCaxtonBank(request: PayByCaxtonBankRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/expense/pay-by-caxton-bank`, request);
  }

  payByCaxtonCard(request: PayByCaxtonCardRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/expense/pay-by-caxton-card`, request);
  }

  unloadFromCaxtonCard(request: UnloadFromCaxtonCardRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/expense/unload-from-caxton-card`, request);
  }

  setCaxtonTransferId(request: SetCaxtonTransferIdRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/set-caxton-transfer-id`, request);
  }

  notOnCaxtonRetry(request: NotOnCaxtonRetryRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/not-on-caxton-retry`, request);
  }

  moveToCaxtonFailed(request: MoveToCaxtonFailedRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/caxton-failed`, request);
  }

  /**
   * Uploads an expense attachment/image
   * @param data
   */
  upload(data: Blob): Observable<UploadResponse> {
    return this.http.post<UploadResponse>(environment.expenseClaimStorageUrl, data);
  }

  appendFileToExpenseClaim(request: AppendFileToExpenseClaimRequest): Observable<ExpenseImage> {
    return this.http.put<ExpenseImage>(`${environment.apiUrl}/expense/append-file`, request);
  }

  removeFileFromExpenseClaim(request: RemoveFileFromExpenseClaimRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/remove-file`, request);
  }

  /**
   * Creates an expense claim
   * @param patientId
   * @param dto
   */
  createExpenseClaim(patientId: string, dto: IExpenseCreate) {
    const amountInt = +dto.amount > 0 ? +dto.amount * 100 : 0;
    const amountBcInt = +dto.amountBC > 0 ? +dto.amountBC * 100 : 0;
    const body = {
      patientId: patientId,
      visitId: dto.visitId,
      expenseCategoryId: dto.expenseCategoryId,
      currency: dto.currency,
      amount: amountInt > 0 ? Math.round(amountInt) : null,
      amountBC: amountBcInt > 0 ? Math.round(amountBcInt) : null,
      distanceUnit: dto.distanceUnit,
      distanceAmount: dto.distanceAmount > 0 ? Math.round(dto.distanceAmount) : null,
      imageFilenames: dto.imageFilenames,
      notes: dto.notes,
      subCategory: dto.subCategory,
      claimFormId: dto.claimFormId,
      claimFormUrl: dto.claimFormUrl,
      claimFormDate: dto.claimFormDate,
      fullyReconciled: dto.fullyReconciled ? dto.fullyReconciled : false,
      onHold: dto.onHold,
      overspendApproved: dto.overspendApproved,
      paymentMethod: dto.paymentMethod
    };

    return this.http.post(environment.apiUrl + '/expense', body);
  }

  updateExpenseClaim(expenseClaimId: string, request: UpdateExpenseClaimRequest): Observable<UpdateExpenseClaimResult> {
    return this.http.put<UpdateExpenseClaimResult>(`${environment.apiUrl}/expense/${expenseClaimId}`, request);
  }

  closeInvestigation(request: CloseExpenseClaimInvestigationRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/close-investigation`, request);
  }

  startInvestigation(request: StartExpenseClaimInvestigationRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/expense/start-investigation`, request);
  }

  /**
   * Retrieve details of an individual expense claim
   * @param expenseClaimId
   */
  retrieveExpenseClaim(expenseClaimId: string) {
    return this.http.get<ExpenseClaimDetails>(environment.apiUrl + '/expense/' + expenseClaimId).pipe(map(rsp => {
      return ExpenseClaimDetails.fromObject(rsp);
    }));
  }

  /**
   * Marks an expense claim as paid
   * @param expenseClaimId
   */
  markExpenseClaimAsPaid(expenseClaimId: string) {
    return this.http.put(environment.apiUrl + '/expense/' + expenseClaimId + '/paid', {});
  }

  revertExpenseClaimToPending(expenseClaimId: string) {
    return this.http.put(environment.apiUrl + '/expense/' + expenseClaimId + '/revert-to-pending', {});
  }

  /**
   * Approve an expense claim
   * @param expenseClaimId
   * @param currency
   * @param amount
   */
  approveExpenseClaim(expenseClaimId: string, manualFinanceReason: string, currency: string, amount: number): Observable<ApproveExpenseClaimResult> {
    const amountInt = +amount > 0 ? Math.round(+amount * 100) : 0;
    const body = {
      currency: currency,
      amount: amountInt,
      manualFinanceReason: manualFinanceReason
    }

    return this.http.put<ApproveExpenseClaimResult>(environment.apiUrl + '/expense/' + expenseClaimId + "/approve", body);
  }

  /**
   * Reject an expense claim
   * @param expenseClaimId
   * @param reason
   */
  rejectExpenseClaim(expenseClaimId: string, reason: string) {
    return this.http.put(environment.apiUrl + '/expense/' + expenseClaimId + "/reject", { reason: reason });
  }

  /**
   * Retrieve a list of expense categories
   * @param page page number
   * @param trialId trial Id
   * @param keywords keywords
   * @param incDeleted includeDeleted expense categories?
   * @param pageSize returns per page
   */
  retrieveExpenseCategories(page, trialId: string, keywords: string, incDeleted: boolean, pageSize: number) {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('incDeleted', incDeleted ? 'true' : 'false');

    if (trialId !== null && trialId !== '') {
      params = params.set('trialId', trialId);
    }

    if (keywords !== null && keywords !== '') {
      params = params.set('keywords', keywords);
    }

    return this.http.get<ExpenseCategoryList>(environment.apiUrl + '/expense/category', { params: params });
  }

  addNoteToExpenseClaim(request: AddExpenseClaimNoteRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/expense/add-note`, request);
  }

  suggestApproval(expenseClaimId: string): Observable<ExpenseApprovalSuggestionResult> {
    return this.http.put<ExpenseApprovalSuggestionResult>(`${environment.apiUrl}/expense/suggest-approval/${expenseClaimId}`, null);
  }

  getOverBudgetRequestDetailsByExpenseClaim(expenseClaimId: string): Observable<OverBudgetRequestDetailsViewModel> {
    return this.http.get<OverBudgetRequestDetailsViewModel>(`${environment.apiUrl}/expense/over-budget-request/${expenseClaimId}`);
  }

  getOverBudgetRequestDetailsByCategories(categories: string[], visitId: string): Observable<OverBudgetRequestDetailsViewModel> {
    let url = `${environment.apiUrl}/expense/over-budget-request?visitId=${visitId}&categories=${categories.join('&categories=')}`;

    return this.http.get<OverBudgetRequestDetailsViewModel>(url);
  }

  createOverBudgetRequestByExpenseClaim(request: CreateOverBudgetRequestByExpenseClaimRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/expense/over-budget-request-by-expense-claim`, request);
  }

  createOverBudgetRequestByCategories(request: CreateOverBudgetRequestByCategoriesRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/expense/over-budget-request-by-categories`, request);
  }

  updateExpenseClaimAmount(request: UpdateExpenseClaimAmountRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/expense/amount', request);
  }

  updateExpenseClaimAmountBc(request: UpdateExpenseClaimAmountBcRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/expense/amount-bc', request);
  }

  updateExpenseClaimCurrency(request: UpdateExpenseClaimCurrencyRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/expense/currency', request);
  }
}
