export class TimezonesGMT {
  public static getValuesForList() {
    const timezones = this.all();
    const options: { value: string, text: string }[] = [];

    timezones.sort((a, b) => a.offset - b.offset);

    timezones.forEach(t => {
      options.push({ value: t.zone, text: t.gmt + ' ' + t.name});
    });


    return options;
  }

  public static all() {
    return [
      {
        "offset": -11,
        "zone": "Pacific/Midway",
        "gmt": "(GMT-11:00)",
        "name": "Midway Island"
      },
      {
        "offset": -11,
        "zone": "US/Samoa",
        "gmt": "(GMT-11:00)",
        "name": "Samoa"
      },
      {
        "offset": -10,
        "zone": "US/Hawaii",
        "gmt": "(GMT-10:00)",
        "name": "Hawaii"
      },
      {
        "offset": -9,
        "zone": "US/Alaska",
        "gmt": "(GMT-09:00)",
        "name": "Alaska"
      },
      {
        "offset": -8,
        "zone": "US/Pacific",
        "gmt": "(GMT-08:00)",
        "name": "Pacific Time (US & Canada)"
      },
      {
        "offset": -8,
        "zone": "America/Tijuana",
        "gmt": "(GMT-08:00)",
        "name": "Tijuana"
      },
      {
        "offset": -7,
        "zone": "US/Arizona",
        "gmt": "(GMT-07:00)",
        "name": "Arizona"
      },
      {
        "offset": -7,
        "zone": "US/Mountain",
        "gmt": "(GMT-07:00)",
        "name": "Mountain Time (US & Canada)"
      },
      {
        "offset": -7,
        "zone": "America/Chihuahua",
        "gmt": "(GMT-07:00)",
        "name": "Chihuahua"
      },
      {
        "offset": -7,
        "zone": "America/Mazatlan",
        "gmt": "(GMT-07:00)",
        "name": "Mazatlan"
      },
      {
        "offset": -6,
        "zone": "America/Mexico_City",
        "gmt": "(GMT-06:00)",
        "name": "Mexico City"
      },
      {
        "offset": -6,
        "zone": "America/Monterrey",
        "gmt": "(GMT-06:00)",
        "name": "Monterrey"
      },
      {
        "offset": -6,
        "zone": "Canada/Saskatchewan",
        "gmt": "(GMT-06:00)",
        "name": "Saskatchewan"
      },
      {
        "offset": -6,
        "zone": "US/Central",
        "gmt": "(GMT-06:00)",
        "name": "Central Time (US & Canada)"
      },
      {
        "offset": -5,
        "zone": "US/Eastern",
        "gmt": "(GMT-05:00)",
        "name": "Eastern Time (US & Canada)"
      },
      {
        "offset": -5,
        "zone": "US/East-Indiana",
        "gmt": "(GMT-05:00)",
        "name": "Indiana (East)"
      },
      {
        "offset": -5,
        "zone": "America/Bogota",
        "gmt": "(GMT-05:00)",
        "name": "Bogota"
      },
      {
        "offset": -5,
        "zone": "America/Lima",
        "gmt": "(GMT-05:00)",
        "name": "Lima"
      },
      {
        "offset": -4.5,
        "zone": "America/Caracas",
        "gmt": "(GMT-04:30)",
        "name": "Caracas"
      },
      {
        "offset": -4,
        "zone": "Canada/Atlantic",
        "gmt": "(GMT-04:00)",
        "name": "Atlantic Time (Canada)"
      },
      {
        "offset": -4,
        "zone": "America/La_Paz",
        "gmt": "(GMT-04:00)",
        "name": "La_Paz"
      },
      {
        "offset": -4,
        "zone": "America/Santiago",
        "gmt": "(GMT-04:00)",
        "name": "Santiago"
      },
      {
        "offset": -3.5,
        "zone": "Canada/Newfoundland",
        "gmt": "(GMT-03:30)",
        "name": "Newfoundland"
      },
      {
        "offset": -3,
        "zone": "America/Buenos_Aires",
        "gmt": "(GMT-03:00)",
        "name": "Buenos Aires"
      },
      {
        "offset": -3,
        "zone": "Greenland",
        "gmt": "(GMT-03:00)",
        "name": "Greenland"
      },
      {
        "offset": -2,
        "zone": "Atlantic/Stanley",
        "gmt": "(GMT-02:00)",
        "name": "Stanley"
      },
      {
        "offset": -1,
        "zone": "Atlantic/Azores",
        "gmt": "(GMT-01:00)",
        "name": "Azores"
      },
      {
        "offset": -1,
        "zone": "Atlantic/Cape_Verde",
        "gmt": "(GMT-01:00)",
        "name": "Cape Verde Is."
      },
      {
        "offset": 0,
        "zone": "Africa/Casablanca",
        "gmt": "(GMT)",
        "name": "Casablanca"
      },
      {
        "offset": 0,
        "zone": "Europe/Dublin",
        "gmt": "(GMT)",
        "name": "Dublin"
      },
      {
        "offset": 0,
        "zone": "Europe/Lisbon",
        "gmt": "(GMT)",
        "name": "Libson"
      },
      {
        "offset": 0,
        "zone": "Europe/London",
        "gmt": "(GMT)",
        "name": "London"
      },
      {
        "offset": 0,
        "zone": "Africa/Monrovia",
        "gmt": "(GMT)",
        "name": "Monrovia"
      },
      {"offset": 1,
        "zone": "Europe/Amsterdam",
        "gmt": "(GMT+01:00)",
        "name": "Amsterdam"
      },
      {
        "offset": 1,
        "zone": "Europe/Belgrade",
        "gmt": "(GMT+01:00)",
        "name": "Belgrade"
      },
      {
        "offset": 1,
        "zone": "Europe/Berlin",
        "gmt": "(GMT+01:00)",
        "name": "Berlin"
      },
      {
        "offset": 1,
        "zone": "Europe/Bratislava",
        "gmt": "(GMT+01:00)",
        "name": "Bratislava"
      },
      {
        "offset": 1,
        "zone": "Europe/Brussels",
        "gmt": "(GMT+01:00)",
        "name": "Brussels"
      },
      {
        "offset": 1,
        "zone": "Europe/Budapest",
        "gmt": "(GMT+01:00)",
        "name": "Budapest"
      },
      {
        "offset": 1,
        "zone": "Europe/Copenhagen",
        "gmt": "(GMT+01:00)",
        "name": "Copenhagen"
      },
      {
        "offset": 1,
        "zone": "Europe/Ljubljana",
        "gmt": "(GMT+01:00)",
        "name": "Ljubljana"
      },
      {
        "offset": 1,
        "zone": "Europe/Madrid",
        "gmt": "(GMT+01:00)",
        "name": "Madrid"
      },
      {
        "offset": 1,
        "zone": "Europe/Paris",
        "gmt": "(GMT+01:00)",
        "name": "Paris"
      },
      {
        "offset": 1,
        "zone": "Europe/Prague",
        "gmt": "(GMT+01:00)",
        "name": "Prague"
      },
      {
        "offset": 1,
        "zone": "Europe/Rome",
        "gmt": "(GMT+01:00)",
        "name": "Rome"
      },
      {
        "offset": 1,
        "zone": "Europe/Sarajevo",
        "gmt": "(GMT+01:00)",
        "name": "Sarajevo"
      },
      {
        "offset": 1,
        "zone": "Europe/Skopje",
        "gmt": "(GMT+01:00)",
        "name": "Skopje"
      },
      {
        "offset": 1,
        "zone": "Europe/Stockholm",
        "gmt": "(GMT+01:00)",
        "name": "Stockholm"
      },
      {
        "offset": 1,
        "zone": "Europe/Vienna",
        "gmt": "(GMT+01:00)",
        "name": "Vienna"
      },
      {
        "offset": 1,
        "zone": "Europe/Warsaw",
        "gmt": "(GMT+01:00)",
        "name": "Warsaw"
      },
      {
        "offset": 1,
        "zone": "Europe/Zagreb",
        "gmt": "(GMT+01:00)",
        "name": "Zagreb"
      },
      {
        "offset": 2,
        "zone": "Europe/Athens",
        "gmt": "(GMT+02:00)",
        "name": "Athens"
      },
      {
        "offset": 2,
        "zone": "Europe/Bucharest",
        "gmt": "(GMT+02:00)",
        "name": "Bucharest"
      },
      {
        "offset": 2,
        "zone": "Africa/Cairo",
        "gmt": "(GMT+02:00)",
        "name": "Cairo"
      },
      {
        "offset": 2,
        "zone": "Africa/Harare",
        "gmt": "(GMT+02:00)",
        "name": "Harere"
      },
      {
        "offset": 2,
        "zone": "Europe/Helsinki",
        "gmt": "(GMT+02:00)",
        "name": "Helsinki"
      },
      {
        "offset": 2,
        "zone": "Europe/Istanbul",
        "gmt": "(GMT+02:00)",
        "name": "Istanbul"
      },
      {
        "offset": 2,
        "zone": "Asia/Jerusalem",
        "gmt": "(GMT+02:00)",
        "name": "Jerusalem"
      },
      {
        "offset": 2,
        "zone": "Europe/Kiev",
        "gmt": "(GMT+02:00)",
        "name": "Kiev"
      },
      {
        "offset": 2,
        "zone": "Europe/Minsk",
        "gmt": "(GMT+02:00)",
        "name": "Minsk"
      },
      {
        "offset": 2,
        "zone": "Europe/Riga",
        "gmt": "(GMT+02:00)",
        "name": "Riga"
      },
      {
        "offset": 2,
        "zone": "Europe/Sofia",
        "gmt": "(GMT+02:00)",
        "name": "Sofia"
      },
      {
        "offset": 2,
        "zone": "Europe/Tallinn",
        "gmt": "(GMT+02:00)",
        "name": "Tallinn"
      },
      {
        "offset": 2,
        "zone": "Europe/Vilnius",
        "gmt": "(GMT+02:00)",
        "name": "Vilnius"
      },
      {
        "offset": 3,
        "zone": "Asia/Baghdad",
        "gmt": "(GMT+03:00)",
        "name": "Baghdad"
      },
      {
        "offset": 3,
        "zone": "Asia/Kuwait",
        "gmt": "(GMT+03:00)",
        "name": "Kuwait"
      },
      {
        "offset": 3,
        "zone": "Africa/Nairobi",
        "gmt": "(GMT+03:00)",
        "name": "Nairobi"
      },
      {
        "offset": 3,
        "zone": "Asia/Riyadh",
        "gmt": "(GMT+03:00)",
        "name": "Riyadh"
      },
      {
        "offset": 3.5,
        "zone": "Asia/Tehran",
        "gmt": "(GMT+03:30)",
        "name": "Tehran"
      },
      {
        "offset": 4,
        "zone": "Europe/Moscow",
        "gmt": "(GMT+04:00)",
        "name": "Moscow"
      },
      {
        "offset": 4,
        "zone": "Asia/Baku",
        "gmt": "(GMT+04:00)",
        "name": "Baku"
      },
      {
        "offset": 4,
        "zone": "Europe/Volgograd",
        "gmt": "(GMT+04:00)",
        "name": "Volgograd"
      },
      {
        "offset": 4,
        "zone": "Asia/Muscat",
        "gmt": "(GMT+04:00)",
        "name": "Muscat"
      },
      {
        "offset": 4,
        "zone": "Asia/Tbilisi",
        "gmt": "(GMT+04:00)",
        "name": "Tbilisi"
      },
      {
        "offset": 4,
        "zone": "Asia/Yerevan",
        "gmt": "(GMT+04:00)",
        "name": "Yerevan"
      },
      {
        "offset": 4.5,
        "zone": "Asia/Kabul",
        "gmt": "(GMT+04:30)",
        "name": "Kabul"
      },
      {
        "offset": 5,
        "zone": "Asia/Karachi",
        "gmt": "(GMT+05:00)",
        "name": "Karachi"
      },
      {
        "offset": 5,
        "zone": "Asia/Tashkent",
        "gmt": "(GMT+05:00)",
        "name": "Tashkent"
      },
      {
        "offset": 5.5,
        "zone": "Asia/Kolkata",
        "gmt": "(GMT+05:30)",
        "name": "Kolkata"
      },
      {
        "offset": 5.75,
        "zone": "Asia/Kathmandu",
        "gmt": "(GMT+05:45)",
        "name": "Kathmandu"
      },
      {
        "offset": 6,
        "zone": "Asia/Yekaterinburg",
        "gmt": "(GMT+06:00)",
        "name": "Yekaterinburg"
      },
      {
        "offset": 6,
        "zone": "Asia/Almaty",
        "gmt": "(GMT+06:00)",
        "name": "Almaty"
      },
      {
        "offset": 6,
        "zone": "Asia/Dhaka",
        "gmt": "(GMT+06:00)",
        "name": "Dhaka"
      },
      {
        "offset": 7,
        "zone": "Asia/Novosibirsk",
        "gmt": "(GMT+07:00)",
        "name": "Novosibirsk"
      },
      {
        "offset": 7,
        "zone": "Asia/Bangkok",
        "gmt": "(GMT+07:00)",
        "name": "Bangkok"
      },
      {
        "offset": 7,
        "zone": "Asia/Jakarta",
        "gmt": "(GMT+07:00)",
        "name": "Jakarta"
      },
      {
        "offset": 8,
        "zone": "Asia/Krasnoyarsk",
        "gmt": "(GMT+08:00)",
        "name": "Krasnoyarsk"
      },
      {
        "offset": 8,
        "zone": "Asia/Chongqing",
        "gmt": "(GMT+08:00)",
        "name": "Chongqing"
      },
      {
        "offset": 8,
        "zone": "Asia/Hong_Kong",
        "gmt": "(GMT+08:00)",
        "name": "Hong Kong"
      },
      {
        "offset": 8,
        "zone": "Asia/Kuala_Lumpur",
        "gmt": "(GMT+08:00)",
        "name": "Kuala Lumpur"
      },
      {
        "offset": 8,
        "zone": "Australia/Perth",
        "gmt": "(GMT+08:00)",
        "name": "Perth"
      },
      {
        "offset": 8,
        "zone": "Asia/Singapore",
        "gmt": "(GMT+08:00)",
        "name": "Singapore"
      },
      {
        "offset": 8,
        "zone": "Asia/Taipei",
        "gmt": "(GMT+08:00)",
        "name": "Taipei"
      },
      {
        "offset": 8,
        "zone": "Asia/Ulaanbaatar",
        "gmt": "(GMT+08:00)",
        "name": "Ulaan Bataar"
      },
      {
        "offset": 8,
        "zone": "Asia/Urumqi",
        "gmt": "(GMT+08:00)",
        "name": "Urumqi"
      },
      {
        "offset": 9,
        "zone": "Asia/Irkutsk",
        "gmt": "(GMT+09:00)",
        "name": "Irkutsk"
      },
      {
        "offset": 9,
        "zone": "Asia/Seoul",
        "gmt": "(GMT+09:00)",
        "name": "Seoul"
      },
      {
        "offset": 9,
        "zone": "Asia/Tokyo",
        "gmt": "(GMT+09:00)",
        "name": "Tokyo"
      },
      {
        "offset": 9.5,
        "zone": "Australia/Adelaide",
        "gmt": "(GMT+09:30)",
        "name": "Adelaide"
      },
      {
        "offset": 9.5,
        "zone": "Australia/Darwin",
        "gmt": "(GMT+09:30)",
        "name": "Darwin"
      },
      {
        "offset": 10,
        "zone": "Asia/Yakutsk",
        "gmt": "(GMT+10:00)",
        "name": "Yakutsk"
      },
      {
        "offset": 10,
        "zone": "Australia/Brisbane",
        "gmt": "(GMT+10:00)",
        "name": "Brisbane"
      },
      {
        "offset": 10,
        "zone": "Australia/Canberra",
        "gmt": "(GMT+10:00)",
        "name": "Canberra"
      },
      {
        "offset": 10,
        "zone": "Pacific/Guam",
        "gmt": "(GMT+10:00)",
        "name": "Guam"
      },
      {
        "offset": 10,
        "zone": "Australia/Hobart",
        "gmt": "(GMT+10:00)",
        "name": "Hobart"
      },
      {
        "offset": 10,
        "zone": "Australia/Melbourne",
        "gmt": "(GMT+10:00)",
        "name": "Melbourne"
      },
      {
        "offset": 10,
        "zone": "Pacific/Port_Moresby",
        "gmt": "(GMT+10:00)",
        "name": "Port Moresby"
      },
      {
        "offset": 10,
        "zone": "Australia/Sydney",
        "gmt": "(GMT+10:00)",
        "name": "Sydney"
      },
      {
        "offset": 11,
        "zone": "Asia/Vladivostok",
        "gmt": "(GMT+11:00)",
        "name": "Vladivostok"
      },
      {
        "offset": 12,
        "zone": "Asia/Magadan",
        "gmt": "(GMT+12:00)",
        "name": "Magadan"
      },
      {
        "offset": 12,
        "zone": "Pacific/Auckland",
        "gmt": "(GMT+12:00)",
        "name": "Auckland"
      },
      {
        "offset": 12,
        "zone": "Pacific/Fiji",
        "gmt": "(GMT+12:00)",
        "name": "Fiji"
      }
    ]
  }
}
