import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AlertComponent } from './alert/alert.component';
import { RouterModule } from "@angular/router";
import { PaginationComponent } from './pagination/pagination.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { ModalComponent } from './modal/modal.component';
import { DropdownInputComponent } from "./dropdown-input/dropdown-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchInputComponent } from './search-input/search-input.component';
import { MultilineDropdownInputComponent } from './multiline-dropdown-input/multiline-dropdown-input.component';
import { MobileTabsComponent } from './mobile-tabs/mobile-tabs.component';
import { SlideInPanelComponent } from './slide-in-panel/slide-in-panel.component';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { AutosuggestDropdownInputComponent } from './autosuggest-dropdown-input/autosuggest-dropdown-input.component';
import { PatientAutoCompleteComponent } from './patient-autocomplete/patient-autocomplete.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { LoaderComponent } from './loader/loader.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { SiteAutocompleteComponent } from './site-autocomplete/site-autocomplete.component';
import { TrialAutocompleteComponent } from './trial-autocomplete/trial-autocomplete.component';
import { CreateSiteModalComponent } from './create-site-modal/create-site-modal.component';
import { ReportPanelComponent } from './report-panel/report-panel.component';
import { DonutChartComponent } from './donut-chart/donut-chart.component';
import { PieChartModule } from "@swimlane/ngx-charts";
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { MatIconModule } from "@angular/material/icon";
import { TrialTimelineEventComponent } from './trial-timeline-event/trial-timeline-event.component';
import { PreviewTimelineComponent } from './preview-timeline/preview-timeline.component';
import { StarRatingComponent } from "./star-rating/star-rating.component";
import { BackButtonComponent } from './back-button/back-button.component';
import { PascalSpaces } from '../core/pipes/pascal-spaces.pipe';
import { SiteContactsDropdownComponent } from './site-contacts-dropdown/site-contacts-dropdown.component';
import { CreateSiteContactModalComponent } from './create-site-contact-modal/create-site-contact-modal.component';
import { DatepickerInputComponent } from './datepicker-input/datepicker-input.component';
import { InputMultiSelectComponent } from './input-multi-select/input-multi-select.component';
import { InputSelectComponent } from './input-select/input-select.component';
import { Datepickerv2InputComponent } from './datepickerv2-input/datepickerv2-input.component';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ListAutocompleteComponent } from './list-autocomplete/list-autocomplete.component';
import { InputFileComponent } from "./input-file/input-file.component";
import { SidebarComponent } from './sidebar/sidebar.component';
import { InputAddressComponent } from './input-address/input-address.component';
import { ModalV2Component } from './modal-v2/modal-v2.component';
import { TabulatedContentComponent } from "./tabulated-content/tabulated-content.component";
import { InputCurrencyComponent } from './input-currency/input-currency.component';
import { EnumToTextPipe } from 'app/core/pipes/enum-to-text.pipe';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { PatientAddModalComponent } from "./patient-add-modal/patient-add-modal.component";
import { PatientEditModalComponent } from "./patient-edit-modal/patient-edit-modal.component";
import {
  PatientManageTrialsComponent
} from "./patient-edit-modal/patient-manage-trials/patient-manage-trials.component";
import { PatientDeleteModalComponent } from "./patient-delete-modal/patient-delete-modal.component";
import { PatientMergeModalComponent } from "./patient-merge-modal/patient-merge-modal.component";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { TableInlineCellDateComponent } from "./table-inline-cell-date/table-inline-cell-date.component";
import {
  TableInlineCellMultiselectComponent
} from "./table-inline-cell-multiselect/table-inline-cell-multiselect.component";
import {
  TableInlineCellPatientCodeComponent
} from "./table-inline-cell-patient-code/table-inline-cell-patient-code.component";
import { TableInlineCellSelectComponent } from "./table-inline-cell-select/table-inline-cell-select.component";
import { TableInlineCellTextComponent } from "./table-inline-cell-text/table-inline-cell-text.component";
import { TableInlineCellTimeComponent } from "./table-inline-cell-time/table-inline-cell-time.component";
import {
  TableInlineCellAmountCurrencyComponent
} from "./table-inline-cell-amount-currency/table-inline-cell-amount-currency.component";
import { TableInlineCellCheckboxComponent } from './table-inline-cell-checkbox/table-inline-cell-checkbox.component';
import { PatientUpdateAddressComponent } from "./patient-update-address/patient-update-address.component";
import { TableCellAmountComponent } from './table-cell-amount/table-cell-amount.component';
import { ExpandableTextComponent } from "./expandable-text/expandable-text.component";
import {
  TableInlineCellShortDateComponent
} from './table-inline-cell-short-date/table-inline-cell-short-date.component';
import { TimezoneSelectComponent } from "./timezone-select/timezone-select.component";
import { InputTimepickerComponent } from "./input-timepicker/input-timepicker.component";
import { TableInlineCellSiteComponent } from "./table-inline-cell-site/table-inline-cell-site.component";
import { ConfirmActionModalComponent } from "./confirm-action-modal/confirm-action-modal.component";
import { BadgeComponent } from "./badge/badge.component";
import { BadgeTrialComponent } from "./badge-trial/badge-trial.component";
import { ButtonComponent } from "./button/button.component";
import { FilterToggleComponent } from "./filter-toggle/filter-toggle.component";
import { ClickOutsideDirective } from "../core/directives/click-outside.directive";
import { PatientDropdownComponent } from "./patient-dropdown/patient-dropdown.component";
import { EditTrialAssignmentComponent } from './edit-trial-assignment/edit-trial-assignment.component';

@NgModule({
  declarations: [
    HeaderComponent,
    AlertComponent,
    PaginationComponent,
    ChangeLogComponent,
    ModalComponent,
    DropdownInputComponent,
    SearchInputComponent,
    MultilineDropdownInputComponent,
    MultilineDropdownInputComponent,
    MobileTabsComponent,
    SlideInPanelComponent,
    NotSupportedComponent,
    AutosuggestDropdownInputComponent,
    PatientAutoCompleteComponent,
    LoadingOverlayComponent,
    LoaderComponent,
    CustomFieldsComponent,
    SiteAutocompleteComponent,
    TrialAutocompleteComponent,
    CreateSiteModalComponent,
    ReportPanelComponent,
    DonutChartComponent,
    VideoUploadComponent,
    TrialTimelineEventComponent,
    PreviewTimelineComponent,
    StarRatingComponent,
    BackButtonComponent,
    PascalSpaces,
    SiteContactsDropdownComponent,
    CreateSiteContactModalComponent,
    DatepickerInputComponent,
    InputMultiSelectComponent,
    InputSelectComponent,
    Datepickerv2InputComponent,
    ListAutocompleteComponent,
    InputFileComponent,
    SidebarComponent,
    InputAddressComponent,
    ModalV2Component,
    TabulatedContentComponent,
    InputCurrencyComponent,
    EnumToTextPipe,
    PatientAddModalComponent,
    PatientEditModalComponent,
    PatientManageTrialsComponent,
    PatientDeleteModalComponent,
    PatientManageTrialsComponent,
    PatientMergeModalComponent,
    PatientUpdateAddressComponent,
    TableInlineCellDateComponent,
    TableInlineCellShortDateComponent,
    TableInlineCellMultiselectComponent,
    TableInlineCellPatientCodeComponent,
    TableInlineCellSelectComponent,
    TableInlineCellTextComponent,
    TableInlineCellTimeComponent,
    TableInlineCellAmountCurrencyComponent,
    TableInlineCellCheckboxComponent,
    TableCellAmountComponent,
    ExpandableTextComponent,
    TimezoneSelectComponent,
    InputTimepickerComponent,
    TableInlineCellSiteComponent,
    ConfirmActionModalComponent,
    BadgeComponent,
    BadgeTrialComponent,
    FilterToggleComponent,
    PatientDropdownComponent,
    EditTrialAssignmentComponent
  ],
  exports: [
    HeaderComponent,
    AlertComponent,
    PaginationComponent,
    ChangeLogComponent,
    ModalComponent,
    DropdownInputComponent,
    SearchInputComponent,
    MultilineDropdownInputComponent,
    MobileTabsComponent,
    SlideInPanelComponent,
    AutosuggestDropdownInputComponent,
    PatientAutoCompleteComponent,
    LoadingOverlayComponent,
    LoaderComponent,
    CustomFieldsComponent,
    SiteAutocompleteComponent,
    TrialAutocompleteComponent,
    CreateSiteModalComponent,
    ReportPanelComponent,
    DonutChartComponent,
    VideoUploadComponent,
    TrialTimelineEventComponent,
    PreviewTimelineComponent,
    StarRatingComponent,
    BackButtonComponent,
    PascalSpaces,
    SiteContactsDropdownComponent,
    CreateSiteContactModalComponent,
    DatepickerInputComponent,
    InputMultiSelectComponent,
    InputSelectComponent,
    Datepickerv2InputComponent,
    ListAutocompleteComponent,
    InputFileComponent,
    SidebarComponent,
    InputAddressComponent,
    MatIconModule,
    ModalV2Component,
    TabulatedContentComponent,
    InputCurrencyComponent,
    EnumToTextPipe,
    NgxSkeletonLoaderModule,
    PatientAddModalComponent,
    PatientEditModalComponent,
    PatientDeleteModalComponent,
    PatientEditModalComponent,
    PatientMergeModalComponent,
    PatientUpdateAddressComponent,
    TableInlineCellDateComponent,
    TableInlineCellShortDateComponent,
    TableInlineCellMultiselectComponent,
    TableInlineCellPatientCodeComponent,
    TableInlineCellSelectComponent,
    TableInlineCellTextComponent,
    TableInlineCellTimeComponent,
    TableInlineCellAmountCurrencyComponent,
    TableInlineCellCheckboxComponent,
    TableCellAmountComponent,
    ExpandableTextComponent,
    TimezoneSelectComponent,
    InputTimepickerComponent,
    TableInlineCellSiteComponent,
    ConfirmActionModalComponent,
    BadgeTrialComponent,
    FilterToggleComponent,
    BadgeComponent,
    PatientDropdownComponent,
    EditTrialAssignmentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    PieChartModule,
    MatProgressBarModule,
    MatIconModule,
    FormsModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxSkeletonLoaderModule,
    NgxMatInputTelComponent,
    ButtonComponent,
    ClickOutsideDirective
  ]
})
export class SharedModule { }
