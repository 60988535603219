<td>
  <span class="label-secondary" *ngIf="!objectHelper.isUndefinedOrNull(country)">{{policy.isDefault ? 'All Countries' : country}}</span>
  <span class="label-primary" *ngIf="!policy.isDefault && policy.allSites">All Sites</span>
  <span class="label-primary" *ngIf="policy.isDefault">Default Expense Policy</span>
  <span class="label-primary" *ngIf="policy.sites && policy.sites.length > 0">
    {{policy.siteCount}} Site{{policy.siteCount > 1 ? 's' : ''}}
    <i class="fas fa-info-circle" (mouseenter)="onShowInfo()" (mouseleave)="onHideInfo()" aria-hidden="true"></i>
  </span>

  <div class="sites-popup" *ngIf="policy.sites.length > 0 && showSites">
    <ul>
      <li *ngFor="let site of policy.sites">
        <span class="line-1">{{site.name}}</span>
        <span class="line-2">{{site.address}} {{site.country}}</span>
      </li>
    </ul>
  </div>
</td>
<td>
  <ul>
    <li [ngClass]="{'enabled': policy.allowExpenseRequests}"><i class="icon-secure" aria-hidden="true" title="Expense Claims {{policy.allowExpenseRequests ? 'Enabled' : 'Disabled'}}"></i></li>
    <li [ngClass]="{'enabled': policy.allowTravelRequests}"><i class="icon-cabinet" aria-hidden="true" title="Travel Requests  {{policy.allowTravelRequests ? 'Enabled' : 'Disabled'}}"></i></li>
  </ul>
</td>
<td>
  <span class="label-secondary">In app expense categories</span>
  <span class="label-primary">{{categoriesToString(policy.expenseCategories)}}</span>
</td>
<td>
  <span class="label-secondary">CMS only expense categories</span>
  <span class="label-primary">{{categoriesToString(policy.cmsOnlyCategories)}}</span>
</td>
<td class="actions">
  <div class="flex justify-end gap-2">
    <app-button
      text="Remove"
      [style]="ButtonStyle.Info"
      [size]="ButtonSize.Sm"
      (clicked)="onRemove()"
      [visible]="authService.hasPermission(Permissions.TrialPolicyDelete) && !policy.isDefault"/>

    <app-button
      text="Edit"
      [style]="ButtonStyle.Secondary"
      [size]="ButtonSize.Sm"
      (clicked)="onEdit()"
      [visible]="authService.hasPermission(Permissions.TrialPolicyEdit)" />
  </div>
</td>
