import { AlertService } from './../../../../shared/alert/alert.service';
import { TrialService } from 'app/core/services/trial.service';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { UpdateVisitScheduleRequest, VisitScheduleViewModel } from "../trial-visit-schedule.model";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { VisitScheduleModalComponent } from "../../../visit/visit-schedule-modal/visit-schedule-modal.component";

@Component({
  selector: 'app-trial-visit-schedule',
  templateUrl: './trial-visit-schedule.component.html',
  styleUrls: ['./trial-visit-schedule.component.scss']
})
export class TrialVisitScheduleComponent implements OnInit {
  @ViewChild('visitScheduleModal') visitScheduleModal: VisitScheduleModalComponent;

  @Input() trialId: string;
  @Input() diaryEnabled: boolean = false;
  @Input() genericDiaryEnabled: boolean = false;

  visits: VisitScheduleViewModel[] = [];

  form: FormGroup = new FormGroup({
    isBusy: new FormControl<boolean>(false),
    visitCount: new FormControl<number>(null, [Validators.required])
  });
  saveBtnVisible = false;
  isBusy = false;
  confirmationModalVisitId: string;
  originalVisitTitle: string = '';

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.populateVisitSchedule();
  }

  populateVisitSchedule() {
    this.trialService.getTrialVisitSchedule(this.trialId).subscribe({
      next: response => {
        this.visits = response;
        console.log(response);
      },
      error: error => {
        this.alertService.showWarningAlert('Unable to retrieve trial visit schedule. Try again or contact support.');
        console.error(error);
      }
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.visits, event.previousIndex, event.currentIndex);
    this.saveBtnVisible = true;
  }

  removeVisit(visit: VisitScheduleViewModel) {
    this.visits.splice(this.visits.findIndex(v => v.title === visit.title), 1);
    this.saveBtnVisible = true;
  }

  onEditTemplate(visit: VisitScheduleViewModel) {
    this.visitScheduleModal.show(visit.templateId, this.diaryEnabled, this.genericDiaryEnabled);
  }

  addVisits() {
    let amount = this.form.get('visitCount').value;

    let start = 1;
    this.visits.forEach(visit => {
      const match = visit.title.match(/\d+/);
      if (match) {
        const number = parseInt(match[0], 10);
        if (number >= start) {
          start = number + 1;
        }

      }
    });

    let end = start + (+amount);

    for (let index = start; index < end; index++) {
      let visitTitle = `Visit ${index}`;

      this.visits.push(new VisitScheduleViewModel({
        templateId: uuidv4(),
        scheduleIndex: index,
        title: visitTitle,
        keyVisit: false,
        editable: false
      }));
    }

    this.saveBtnVisible = true;

    this.form.reset();
  }

  updateVisitTitle(visit: VisitScheduleViewModel, newValue: string) {
    visit.title = newValue;
    this.confirmationModalVisitId = visit.templateId;
    this.saveBtnVisible = true;
  }

  onVisitTranslationUpdated(data: { templateId: string, title: string }) {
    this.visits = [];
    this.populateVisitSchedule();
  }

  save(confirmed: boolean = false) {
    this.isBusy = true;
    let request: UpdateVisitScheduleRequest[] = []

    for (let index = 0; index < this.visits.length; index++) {
      request.push({
        templateId: this.visits[index].templateId,
        scheduleIndex: index,
        visitTitle: this.visits[index].title,
      })
    }

    this.trialService.updateTrialVisitSchedule(this.trialId, request).subscribe({
      next: () => {
        this.isBusy = false;
        this.saveBtnVisible = false;
        this.alertService.showSuccessAlert("Visit schedule successfully updated.");

        this.populateVisitSchedule();
      },
      error: error => {
        this.isBusy = false;
        this.alertService.showErrorAlert(error);
        console.error(error);
      }
    });
  }
}
