export class PatientTrial {
  public id: string;
  public trialId: string;
  public trialCode: string;
  public trialLabel: string;
  public siteId: string;
  public siteName: string;
  public siteAddress: string;
  public siteCountry: string;
  public siteNumber: string;
  public patientCode: string;
  public consent: boolean;
  public overspendTravel: boolean;
  public overspendMileage: boolean;
  public fixedFeePerVisit: boolean;
  public approvalNoReceipts: boolean;
  public bankFeesIncurred: boolean;
  public stateOnTrial: string;
  public endingParticipationReason: string;
  public intercashRemoteId: string;
  public intercashSanNumber: string;
  public engineSize: string;
  public engineSizes: string[];
  public imsLegacyTrial: boolean;

  constructor(init: Partial<PatientTrial>) {
    Object.assign(this, init);
  }
}
