import { AlertService } from './../../../shared/alert/alert.service';
import { SiteContactService } from './../../../core/services/site-contact.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreatePatientRegistrationRequest, GetSiteContactTrialsRequest } from 'app/core/models/patient.model';
import { LogHelper } from 'app/core/helpers/log.helper';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { StringHelper } from 'app/core/helpers/string-helper';
import { FileAttachment } from 'app/shared/shared.model';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { SelectOption } from 'app/core/models/select-option.model';

@Component({
  selector: 'app-patient-register',
  templateUrl: './patient-register.component.html',
  styleUrls: ['./patient-register.component.scss']
})
export class PatientRegisterComponent implements OnInit {
  @ViewChild('trialSelect') trialSelect: DropdownInputComponent;
  @ViewChild('siteSelect') siteSelect: DropdownInputComponent;
  @ViewChild('removePhotoModal') removePhotoModal: ModalComponent;
  @ViewChild('uploadImageInput') uploadImageInput: ElementRef;

  trialOptions: SelectOption[] = [];
  siteOptions: Record<string, SelectOption[]> = {};

  processingRequest: boolean = false;
  showAttachmentError = false;
  patientRegistrationCode: string;
  siteContactId: string;

  attachment: FileAttachment;

  patientRegistrationForm = new FormGroup({
    trialId: new FormControl(''),
    siteId: new FormControl(''),
    investigatorName: new FormControl(''),
    formFillerEmail: new FormControl(''),
    patientName: new FormControl(''),
    patientNumber: new FormControl(''),
    email: new FormControl(''),
  });

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private siteContactService: SiteContactService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.subscribeTrialChanges();

    this.activatedRoute.params.subscribe(params => {
      this.patientRegistrationCode = params.patientRegistrationCode;
      this.siteContactId = params.siteContactId;

      this.getSiteContactTrials();
    })
  }

  subscribeTrialChanges() {
    this.patientRegistrationForm.controls.trialId.valueChanges.subscribe(_ => {
      this.siteSelect.reset();
      this.patientRegistrationForm.controls.siteId.setValue('');
    });
  }

  getSiteContactTrials() {
    const request: GetSiteContactTrialsRequest = {
      siteContactId: this.siteContactId,
      patientRegistrationCode: this.patientRegistrationCode
    };

    this.siteContactService.getSiteContactTrials(request)
      .subscribe({
        next: result => {
          result.forEach(t => {
            this.trialOptions.push(
              {
                value: t.trialId,
                text: t.publicTrialCode
              });

            this.siteOptions[t.trialId] = [];

            t.sites.forEach(s => {
              this.siteOptions[t.trialId].push({
                value: s.siteId,
                text: s.siteText
              });
            });
          });

          this.preselectTrialAndSite();
        }
      })
  };

  private preselectTrialAndSite() {
    if (this.trialOptions.length === 1) {
      this.trialSelect.setValue(this.trialOptions[0].value);
      if (this.siteOptions[this.trialOptions[0].value].length === 1) {
        this.siteSelect.options = this.siteOptions[this.trialOptions[0].value];
        this.siteSelect.setValue(this.siteOptions[this.trialOptions[0].value][0].value);
      }
    }
  }

  createPatientRegistration() {
    this.processingRequest = true;
    let request = this.patientRegistrationForm.value as CreatePatientRegistrationRequest;
    request.siteContactId = this.siteContactId;
    request.patientRegistrationCode = this.patientRegistrationCode;

    if (!this.attachment) {
      this.processingRequest = false;
      this.showAttachmentError = true;
    }

    this.siteContactService.uploadFile(this.attachment.data, this.siteContactId, this.patientRegistrationCode).subscribe({
      next: result => {
        request.signedRegistrationFilename = result.signedRegistrationFilename;
        this.createRegistration(request);
      },
      error: error => {
        LogHelper.log(error);
        this.processingRequest = false;
        this.alertService.showWarningAlert("Failed to upload patient signature. Please try again or contact support.");
      }
    });
  }

  private createRegistration(request: CreatePatientRegistrationRequest) {
    this.siteContactService.createPatientRegistration(request).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Patient registration successfully created");
        this.processingRequest = false;
        this.showAttachmentError = false;
        this.patientRegistrationForm.reset();
        this.trialSelect.reset()
        this.siteSelect.reset()
        this.preselectTrialAndSite();
        this.uploadImageInput.nativeElement.value = '';
        this.attachment = null;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert("Failed to create patient registration. Please try again or contact support.");
        this.processingRequest = false;
      }
    });
  }

  onAddImage() {
    this.uploadImageInput.nativeElement.click();
  }

  onConfirmRemoveImage() {
    this.attachment = null;

    this.removePhotoModal.hide();
  }

  preview(files) {
    if (files.length === 0)
      return;

    if (!this.isFileTypeAllowed(files[0])) {
      this.alertService.showWarningAlert("Invalid file type.");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.attachment = {
        data: files[0],
        filename: files[0].name,
        fileUrl: reader.result,
        extension: StringHelper.getFileExtension(files[0].name)
      };
    };
  }

  private isFileTypeAllowed(file: File): boolean {
    const allowedTypes = /(image\/(jpeg|png|gif))|(application\/pdf)/;
    return allowedTypes.test(file.type);
  }
}
