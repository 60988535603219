export class TrialAdminUser {
  public id: string;
  public assignmentId: string;
  public name: string;
  public email: string;
  public projectManager: boolean;
  public designatedContact: boolean;
  public allCountries: boolean;
  public countries: string;

  constructor(init?: Partial<TrialAdminUser>) {
    Object.assign(this, init);
  }
}
