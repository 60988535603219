import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ProjectManagementListComponent } from "./project-manager-list/project-management-list.component";
import { VisitModule } from "../visit/visit.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatOptionModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import {
  OverBudgetRequestItemsComponent
} from './project-manager-list/over-budget-request-items/over-budget-request-items.component';
import {
  OverBudgetRequestHistoryModalComponent
} from './project-manager-list/over-budget-request-history-modal/over-budget-request-history-modal.component';
import { TableCellCurrencyComponent } from './project-manager-list/table-cell-currency/table-cell-currency.component';
import {
  VisitPatientDetailModalComponent
} from '../visit/visits/visit-patient-detail-modal/visit-patient-detail-modal.component';
import { ButtonComponent } from "../../shared/button/button.component";


@NgModule({
  declarations: [
    TableCellCurrencyComponent,
    ProjectManagementListComponent,
    OverBudgetRequestItemsComponent,
    OverBudgetRequestHistoryModalComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        VisitModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        VisitPatientDetailModalComponent,
        ButtonComponent,
    ]
})
export class ProjectManagementModule { }
