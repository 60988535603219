import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { BeneficiaryDetails, UpdatePatientCaxtonBeneficiaryRequest } from 'app/core/models/patient.model';
import { PatientService } from 'app/core/services/patient.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { ModalV2Component } from 'app/shared/modal-v2/modal-v2.component';

@Component({
  selector: 'app-beneficiaries-list-modal',
  templateUrl: './beneficiaries-list-modal.component.html',
  styleUrls: ['./beneficiaries-list-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BeneficiariesListModalComponent implements OnInit {
  @ViewChild('beneficiariesListModal') modal: ModalV2Component;
  @ViewChild("beneficiarySearch") beneficiarySearch: ElementRef;

  beneficiaries: BeneficiaryDetails[] = [];
  filteredBeneficiaries: BeneficiaryDetails[] = [];
  selectedBeneficiary: BeneficiaryDetails;
  patientTrialId: string;

  beneficiaryForm = new FormGroup({
    beneficiarySearch: new FormControl(''),
    beneficiaryId: new FormControl('', Validators.required),
  })

  processingRequest = false;

  @Output() beneficiaryUpdated = new EventEmitter<string>();

  constructor(
    private patientService: PatientService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.subscribeBeneficiarySearch();
  }

  private subscribeBeneficiarySearch() {
    this.beneficiaryForm.controls.beneficiarySearch.valueChanges.subscribe(value => {
      this.filteredBeneficiaries = this.beneficiaries.filter(b => b.firstName.toLowerCase().includes(value.toLowerCase()) ||
        b.lastName.toLowerCase().includes(value.toLowerCase())
      );
    });
  }

  getBeneficiaryTitle(beneficiary: BeneficiaryDetails) {
    return `${beneficiary.firstName} ${beneficiary.lastName} - ${beneficiary.currency}`;
  }

  updateBeneficiaryPreview(value: MatRadioChange) {
    this.selectedBeneficiary = this.beneficiaries.find(b => b.id === value.value);
  }

  onUpdateBeneficiary() {
    this.processingRequest = true;

    const request: UpdatePatientCaxtonBeneficiaryRequest = {
      patientTrialId: this.patientTrialId,
      caxtonBeneficiaryId: this.selectedBeneficiary.id
    }

    this.patientService.updatePatientCaxtonBeneficiaryId(request).subscribe({
      next: () => {
        this.processingRequest = false;
        this.beneficiaryUpdated.emit(request.caxtonBeneficiaryId);
        this.alertService.showSuccessAlert("Caxton beneficiary successfully assigned");
        this.modal.hide();
      },
      error: () => {
        this.processingRequest = false;
        this.alertService.showWarningAlert("Caxton beneficiary assignment failed. Please try again or contact an administrator");
      }
    })
  }
}
