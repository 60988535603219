<div class="trials">

  <div class="view-head">
    <div class="row">

      <div class="col-12 text-center">
        <h2 class="heading-small">Assign New Site</h2>
      </div>

    </div>
  </div>

  <div class="view-body">

    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">

        <div class="std-tabbed-content margin-after-tabs">
          <div class="tabs-wrap">
            <app-mobile-tabs #mobileTabs (onSelected)="onHandleTabClick($event)">
              <ul>
                <li><a (click)="mobileTabs.onSelectItem('site-info', $event)" class="selected">Trial Site Info</a>
                </li>
                <li *ngIf="authService.hasPermission(Permissions.SiteContactRead)"><a class="selected" (click)="mobileTabs.onSelectItem('site-contacts', $event)">Site
                    Contacts</a>
                </li>
              </ul>
            </app-mobile-tabs>

            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': selectedTab == 'site-info'}"
                  (click)="onHandleTabClick('site-info')">Trial Site Info</a>
              </li>
              <li class="nav-item" *ngIf="authService.hasPermission(Permissions.SiteContactRead)">
                <a class="nav-link" [ngClass]="{'active': selectedTab == 'site-contacts'}"
                  (click)="onHandleTabClick('site-contacts')">Site Contacts</a>
              </li>
            </ul>
          </div>

          <div class="results-wrap">

            <div class="tab-content" [ngClass]="{'visibility-hidden': selectedTab !== 'site-info'}">
              <form (mousemove)="onMouseEnterForm()" [formGroup]="form">

                <table aria-hidden="true" class="simple-form">
                  <tr>
                    <th><label>Site Number</label></th>
                    <td>
                      <div class="form-group">
                        <input type="text" id="site-number" class="form-control" formControlName="siteNumber">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style="min-width: 50px;"><label>Site</label></th>
                    <td>
                      <div class="form-group">
                        <app-site-autocomplete #siteAutocomplete
                          [allowAllSites]="false"
                          [assignedOnly]="false"
                          [unassignedOnly]="true"
                          [useSuggestEndpoint]="true"
                          [form]="form"
                          [trialFilter]="trial.id"
                           controlName="site" placeholder="Start typing to find a site..."
                          (valueChanged)="onSiteChanged(form.get('site').value)" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <a routerLink="/trial/{{ trialId }}/site/add" class="btn btn-outline btn-outline-slim">+ Add New
                        Site</a>
                    </td>
                  </tr>
                  <tr>
                    <th><label>Method of Initial Introduction</label></th>
                    <td>
                      <div class="form-group">
                        <app-dropdown-input [options]="introductionMethodOptions" placeholder="Select..."
                          [parentForm]="form" (selectValueChanged)="onIntroductionMethodChanged($event)"
                          controlName="introductionMethod"></app-dropdown-input>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th><label>Approval Status</label></th>
                    <td>
                      <div class="form-group">
                        <app-dropdown-input [options]="approvalStatusOptions" selectedValue="0" [parentForm]="form"
                          (selectValueChanged)="onApprovalStatusChanged($event)"
                          controlName="approvalStatus"></app-dropdown-input>
                      </div>
                    </td>
                  </tr>
                  <tr [ngClass]="{'visibility-hidden': form.get('approvalStatus').value != 1}">
                    <th><label>Date</label></th>
                    <td>
                      <div class="form-group">
                        <input type="text" #dateInput class="date" data-toggle="datepicker"
                          formControlName="approvalDate">
                      </div>
                    </td>
                  </tr>
                  <tr [ngClass]="{'visibility-hidden': form.get('approvalStatus').value != 2}">
                    <th><label>Declined Reason</label></th>
                    <td>
                      <div class="form-group">
                        <app-dropdown-input [options]="declinedReasonOptions" placeholder="Select..."
                          [parentForm]="form" (selectValueChanged)="onDeclinedReasonChanged($event)"
                          controlName="declinedReason"></app-dropdown-input>
                      </div>
                    </td>
                  </tr>
                  <tr [ngClass]="{'visibility-hidden': form.get('approvalStatus').value != 2}">
                    <th><label>Declined Reason Text</label></th>
                    <td>
                      <div class="form-group">
                        <textarea type="text" id="declined-text" class="form-control"
                          formControlName="declinedText"></textarea>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td>
                      <div class="checkbox-wrap">
                        <div class="form-group">
                          <label class="checkbox">
                            <input formControlName="introductionOccurred" id="introductionOccurred" type="checkbox"
                              value="true">
                            <span></span>
                          </label>
                          <label class="checkbox-label" for="introductionOccurred">Introduction Session Occurred</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [class.visibility-hidden]="!form.get('introductionOccurred').value">
                    <th><label>Introduction Session Date</label></th>
                    <td>
                      <div class="form-group">
                        <input #introductionDateInput class="date" data-toggle="datepicker"
                          formControlName="introductionDate" type="text">
                      </div>
                    </td>
                  </tr>
                </table>

              </form>
            </div>

            <div *ngIf="authService.hasPermission(Permissions.SiteContactRead)" [ngClass]="{'visibility-hidden': selectedTab !== 'site-contacts'}" class="tab-content">
              <app-manage-site-contacts #trialSiteContacts></app-manage-site-contacts>
            </div>

            <table *ngIf="!hideActionButtons" aria-hidden="true" class="buttons">
              <tr>
                <th></th>
                <td>
                  <div class="btn-wrap">
                    <div class="row">
                      <div class="col-6">
                        <a class="btn btn-secondary" routerLink="/trial/{{ trialId }}">Cancel</a>
                      </div>
                      <div class="col-6">
                        <button (click)="onFormSubmit()" [disabled]="!form.valid" class="btn btn-primary" type="submit">
                          <span *ngIf="isFormProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                          <span *ngIf="!isFormProcessing">Assign</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>

    </div>

  </div>
</div>
