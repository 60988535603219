<app-modal #modal class="create-expense-modal">
  <div class="modal-header">
    <h2 class="modal-title">Edit Expense</h2>
  </div>
  <div class="modal-body">
    <form (submit)="onFormSubmit()" [formGroup]="form">

      <table aria-hidden="true" class="simple-form">
        <tr>
          <th><label>Trial</label></th>
          <td>
            <div class="form-group">
              <app-trial-autocomplete #trialAutocomplete [form]="form" controlName="trialId"
                (valueChanged)="onTrialChanged($event)" placeholder="Select a trial..."></app-trial-autocomplete>
            </div>
          </td>
        </tr>
        <tr>
          <th style="width: 150px;"><label>Visit</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #visitSelect (selectValueChanged)="onSelectChanged('visitId', $event)"
                [options]="visitOptions" [parentForm]="form" name="visitId"
                placeholder="Select Visit..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Payment Method</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #paymentMethodSelect (selectValueChanged)="onSelectChanged('paymentMethod', $event)"
                [options]="paymentMethodOptions" [parentForm]="form" name="paymentMethod"
                placeholder="Select Payment Method..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Expense Category</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #categorySelect (selectValueChanged)="onSelectChanged('categoryId', $event)"
                [options]="categoryOptions" [parentForm]="form" name="categoryId"
                placeholder="Select Category..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr *ngIf="expenseClaimDetails !== undefined && expenseClaimDetails.subCategories !== undefined"
          [ngClass]="{'visibility-hidden': selectedCategory === null || selectedCategory.internalType.toLowerCase() !== 'other'}">
          <th></th>
          <td>
            <div class="radio-button-container">
              <div *ngFor="let subCategory of expenseClaimDetails.subCategories" class="form-group">
                <label class="radio-button">
                  <input [value]="subCategory.id" formControlName="subCategoryId" type="radio" />
                  <span></span>
                  {{ subCategory.name }}
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Currency</label></th>
          <td>
            <div class="form-group">
              <app-autosuggest-dropdown-input #currencySelect (selectValueChanged)="onSelectChanged('currency', $event)"
                [options]="currencyOptions" [parentForm]="form" controlName="currency"
                placeholder="Select Currency..."></app-autosuggest-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Amount</label></th>
          <td>
            <div class="form-group amount-container">
              <input class="form-control" formControlName="amount" type="number" (wheel)="disableScrollWheel($event)"
                (keydown)="numberHelper.decimalFilter($event)">
              <span *ngIf="form.controls.subCategoryId.value === ExpenseSubCategory.CardPreloadRedemption">-</span>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': !showTransformedCurrency}">
          <th class="valign-top"><label>&nbsp;</label></th>
          <td>
            <a class="col-12 btn btn-primary" (click)="onShowTransformedFxRate()">
              <span *ngIf="transformedCurrencyProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Loading...</span>
              </span>
              <span *ngIf="!transformedCurrencyProcessing">FX</span>
            </a>
          </td>
        </tr>
        <tr *ngIf="showFxTransformError && !canTransformRate"
          [ngClass]="{'visibility-hidden': selectedCategory.type != 'Value'}">
          <th class="valign-top"><label>&nbsp;</label></th>
          <td>
            <div class="col-12 col-lg-12 quoted-amount-error-message-wrapper">
              <span class="quoted-amount-error-message">
                The quoted amount, currency and the base currency on connected
                trial cannot be blank.
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Amount Quoted (BC)</label></th>
          <td>
            <div class="form-group amount-container">
              <app-input-currency #amountQuotedBCControl [parentForm]="transformedFxRateForm" placeholder="0.00"
                amountControlName="transformedAmount" currencyControlName="transformedCurrency">
              </app-input-currency>
              <span *ngIf="form.controls.subCategoryId.value === ExpenseSubCategory.CardPreloadRedemption">-</span>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Distance'}">
          <th class="valign-top"><label>Distance Unit</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #distanceUnitSelect (selectValueChanged)="onSelectChanged('distanceUnit', $event)"
                [options]="distanceUnitOptions" [parentForm]="form" name="distanceUnit"
                placeholder="Select Distance Unit..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr [ngClass]="{'visibility-hidden': selectedCategory.type != 'Distance'}">
          <th class="valign-top"><label>Distance</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="distanceAmount" type="text">
            </div>
          </td>
        </tr>

        <tr>
          <th class="valign-top"><label>Expense Notes</label></th>
          <td>
            <div class="form-group">
              <textarea class="form-control" formControlName="notes"></textarea>
            </div>
          </td>
        </tr>
        <tr *ngIf="trial?.imsLegacyTrial">
          <th><label>Claim Form Id</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="claimFormId" type="text">
            </div>
          </td>
        </tr>
        <tr *ngIf="trial?.imsLegacyTrial">
          <th><label>Claim Form Date</label></th>
          <td>
            <div class="form-group form-datepicker">
              <mat-form-field>
                <input matInput [matDatepicker]="picker" formControlName="claimFormDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </td>
        </tr>
        <tr *ngIf="trial?.imsLegacyTrial">
          <th><label>Claim Form URL</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="claimFormUrl" type="text">
            </div>
          </td>
        </tr>
        <tr *ngIf="expenseClaimDetails && expenseClaimDetails.state === 'Rejected'">
          <th class="valign-top"><label>Rejection Reason</label></th>
          <td>
            <div class="form-group">
              <textarea class="form-control" formControlName="rejectionReason"></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>On Hold</label></th>
          <td>
            <div class="form-group">
              <label class="checkbox">
                <input formControlName="onHold" type="checkbox">
                <span></span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Sponsor Approved</label></th>
          <td>
            <div class="form-group">
              <label class="checkbox">
                <input formControlName="overspendApproved" type="checkbox">
                <span></span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Fully Reconciled</label></th>
          <td>
            <div class="form-group">
              <label class="checkbox">
                <input formControlName="fullyReconciled" type="checkbox">
                <span></span>
              </label>
            </div>
          </td>
        </tr>
        <tr>
          <th class="valign-top"><label>Reason for Edit</label></th>
          <td>
            <div class="form-group">
              <textarea class="form-control" formControlName="reasonForEdit"></textarea>
              <span *ngIf="form.get('reasonForEdit').errors?.invalidCharacters" class="error-label">
                Reason for edit
                contains invalid characters: '|', '+'.
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="btn-wrap">
              <div class="row">
                <div class="col-6">
                  <a (click)="onCancelEditing()" class="btn btn-secondary">Cancel</a>
                </div>
                <div class="col-6">
                  <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                    <span *ngIf="isFormProcessing">
                      <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                    </span>
                    <span *ngIf="!isFormProcessing">Save</span>
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </form>
  </div>
</app-modal>

<app-modal-v2 #recalculateAmountBcModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Recalculate BC amount ?</h2>
    </div>
    <div class="modal-body text-center">
      <p>Expense claim amount or currency changed, do you want to recalculate amount in BC ?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <button (click)="onFormSubmit(false)" class="btn btn-secondary">
            <span *ngIf="isFormProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isFormProcessing">No</span>
          </button>
        </div>

        <div class="col-6">
          <button (click)="onFormSubmit(true)" class="btn btn-primary">
            <span *ngIf="isFormProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isFormProcessing">Yes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>