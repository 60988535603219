import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { TrialService } from "../../../core/services/trial.service";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AlertService } from "../../../shared/alert/alert.service";
import { ChartItem } from "../../../shared/donut-chart/chart-item.model";
import { TemplateService } from "../../../core/services/template.service";
import { TrialReport } from "../../../core/models/trial-report.model";
import { StringHelper } from "../../../core/helpers/string-helper";
import { fromBeforeToDateValidator } from 'app/core/validators/from-before-to-date.validator';
import { DateHelper } from "../../../core/helpers/date-helper";

@Component({
  selector: 'app-trial-report',
  templateUrl: './trial-report.component.html',
  styleUrls: ['./trial-report.component.scss']
})
export class TrialReportComponent implements OnInit {
  @ViewChild('studySelect') studySelect: DropdownInputComponent;
  @ViewChild('fromDateInput') fromDateInput: ElementRef;
  @ViewChild('toDateInput') toDateInput: ElementRef;

  trialList: { value: string, text: string }[] = [];
  chartData: ChartItem[] = [];
  report = new TrialReport();
  reportLoaded = false;
  selectedTrials: string[] = [];
  trialCount = 0;
  hasTravelSplit = false;
  isFormProcessing = false;
  datePickerDisabled = true;
  lastMonthDisabled = true;
  last30DaysDisabled = true;

  filterForm: UntypedFormGroup;
  showViewAllBtn = false;

  allTimeToDate = DateHelper.addDaysToDate(new Date(), 365);

  constructor(private templateService: TemplateService, private trialService: TrialService, private alertService: AlertService) {
  }

  ngOnInit(): void {
    this.templateService.showHeader();
    this.filterForm = new UntypedFormGroup({
      trialId: new UntypedFormControl(),
      from: new UntypedFormControl({ value: this.getDateStr(new Date(2020, 0)), disabled: true }),
      to: new UntypedFormControl({ value: this.getDateStr(this.allTimeToDate), disabled: true }),
      allTime: new UntypedFormControl(true),
      lastMonth: new UntypedFormControl(false),
      last30Days: new UntypedFormControl(false),
    }, { validators: fromBeforeToDateValidator }
    );

    this.filterForm.get('trialId').valueChanges.subscribe(trialId => {
      if (trialId !== null && trialId !== '') {
        this.showViewAllBtn = true;
      } else {
        this.showViewAllBtn = false;
        this.studySelect.reset();
      }
    });

    this.filterForm.get('allTime').valueChanges.subscribe(value => {
      if (value) {
        this.filterForm.patchValue({
          lastMonth: false,
          last30Days: false
        })
        this.filterForm.patchValue({
          from: this.getDateStr(new Date(2020, 0)),
          to: this.getDateStr(this.allTimeToDate)
        });
        this.filterForm.get('from').disable();
        this.filterForm.get('to').disable();
      } else {
        this.filterForm.get('from').enable();
        this.filterForm.get('to').enable();
      }
    });

    this.filterForm.get('last30Days').valueChanges.subscribe(value => {
      if (value) {
        let fromDate = new Date()
        fromDate.setDate(fromDate.getDate() - 30)
        let toDate = new Date()

        this.filterForm.patchValue({
          allTime: false,
          lastMonth: false,
          from: fromDate.toLocaleDateString("en-GB"),
          to: toDate.toLocaleDateString("en-GB")
        });

        this.filterForm.get('from').disable();
        this.filterForm.get('to').disable();
      } else {
        this.filterForm.get('from').enable();
        this.filterForm.get('to').enable();
      }
    })

    this.filterForm.get('lastMonth').valueChanges.subscribe(value => {
      if (value) {
        let fromDate = new Date()
        fromDate.setDate(1);
        fromDate.setHours(-1);
        fromDate.setDate(1);
        let toDate = new Date()
        toDate.setDate(1);
        toDate.setHours(-1);

        this.filterForm.patchValue({
          allTime: false,
          last30Days: false,
          from: fromDate.toLocaleDateString("en-GB"),
          to: toDate.toLocaleDateString("en-GB")
        });
        this.filterForm.get('from').disable();
        this.filterForm.get('to').disable();
      } else {
        this.filterForm.get('from').enable();
        this.filterForm.get('to').enable();
      }
    })

    this.onFormSubmit();
  }

  onMouseEnterForm() {
    if (!this.filterForm.get('allTime').value) {
      if (this.fromDateInput.nativeElement.value != this.filterForm.get('from')) {
        this.filterForm.patchValue({ from: this.fromDateInput.nativeElement.value });
      }

      if (this.toDateInput.nativeElement.value != this.filterForm.get('to')) {
        this.filterForm.patchValue({ to: this.toDateInput.nativeElement.value });
      }
    }
  }

  loadReport(trialIds: string[], from: string, to: string) {
    this.trialService.report(trialIds, from, to).subscribe({
      next: (report: any) => {
        this.report = report;

        this.chartData = [];
        this.hasTravelSplit = false;

        if (report.carTrips > 0 || report.planTrips > 0 || report.trainTrips > 0) {
          this.chartData.push(new ChartItem('Car', report.carPercent, report.carPercent + '%', '#c5131b'));
          this.chartData.push(new ChartItem('Plane', report.planePercent, report.planePercent + '%', '#fec000'));
          this.chartData.push(new ChartItem('Train', report.trainPercent, report.trainPercent + '%', '#00b958'));
          this.hasTravelSplit = true;
        }

        this.selectedTrials = report.trialIds;
        this.trialCount = report.trialIds.length;

        this.loadTrials();

        this.reportLoaded = true;
        this.isFormProcessing = false;
      },
      error: (error) => {
        LogHelper.log(error);
        this.isFormProcessing = false;
      }
    });
  }

  onViewAll() {
    this.filterForm.patchValue({ trialId: '' });
  }

  loadTrials() {
    this.trialService.retrieveTrials(1, null, 9999).subscribe({
      next: (trials: any) => {
        trials.results.sort((a, b) => a.code.localeCompare(b.code));

        this.trialList = [];

        trials.results.forEach(trial => {
          this.trialList.push({ value: trial.id, text: StringHelper.limit(trial.label, 30) });
        });
      },
      error: (error) => {
        this.alertService.showWarningAlert("Unable to load list of trials");
      }
    });
  }

  getDateStr(date: Date) {
    return (`00${date.getDate()}`).slice(-2) + '/' + (`00${+date.getMonth() + 1}`).slice(-2) + '/' + date.getFullYear();
  }

  onFormSubmit() {
    if (this.filterForm.valid) {
      let trialId = this.filterForm.get('trialId').value
      let from = this.filterForm.get('from').value
      let to = this.filterForm.get('to').value

      this.isFormProcessing = true;
      if (trialId !== null && trialId !== '') {
        this.loadReport([trialId], from, to);
      } else {
        this.loadReport([], from, to);
      }
    }
  }
}
