import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ExpenseListComponent } from './expense-list/expense-list.component';
import { SharedModule } from "../../shared/shared.module";
import { CreateExpenseModalComponent } from './create-expense-modal/create-expense-modal.component';
import { EditExpenseModalComponent } from './edit-expense-modal/edit-expense-modal.component';
import { CheckExpenseModalComponent } from './check-expense-modal/check-expense-modal.component';
import { ExpenseCardComponent } from './expense-card/expense-card.component';
import { ExpenseCheckPreviewComponent } from './expense-check-preview/expense-check-preview.component';
import { ExpenseDetailsHeadComponent } from './expense-list/expense-details-head/expense-details-head.component';
import { ExpenseStateBadgeComponent } from './expense-list/expense-state-badge/expense-state-badge.component';
import { ExpenseMetaDataComponent } from './expense-list/expense-meta-data/expense-meta-data.component';
import { ExpenseCostComponent } from './expense-list/expense-cost/expense-cost.component';
import { ExpenseDescriptionComponent } from './expense-list/expense-description/expense-description.component';
import { ExpenseImagesComponent } from './expense-list/expense-images/expense-images.component';
import { ExpenseActionsComponent } from './expense-list/expense-actions/expense-actions.component';
import { ExpenseEditReasonsComponent } from './expense-list/expense-edit-reasons/expense-edit-reasons.component';
import { ExpenseRejectReasonComponent } from './expense-list/expense-reject-reason/expense-reject-reason.component';
import {
  ExpenseCaxtonDetailsHeadComponent
} from './expense-list/expense-caxton-details-head/expense-caxton-details-head.component';
import {
  ExpenseCaxtonPaymentDatesComponent
} from './expense-list/expense-caxton-payment-dates/expense-caxton-payment-dates.component';
import {
  ExpenseCaxtonPaymentActionsComponent
} from './expense-list/expense-caxton-payment-actions/expense-caxton-payment-actions.component';
import {
  ExpenseAutoApprovalResultComponent
} from './expense-list/expense-auto-approval-result/expense-auto-approval-result.component';
import { TrialModule } from '../trial/trial.module';
import { StartInvestigationModalComponent } from './start-investigation-modal/start-investigation-modal.component';
import { CloseInvestigationModalComponent } from './close-investigation-modal/close-investigation-modal.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import {
  ApprovedExpensesContainerComponent
} from './approved-expenses-container/approved-expenses-container.component';
import {
  OverBudgetCategoriesSelectModalComponent
} from './over-budget-categories-select-modal/over-budget-categories-select-modal.component';
import {
  OverBudgetRequestModalComponent
} from './expense-list/over-budget-request-modal/over-budget-request-modal.component';
import { ExpenseAddNotesComponent } from './expense-list/expense-add-notes/expense-add-notes.component';
import { ExpenseImageComponent } from "./expense-list/expense-images/expense-image/expense-image.component";
import { ButtonComponent } from "../../shared/button/button.component";
import { DuplicateExpenseModalComponent } from "./duplicate-expense-modal/duplicate-expense-modal.component";
import { MatDatepickerModule } from '@angular/material/datepicker';


@NgModule({
  declarations: [
    ExpenseListComponent,
    CreateExpenseModalComponent,
    EditExpenseModalComponent,
    CheckExpenseModalComponent,
    ExpenseCardComponent,
    ExpenseCheckPreviewComponent,
    ExpenseDetailsHeadComponent,
    ExpenseStateBadgeComponent,
    ExpenseMetaDataComponent,
    ExpenseCostComponent,
    ExpenseDescriptionComponent,
    ExpenseImagesComponent,
    ExpenseActionsComponent,
    ExpenseEditReasonsComponent,
    ExpenseRejectReasonComponent,
    ExpenseCaxtonDetailsHeadComponent,
    ExpenseCaxtonPaymentDatesComponent,
    ExpenseCaxtonPaymentActionsComponent,
    ExpenseAutoApprovalResultComponent,
    StartInvestigationModalComponent,
    CloseInvestigationModalComponent,
    ApprovedExpensesContainerComponent,
    OverBudgetCategoriesSelectModalComponent,
    OverBudgetRequestModalComponent,
    ExpenseAddNotesComponent,
    ExpenseImageComponent,
    DuplicateExpenseModalComponent,
    DuplicateExpenseModalComponent
  ],
  exports: [
    EditExpenseModalComponent,
    CreateExpenseModalComponent,
    ExpenseCheckPreviewComponent,
    StartInvestigationModalComponent,
    CloseInvestigationModalComponent,
    DuplicateExpenseModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatInputModule,
    TrialModule,
    ButtonComponent
  ]
})
export class ExpenseModule { }
