import { Component, EventEmitter, inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Permissions } from "../../../core/constants/permissions";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SelectOption } from "../../../core/models/select-option.model";
import {
  AssignCardsRequest,
  CaxtonCardAction,
  CaxtonCardDelivery,
  UpdateCaxtonCardsDeliveryStatusRequest,
  UpdateCaxtonCardsExpiryDateRequest
} from "../../../core/models/card-tracker.model";
import { TrialAutocomplete } from "../../../shared/trial-autocomplete/trial-autocomplete.model";
import { LogHelper } from "../../../core/helpers/log.helper";
import { SiteService } from "../../../core/services/site.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { CaxtonCardService } from "../../../core/services/caxton-card.service";
import { TrialAutocompleteComponent } from "../../../shared/trial-autocomplete/trial-autocomplete.component";

@Component({
    selector: 'app-caxton-card-action-selector',
    templateUrl: './caxton-card-action-selector.component.html',
    styleUrl: './caxton-card-action-selector.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class CaxtonCardActionSelectorComponent implements OnInit {
    @ViewChild('actionSelect') actionSelect: DropdownInputComponent;
    @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
    @ViewChild('siteAutocomplete') siteAutocomplete: DropdownInputComponent;

    @Output('changed') changed = new EventEmitter();

    private readonly _siteService = inject(SiteService);
    private readonly _alertService = inject(AlertService);
    private readonly _caxtonCardService = inject(CaxtonCardService);

    protected readonly CaxtonCardAction = CaxtonCardAction;
    protected readonly Permissions = Permissions;
    protected actions: SelectOption[] = [];
    protected selectedCardIds: string[] = [];
    protected assignCardsSiteOptions: SelectOption[] = [];
    protected actionForm: FormGroup = new FormGroup({
        isBusy: new FormControl<boolean>(false),
        action: new FormControl<string>('', Validators.required),
    });
    protected assignForm: FormGroup = new FormGroup({
        isBusy: new FormControl<boolean>(false),
        trialId: new FormControl<string>(null, Validators.required),
        siteId: new FormControl<string>(null),
        siteContact: new FormControl<string>(null),
        patientDetails: new FormControl<string>(null),
        sendToPatient: new FormControl<boolean>(false)
    });
    protected expiryDateForm: FormGroup = new FormGroup({
        isBusy: new FormControl<boolean>(false),
        expiryDate: new FormControl(null, Validators.required)
    });
    protected sentForm: FormGroup = new FormGroup({
        isBusy: new FormControl<boolean>(false),
        courierCost: new FormControl(null),
        trackingNumber: new FormControl(null)
    });
    protected deliveryActions: string[] = [
        CaxtonCardAction.Missing.toString(),
        CaxtonCardAction.Received.toString()
    ];

    ngOnInit() {
        this.actions.push({value: CaxtonCardAction.Assign.toString(), text: 'Assign Cards'});
        this.actions.push({value: CaxtonCardAction.Received.toString(), text: 'Mark as Received'});
        this.actions.push({value: CaxtonCardAction.Sent.toString(), text: 'Mark as Sent'});
        this.actions.push({value: CaxtonCardAction.Missing.toString(), text: 'Mark as Missing'});
        this.actions.push({value: CaxtonCardAction.ExpiryDate.toString(), text: 'Set Expiry Date'});

        this.actionForm.get('action').valueChanges.subscribe(action => {
            if (!action)
                return;

            switch (action) {
              case CaxtonCardAction.Assign.toString():
                this.trialAutocomplete.reset();
                this.siteAutocomplete.reset();
                this.assignForm.patchValue({trialId: null, siteId: null, siteContact: null, patientDetails: null, sendToPatient: false});
                break;
            }

            if (this.deliveryActions.includes(action)) {
                switch (action) {
                    case CaxtonCardAction.Received.toString():
                        this.updateDeliveryStatus(CaxtonCardDelivery.Received);
                        break;
                    case CaxtonCardAction.Missing.toString():
                        this.updateDeliveryStatus(CaxtonCardDelivery.Missing);
                        break;
                }
            }
        });
    }

    shouldShowForm(action: CaxtonCardAction) {
        return this.actionForm.get('action').value === action.toString() && this.selectedCardIds.length > 0;
    }

    clearSelection() {
        this.selectedCardIds = [];
        this.clearAction();
    }

    clearAction() {
        this.actionForm.patchValue({action: null});
        this.actionSelect.reset();
    }

    addCard(id: string) {
        if (!this.selectedCardIds.includes(id))
            this.selectedCardIds.push(id);
    }

    cardIsSelected(id: string) {
        return this.selectedCardIds.includes(id);
    }

    removeCard(cardId: string) {
        this.selectedCardIds = this.selectedCardIds.filter(c => c !== cardId);
    }

    cardCountLabel() {
        let label = this.selectedCardIds.length + ' card';
        if (this.selectedCardIds.length === 0 || this.selectedCardIds.length > 1)
            label += 's';

        return label + ' selected';
    }

    updateDeliveryStatus(deliveryStatus: CaxtonCardDelivery) {
        let updateCaxtonCardsDeliveryStatus: UpdateCaxtonCardsDeliveryStatusRequest = {
            cardIds: this.selectedCardIds,
            delivery: deliveryStatus,
            courierCost: null,
            trackingNumber: null
        }

        this._caxtonCardService.updateCardsDeliveryStatus(updateCaxtonCardsDeliveryStatus).subscribe({
            next: () => {
                this._alertService.showSuccessAlert("Cards successfully updated.");
                this.clearSelection();
                this.changed.emit();
            },
            error: error => {
                this._alertService.showErrorResponse(error.error);
            }
        })
    }

    onSubmitSetExpiryDate(): void {
        if (this.expiryDateForm.invalid)
            return;

        this.expiryDateForm.patchValue({isBusy: true});

        let request = this.expiryDateForm.value as UpdateCaxtonCardsExpiryDateRequest;
        request.cardIds = this.selectedCardIds;
        request.expiryDate = this.expiryDateForm.get('expiryDate').value;

        this._caxtonCardService.updateCardsExpiryDate(request).subscribe({
            next: () => {
                this._alertService.showSuccessAlert("Cards successfully updated.");
                this.expiryDateForm.patchValue({isBusy: false});
                this.clearSelection();
                this.changed.emit();
            },
            error: error => {
                this.expiryDateForm.patchValue({isBusy: false});
                this._alertService.showErrorResponse(error.error);
            }
        })
    }

    onSubmitSentForm(): void {
        if (this.sentForm.invalid)
            return;

        let updateCaxtonCardsDeliveryStatus: UpdateCaxtonCardsDeliveryStatusRequest = {
            cardIds: this.selectedCardIds,
            delivery: CaxtonCardDelivery.Sent,
            courierCost: this.sentForm.get('courierCost').value,
            trackingNumber: this.sentForm.get('trackingNumber').value,
        }

        this._caxtonCardService.updateCardsDeliveryStatus(updateCaxtonCardsDeliveryStatus).subscribe({
            next: () => {
                this._alertService.showSuccessAlert("Cards successfully updated.");
                this.clearSelection();
                this.changed.emit();
            },
            error: error => {
                this._alertService.showErrorResponse(error.error);
            }
        })
    }

    onAssignCardTrialFilterChanged(selection: TrialAutocomplete): void {
      if (!selection)
        this.siteAutocomplete.reset();

        if (this.assignForm.invalid)
            return;

        this.assignForm.controls.trialId.setValue(selection.id);

        this._siteService.retrieveSites(1, selection.id, null, null, false, null, 9999).subscribe({
            next: response => {
                this.assignCardsSiteOptions = response.results.map(r => new SelectOption(r.id, `${r.name}-${r.address}`));
            },
            error: error => {
                this._alertService.showWarningAlert("Failed to get sites. Please try again or contact support");
                LogHelper.log(error);
            }
        })
    }

    onSubmitAssignCards(): void {
        this.actionForm.patchValue({isBusy: true});

        let assignCardsRequest = this.assignForm.value as AssignCardsRequest;
        assignCardsRequest.cardIds = this.selectedCardIds;

        this._caxtonCardService.assignCards(assignCardsRequest).subscribe({
            next: () => {
                this._alertService.showSuccessAlert("Cards successfully updated.");
                this.actionForm.patchValue({isBusy: false});
                this.clearSelection();
                this.changed.emit();
            },
            error: error => {
                this.actionForm.patchValue({isBusy: false});
                this._alertService.showErrorResponse(error.error);
            }
        })
    }
}
