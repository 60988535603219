import { SearchPage } from "./search-page.model";

export class TrialListItem {
  constructor(
    public id: string,
    public code: string,
    public label: string,
    public visitCount: number,
    public state: string,
    public supportEmail: string,
    public supportPhone: string,
    public oracleCode: string,
    public apiEnabled: boolean,
    public diaryEnabled: boolean
  ) {
  }
}

export class TrialList extends SearchPage {
  results: TrialListItem[] = [];

  buildFromResponse(response: TrialList): TrialList {
    this.currentPage = response.currentPage;
    this.pageSize = response.pageSize;
    this.totalRecordCount = response.totalRecordCount;
    this.filteredRecordCount = response.filteredRecordCount;
    this.policyReminders = response.policyReminders;
    this.totalPageCount = Math.ceil(response.filteredRecordCount / response.pageSize);

    this.results = response.results.map(t => new TrialListItem(
      t.id,
      t.code,
      t.label,
      t.visitCount,
      t.state,
      t.supportEmail,
      t.supportPhone,
      t.oracleCode,
      t.apiEnabled,
      t.diaryEnabled
    ));

    return this;
  }
}
