<div #autoCompleteContainer (click)="onClick()"
     [ngClass]="{'focus': showDropdown, 'disabled': disabled, 'loading': resultsAreLoading}"
     class="autocomplete">
  <label *ngIf="selected !== null">{{ truncate(selected.line1) }}</label>
  <label class="placeholder" *ngIf="selected === null">{{ truncate(placeholder) }}</label>

  <a class="arrow"><i class="far fa-angle-down" aria-hidden="true"></i></a>

  <div #dropdown class="autocomplete-dropdown" [ngClass]="{'visibility-hidden': !showDropdown, 'dropdown-upwards': dropdownUpwards}">
    <div class="autocomplete-search">
      <div [formGroup]="form" class="form-group search-container">
        <a (click)="resetSearch($event)"><i class="far fa-times" *ngIf="form.get('input').value"></i></a>
        <input #input formControlName="input" type="text" (click)="$event.stopPropagation();">
      </div>

      <i class="far fa-search" aria-hidden="true"></i>
      <span *ngIf="allowAllSites && !restrictToUnassignedSites" class="filter"><a
        (click)="onShowAll(); $event.stopPropagation()" *ngIf="!showAllSites">show all sites</a></span>
      <span *ngIf="allowAllSites && !restrictToUnassignedSites" class="filter"><a
        (click)="onShowTrialSites(); $event.stopPropagation()" *ngIf="showAllSites">show trial sites</a></span>
    </div>

    <p *ngIf="resultsAreLoading && searchHasValue">Loading...</p>
    <p *ngIf="!resultsAreLoading && !searchHasValue">Start typing to find a site...</p>
    <p *ngIf="filteredOptions.length === 0 && !resultsAreLoading && searchHasValue">No results found! {{ filteredOptions.length }}</p>

    <div *ngIf="filteredOptions.length > 0 && resultsAreLoading === false" class="autocomplete-results">
      <ul>
        <ng-container *ngFor="let option of filteredOptions">
          <li *ngIf="!restrictToUnassignedSites && option.visible"
              [id]="option.id"
              [ngClass]="{'highlight': option.highlight, 'visibility-hidden': option.highlight && !allowAllSites}">
            <a (click)="onSelectOption(option)">
              <span class="badge badge-info" *ngIf="option.apiConsumerName">{{ option.apiConsumerName }}</span>
              <span>{{ option.line1 }}</span>
              <span>{{ option.line2 }}</span>
            </a>
          </li>

          <li *ngIf="restrictToUnassignedSites && option.visible" [id]="option.id"
              [ngClass]="{'visibility-hidden': !option.highlight}">
            <a (click)="onSelectOption(option)">
              <span class="badge badge-info" *ngIf="option.apiConsumerName">{{ option.apiConsumerName }}</span>
              <span>{{ option.line1 }}</span>
              <span>{{ option.line2 }}</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="loading-spinner">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
