<app-modal-v2 #beneficiariesListModal customClassName="beneficiaries-list-modal">
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title text-center">Select beneficiary</h2>
    </div>
    <div class="modal-body">
      <p>Beneficiary with the same name and currency already exists in Caxton. Please confirm beneficiary selection</p>
      <div class="beneficiaries-wrapper">
        <div class="beneficiary-preview" *ngIf="selectedBeneficiary">
          <h3>Beneficiary preview</h3>
          <label>First name: <span>{{selectedBeneficiary.firstName}}</span></label>
          <label>Last name: <span>{{selectedBeneficiary.lastName}}</span></label>
          <label>Currency: <span>{{selectedBeneficiary.currency}}</span></label>
          <label>Country: <span>{{selectedBeneficiary.country}}</span></label>
          <label *ngIf="selectedBeneficiary.iban">Iban: <span>{{selectedBeneficiary.iban}}</span></label>
          <label *ngIf="selectedBeneficiary.bankCode">Bank Code: <span>{{selectedBeneficiary.bankCode}}</span></label>
          <label *ngIf="selectedBeneficiary.sortCode">Sort Code: <span>{{selectedBeneficiary.sortCode}}</span></label>
          <label *ngIf="selectedBeneficiary.swift">Swift: <span>{{selectedBeneficiary.swift}}</span></label>
          <label *ngIf="selectedBeneficiary.accountNumber">Account number:
            <span>{{selectedBeneficiary.accountNumber}}</span></label>
        </div>
        <form [formGroup]="beneficiaryForm" class="beneficiaries">
          <label for="search">Search beneficiaries</label>
          <input type="text" id="search" class="form-control" formControlName="beneficiarySearch"
            placeholder="Search beneficiary">
          <mat-radio-group formControlName="beneficiaryId" name="beneficiaryId"
            *ngFor="let beneficiary of filteredBeneficiaries">
            <mat-radio-button [value]="beneficiary.id" (change)="updateBeneficiaryPreview($event)" [color]="'primary'"
              [checked]="beneficiary.id === selectedBeneficiary.id">{{ getBeneficiaryTitle(beneficiary)
              }}</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>

      <button (click)="onUpdateBeneficiary()" class="btn btn-primary" [disabled]="processingRequest">
        <span *ngIf="processingRequest">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!processingRequest">Save</span>
      </button>
    </div>
  </div>
</app-modal-v2>