export class VisitScheduleModel {
  visitTitle: string;
  scheduleIndex: number;
}

export class UpdateVisitScheduleRequest extends VisitScheduleModel {
  templateId: string;
}

export class VisitScheduleViewModel {
  public templateId: string;
  public scheduleIndex: number;
  public title: string;
  public keyVisit: boolean;
  public amount?: number;
  public caregiverAmount?: number;
  public editable: boolean = true;
  public inUse: boolean = false;

  constructor(init?: Partial<VisitScheduleViewModel>) {
    Object.assign(this, init);
  }
}
