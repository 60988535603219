<div [formGroup]="parentForm" class="input-multi-select" [class.allow-clear]="allowClear"
  [ngClass]="{'disabled' : parentForm.get(controlName).disabled}">
  <div class="clear-all" *ngIf="allowClear && this.parentForm.get(controlName).value.length > 0">
    <a (click)="onClear()"><i class="far fa-times"></i></a>
  </div>

  <mat-select #matSelect [formControlName]="controlName" multiple [placeholder]="inputPlaceholder"
    [disabled]="parentForm.get(controlName).disabled">
    <input class="search-input" matInput #searchInput (keyup)="onSearchInputChanged()"
      [placeholder]="searchInputPlaceholder" *ngIf="allowFiltering">

    <div class="select-all" *ngIf="allowSelectDeselect">
      <a (click)="onSelectAll()">Select all</a> <a (click)="onDeselectAll()"
        *ngIf="parentForm.get(controlName).value !== null && parentForm.get(controlName).value.length > 0">Deselect
        all</a>
    </div>

    <mat-option *ngFor="let item of items" [value]="item.value" (click)="onItemClicks(item)">{{item.text}}</mat-option>
  </mat-select>
</div>