import { Component } from '@angular/core';

@Component({
  selector: 'app-trial-generic-diary',
  templateUrl: './trial-generic-diary.component.html',
  styleUrl: './trial-generic-diary.component.scss'
})
export class TrialGenericDiaryComponent {

}
