<app-modal-v2 #editTrialAssignment>
  <div class="modal-header">
    <h2 class="modal-title text-center">Edit administrator assignment</h2>
  </div>
  <div class="modal-body text-center">
    <form [formGroup]="editTrialAssignmentForm">
      <table class="simple-form edit-trial-assignment-form" aria-hidden="true">
        <tr>
          <th style="min-width: 50px; vertical-align: middle"><label>Countries </label></th>
          <td>
            <app-input-multi-select #editCountriesSelect [parentForm]="editTrialAssignmentForm" controlName="countries"
              [items]="filteredCountries" inputPlaceholder="Select countries"
              searchInputPlaceholder="Search for country..." (onFilter)="onCountryFiltered($event)" [allowFiltering]="true"></app-input-multi-select>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="checkbox-wrap">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="projectManager" formControlName="projectManager">
                  <span></span>
                </label>
                <label class="checkbox-label" for="projectManager">Project Manager</label>
              </div>
              <div class="designated-contacts-checkbox">
                <div class="form-group">
                  <label class="checkbox">
                    <input type="checkbox" id="designatedContact" formControlName="designatedContact">
                    <span></span>
                  </label>
                  <label class="checkbox-label" for="designatedContact">Designated Contact <span
                      class="helper-text">Expense/Travel Coordinators Only</span></label>
                </div>
              </div>
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="assignToAllCountries" formControlName="assignToAllCountries">
                  <span></span>
                </label>
                <label class="checkbox-label" for="assignToAllCountries">Assign administrator to all countries</label>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="hideTrialAssignmentModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>

      <div class="col-6">
        <button class="btn btn-primary" [disabled]="editTrialAssignmentForm.pristine"
          (click)="saveTrialAssignmentChanges()">
          Save
        </button>
      </div>
    </div>
  </div>
</app-modal-v2>
