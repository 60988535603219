import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ReimbursementSearch } from "../models/reimbursement-search.model";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';
import {
  ApproveTotalFromOverBudgetRequestResult,
  DeleteOverBudgetRequestRequest,
  GetOverBudgetRequestsRequest,
  OverBudgetRequestApproveAllRequest,
  OverBudgetRequestApproveTotalRequest,
  OverBudgetRequestHistoryViewModel,
  OverBudgetRequestItemsViewModel,
  OverBudgetRequestsViewModel,
  PartialRejectOverBudgetRequestRequest,
  ProjectManagementExportRequest,
  ReferBackOverBudgetRequestRequest,
  RejectOverBudgetRequestRequest,
  RejectOverBudgetRequestResult
} from '../models/project-management.model';
import { ReimbursmentSearchCriteria } from "../models/project-management/reimbursment-search-criteria.model";
import { ResponsePage } from '../models/search-page.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService {
  private _endpoint = '/projectmanagement';

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Gets a list of reimbursements
   * @param criteria
   * @param page
   */
  public getReimbursements(criteria: ReimbursmentSearchCriteria, page: number = 1) {
    let params = new HttpParams();
    params = params.append('page', page.toString());

    if (criteria.searchTerm !== null && criteria.searchTerm !== '')
      params = params.append('searchTerm', criteria.searchTerm);

    if (criteria.startDate) {
      // Ensure the start date starts at midnight
      criteria.startDate.setHours(0, 0, 0);
      params = params.set('startDate', criteria.startDate.toISOString());
    }

    if (criteria.endDate) {
      // Ensure the end date ends at 11:59:59
      criteria.endDate.setHours(23, 59, 59);
      params = params.set('endDate', criteria.endDate.toISOString());
    }

    if (criteria.patientId)
      params = params.set('patientId', criteria.patientId);

    if (criteria.visitId)
      params = params.set('visitId', criteria.visitId);

    if (criteria.trialCode)
      params = params.set('trialCode', criteria.trialCode);

    if (criteria.type !== null && criteria.type !== '' && criteria.type !== 'All')
      params = params.set('type', criteria.type);

    params = params.set('myTrials', criteria.myTrials.toString());

    return this.http.get<ReimbursementSearch>(environment.apiUrl + this._endpoint + '/reimbursements', { params: params });
  }

  obToggleSponsorEmailed(id: string): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this._endpoint}/${id}/toggle-sponsor-emailed`, {});
  }

  exportReimbursements(exportReimbursementsRequest: ProjectManagementExportRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}${this._endpoint}/export-reimbursements`, exportReimbursementsRequest);
  }

  exportOverBudgetRequests(exportReimbursementsRequest: ProjectManagementExportRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}${this._endpoint}/export-over-budget-requests`, exportReimbursementsRequest);
  }

  getOverBudgetRequests(request: GetOverBudgetRequestsRequest): Observable<ResponsePage<OverBudgetRequestsViewModel>> {
    let params = new HttpParams();
    params = params.set('page', request.page);
    params = params.set('myTrials', request.myTrials);
    params = params.set('orderBy', request.orderBy);
    params = params.set('openOnly', request.openOnly);
    params = params.set('sponsorEmailed', request.sponsorEmailed);

    if (request.search) {
      params = params.set('search', request.search);
    }

    return this.http.get<ResponsePage<OverBudgetRequestsViewModel>>(`${environment.apiUrl}${this._endpoint}/over-budget-requests`, { params: params });
  }

  overBudgetRequestItems(overBudgetRequestId: string): Observable<OverBudgetRequestItemsViewModel[]> {
    return this.http.get<OverBudgetRequestItemsViewModel[]>(`${environment.apiUrl}${this._endpoint}/over-budget-request-details/${overBudgetRequestId}`);
  }

  deleteOverBudgetRequest(request: DeleteOverBudgetRequestRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this._endpoint}/delete-over-budget-request`, request);
  }

  referBackOverBudgetRequest(request: ReferBackOverBudgetRequestRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this._endpoint}/refer-back-over-budget-request`, request);
  }

  approveTotalFromOverBudgetRequest(request: OverBudgetRequestApproveTotalRequest): Observable<ApproveTotalFromOverBudgetRequestResult> {
    return this.http.put<ApproveTotalFromOverBudgetRequestResult>(`${environment.apiUrl}${this._endpoint}/over-budget-request-approve-total`, request);
  }

  approveAllFromOverBudgetRequest(request: OverBudgetRequestApproveAllRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this._endpoint}/over-budget-request-approve-all`, request);
  }

  rejectOverBudgetRequest(request: RejectOverBudgetRequestRequest): Observable<RejectOverBudgetRequestResult> {
    return this.http.put<RejectOverBudgetRequestResult>(`${environment.apiUrl}${this._endpoint}/over-budget-request-reject`, request);
  }

  partialRejectOverBudgetRequest(request: PartialRejectOverBudgetRequestRequest): Observable<RejectOverBudgetRequestResult> {
    return this.http.put<RejectOverBudgetRequestResult>(`${environment.apiUrl}${this._endpoint}/over-budget-request-partial-reject`, request);
  }

  viewHistory(overBudgetRequestId: string): Observable<OverBudgetRequestHistoryViewModel[]> {
    return this.http.get<OverBudgetRequestHistoryViewModel[]>(`${environment.apiUrl}${this._endpoint}/view-history/${overBudgetRequestId}`);
  }
}
