<div *ngIf="expense" class="expense-details">
  <div class="expense-details-head">

    <div class="patient">
      <span class="label"><a routerLink="/patient/{{expense.patientTrialId}}">{{expense.patientCode}}</a></span>
      <span class="field-value">{{expense.patientName}}</span>
      <span class="field-value">{{ getPatientAddress(expense.patientAddress, expense.patientCountry) }}</span>
    </div>

    <div *ngIf="expense.paymentMethod !== null" class="payment-method">
      <span class="field-value">
        {{expense.paymentMethod | enumToText: ExpenseClaimPaymentMethod }}
        <span *ngIf="expense.paymentMethod === ExpenseClaimPaymentMethod.CaxtonCard"> -
          {{expense.cardIdentifier}}</span>
      </span>
    </div>

    <div class="trial">
      <span class="label"><a routerLink="/trial/{{expense.trialId}}">{{expense.trialCode}}</a></span>
    </div>
  </div>

  <button (click)="onEdit.emit()" *ngIf="authService.hasPermission(Permissions.ExpenseWrite)"
    [disabled]="expense.overBudgetRequestRaised" class="btn btn-secondary btn-slim btn-edit">
    Edit
  </button>

  <a (click)="onStartInvestigation(expense.id)"
    *ngIf="authService.hasPermission(Permissions.ExpenseWrite) && !expense.underInvestigation && expense.state === ExpenseClaimState.Pending && showInvestigation"
    class="btn btn-secondary btn-slim btn-edit">
    Requires
    Investigation
  </a>

  <a (click)="onCloseInvestigation(expense.id)"
    *ngIf="authService.hasPermission(Permissions.ExpenseWrite) && expense.underInvestigation && expense.state === ExpenseClaimState.Pending && showInvestigation"
    class="btn btn-secondary btn-slim btn-edit btn-close-investigation">
    Close
    Investigation
  </a>

  <div class="badge-wrap">
    <span [ngClass]="{'badge-warning':  (expense.state === ExpenseClaimState.Pending || expense.state === ExpenseClaimState.CaxtonFailed),
                              'badge-success': expense.state === ExpenseClaimState.Approved,
                              'badge-danger': expense.state === ExpenseClaimState.Rejected,
                              'badge-grey': expense.state === ExpenseClaimState.Paid}" class="badge">
      {{ expense.state | enumToText: ExpenseClaimState }}
    </span>
    <span
      *ngIf="expense.state === ExpenseClaimState.Pending && (expense.overBudgetRequestStatus !== null || expense.overBudgetRequestRaised)"
      class="over-budget over-budget-{{(expense.overBudgetRequestStatus | enumToText: OverBudgetRequestDetailsStatus)?.replace(' ', '-') | lowercase}}">
      <mat-icon>error</mat-icon>
      Over budget - {{expense.overBudgetRequestStatus !== null ? (expense.overBudgetRequestStatus | enumToText:
      OverBudgetRequestDetailsStatus) : 'Pending'}} decision
    </span>
    <span class="over-budget referred-back" *ngIf="expense.state === ExpenseClaimState.Pending && expense.referredBack">
      <mat-icon>error</mat-icon>
      Over budget - Referred back
    </span>

    <span *ngIf="showExpensePaidBy" class="expense-paid-by">{{ getPaidByText(expense.paidBy) }}</span>

    <span *ngIf="expense.state === ExpenseClaimState.Approved && expense.dateApproved" class="label">
      on {{ expense.dateApproved | date: 'd MMM yyyy' }} at {{ expense.dateApproved | date: 'h:mm aaa' }}
    </span>

    <span *ngIf="expense.state === ExpenseClaimState.Rejected && expense.dateRejected" class="label">
      on {{ expense.dateRejected | date: 'd MMM yyyy' }} at {{ expense.dateRejected | date: 'h:mm aaa' }}
    </span>

    <span *ngIf="expense.state === ExpenseClaimState.Paid && expense.datePaid" class="label">
      on {{ expense.datePaid | date: 'd MMM yyyy' }} at {{ expense.datePaid | date: 'h:mm aaa' }}
    </span>
  </div>

  <span class="label submitted-date">
    {{expense.addedByPatient ? 'Submitted by patient on ' : 'Created by administrator on '}} {{
    expense.created | date: 'd MMM yyyy'
    }} at {{ expense.created | date: 'h:mm aaa'}}
  </span>

  <span class="label visit-desc">{{ expense.visitType | enumToText: VisitType }}: {{ expense.visitTitle }}: {{
    expense.visitAttendance | enumToText:VisitAttendance }}</span>

  <div *ngIf="expense.categoryType === ExpenseCategoryType.Distance" class="title-cost-wrap">
    <span class="result-heading title">{{ expense.categoryName }}</span>
    <span class="result-heading cost">
      {{ expense.distanceAmount }} {{ expense.distanceUnit }}
      <span *ngIf="expense.amount > 0">/ {{ expense.currency}} {{ expense.amount / 100 | number:'1.2-2' }}</span>
    </span>
  </div>

  <div *ngIf="expense.categoryType === ExpenseCategoryType.Value" class="title-cost-wrap">
    <span class="result-heading title">
      {{ expense.categoryName }}
      <span *ngIf="expense.subCategory !== null && expense.subCategory !== ExpenseSubCategory.None">
        - {{ expense.subCategory | enumToText: ExpenseSubCategory }}
      </span>
    </span>
    <span class="result-heading cost">{{ expense.currency}} <span class="negative-amount"
        *ngIf="expense.subCategory === ExpenseSubCategory.CardPreloadRedemption">-</span> {{ expense.amount / 100 |
      number:'1.2-2' }}</span>
  </div>

  <div *ngIf="showDescription" class="desc">
    <p *ngIf="expense.policyReminders" class="policy-reminder">
      <span class="text">
        <span class="text-style-1">Policy Reminders: </span>
        {{expense.policyReminders}}
      </span>
    </p>
    <p *ngIf="expense.notes">{{ expense.notes }}</p>
    <p *ngIf="expense.sendToFinanceReason && expense.state === ExpenseClaimState.Approved">
      <strong>
        Reason for manual
        payment:
      </strong> {{expense.sendToFinanceReason}}
    </p>
  </div>

  <p *ngIf="expense.underInvestigation && showInvestigation" class="rejected-reason orange">
    <strong>Reason for Investigation:</strong> {{ expense.reasonForInvestigation }}
  </p>
  <p *ngIf="expense.state === ExpenseClaimState.Rejected" class="rejected-reason">
    <strong>Rejection Reason:</strong> {{ expense.rejectionReason }}
  </p>

  <div *ngIf="showImages" class="images">
    <a *ngFor="let imageUrl of expense.imageUrls; let i = index" style="display: inline-block">
      <img (click)="viewReceipt(imageUrl.imageUrl)" [src]="imageUrl" alt="" />
    </a>
  </div>

  <ul *ngIf="authService.hasPermission(Permissions.ExpenseWrite) && expense.state !== ExpenseClaimState.Rejected"
    class="actions">
    <li *ngIf="expense.state === ExpenseClaimState.Pending && !expense.underInvestigation && showApproveOptions">
      <a (click)="onApproveExpenseClaim(expense.id, expense.categoryType)" class="btn btn-primary btn-slim">Approve</a>
    </li>

    <li *ngIf="expense.state === ExpenseClaimState.Approved && authService.hasPermission(Permissions.ExpenseWrite)">
      <a (click)="onMarkAsPaid(expense.id)" class="btn btn-primary btn-slim">Mark as Paid</a>
    </li>

    <li *ngIf="expense.state === ExpenseClaimState.Pending && showApproveOptions">
      <a (click)="onRejectModal(expense.id)" class="btn btn-outline btn-slim">Reject</a>
    </li>

    <li *ngIf="expense.state === ExpenseClaimState.Paid">&nbsp;</li>
  </ul>

  <div class="clearfix"></div>

  <div *ngIf="expense.reasonsForEdit.length > 0 && showReasonForEdit" class="reason-for-edit">
    <label><i aria-hidden="true" class="fas fa-pencil"></i> Reasons for edit</label>
    <ul>
      <li *ngFor="let reason of expense.reasonsForEdit">
        {{ reason.created | date: 'd MMM yyyy' }} at {{ reason.created | date: 'h:mm aaa' }} -
        {{reason.reason}}
      </li>
    </ul>
  </div>

</div>
