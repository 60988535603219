import { Component, inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DiaryConfigModalComponent } from "../diary-config-modal/diary-config-modal.component";
import { PatientService } from "../../../../core/services/patient.service";
import { PatientDiaryEntry } from "../../../../core/models/patient-diary-entry.model";
import { AlertService } from "../../../../shared/alert/alert.service";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { DateHelper } from "../../../../core/helpers/date-helper";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { PatientDiaryConfig } from "../../../../core/models/patient-diary-config.model";
import moment from "moment";
import { TimezoneSelectComponent } from "../../../../shared/timezone-select/timezone-select.component";
import { environment } from "../../../../../environments/environment";
import { TimezonesGMT } from "../../../../core/constants/timezones-gmt";

@Component({
  selector: 'app-patient-diary',
  templateUrl: './patient-diary.component.html',
  styleUrl: './patient-diary.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PatientDiaryComponent implements OnInit{
  @ViewChild('timezoneSelect') timezoneSelect: TimezoneSelectComponent;
  @ViewChild('diaryConfigModal') diaryConfigModal: DiaryConfigModalComponent;
  @ViewChild('dosageModal') dosageModal: ModalComponent;

  @Input('patientTrialId') patientTrialId: string;

  private readonly _patientService = inject(PatientService);
  private readonly _alertService = inject(AlertService);

  entries: PatientDiaryEntry[] = [];
  hasSubmissions = false;
  dosageForm = new FormGroup({
    isBusy: new FormControl(false),
    dosage: new FormControl(0, [Validators.required, , Validators.max(100)]),
    dosageText: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    startDate: new FormControl<Date>(null, Validators.required),
    minDate: new FormControl<Date>(null, Validators.required),
    dailyDoseReminderTime: new FormControl(''),
    dailyDoseReminderTimeUtc: new FormControl(''),
    dosageTimezone: new FormControl('', Validators.required),
    dosageTimezoneLabel: new FormControl(''),
  });
  diaryConfig: PatientDiaryConfig = null;
  allTimezones = TimezonesGMT.all();
  isDevelopmentEnv: boolean = false;

  ngOnInit() {
    this.loadEntries();
    this.loadDiaryConfig();

    if (environment.environment === 'DEVELOPMENT' || environment.environment === 'STAGING')
      this.isDevelopmentEnv = true;
  }

  loadEntries() {
    this._patientService.getPatientDiaryEntries(this.patientTrialId).subscribe({
      next: entries => {
        this.entries = entries.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

        this.hasSubmissions = entries.filter(e => e.submittedDate).length > 0
      },
      error: error => {
        LogHelper.log(error);
        this._alertService.showErrorAlert(error);
      }
    });
  }

  loadDiaryConfig(): void {
    this._patientService.patientDiaryConfig(this.patientTrialId).subscribe({
      next: config => {
        this.diaryConfig = config;
      },
      error: (error) => {
        this._alertService.showErrorAlert(error);
      }
    })
  }

  shouldShowUtcTime(sourceControl: string): boolean {
    let time = this.dosageForm.get(sourceControl).value;
    let timezoneStr = this.dosageForm.get('dosageTimezone').value;

    return time !== '' && timezoneStr !== null && timezoneStr !== '';
  }

  getFasting(entry: PatientDiaryEntry): string {
    if (!entry.submittedDate)
      return '-';

    return entry.fasting ? 'Yes' : 'No';
  }

  getMinutesExpressedAsTime(time: number): string {
    if (time === null)
      return '-';

    return DateHelper.convertMinutesTo24HourTime(time);
  }

  shouldShowAmendDosageButton(): boolean {
    if (this.diaryConfig === null)
      return false;

    // If today is after the diary end date - hide the button
    const today = new Date();
    if (this.diaryConfig.dailyDoseRemindersEnabled && today > new Date(this.diaryConfig.endDate))
      return false;

    if (!this.entries || this.entries.length === 0)
      return false;

    // If the last entry has been submitted - hide the button
    const lastEntry = this.entries[this.entries.length - 1];
    if (lastEntry.submittedDate)
      return false;

    return true;
  }

  onShowAmendDosageModal() {
    let startDate = new Date(this.diaryConfig.startDate);
    let today = new Date();

    if (today > startDate)
      startDate = today;

    let submittedEntries = this.entries.filter(entry => entry.submittedDate);
    if (submittedEntries.length > 0) {
      const lastSubmittedEntry = submittedEntries[submittedEntries.length - 1];
      startDate = DateHelper.addDaysToDate(lastSubmittedEntry.date, 1);
    }

    // If the date is today, we need to add one day
    // For development we want to allow the user to change the current day for testing purposes
    if (!this.isDevelopmentEnv && startDate <= today)
      startDate = DateHelper.addDaysToDate(today, 1);

    if (this.diaryConfig.dailyDoseRemindersEnabled) {
      this.dosageForm.get('dailyDoseReminderTime').setValidators(Validators.required);
      this.dosageForm.get('dailyDoseReminderTimeUtc').setValidators(Validators.required);
    } else {
      this.dosageForm.get('dailyDoseReminderTime').setValidators(null);
      this.dosageForm.get('dailyDoseReminderTimeUtc').setValidators(null);
    }

    this.dosageForm.get('dailyDoseReminderTime').updateValueAndValidity();
    this.dosageForm.get('dailyDoseReminderTimeUtc').updateValueAndValidity();

    this.dosageForm.patchValue({
      dosage: this.diaryConfig.dosage,
      dosageText: this.diaryConfig.dosageText,
      startDate: startDate,
      minDate: startDate,
      dailyDoseReminderTime: DateHelper.convertMinutesTo24HourTime(this.diaryConfig.dailyDoseReminderTimeMinutes),
      dailyDoseReminderTimeUtc: DateHelper.convertMinutesTo24HourTime(this.diaryConfig.dailyDoseReminderTimeMinutesUtc),
      dosageTimezone: this.diaryConfig.timezone
    });

    this.timezoneSelect.setTimezone(this.diaryConfig.timezone);

    this.dosageForm.get('dailyDoseReminderTime').valueChanges.subscribe(value => {
      this.updateTimeUtc('dailyDoseReminderTime', 'dailyDoseReminderTimeUtc', this.dosageForm);
    });

    this.dosageModal.show();
  }

  convertSubmittedDateTimeToPatientTimezone(entry: PatientDiaryEntry) {
    return DateHelper.convertToTimeZone(new Date(entry.submittedDate), entry.dosageTimezone);
  }

  getTimezoneWithOffset(timezone: string) {
    let t = this.allTimezones.find(a => a.zone === timezone);
    if (t === null)
      return '';

    return t.gmt + ' ' + t.name;
  }

  updateTimeUtc(sourceControl: string, targetControl: string, form: FormGroup) {
    let time = form.get(sourceControl).value;
    let timezoneStr = form.get('dosageTimezone').value;

    if (time === null || time === '' || timezoneStr === null || timezoneStr === '') {
      form.patchValue({ targetControl: '' });
      return;
    }

    let timezone = form.get('dosageTimezone').value;
    if (timezone === null || timezone === '')
      return;

    form.get(targetControl).setValue(this.convertToUTC(time, timezone));
  }

  convertToUTC(time: string, timezone: string): string {
    // Parse the input time and timezone
    const timeInZone = moment.tz(time, "HH:mm", timezone);

    // Convert to UTC and format it as a 24-hour string
    const timeInUTC = timeInZone.utc().format("HH:mm");

    return timeInUTC;
  }

  onDiaryConfigChanged() {
    this.loadEntries();
    this.loadDiaryConfig();
  }

  /**
   * When the timezone is changed we need to get the full text label of the selected timezone to show in the disabled
   * timezone input
   * @param option
   */
  onTimezoneChanged(option: { value: string, text: string }) {
    if (option)
      this.dosageForm.patchValue({dosageTimezoneLabel: option.text});
  }

  onResetDiary() {
    this._patientService.resetPatientDiary(this.patientTrialId).subscribe({
      next: rsp => {
        this._alertService.showSuccessAlert("Diary entries and settings have been reset");
        this.loadEntries();
      },
      error: err => {
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onClearUrineReminders() {
    this._patientService.clearUrineReminders(this.patientTrialId).subscribe({
      next: rsp => {
        this._alertService.showSuccessAlert("Urine reminders cleared for this patient");
      },
      error: err => {
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onClearVisitReminders() {
    this._patientService.clearVisitReminders(this.patientTrialId).subscribe({
      next: rsp => {
        this._alertService.showSuccessAlert("Visit reminders cleared for this patient");
      },
      error: err => {
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onDosageFormSubmit() {
    if (!this.dosageForm.valid)
      return;

    const startDate = this.dosageForm.get('startDate').value;
    const minDate = this.dosageForm.get('minDate').value;

    // If startDate is before minDate, return an error as an observable
    if (startDate < minDate) {
      this._alertService.showWarningAlert('Date cannot be before ' + this.dosageForm.get('minDate').value);
      return;
    }

    if (new Date(startDate) > new Date(this.diaryConfig.endDate)) {
      this._alertService.showWarningAlert('Date cannot be after the dosage end date');
      return;
    }

    this.dosageForm.patchValue({ isBusy: true });

    this._patientService.updatePatientDiaryDose(
      this.patientTrialId,
      this.dosageForm.get('dosage').value,
      this.dosageForm.get('dosageText').value,
      DateHelper.convert24HourTimeToMinutes(this.dosageForm.get('dailyDoseReminderTime').value),
      DateHelper.convert24HourTimeToMinutes(this.dosageForm.get('dailyDoseReminderTimeUtc').value),
      this.dosageForm.get('dosageTimezone').value,
      DateHelper.dateToYyyyMmDd(startDate)).subscribe({
      next: rsp => {
        this.dosageForm.patchValue({ isBusy: false });
        this.loadEntries();
        this.dosageModal.hide();
      },
      error: error => {
        LogHelper.log(error);
        this._alertService.showErrorAlert(error);
        this.dosageForm.patchValue({ isBusy: false });
      }
    });
  }

  protected readonly environment = environment;
}
