import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import moment from "moment";
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import {
  PatientTrip,
  PatientVisit,
  VisitTemplateResponse
} from "../../features/patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import {
  ActivateCardsRequest,
  ActivateCardsResult,
  ActivePatientTrialCodesViewModel,
  AssignAndMoveWalletRequest,
  AssignCardRequest,
  AssignCardResult,
  AssignWalletOnParentRequest,
  AssignWalletResult,
  AvailableWalletsResponse,
  CardPinViewModel,
  CreatePatientResponse,
  DeletePatientRequest,
  FullPatientPaymentInfo,
  GetCardPinRequest,
  PatientPaymentInfo,
  PatientPaymentInfoUpdateRequest,
  PatientRegistrationListViewModel,
  PatientRegistrationViewModel,
  UpdatePatientCaxtonBeneficiaryRequest,
  UpdatePatientTripInvoicedAmountBcRequest,
  UpdatePatientTripInvoicedAmountRequest,
  UpdatePatientTripInvoicedCurrencyRequest,
  UpdatePatientTripInvoiceNumberRequest,
  UpdatePatientTripQuotedAmountBcRequest,
  UpdatePatientTripQuotedAmountRequest,
  UpdatePatientTripQuotedCurrencyRequest
} from "../models/patient.model";
import { PatientNote } from "../models/patient-note.model";
import { DateHelper, IllyTime } from "../helpers/date-helper";
import {
  PatientVisitListItem
} from "../../features/patient/patient-detail/patient-detail-visits/patient-visit-list-item.model";
import { PatientDetail } from "../models/patient-detail.model";
import { PatientList } from "../models/patient-list.model";
import { ChangeLog } from "../../shared/change-log/change-log.model";
import { PatientExpensesList } from "../models/patient-expenses-list.model";
import { IPatientUpdate } from './interfaces/patient-update.interface';
import { TrialList } from '../models/trial-list.model';
import { TrialDetail } from '../models/trial-detail.model';
import { PatientAddVisit } from './interfaces/patient-add-visit.interface';
import { PatientUpdateVisit } from "./interfaces/patient-update-visit.interface";
import { LogHelper } from "../helpers/log.helper";
import { CreateTripDto } from "./interfaces/create-trip.interface";
import { UpdateTripDto } from "./interfaces/update-trip.interface";
import { ResponsePage } from '../models/search-page.model';
import { UpdatePatientTripStatusRequest, UpdatePatientTripStatusResult } from '../models/patient-trip.model';
import { BankAccount } from "../models/bank-account.model";
import { CreatePatientDto } from "./interfaces/create-patient.interface";
import { UpdatePatientAdditionalDetails } from "./interfaces/update-patient-additional-details.interface";
import { TrialPolicy } from "../../features/trial/trial-details/trial-policy.model";
import { PatientPreApprovalCheck } from "../models/patient-pre-approval-check.model";
import { ReimbursementAndTravelAmountUpdateResult } from '../models/reimbursement-search.model';
import { PatientDiaryConfig } from "../models/patient-diary-config.model";
import { UpdateDiaryConfig } from "./interfaces/update-diary-config.interface";
import { PatientDiaryEntry } from "../models/patient-diary-entry.model";
import { AssignCardPatient } from "../models/assign-card-patient.model";

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  filterKeywords: string = null;
  filterCountry: string = null;

  constructor(private http: HttpClient) {
  }

  /**
   * Returns a list of template visits for a patient/trial combination
   * @param patientId
   * @param trialId
   */
  retrievePatientTemplateVisits(patientId: string, trialId: string): Observable<VisitTemplateResponse[]> {
    return this.http.get<VisitTemplateResponse[]>(environment.apiUrl + '/patient/' + patientId + '/trial/' + trialId + '/template-visits');
  }

  resetPatientDiary(patientTrialId: string) {
    return this.http.put(environment.apiUrl + '/patient/' + patientTrialId + '/diary/reset', {});
  }

  clearUrineReminders(patientTrialId: string) {
    return this.http.put(environment.apiUrl + '/patient/' + patientTrialId + '/reminders/urine/reset', {});
  }

  clearVisitReminders(patientTrialId: string) {
    return this.http.put(environment.apiUrl + '/patient/' + patientTrialId + '/reminders/visit/reset', {});
  }

  updatePatientDiaryDose(patientTrialId: string, dosage: number, dosageText: string, dailyDoseReminderTimeMinutes: number, dailyDoseReminderTimeMinutesUtc, dosageTimezone: string, from: string) {

    const body = {
      dosage,
      dosageText,
      dailyDoseReminderTimeMinutes,
      dailyDoseReminderTimeMinutesUtc,
      dosageTimezone,
      from: from
    };

    return this.http.put(environment.apiUrl + '/patient/' + patientTrialId + '/diary/dosage', body);
  }


  /**
     * Retrieves the diary entries for a given patient trial.
     *
     * @param {string} patientTrialId - The unique identifier for the patient trial.
     * @return {Observable<PatientDiaryEntry[]>} An observable containing an array of patient diary entries.
     */
  getPatientDiaryEntries(patientTrialId: string) {
    return this.http
      .get<PatientDiaryEntry[]>(`${environment.apiUrl}/patient/${patientTrialId}/diary/entries`)
      .pipe(
        map(entries => entries.map(entry => new PatientDiaryEntry(entry)))
      );
  }

  /**
   * Updates the configuration of a patient's diary for a specified trial.
   *
   * @param {string} patientTrialId - The unique identifier of the patient's trial.
   * @param {UpdateDiaryConfig} dto - The data transfer object containing the updated diary configuration.
   * @return {Observable<Object>} - An observable containing the response from the HTTP PUT request.
   */
  updatePatientDiaryConfig(patientTrialId: string, dto: UpdateDiaryConfig) {
    LogHelper.log(dto);


    return this.http.put(environment.apiUrl + '/patient/' + patientTrialId + '/diary/config', dto);
  }

  /**
   * Retrieves the configuration for a patient's diary based on the provided trial ID.
   *
   * @param {string} patientTrialId - The unique identifier for the patient's trial.
   * @return {Observable<any>} - An observable containing the patient's diary configuration.
   */
  patientDiaryConfig(patientTrialId: string) {
    return this.http.get<PatientDiaryConfig>(environment.apiUrl + '/patient/' + patientTrialId + '/diary/config');
  }

  /**
   * Submits a request to merge a patient into another
   * @param sourcePatientTrial
   * @param targetPatientTrial
   */
  mergePatient(sourcePatientTrial: string, targetPatientTrial: string) {
    return this.http.post(environment.apiUrl + '/merge', { sourcePatientTrialId: sourcePatientTrial, targetPatientTrialId: targetPatientTrial });
  }

  /**
   * Gets a patients policy from their patientTrialId
   * @param patientTrialId
   */
  getPolicy(patientTrialId: string): Observable<TrialPolicy> {
    return this.http.get<TrialPolicy>(environment.apiUrl + '/patient/trial/' + patientTrialId + '/policy');
  }

  /**
   * Gets a patients bank account details
   * @param patientId
   */
  bankAccount(patientId: string) {
    return this.http.get<BankAccount>(environment.apiUrl + '/patient/' + patientId + '/bank-account');
  }

  /**
   * Loads a patients activity
   * @param patientId
   * @param page
   */
  loadPatientActivity(patientId: string, page: number) {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    return this.http.get(environment.apiUrl + "/patient/" + patientId + "/notification", { params: params }).pipe(map((activities: any) => {
      activities.results.forEach(result => {
        result.date = DateHelper.dateUTCToLocal(result.date)
      });

      return activities;
    }));
  }

  /**
   * Send push notification to patient
   * @param patientId
   * @param trialId
   * @param title
   * @param content
   * @constructor
   */
  sendPatientNotification(patientId: string, trialId: string, title: string, content: string) {
    let body = {
      trialId: trialId,
      patientId: patientId,
      title: title,
      content: content
    };

    return this.http.post(environment.apiUrl + '/patient/notification', body);
  }

  sendClaimAccountEmail(patientId: string) {
    return this.http.put(environment.apiUrl + '/patient/' + patientId + '/claim', {});
  }

  exportPatients(dateFromStr: string, dateToStr: string, anonymisePatientData: boolean) {
    let body = {
      anonymisePatientData: anonymisePatientData,
      dateFrom: dateFromStr ? moment(dateFromStr + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateToStr ? moment(dateToStr + ' 23:59:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/patient/export', body);
  }

  exportChangeLog(patientTrialId: string, dateFromStr: string, dateToStr: string) {
    let body = {
      dateFrom: dateFromStr ? moment(dateFromStr + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateToStr ? moment(dateToStr + ' 23:59:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/patient/trial/' + patientTrialId + '/changelog/export', body);
  }

  /**
   * Get the change log for a patient
   * @param patientTrialId
   * @param page
   */
  changeLog(patientTrialId: string, page = 1) {
    let params = new HttpParams().set('page', page.toString());

    return this.http.get<ChangeLog>(environment.apiUrl + '/patient/trial/' + patientTrialId + '/changelog', { params: params }).pipe(map(rsp => {
      return new ChangeLog(rsp);
    }));
  }

  /**
   * Add a patient note
   * @param patientId
   * @param note
   */
  addPatientNote(patientId: string, note: string) {
    return this.http.post(environment.apiUrl + '/patient/' + patientId + '/notes', { note: note });
  }

  /**
   * Retrieve patient notes
   * @param patientId
   */
  retrieveNotes(patientId: string) {
    return this.http.get<PatientNote>(environment.apiUrl + '/patient/' + patientId + '/notes').pipe(map((rsp: any) => {
      let notes: PatientNote[] = [];

      for (const note of rsp) {
        notes.push(PatientNote.fromObject(note));
      }

      return notes;
    }));
  }

  /**
   * Updates a patients additional details
   * @param patientId
   * @param dto
   */
  updatePatientAdditionalDetails(patientId: string, dto: UpdatePatientAdditionalDetails) {
    const body = {
      passportName: dto.passportName,
      passportExpiryDate: dto.passportExpiryDate != null && dto.passportExpiryDate != '' ? DateHelper.yyyyMmDdToDate(dto.passportExpiryDate) : null,
      dateOfBirth: dto.dob != null && dto.dob != '' ? DateHelper.yyyyMmDdToDate(dto.dob) : null,
      phone: dto.phone,
      nextOfKin: dto.nextOfKin,
      nextOfKinPhone: dto.nextOfKinPhone,
      passportNumber: dto.passportNumber,
      visaInfo: dto.visaInfo,
      otherNotes: dto.otherNotes,
      travellingWithCaregiver: dto.travellingWithCaregiver,
      caregiverDetails: dto.caregiverDetails,
      customData: dto.customData
    };

    return this.http.put(environment.apiUrl + '/patient/' + patientId + '/details', body);
  }

  retrievePatientExpensesForPatientTrial(patientTrialId: string, page = 1) {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    return this.http.get<PatientExpensesList>(environment.apiUrl + "/patient/trial/" + patientTrialId + "/expenses", { params: params })
      .pipe(map(rsp => {
        return new PatientExpensesList().resultsFromResponse(rsp);
      }));
  }

  /**
   * Deletes a patient trip
   * @param tripId the Id of the trip to remove
   */
  deleteTrip(tripId: string) {
    return this.http.delete(environment.apiUrl + '/patient/trip/' + tripId);
  }

  /**
   * Retrieves an individual patient trip
   * @param tripId the Id of the trip to retrieve
   */
  retrievePatientTrip(tripId: string) {
    return this.http.get<PatientTrip>(environment.apiUrl + '/patient/trip/' + tripId).pipe(map(rsp => {
      return PatientTrip.fromObject(rsp);
    }));
  }

  suggestPatient(searchTerm: string, trialId: string, globalOnly = false, apiConsumerIds: string[] = []) {
    let params = new HttpParams();

    params = params.set('searchTerm', searchTerm);

    if (trialId !== null && trialId !== '') {
      params = params.set('trialId', trialId);
    }

    if (apiConsumerIds !== null && apiConsumerIds.length > 0)
      params = params.set('apiConsumerIds', apiConsumerIds.join(','));

    params = params.set('globalOnly', globalOnly);

    return this.http.get(environment.apiUrl + '/patient/suggest', { params: params });
  }

  /**
   * Removes one or more attachments from a trip
   * @param tripId
   * @param attachmentIds
   */
  deleteTripTicket(tripId: string, attachmentIds: string[]) {
    return this.http.put(environment.apiUrl + "/patient/trip/" + tripId + "/ticket/delete", { fileAttachmentIds: attachmentIds });
  }

  /**
   * Sets the trip ticket order.
   *
   * @param {string} tripId - The trip ID.
   * @param {string[]} originalFileNames - The array of original file names.
   * @returns {Observable} - An Observable object representing the HTTP PUT request to update the ticket order.
   *
   * @example
   * setTripTicketOrder('123', ['ticket1.pdf', 'ticket2.pdf'], true)
   *   .subscribe(response => {
   *     console.log(response);
   *   });
   */
  setTripTicketOrder(tripId: string, originalFileNames: string[]) {
    return this.http.put(environment.apiUrl + "/patient/trip/" + tripId + "/ticket/order", { originalFileNames: originalFileNames, ticketsHaveChanged: true });
  }

  /**
   * Upload a ticket PDF to an existing patient trip
   * @param tripId the Id of the trip
   * @param originalFilename the original filename of the ticket
   * @param weight the weight/order of the ticket in the list
   * @param file the file to upload
   */
  uploadTripTicket(tripId: string, originalFilename: string, weight: number, file: Blob) {
    const formData = new FormData();
    formData.append('file', file, originalFilename);
    formData.append('originalFilename', originalFilename);
    formData.append('weight', weight.toString());

    return this.http.put(environment.apiUrl + "/patient/trip/" + tripId + "/ticket", formData);
  }

  /**
   * Updates an existing trip
   * @param tripId
   * @param dto
   */
  updateTrip(tripId: string, dto: UpdateTripDto) {
    const body = {
      ticketRemoved: dto.ticketRemoved,
      type: dto.tripType,
      groundTransportTravelType: dto.groundTransportTravelType,
      tripDirection: dto.tripDirection,
      departureDate: dto.departureDate,
      departureLocation: dto.departureLocation,
      departureTimeMinutes: dto.departureTimeMinutes,
      arrivalDate: dto.arrivalDate,
      arrivalLocation: dto.arrivalLocation,
      arrivalTimeMinutes: dto.arrivalTimeMinutes,
      accommodationLocation: dto.accommodationLocation,
      notes: dto.notes,
      internalNotes: dto.internalNotes,
      carrier: dto.carrier,
      bookingProvider: dto.bookingProvider,
      paymentCardUsed: dto.paymentCardUsed,
      ticketConfirmationNo: dto.ticketConfirmationNo,
      reference: dto.reference,
      bookingStatus: dto.bookingStatus,
      invoiceNo: dto.invoiceNo,
      quotedAmount: +dto.quotedAmount > 0 ? +dto.quotedAmount * 100 : 0,
      quotedAmountBC: +dto.quotedAmountBC > 0 ? +dto.quotedAmountBC * 100 : null,
      quotedCurrency: +dto.quotedAmount > 0 ? dto.quotedCurrency : null,
      overBudget: dto.overBudget,
      recalculateAmountBc: dto.recalculateAmountBc,
    };

    return this.http.put<PatientTrip>(environment.apiUrl + '/patient/trip/' + tripId, body)
      .pipe(map(rsp => {
        return PatientTrip.fromObject(rsp);
      }));
  }

  /**
   * Add a new patient trip
   * @param patientId
   * @param visitId
   * @param dto
   */
  addTrip(patientId: string, visitId: string, dto: CreateTripDto) {
    const body = {
      type: dto.tripType,
      groundTransportTravelType: dto.groundTransportTravelType,
      tripDirection: dto.tripDirection,
      departureDate: dto.departureDate,
      departureLocation: dto.departureLocation,
      departureTimeMinutes: dto.departureTimeMinutes,
      arrivalDate: dto.arrivalDate,
      arrivalLocation: dto.arrivalLocation,
      arrivalTimeMinutes: dto.arrivalTimeMinutes,
      accommodationLocation: dto.accommodationLocation,
      notes: dto.notes,
      internalNotes: dto.internalNotes,
      carrier: dto.carrier,
      bookingProvider: dto.bookingProvider,
      paymentCardUsed: dto.paymentCardUsed,
      ticketConfirmationNo: dto.ticketConfirmationNo,
      reference: dto.reference,
      bookingStatus: dto.bookingStatus,
      invoiceNo: dto.invoiceNo,
      quotedAmount: +dto.quotedAmount > 0 ? +dto.quotedAmount * 100 : 0,
      quotedAmountBC: +dto.quotedAmountBC > 0 ? +dto.quotedAmountBC * 100 : null,
      quotedCurrency: +dto.quotedAmount > 0 ? dto.quotedCurrency : null,
      overBudget: dto.overBudget,
    };

    return this.http.post<PatientTrip>(environment.apiUrl + '/patient/' + patientId + '/visit/' + visitId + '/trip', body)
      .pipe(map(rsp => {
        return PatientTrip.fromObject(rsp);
      }));
  }

  /**
   * Make a call to the API to re-order trips
   * @param trips the trips to re-order
   */
  reOrderTrips(trips: { tripId: string, order: number }[]) {
    return this.http.put(environment.apiUrl + '/patient/visit/trips/reorder', trips);
  }

  /**
   * Retrieve details of a vist and associated trips
   * @param visitId the Id of the visit
   */
  retrieveVisit(visitId: string) {
    return this.http.get<PatientVisit>(environment.apiUrl + "/patient/visit/" + visitId).pipe(map(rsp => {
      return PatientVisit.fromObject(rsp);
    }));
  }

  /**
   * Deletes a patients visit
   * @param visitId
   */
  deleteVisit(visitId: string) {
    return this.http.delete(environment.apiUrl + '/patient/visit/' + visitId);
  }

  /**
   * Updates an existing visit
   * @param patientId the patient Id
   * @param visitId the visit Id being updated
   * @param dto
   */
  updateVisit(patientId: string, visitId: string, dto: PatientUpdateVisit
  ) {
    let tmpDate = dto.date !== null && dto.date !== '' ? moment(dto.date + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;
    let tmpEndDate = dto.endDate !== null && dto.endDate !== '' ? moment(dto.endDate + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;
    if (tmpDate !== null && dto.timeMinutes !== null) {
      let tmpTime = IllyTime.parseMinutes(dto.timeMinutes);
      tmpDate = moment(dto.date + ' ' + tmpTime.to24HourString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    }

    if (tmpEndDate !== null && dto.endTimeMinutes !== null) {
      let tmpEndTime = IllyTime.parseMinutes(dto.endTimeMinutes);
      tmpEndDate = moment(dto.endDate + ' ' + tmpEndTime.to24HourString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    }

    const body = {
      date: tmpDate,
      timeMinutes: dto.timeMinutes,
      endDate: tmpEndDate,
      endTimeMinutes: dto.endTimeMinutes,
      title: dto.title,
      visitType: dto.visitType,
      department: dto.department,
      siteId: dto.siteId === '' ? null : dto.siteId,
      description: dto.description,
      notes: dto.notes,
      caregiverTravelling: dto.caregiverTravelling,
      attendance: dto.attendance,
      travelRequested: dto.travelRequested,
      bookingStatus: dto.bookingStatus,
      publishedStatus: dto.publishedStatus,
      sendPatientNotification: dto.sendPatientNotification,
      designatedContacts: dto.designatedContacts ?? [],
      templateId: dto.templateId,
      travelDays: dto.travelDays,
      takeMedicationAtVisit: dto.takeMedicationAtVisit,
      urineSample3DaysBefore: dto.urineSample3DaysBefore,
      urineSample10DaysBefore: dto.urineSample10DaysBefore
    };

    return this.http.put(environment.apiUrl + '/patient/' + patientId + '/visit/' + visitId, body);
  }

  /**
   * Adds a visit to a patient
   * @param patientId Id of the patient
   * @param dto
   */
  addVisit(patientId: string, dto: PatientAddVisit) {
    let tmpDate = moment(dto.date + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    let tmpEndDate = dto.endDate !== null && dto.endDate !== '' ? moment(dto.endDate + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;
    if (dto.timeMinutes !== null) {
      let tmpTime = IllyTime.parseMinutes(dto.timeMinutes);
      tmpDate = moment(dto.date + ' ' + tmpTime.to24HourString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    }

    if (tmpEndDate !== null && dto.endTimeMinutes !== null) {
      let tmpEndTime = IllyTime.parseMinutes(dto.endTimeMinutes);
      tmpEndDate = moment(dto.endDate + ' ' + tmpEndTime.to24HourString(), 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    }

    const body = {
      trialId: dto.trialId,
      templateId: dto.templateId,
      date: tmpDate,
      timeMinutes: dto.timeMinutes,
      endDate: tmpEndDate,
      endTimeMinutes: dto.endTimeMinutes,
      title: dto.title,
      visitType: dto.visitType,
      siteId: dto.siteId,
      description: dto.description,
      department: dto.department,
      notes: dto.notes,
      travelRequested: dto.travelRequested,
      caregiverTravelling: dto.caregiverTravelling,
      attendance: dto.attendance,
      bookingStatus: dto.bookingStatus,
      publishedStatus: dto.publishedStatus,
      designatedContacts: dto.designatedContacts ?? [],
      travelDays: dto.travelDays,
      takeMedicationAtVisit: dto.takeMedicationAtVisit,
      urineSample3DaysBefore: dto.urineSample3DaysBefore,
      urineSample10DaysBefore: dto.urineSample10DaysBefore
    };

    return this.http.post(environment.apiUrl + "/patient/" + patientId + "/visit/add", body);
  }

  retrievePatientVisitsForPatientTrial(patientTrialId: string) {
    return this.http.get<PatientVisitListItem[]>(environment.apiUrl + '/patient/trial/' + patientTrialId + "/visits")
      .pipe(map(rsp => {
        let visits: PatientVisitListItem[] = [];
        for (const v of rsp) {
          visits.push(PatientVisitListItem.fromObject(v));
        }

        return visits;
      }));
  }

  /**
   * Retrieve a list of a patients visits for a trial
   * @param patientId the Id of the patient
   * @param trialId the Id of the trial
   */
  retrievePatientVisitsForTrial(patientId: string, trialId: string) {
    return this.http.get<PatientVisitListItem[]>(environment.apiUrl + '/patient/' + patientId + '/trial/' + trialId + '/visits')
      .pipe(map(rsp => {
        let visits: PatientVisitListItem[] = [];
        for (const v of rsp) {
          visits.push(PatientVisitListItem.fromObject(v));
        }

        return visits;
      }));
  }

  /**
   * Updates a patient address
   * @param patientId
   * @param address
   */
  updatePatientAddress(patientId, address) {
    const body = {
      homeAddress: address
    };

    LogHelper.log(body);

    return this.http.put(environment.apiUrl + '/patient/' + patientId + '/address', body);
  }

  /**
   * Updates a patients information
   * @param patientId the Id of the patient being update
   * @param dto
   */
  updatePatient(patientId: string, dto: IPatientUpdate) {
    return this.http.put(environment.apiUrl + '/patient/' + patientId, {
      firstname: dto.firstname,
      lastname: dto.lastname,
      address: dto.address,
      country: dto.country,
      notes: dto.notes,
      policyReminders: dto.reminders,
      patientNotOnApp: dto.patientNotOnApp,
      email: dto.email,
      trials: dto.trials,
      homeAddress: dto.homeAddress,
      culture: dto.culture
    });
  }

  /**
   * Resyncs patient data and re-queues failed webhooks
   * @param patientTrialId
   */
  resyncPatient(patientTrialId: string) {
    return this.http.get(environment.apiUrl + '/patient/' + patientTrialId + '/resync');
  }

  /**
   * Retrieves patient details for a visit
   * @param visitId
   */
  retrievePatientDetailForVisit(visitId: string,) {
    return this.http.get<PatientDetail>(environment.apiUrl + '/patient/DetailsForVisit/' + visitId).pipe(map(rsp => {
      return new PatientDetail().fromObject(rsp);
    }));
  }

  /**
   * Retrieves patient details from a patient trial Id
   * @param patientTrialId
   */
  retrievePatientDetailFromPatientTrialId(patientTrialId: string) {
    return this.http.get<PatientDetail>(environment.apiUrl + '/patient/trial/' + patientTrialId).pipe(map(rsp => {
      return new PatientDetail().fromObject(rsp);
    }));
  }

  /**
   * Retrieves patient details from a patient Id and trial Id
   * @param patientId
   * @param trialId
   */
  retrievePatientDetailFromPatientIdAndTrialId(patientId: string, trialId: string) {
    return this.http.get<PatientDetail>(environment.apiUrl + '/patient/' + patientId + '/details/trial/' + trialId).pipe(map(rsp => {
      return new PatientDetail().fromObject(rsp);
    }));
  }

  /**
   * Retrieves a patients details
   * @param patientId the Id of the patient
   */
  retrievePatientDetail(patientId: string) {
    return this.http.get<PatientDetail>(environment.apiUrl + '/patient/' + patientId).pipe(map(rsp => {
      return new PatientDetail().fromObject(rsp);
    }));
  }

  /**
   * Makes a request to the API to reject a patient
   * @param patientTrialId
   */
  rejectPatient(patientTrialId: string) {
    return this.http.put(environment.apiUrl + '/patient/trial/' + patientTrialId + '/reject', {});
  }

  mergeAndApprovePatient(patientTrialId: string, patientCode: string, siteId: string, patientConsent: boolean) {
    return this.http.put(environment.apiUrl + '/patient/trial/' + patientTrialId + '/mergeandapprove', {
      patientCode: patientCode,
      siteId: siteId,
      patientConsent: patientConsent
    });
  }

  /**
   * Makes a request to the API to approve a patient
   * @param patientTrialId the Id of the being being approved
   * @param patientCode the code to be assigned to the patient
   * @param siteId
   * @param patientConsent
   */
  approvePatient(patientTrialId: string, patientCode: string, siteId: string, patientConsent: boolean) {
    return this.http.put(environment.apiUrl + '/patient/trial/' + patientTrialId + '/approve', {
      patientCode: patientCode,
      siteId: siteId,
      patientConsent: patientConsent
    });
  }

  /**
   * Performs a pre-approval check on a patient to make sure the patient code isn't already in use on the trial
   * @param patientTrialId
   * @param patientCode
   * @param siteId
   * @param patientConsent
   */
  preApprovePatient(patientTrialId: string, patientCode: string, siteId: string, patientConsent: boolean) {
    return this.http.put<PatientPreApprovalCheck>(environment.apiUrl + '/patient/trial/' + patientTrialId + '/preapprovecheck', {
      patientCode: patientCode,
      siteId: siteId,
      patientConsent: patientConsent
    });
  }

  /**
   * Calls the API and sets the patient account as deactivated
   * @param patientId the patient Id to deactivate
   */
  deactivatePatient(request: DeletePatientRequest): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/patient/delete', request);
  }

  /**
   * Makes a request to the API to retrieve a list of patients who have requested that their account be deleted
   * @param page page number
   * @param keywords search keywords
   * @param country search country
   * @param orderBy
   */
  retrieveDeletionRequests(page: number, keywords: string, country: string, orderBy: number = 0) {
    let params = new HttpParams().set('page', page.toString());
    if (keywords !== null && keywords !== '') {
      params = params.set('keywords', keywords);
    }
    if (country !== null && country !== '') {
      params = params.set('country', country);
    }
    params = params.set('orderBy', orderBy.toString());

    return this.http.get(environment.apiUrl + '/patient/DeleteRequests', { params: params })
      .pipe(map(response => {
        return new PatientList().resultsFromResponse(response);
      }));
  }

  /**
   * Makes a request to the API to retrieve a list of patients with a given state
   * @param state patient state (0 = Approved, 1 = Pending, 2 = Rejected)
   * @param keywords search keywords
   * @param country search country
   * @param page page number
   * @param orderBy
   */
  retrievePatients(state: number, keywords: string, country: string, page: number, orderBy: number = 0) {
    let params = new HttpParams()
      .set('state', state.toString())
      .set('page', page.toString());

    if (orderBy != null) {
      params = params.set('orderBy', orderBy);
    }

    if (keywords != null && keywords != '') {
      params = params.set('keywords', keywords);
    }

    if (country != null && country != '') {
      params = params.set('country', country);
    }

    return this.http.get(environment.apiUrl + '/patient', { params: params })
      .pipe(map(response => {
        return new PatientList().resultsFromResponse(response);
      }));
  }

  /**
   * Create a new patient
   */
  create(dto: CreatePatientDto): Observable<CreatePatientResponse> {
    const body = {
      trialCode: dto.trialCode,
      siteId: dto.site,
      patientCode: dto.patientCode,
      firstname: dto.firstname,
      lastname: dto.lastname,
      email: dto.email,
      country: dto.country,
      address: dto.address,
      notes: dto.notes,
      policyReminders: dto.policyReminders,
      state: dto.state,
      patientConsent: dto.patientConsent,
      patientNotOnApp: dto.patientNotOnApp,
      homeAddress: dto.homeAddress,
      culture: dto.culture
    };

    return this.http.post<CreatePatientResponse>(environment.apiUrl + '/patient', body);
  }

  /**
   * Gets details of a specific trial for a patient
   * @param patientId
   * @param trialId
   */
  getTrialForPatient(patientId: string, trialId: string): Observable<TrialDetail> {
    return this.http.get<TrialDetail>(environment.apiUrl + '/patient/' + patientId + '/trial/' + trialId);
  }

  retrievePatientTrials(patientId: string, keywords: string, page: number, pageSize: number) {
    let params = new HttpParams()
      .set('pageSize', environment.pageSize.toString());
    if (page > 1) {
      params = params.set('page', page.toString());
    }
    if (keywords != null) {
      params = params.set('keywords', keywords);
    }
    if (pageSize > 0) {
      params = params.set('pageSize', pageSize.toString());
    }

    return this.http.get<TrialList>(environment.apiUrl + '/patient/' + patientId + '/trials', { params: params });
  }

  getFullPatientPaymentInfo(patientId: string): Observable<FullPatientPaymentInfo> {
    return this.http.get<FullPatientPaymentInfo>(`${environment.apiUrl}/patient/${patientId}/full-payment-info`);
  }

  getPatientPaymentInfo(patientId: string): Observable<PatientPaymentInfo> {
    return this.http.get<PatientPaymentInfo>(`${environment.apiUrl}/patient/${patientId}/payment-info`);
  }

  updatePatientPaymentInfo(request: PatientPaymentInfoUpdateRequest): Observable<FullPatientPaymentInfo> {
    return this.http.put<FullPatientPaymentInfo>(`${environment.apiUrl}/patient/payment-info`, request);
  }

  updatePatientCaxtonBeneficiaryId(request: UpdatePatientCaxtonBeneficiaryRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/patient/caxton-beneficiary`, request);
  }

  assignWalletOnParent(request: AssignWalletOnParentRequest): Observable<AssignWalletResult> {
    return this.http.post<AssignWalletResult>(`${environment.apiUrl}/patient/assign-wallet-on-parent`, request);
  }

  availableWallets(trialId: string): Observable<AvailableWalletsResponse[]> {
    return this.http.get<AvailableWalletsResponse[]>(`${environment.apiUrl}/patient/available-wallets/${trialId}`);
  }

  linkCardToTracker(patientTrialId: string): Observable<AssignCardPatient> {
    return this.http.put<AssignCardPatient>(`${environment.apiUrl}/patient/${patientTrialId}/link-card-tracker`, {});
  }

  assignAndMoveWallet(request: AssignAndMoveWalletRequest): Observable<AssignWalletResult> {
    return this.http.put<AssignWalletResult>(`${environment.apiUrl}/patient/assign-wallet`, request);
  }

  grantOnlineAccess(patientId: string, request: AssignCardRequest): Observable<AssignCardResult> {
    return this.http.put<AssignCardResult>(`${environment.apiUrl}/patient/${patientId}/grant-caxton-access`, request);
  }

  activateCards(patientId: string, request: ActivateCardsRequest): Observable<ActivateCardsResult> {
    return this.http.put<ActivateCardsResult>(`${environment.apiUrl}/patient/${patientId}/activate-cards`, request);
  }

  getPatientRegistrations(page: number, keywords: string, orderBy: number = 0): Observable<ResponsePage<PatientRegistrationListViewModel>> {
    let url = `${environment.apiUrl}/patient/patient-registrations?page=${page}&orderBy=${orderBy}`;

    if (keywords && keywords.trim().length > 0) {
      url += `&keywords=${keywords}`;
    }

    return this.http.get<ResponsePage<PatientRegistrationListViewModel>>(url);
  }

  getPatientRegistration(patientRegistrationId: string): Observable<PatientRegistrationViewModel> {
    return this.http.get<PatientRegistrationViewModel>(`${environment.apiUrl}/patient/patient-registration/${patientRegistrationId}`);
  }

  getCardPin(request: GetCardPinRequest): Observable<CardPinViewModel> {
    return this.http.get<CardPinViewModel>(`${environment.apiUrl}/patient/${request.patientTrialId}/wallet/${request.walletId}/view-card-pin`);
  }

  getActiveTrialCodesForPatient(patientId: string): Observable<ActivePatientTrialCodesViewModel[]> {
    return this.http.get<ActivePatientTrialCodesViewModel[]>(`${environment.apiUrl}/patient/active-trial-codes/${patientId}`);
  }

  getEngineSizesForTrialAndSite(trialId: string, siteId: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/patient/engine-sizes/${trialId}/${siteId}`);
  }

  updatePatientTripStatus(request: UpdatePatientTripStatusRequest): Observable<UpdatePatientTripStatusResult> {
    return this.http.put<UpdatePatientTripStatusResult>(`${environment.apiUrl}/patient/trip-status`, request);
  }

  updatePatientTripInvoicedAmount(request: UpdatePatientTripInvoicedAmountRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/invoiced-amount', request);
  }

  updatePatientTripInvoiceNumber(request: UpdatePatientTripInvoiceNumberRequest) {
    console.log('This is the update invoice number service method');
    return this.http.put(environment.apiUrl + '/patient/trip/invoice-number', request);
  }

  updatePatientTripInvoicedAmountBc(request: UpdatePatientTripInvoicedAmountBcRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/invoiced-amount-bc', request);
  }

  updatePatientTripInvoicedCurrency(request: UpdatePatientTripInvoicedCurrencyRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/invoiced-currency', request);
  }

  updatePatientTripQuotedAmount(request: UpdatePatientTripQuotedAmountRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/quoted-amount', request);
  }

  updatePatientTripQuotedAmountBc(request: UpdatePatientTripQuotedAmountBcRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/quoted-amount-bc', request);
  }

  updatePatientTripQuotedCurrency(request: UpdatePatientTripQuotedCurrencyRequest): Observable<ReimbursementAndTravelAmountUpdateResult> {
    return this.http.put<ReimbursementAndTravelAmountUpdateResult>(environment.apiUrl + '/patient/trip/quoted-currency', request);
  }
}
