import { PatientService } from 'app/core/services/patient.service';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { PatientStateOnTrialOptions } from 'app/core/constants/patient-state-on-trial-options';
import { SiteAutocompleteComponent } from "../../site-autocomplete/site-autocomplete.component";
import { TrialAutocompleteComponent } from "../../trial-autocomplete/trial-autocomplete.component";
import { PatientTrial } from "../../../core/models/patient-trial.model";
import { ObjectHelper } from "../../../core/helpers/object.helper";
import { SiteAutocomplete } from "../../site-autocomplete/site-autocomplete.model";
import { TrialAutocomplete } from "../../trial-autocomplete/trial-autocomplete.model";
import { Countries } from "../../../core/constants/countries";
import { LanguageCultureOptions } from "../../../core/constants/language-culture-options";
import { SelectOption } from "../../../core/models/select-option.model";

@Component({
  selector: 'app-patient-manage-trials',
  templateUrl: './patient-manage-trials.component.html',
  styleUrls: ['./patient-manage-trials.component.scss']
})
export class PatientManageTrialsComponent implements AfterViewInit {
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('stateOnTrialSelect') stateOnTrialSelect: DropdownInputComponent;
  @ViewChild('engineSizeSelect') engineSizeSelect: DropdownInputComponent;

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('index') index: number;
  @Input('patientTrial') patientTrial: PatientTrial;
  @Input('assignedTrialIds') assignedTrialIds: string[] = [];
  @Input('patientName') patientName: string;
  @Input('displayRemoveButton') displayRemoveButton: boolean;

  @Output('trialAdded') trialAdded = new EventEmitter<PatientTrial>();
  @Output('cancelled') cancelled = new EventEmitter<void>();
  @Output('removeFromTrial') removeFromTrial = new EventEmitter<string>();

  added = false;
  optionsVisible = false;
  endingParticipationReasonVisible = false;
  stateOnTrialOptions = PatientStateOnTrialOptions.all();
  engineSizes: { value: string, text: string }[] = [];
  selectedTrialLabel: string;
  selectedSiteLabel: string;
  countries: { value: string, text: string }[] = [];
  languageOptions: SelectOption[] = [];

  constructor(private patientService: PatientService) {
    for (const country of Countries.all()) {
      this.countries.push({ value: country.code, text: country.name });
    }

    const languageCultures = LanguageCultureOptions.all();
    languageCultures.forEach(culture => {
      this.languageOptions.push({ value: culture.cultureInfoCode.toLowerCase(), text: culture.language + ' (' + culture.cultureInfoCode.toLowerCase() + ')' });
    });
    this.languageOptions.sort((a, b) => a.text.localeCompare(b.text));
  }

  ngAfterViewInit() {
    if (this.patientTrial !== undefined && this.patientTrial !== null) {
      const text = this.patientTrial.siteName + ' / ' + 'address to be added';
      //const siteName = ObjectHelper.isUndefinedNullOrEmpty(this.patientTrial.siteNumber) ? this.patientTrial.siteName : this.patientTrial.siteNumber + ' ' + this.patientTrial.siteName + ' ' + this.patientTrial.siteCountry;
      const siteName = this.generateSiteDisplayName(this.patientTrial.siteNumber, this.patientTrial.siteName, this.patientTrial.siteCountry);
      const option = new SiteAutocomplete(this.patientTrial.siteId,
        '',
        text.substring(0, 30) + ' ...',
        siteName,
        this.patientTrial.siteAddress + ' ' + this.patientTrial.siteCountry);

      this.selectedSiteLabel = this.patientTrial.siteName.substring(0, 30) + (this.patientTrial.siteName.length > 30 ? '...' : '');
      this.selectedTrialLabel = this.patientTrial.trialLabel.substring(0, 30) + (this.patientTrial.trialLabel.length > 30 ? '...' : '');

      this.stateOnTrialSelect.setValue(this.patientTrial.stateOnTrial);

      this.patientTrial.engineSizes.forEach(engineSize => {
        this.engineSizes.push({ value: engineSize, text: engineSize });
      });
      this.engineSizeSelect.setValue(this.patientTrial.engineSize);

      this.setEndingParticipationVisibility(this.patientTrial.stateOnTrial);

      setTimeout(() => {
        this.stateOnTrialSelect.setValue(this.patientTrial.stateOnTrial);
        this.siteAutocomplete.onSelectOption(option);
        this.siteAutocomplete.onSelectOption(option);

        this.siteAutocomplete.enable();
        this.siteAutocomplete.setTrialId(this.patientTrial.trialId);
      }, 100);

      this.added = true;
    } else {
      this.stateOnTrialSelect.setValue("Active");
    }

    this.listenToTrialSelection();
    this.listenToPatientStateSelection();
    this.listenToSiteChanges();
  }

  generateSiteDisplayName(siteNumber: string, siteName: string, siteCountry: string) : string {
    let displayName = '';

    if (!ObjectHelper.isUndefinedNullOrEmpty(siteNumber)) {
      displayName = siteNumber + ' ';
    }

    if (!ObjectHelper.isUndefinedNullOrEmpty(siteName)) {
      displayName += siteName + ' ';
    }

    if (!ObjectHelper.isUndefinedNullOrEmpty(siteCountry)) {
      displayName += '('+siteCountry+')';
    }

    return displayName;
  }

  listenToSiteChanges(): void {
    this.siteAutocomplete.valueChanged.subscribe((option: SiteAutocomplete) => {
      if (this.trialAutocomplete.selected) {
        this.patientService.getEngineSizesForTrialAndSite(this.trialAutocomplete.selected
          .id, option.id).subscribe(engineSizes => {
            this.engineSizes = [];
            engineSizes.forEach(engineSize => {
              this.engineSizes.push({ value: engineSize, text: engineSize });
            });
          });
      }
    });
  }

  private setEndingParticipationVisibility(stateOnTrial: string) {
    if (stateOnTrial === PatientStateOnTrialOptions.Dropout ||
      stateOnTrial === PatientStateOnTrialOptions.ScreenFail ||
      stateOnTrial === PatientStateOnTrialOptions.Withdrawn) {
      this.endingParticipationReasonVisible = true;
    }
    else {
      this.endingParticipationReasonVisible = false;
    }
  }

  onToggleOptions() {
    this.optionsVisible = !this.optionsVisible;
  }

  listenToTrialSelection(): void {
    this.trialAutocomplete.valueChanged.subscribe((option: TrialAutocomplete) => {
      this.siteAutocomplete.setTrialId(option.id);
      this.parentForm.patchValue({ trialId: option.id });
      this.siteAutocomplete.enable();

      this.engineSizes = [];
      // We need to check that the selected trial is not already assigned to the patient
      this.parentForm.patchValue({ trialIdIsUnique: !this.assignedTrialIds.includes(option.id) });
    });
  }

  listenToPatientStateSelection(): void {
    this.stateOnTrialSelect.selectValueChanged.subscribe((option) => {
      this.setEndingParticipationVisibility(option);
    });
  }

  onTrialAdded() {
    const patientTrial = new PatientTrial({
      siteId: this.parentForm.get('siteId').value,
      trialId: this.parentForm.get('trialId').value,
      patientCode: this.parentForm.get('patientCode').value,
      intercashRemoteId: this.parentForm.get('intercashRemoteId').value,
      intercashSanNumber: this.parentForm.get('intercashSanNumber').value,
      trialCode: this.trialAutocomplete.selected.label,
      consent: this.parentForm.get('consent').value,
      bankFeesIncurred: this.parentForm.get('bankFeesIncurred').value,
      approvalNoReceipts: this.parentForm.get('approvalNoReceipts').value,
      overspendMileage: this.parentForm.get('overspendMileage').value,
      overspendTravel: this.parentForm.get('overspendTravel').value,
      fixedFeePerVisit: this.parentForm.get('fixedFeePerVisit').value,
      stateOnTrial: this.stateOnTrialSelect.selectedValue,
      endingParticipationReason: this.parentForm.get('endingParticipationReason').value,
      engineSize: this.engineSizeSelect.selectedValue,
    });

    this.trialAdded.emit(patientTrial);
    this.added = true;
  }

  onCancel() {
    this.added = false;
    this.cancelled.emit();
  }

  setFakeFieldValue(trial: TrialAutocomplete) {
    if (trial) {
      this.selectedTrialLabel = trial.label.substring(0, 30) + (trial.label.length > 30 ? '...' : '');
    }
  }

  setFakeSiteFieldValue(site: SiteAutocomplete) {
    this.selectedSiteLabel = site.line1.substring(0, 30) + (site.line1.length > 30 ? '...' : '');
  }
}
