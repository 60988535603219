<div class="images">
  <input #uploadImageInput (change)="preview(uploadImageInput.files)" accept='image/*,.pdf' style="display: none;"
    type="file">
  <div class="image-item" *ngFor="let image of imagery; let i = index">
    <ng-container *ngIf="!uploadIsPdf(image.imageUrl)">
      <app-expense-image [enableImagesManagement]="enableImagesManagement" (removeFile)="onRemoveFile(image.filename)"
        [thumbnailUrl]="image.thumbnailUrl" [filename]="image.filename"></app-expense-image>
    </ng-container>

    <ng-container *ngIf="uploadIsPdf(image.imageUrl)">
      <a (click)="onDownloadFile(image.filename)" title="View PDF" class="pdf-icon">
        <i class="far fa-file-pdf"></i>
      </a>
      <a *ngIf="enableImagesManagement" (click)="onRemoveFile(image.filename)" class="remove">
        <i aria-hidden="true" class="far fa-times"></i>
      </a>
    </ng-container>
  </div>
  <button *ngIf="enableImagesManagement" class="add-img" [disabled]="uploadingImage" (click)="onAddImage()">+</button>
</div>

<app-modal #removeFileModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete File</h2>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="removeFileModal.hide()" class="btn btn-secondary btn-full-span">Cancel</button>
      </div>
      <div class="col-6">
        <button (click)="confirmFileRemove()" [disabled]="processingRequest"
          class="btn btn-primary btn-full-span">Confirm</button>
      </div>
    </div>
  </div>
</app-modal>