import { LogHelper } from 'app/core/helpers/log.helper';
import { AlertService } from './../../../../shared/alert/alert.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientRegistrationListViewModel, PatientRegistrationViewModel } from 'app/core/models/patient.model';
import { ResponsePage } from 'app/core/models/search-page.model';
import { PatientService } from 'app/core/services/patient.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-patient-registrations',
  templateUrl: './patient-registrations.component.html',
  styleUrls: ['./patient-registrations.component.scss']
})
export class PatientRegistrationsComponent implements OnInit {
  keywords: string;
  orderBy: number = 0;
  result: ResponsePage<PatientRegistrationListViewModel>;
  patientRegistration: PatientRegistrationViewModel;
  subscription: Subscription;

  @ViewChild('patientRegistrationDetails') patientRegistrationDetails: ModalComponent;

  constructor(private patientService: PatientService, private alertService: AlertService) { }

  ngOnInit() {
    this.loadPatientRegistrations(1, this.keywords, this.orderBy);
  }

  updateFilter(keywords: string, orderBy: number = 0) {
    this.keywords = keywords === '' ? null : keywords;
    this.orderBy = orderBy
    this.loadPatientRegistrations(1, keywords, orderBy);
  }


  loadPatientRegistrations(page: number, keywords: string, orderBy: number) {
    this.patientService.getPatientRegistrations(page, keywords, orderBy).subscribe({
      next: result => {
        this.result = result
      }
    })
  }

  onPatientRegistrationClicked(patientRegistrationId: string) {
    if (this.subscription !== undefined && this.subscription !== null && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.patientService.getPatientRegistration(patientRegistrationId).subscribe({
      next: response => {
        this.patientRegistration = response;
        this.patientRegistrationDetails.show()
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert("Failed to get patient registration details.");
      }
    })
  }

  public isPdf(uploadUrl: string) {
    let parts = uploadUrl.toLowerCase().split('?');
    return parts[0].endsWith('.pdf');
  }
}
