<app-modal-v2 #modal class="add-patient-modal" [viewPort]="viewPort" [tabulated]="true">
  <div class="modal-header">
    <h2 class="modal-title">{{ patientLoaded ? 'Edit Patient' : 'Loading Patient ...' }}</h2>

    <div class="modal-actions" *ngIf="patientLoaded">
      <button type="button" class="btn btn-outline"(click)="deletePatientModal.show()" *ngIf="authService.hasPermission(Permissions.PatientDelete) && patient.apiConsumerId === null">Delete</button>

      <button class="btn btn-primary" [disabled]="form.invalid && !saveButtonDisabled" (click)="onFormSubmit()">
        <span *ngIf="form.get('processing').value">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!form.get('processing').value">Save Patient</span>
      </button>
    </div>
  </div>

  <app-patient-merge-modal></app-patient-merge-modal>

  <div class="modal-body">
    <form [formGroup]="form">
      <app-tabulated-content #tabulatedContent [showNavButtons]="true" [disabled]="!patientLoaded" [inModal]="true" [tabs]="tabs" (onTabChange)="onHandleTabChanged($event)">
        <section [formGroup]="patientInfoForm">
          <div class="row">
            <div class="col-6">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <label>First Name <sup *ngIf="isRequired(patientInfoForm.get('firstname'))">*</sup></label>
                    <input type="text" class="form-control" id="firstname" formControlName="firstname" placeholder="{{ !patientLoaded ? 'Loading patient...' : ''}}">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Last Name <sup *ngIf="isRequired(patientInfoForm.get('lastname'))">*</sup></label>
                    <input type="text" class="form-control" id="lastname" formControlName="lastname" placeholder="{{ !patientLoaded ? 'Loading patient...' : ''}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Email Address <sup *ngIf="isRequired(patientInfoForm.get('email'))">*</sup></label>
                    <input type="text" class="form-control" id="email" formControlName="email" placeholder="{{ !patientLoaded ? 'Loading patient...' : ''}}">
                  </div>

                  <div class="form-group">
                    <label>Language <sup *ngIf="isRequired(patientInfoForm.get('culture'))">*</sup></label>
                    <app-input-select [parentForm]="patientInfoForm" controlName="culture" [items]="languageOptions" inputPlaceholder="{{ !patientLoaded ? 'Loading patient...' : 'Select language...'}}"></app-input-select>
                  </div>

                  <div class="form-group" [ngClass]="{'visibility-hidden': hideAddress}">
                    <label>Country <sup *ngIf="isRequired(patientInfoForm.get('country'))">*</sup></label>
                    <app-autosuggest-dropdown-input #countriesSelect [options]="countries" [parentForm]="form"
                                                    [required]="true" controlName="country"
                                                    placeholder="{{ !patientLoaded ? 'Loading patient...' : 'Select country...'}}"></app-autosuggest-dropdown-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group" *ngIf="patientInfoForm.get('address').value !== null && patientInfoForm.get('address').value !== ''">
                <label style="margin-left: 16px;">Address (Old) <sup *ngIf="isRequired(patientInfoForm.get('address'))">*</sup></label>
                <textarea class="form-control" formControlName="address" id="address" placeholder="{{ !patientLoaded ? 'Loading patient...' : ''}}"></textarea>
              </div>

              <div [ngClass]="{'visibility-hidden': !patientLoaded}">
                <label style="margin-left: 16px;">Home Address<sup *ngIf="isRequired(patientInfoForm.get('homeAddress'))">*</sup></label>
                <app-input-address #homeAddress [parentForm]="patientInfoForm" controlName="homeAddress"></app-input-address>
              </div>
            </div>
          </div>
        </section>

        <section [formGroup]="extraInfoForm">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Important Information <sup *ngIf="isRequired(extraInfoForm.get('notes'))">*</sup></label>
                <textarea class="form-control" formControlName="notes" id="notes"></textarea>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label>Expense Policy Reminders <sup *ngIf="isRequired(extraInfoForm.get('policyReminders'))">*</sup></label>
                <textarea class="form-control" formControlName="policyReminders" id="policyReminders"></textarea>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group" style="margin-left: -16px;">
                <label class="checkbox">
                  <input formControlName="patientNotOnApp" id="patientNotOnApp"
                         type="checkbox">
                  <span></span>
                </label>
                <label class="checkbox-label" for="patientNotOnApp">Patient is not on the app (Will not receive
                  notifications)</label>
              </div>
            </div>
          </div>
        </section>

        <section>
          <app-patient-manage-trials (cancelled)="onHandleCancelled()" (trialAdded)="onTrialAdded($event)"
                             *ngFor="let ptForm of patientTrialForms; let i = index"
                             [assignedTrialIds]="assignedTrialIds"
                             [index]="i"
                             [parentForm]="ptForm"
                             [patientTrial]="patient.trials[i] !== undefined ? patient.trials[i] : null"
                             (removeFromTrial)="removeFromTrial($event)"
                             [displayRemoveButton]="patient.trials.length > 1"></app-patient-manage-trials>

          <div *ngIf="!hideAssignTrialButton" class="add-patient-trial">
            <button (click)="onAddNewTrial()" class="btn btn-outline" [disabled]="patient.apiConsumerId !== null && patient.apiConsumerId !== ''" type="button">+ Assign New Trial</button>
          </div>
        </section>
      </app-tabulated-content>
    </form>
  </div>
</app-modal-v2>

<app-patient-delete-modal #deletePatientModal [activeTrialCodes]="patient.activeTrialCodes" [patientId]="patient.id" (deletedPatient)="onDeletedPatient()"></app-patient-delete-modal>
