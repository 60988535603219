import { Component, Input } from '@angular/core';
import { BadgeStyle } from "../badge/badge-style.enum";

@Component({
  selector: 'app-badge-trial',
  templateUrl: './badge-trial.component.html',
  styleUrl: './badge-trial.component.scss'
})
export class BadgeTrialComponent {
  @Input('state') state: string = '';

  get badgeStyle(): BadgeStyle {
    switch (this.state) {
      case 'Live':
        return BadgeStyle.Success;
      case 'Pending':
        return BadgeStyle.Warning;
      case 'Closed':
        return BadgeStyle.Secondary;
      case 'Cancelled':
        return BadgeStyle.Danger;
    }
  }
}
