<div class="std-results">

  <!-- Start: View head -->
  <div class="view-head">
    <div class="row">

      <div class="col-12 d-none d-md-block col-md-3">
        <h2>Trial</h2>
      </div>

      <div class="col-12 col-md-9 text-right">
        <div class="button-container">
          <app-button href="/trial/add" text="Add New Trial" faIcon="fas fa-plus" *ngIf="authService.hasPermission(Permissions.TrialAdd)"></app-button>
        </div>

        <form [formGroup]="searchForm">
          <app-search-input name="keywords" [parentForm]="searchForm"
            (inputChange)="onSearchInputChanged($event)"></app-search-input>
        </form>
      </div>

    </div>
  </div>
  <!-- End: View head -->

  <!-- Start: View body -->
  <div class="view-body">
    <div class="content-panel">
      <table class="std-results-list">
        <tbody *ngIf="dataLoaded">
          <tr data-toggle="link" data-toggle-class="more-btn" *ngFor="let trial of results.results; let i = index;"
            (mousedown)="onClickTrial(trial.id)">
            <td>
              <span class="badge-reference-wrap">
                <app-badge-trial [state]="trial.state"></app-badge-trial>
                <span class="result-reference">{{ trial.label }}</span>
              </span>
            </td>
            <td><span class="result-visits">{{ trial.visitCount }} Visits</span></td>
            <td class="result-btn negative-margin"><a (mousedown)="onClickTrial(trial.id)"
                class="btn btn-circle more-btn"><i class="icon-right" aria-hidden="true"></i></a></td>
          </tr>
        </tbody>

        <tbody *ngIf="!dataLoaded" class="trials-loader">
          <tr *ngFor="let _ of loaderArray">
            <td style="width: 10%;"><ngx-skeleton-loader class="d-block"></ngx-skeleton-loader></td>
            <td style="width: 75%;"><ngx-skeleton-loader class="d-block" style="width: 40%;"></ngx-skeleton-loader></td>
            <td style="width: 10%;"><ngx-skeleton-loader class="d-block"></ngx-skeleton-loader></td>
            <td><ngx-skeleton-loader appearance="circle" class="d-block" style="margin-top: -25px"></ngx-skeleton-loader></td>
          </tr>
        </tbody>
      </table>

      <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
        (pageSelected)="loadTrials($event, searchForm.get('keywords').value)"></app-pagination>

      <div class="no-results inside" *ngIf="results.results.length == 0">
        <div class="inner">
          <div class="image">
            <img src="assets/img/no-results-illustration.png" alt="No results found" />
          </div>
          <div class="content">
            <h2>No Results Found</h2>
            <p>There are no trials matching your search.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: View body -->

</div>
