import {
  AccommodationBudgetType,
  ExpenseAutoApprovalRuleType
} from "app/features/trial/trial-details/trial-policy.model";
import { OrderBy } from "./expense.model";

export class ProjectManagementExportRequest {
  startDate?: string;
  endDate?: string;
  trialId: string;
  // trials: string[];
}

export interface OverBudgetRequestsViewModel {
  id: string;
  dateRaised: Date;
  patientId: string;
  patientTrialId: string;
  trialCode: string;
  visitTitle: string;
  visitDate?: Date;
  oracleCode: string;
  category: OverBudgetRequestCategory;
  status: OverBudgetRequestStatus;
  sponsorEmailed: boolean;
}

export enum OverBudgetRequestStatus {
  Open,
  Closed
}

export enum OverBudgetRequestDetailsStatus {
  Approved,
  Rejected,
  PartialApproval,
}

export interface GetOverBudgetRequestsRequest {
  page: number;
  myTrials: boolean;
  search: string;
  orderBy: OrderBy;
  openOnly: boolean;
  sponsorEmailed: boolean;
}

export enum OverBudgetRequestCategory {
  Expense,
  Trip
}


export interface OverBudgetRequestItemsViewModel {
  id: string;
  category: OverBudgetRequestCategory;
  budgetCost?: number;
  accommodationBudgetType?: AccommodationBudgetType;
  careGiver: boolean;
  additionalDetails: string;
  totalActualCost?: number;
  currency: string;
  ruleName: string;
  rulePer?: ExpenseAutoApprovalRuleType;
  blanketReceiptApproval?: boolean;
  status?: OverBudgetRequestDetailsStatus,
  amountPaid?: number;
  newOverBudgetRequestExists: boolean;
  newOverBudgetRequestCurrency: string;
  newOverBudgetRequestAmount?: number;
  newOverBudgetRequestStatus?: OverBudgetRequestDetailsStatus;
  existingOverBudgetRequestExists: boolean;
  existingOverBudgetRequestAmount?: number;
  existingOverBudgetRequestCurrency: string;
  existingOverBudgetRequestStatus?: OverBudgetRequestDetailsStatus;
  items: OverBudgetRequestItemViewModel[];
}

export interface OverBudgetRequestItemViewModel {
  type: string;
  status: string;
  date?: Date;
  actualCost?: number;
  actualCostCurrency: string;
  actualCostInBaseCurrency?: number;
  baseCurrency: string;
  missingReceipt: boolean;
}

export interface OverBudgetRequestApproveTotalRequest {
  overBudgetRequestDetailsId: string;
  approvedBy: string;
  approvedAt?: Date;
}

export interface OverBudgetRequestApproveAllRequest {
  overBudgetRequestId: string;
  approvedBy: string;
  approvedAt?: Date;
}

export interface ApproveTotalFromOverBudgetRequestResult {
  status: OverBudgetRequestStatus;
}

export interface RejectOverBudgetRequestRequest {
  overBudgetRequestId: string;
  rejectedBy: string;
  rejectedAt?: Date;
  notes: string;
}

export interface PartialRejectOverBudgetRequestRequest extends RejectOverBudgetRequestRequest {
  amountToBePaid: number;
}

export interface RejectOverBudgetRequestResult {
  overBudgetClosed: boolean;
}

export interface OverBudgetRequestHistoryViewModel {
  type: string;
  status: OverBudgetRequestHistoryItemStatus;
  budgetCost?: number;
  budgetCurrency: string;
  actualCost?: number;
  amountPaid?: number;
  amountRejected?: number;
  itemCurrency: string;
  closedAt?: Date;
  notes: string;
  visitTitle: string;
}

export interface DeleteOverBudgetRequestRequest {
  overBudgetRequestId: string;
  notes: string;
}

export interface ReferBackOverBudgetRequestRequest {
  overBudgetRequestId: string;
  notes: string;
}

export enum OverBudgetRequestHistoryItemStatus {
  Pending,
  Approved,
  Rejected,
  Booked,
  Blank
}
