<div class="flags">
  <ul>
    <li *ngIf="expense.bankFeesIncurred"><i class="far fa-check"></i> Bank fees incurred</li>
    <li *ngIf="expense.fixedFeePerVisit"><i class="far fa-check"></i> Fixed fee per visit</li>
    <li *ngIf="expense.approvalNoReceipts"><i class="far fa-check"></i> Blanket approval for no receipts</li>
    <li *ngIf="expense.overspendMileage || expense.overspendTravel"><i class="far fa-check"></i> Blanket approval for {{
      expense.overspendTravel ? 'Travel' : '' }} {{ expense.overspendTravel && expense.overspendMileage ? '&' : '' }} {{
      expense.overspendMileage ? 'Mileage' : '' }}</li>
    <li *ngIf="expense.overspendApproved"><i class="far fa-check"></i> Sponsor Approved</li>
    <li *ngIf="expense.fullyReconciled"><i class="far fa-check"></i> Fully Reconciled</li>
  </ul>
</div>
