import { AfterViewInit, Component, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { ModalV2Component } from "../../../shared/modal-v2/modal-v2.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { VisitService } from "../../../core/services/visit.service";
import { VisitTemplate } from "../../../core/models/visit-template.model";
import { AlertService } from "../../../shared/alert/alert.service";
import { Cultures } from "../../../core/constants/cultures";
import { VisitTemplateTranslation } from "../../../core/models/visit-template-translation.model";
import { InputSelectComponent } from "../../../shared/input-select/input-select.component";
import { ModalComponent } from "../../../shared/modal/modal.component";

@Component({
  selector: 'app-visit-schedule-modal',
  templateUrl: './visit-schedule-modal.component.html',
  styleUrl: './visit-schedule-modal.component.scss'
})
export class VisitScheduleModalComponent implements  AfterViewInit {
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('cultureSelect') cultureSelect: InputSelectComponent;
  @ViewChild('confirmationModal') confirmationModal: ModalComponent;

  @Output() changed = new EventEmitter<{templateId: string, title: string}>();

  private readonly _visitService = inject(VisitService);
  private readonly _alertService = inject(AlertService);

  diaryEnabledOnTrial: boolean = false;
  genericDiaryEnabledOnTrial: boolean = false;
  availableCultures: { value: string, text: string }[] = [];
  filteredCultures: { value: string, text: string }[] = [];
  visitTemplate: VisitTemplate | undefined;
  translationForms: FormGroup[] = [];
  form: FormGroup = new FormGroup({
    isBusy: new FormControl<boolean>(false),
    selectedCulture: new FormControl<string>('en'),
    id: new FormControl<string>(''),
    takeMedicationAtVisit: new FormControl<boolean>(false),
    urineSample3DaysBefore: new FormControl<boolean>(false),
    urineSample10DaysBefore: new FormControl<boolean>(false)
  });
  originalVisitTitle: string = '';

  constructor() {
    Cultures.all().forEach(culture => {
      this.availableCultures.push({ value: culture.culture, text: culture.name });
    });
    this.filteredCultures = this.availableCultures;
  }

  ngAfterViewInit() {
    this.form.get('takeMedicationAtVisit').valueChanges.subscribe(checked => {
      if (this.visitTemplate)
        this.visitTemplate.takeMedicationAtVisit = checked;
    });

    this.form.get('urineSample3DaysBefore').valueChanges.subscribe(checked => {
      if (this.visitTemplate)
        this.visitTemplate.urineSample3DaysBefore = checked;
    });

    this.form.get('urineSample10DaysBefore').valueChanges.subscribe(checked => {
      if (this.visitTemplate)
        this.visitTemplate.urineSample10DaysBefore = checked;
    });

    this.form.get('selectedCulture').valueChanges.subscribe(culture => {
      if (this.visitTemplate) {
        let translation = this.visitTemplate.translations.find(x => x.culture === culture);
        if (!translation) {
          // Add translation to visit template
          this.visitTemplate.translations.push(new VisitTemplateTranslation({
            title: '',
            description: '',
            notes: '',
            culture: culture
          }));
        }

        // Add form for translation if it doesn't exist
        const translationForm = this.translationForms.find(f => f.get('culture').value === culture);
        if (!translationForm) {
          this.addTranslationForm(this.visitTemplate.translations.find(x => x.culture === culture));
        }
      }
    });
  }

  onSelectLanguage(language: string) {
    const culture = this.availableCultures.find(t => t.text === language);
    console.log(culture);
    if (culture) {
      this.form.patchValue({selectedCulture: culture.value});
    }
  }

  onCultureFiltered(value: string) {
    // Trim the input value and convert it to lowercase for case-insensitive filtering
    const filterValue = value.trim().toLowerCase();

    this.filteredCultures = this.availableCultures.filter(x =>
      x.text.toLowerCase().includes(filterValue)
    );
  }

  reset() {
    this.form.reset();
    this.form.patchValue({isBusy: false, selectedCulture: 'en'});
  }

  show(templateId: string, diaryEnabled: boolean = false, genericDiaryEnabled: boolean = false): void {
    this.reset();
    this.translationForms = [];
    this.loadVisitTemplate(templateId);
    this.diaryEnabledOnTrial = diaryEnabled;
    this.genericDiaryEnabledOnTrial = genericDiaryEnabled;
    this.cultureSelect.onClear();
    this.modal.show();
  }

  hide() {
    this.reset();
    this.modal.hide();
  }

  private addTranslationForm(translation: VisitTemplateTranslation) {
    let form = new FormGroup({
      id: new FormControl<string>(translation.id),
      culture: new FormControl<string>(translation.culture),
      title: new FormControl<string>(translation.title, Validators.required),
      description: new FormControl<string>(translation.description, Validators.maxLength(2000)),
      notes: new FormControl<string>(translation.notes, Validators.maxLength(2000))
    });

    // When a form changes, update the translation in the original object
    form.valueChanges.subscribe(changes => {
      let translation = this.visitTemplate.translations.find(x => x.culture === changes.culture);
      if (translation) {
        translation.title = changes.title;
        translation.description = changes.description;
        translation.notes = changes.notes;
      }
    });

    this.translationForms.push(form);
  }

  loadVisitTemplate(id: string) {
    this._visitService.getVisitTemplate(id).subscribe({
      next: template => {
        this.visitTemplate = template;
        this.originalVisitTitle = template.translations.find(x => x.culture === 'en').title;

        this.form.patchValue({
          id: template.id,
          selectedCulture: 'en',
          takeMedicationAtVisit: template.takeMedicationAtVisit,
          urineSample3DaysBefore: template.urineSample3DaysBefore,
          urineSample10DaysBefore: template.urineSample10DaysBefore
        });

        template.translations.forEach(translation => {
          const translationForm = this.translationForms.find(f => f.get('culture').value === translation.culture);
          if (!translationForm)
            this.addTranslationForm(translation)
        });
      },
      error: err => {
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onRemoveLanguage(form: FormGroup) {
    let translation = this.visitTemplate.translations.find(x => x.culture === form.get('culture').value);
    if (!translation)
      return;

    translation.title = '';
    translation.description = '';
    translation.notes = '';

    const formIndex = this.translationForms.findIndex(f => f.get('culture').value === form.get('culture').value);
    if (formIndex > -1) {
      this.translationForms.splice(formIndex, 1);
    }

    this.form.patchValue({selectedCulture: 'en'});
  }

  invalidTranslationForms() {
    let invalidCultureForms = [];
    this.translationForms.forEach(form => {
      if (form.invalid) {
        const language = this.availableCultures.find(c => c.value === form.get('culture').value);
        if (language) {
          invalidCultureForms.push(language.text);
        }
      }
    });

    return invalidCultureForms;
  }

  onConfirmCancel() {
    this.confirmationModal.hide();
    this.hide();
  }

  onSubmit(confirmed: boolean = false) {
    if (this.form.invalid)
      return;

    // Has the english visit title changed?
    let englishTitle = this.visitTemplate.translations.find(x => x.culture === 'en').title;
    if (englishTitle !== this.originalVisitTitle && this.visitTemplate.inUse && !confirmed) {
      this.confirmationModal.show();
      return;
    }

    this.form.patchValue({isBusy: true});

    this._visitService.updateVisitTemplate(this.visitTemplate.id, this.visitTemplate).subscribe({
      next: rsp => {
        const english = this.visitTemplate.translations.find(x => x.culture === 'en');

        this.form.patchValue({isBusy: false});
        this.changed.emit({templateId: this.visitTemplate.id, title: english.title});
        this.hide();
        this._alertService.showSuccessAlert('Visit schedule updated successfully!');
      },
      error: err => {
        console.log(err);
        this.form.patchValue({isBusy: false});
        this._alertService.showErrorAlert(err);
      }
    });

    this.confirmationModal.hide();
  }
}
