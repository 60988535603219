import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from './button-type.enum';
import { ButtonStyle } from './button-style.enum';
import { NgClass, NgIf } from '@angular/common';
import { ButtonSize } from './button-size.enum';
import { ButtonIconPosition } from './button-icon-position.enum';
import { ButtonWidth } from './button-width.enum';
import { ButtonTextAlign } from './button-text-align.enum';
import { ButtonMargin } from "./button-margin.enum";
import { ButtonPadding } from "./button-padding.enum";
import { Router } from "@angular/router";

@Component({
    selector: 'app-button',
    standalone: true,
    imports: [NgIf, NgClass],
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input() text: string = 'Submit';
    @Input() href: string | undefined;
    @Input() type: ButtonType = ButtonType.Submit;
    @Input() style: ButtonStyle = ButtonStyle.Primary;
    @Input() size: ButtonSize = ButtonSize.Md;
    @Input() iconPosition: ButtonIconPosition = ButtonIconPosition.Left;
    @Input() width: ButtonWidth = ButtonWidth.Wrap;
    @Input() margin: ButtonMargin = ButtonMargin.None;
    @Input() padding: ButtonPadding = ButtonPadding.Md;
    @Input() disabled: boolean = false;
    @Input() visible: boolean = true;
    @Input() isBusy: boolean = false;
    @Input() className: string = '';
    @Input() faIcon: string | null = null;
    @Input() confirm: boolean = false;
    @Input() confirmText: string = 'Confirm';
    @Input() iconOnly: boolean = false;
    @Input() textAlign: ButtonTextAlign = ButtonTextAlign.Center;
    @Input() title: string = '';

    @Output() clicked = new EventEmitter();

    private readonly _router = inject(Router);

    protected readonly ButtonIconPosition = ButtonIconPosition;
    protected readonly ButtonTextAlign = ButtonTextAlign;
    protected readonly ButtonWidth = ButtonWidth;

    private originalText: string = this.text;
    private originalFaIcon: string | null = this.faIcon;
    protected confirmationActive: boolean = false;
    protected countdown: number = 5;
    private countdownInterval: any;

    ngOnInit() {
        this.originalText = this.text;
        this.originalFaIcon = this.faIcon;
    }

    protected onClick(): void {
        if (this.disabled) return;

        // Handle confirmation logic
        if (this.confirm && !this.confirmationActive) {
            this.startConfirmationCountdown();
        } else {
            this.resetConfirmation();

            if (this.href) {
              this._router.navigate([this.href]).then();
            } else {
              this.clicked.emit(); // Emit the click event if confirmed
            }
        }
    }

    /**
     * Starts the confirmation countdown
     */
    private startConfirmationCountdown(): void {
        this.confirmationActive = true;
        this.originalText = this.text;
        this.originalFaIcon = this.faIcon;
        this.text = `${this.confirmText} (${this.countdown})`;
        this.faIcon = null;

        this.countdownInterval = setInterval(() => {
            this.countdown--;

            if (this.countdown > 0) {
                this.text = `${this.confirmText} (${this.countdown})`;
            } else {
                this.resetConfirmation(); // Reset the button after countdown
            }
        }, 1000);
    }

    /**
     * Resets the confirmation state and restores the original button text
     */
    private resetConfirmation(): void {
        this.confirmationActive = false;
        this.text = this.originalText;
        this.faIcon = this.originalFaIcon;
        this.countdown = 5;

        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        }
    }

    /**
     * Returns a string representation of the button type
     */
    protected buttonType(): string {
        return ButtonType[this.type].toLowerCase();
    }

    /**
     * Builds and returns a list of class names to apply to the button
     */
    protected classNames(): string {
        let classes = [];

        // Add button style
        classes.push('button button-' + ButtonStyle[this.style].toLowerCase());

        // Add button size
        classes.push('size-' + ButtonSize[this.size].toLowerCase());

        // Add margin
        classes.push('margin-' + ButtonMargin[this.margin].toLowerCase());

        // Add padding
        classes.push('padding-' + ButtonPadding[this.padding].toLowerCase());

        // Add custom classes
        if (this.className) classes.push(this.className);

        if (this.confirmationActive) classes.push('px-3');

        if (this.iconOnly) classes.push('icon-only');

        return classes.join(' ');
    }
}
