<div class="patient-registration-container view-body">
  <div class="patient-registration-header">
    <h1>Patient Registration Form for PatientGO®'s Services</h1>
  </div>

  <div class="content-panel">
    <form [formGroup]="patientRegistrationForm" (submit)="createPatientRegistration()">
      <input #uploadImageInput (change)="preview(uploadImageInput.files)" accept='image/*,.pdf' style="display: none;"
        type="file">

      <table class="simple-form">
        <tr>
          <th><label for="trialCode">Public Trial Code</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #trialSelect [options]="trialOptions" [parentForm]="patientRegistrationForm"
                name="trialId" placeholder="Select Trial..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="siteNumber">Site Number</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input [disabled]="!patientRegistrationForm.get('trialId').value" #siteSelect
                [options]="siteOptions[patientRegistrationForm.get('trialId').value]"
                [parentForm]="patientRegistrationForm" name="siteId" placeholder="Select Site..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="investigatorName">Investigator Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="investigatorName" class="form-control" formControlName="investigatorName">
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="formFillerEmail">Your Email (person completing this form)</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="formFillerEmail" class="form-control" formControlName="formFillerEmail">
            </div>
          </td>
        </tr>
      </table>

      <h2>Patient Details</h2>
      <table class="simple-form">
        <tr>
          <th><label for="patientName">Patient Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="patientName" class="form-control" formControlName="patientName">
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="patientNumber">Patient Number</label></th>
          <td>
            <div class="form-group">
              <input type="text" id="patientNumber" class="form-control" formControlName="patientNumber">
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="email">Email Address</label></th>
          <td>
            <div class="form-group">
              <input type="email" id="email" class="form-control" formControlName="email">
            </div>
          </td>
        </tr>
      </table>

      <h2>Signed Registration Form</h2>
      <table class="simple-form">
        <tr>
          <td class="expense-add-images" colspan="2">
            <div class="form-group">
              <ul class="images">
                <li *ngIf="attachment">
                  <ng-container *ngIf="attachment.extension !== 'pdf'">
                    <img [src]="attachment.fileUrl" alt="" />
                    <a (click)="removePhotoModal.show()" class="remove">
                      <i aria-hidden="true" class="far fa-times"></i>
                    </a>
                  </ng-container>

                  <ng-container *ngIf="attachment.extension === 'pdf'">
                    <span class="pdf-icon">
                      <i class="far fa-file-pdf"></i>
                    </span>
                    <a (click)="removePhotoModal.show()" class="remove">
                      <i aria-hidden="true" class="far fa-times"></i>
                    </a>
                  </ng-container>
                </li>
                <li><a *ngIf="!attachment" (click)="onAddImage()">+</a></li>
              </ul>
            </div>
          </td>
        </tr>
        <tr class="attachment-error" *ngIf="showAttachmentError">Please add signed registration form.</tr>
      </table>

      <button [disabled]="processingRequest" type="submit" class="btn btn-primary">
        <span *ngIf="processingRequest">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!processingRequest">Create Patient Registration</span></button>
    </form>
  </div>

</div>

<app-modal #removePhotoModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Photo</h2>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <button (click)="removePhotoModal.hide()" class="btn btn-secondary btn-full-span">Cancel</button>
      </div>
      <div class="col-6">
        <button (click)="onConfirmRemoveImage()" class="btn btn-primary btn-full-span">Confirm</button>
      </div>
    </div>
  </div>
</app-modal>