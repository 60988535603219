import { AdministratorTrial } from "./administrator-trial.model";

export class AdminUser {
  public id: string;
  public firstname: string;
  public lastname: string;
  public email: string;
  public isCurrentAdmin: boolean;
  public roles: string[];
  public trials: AdministratorTrial[] = [];
  public muvUserId: string;
}

export class UpdateAdminUserRequest {
  id: string;
  firstname: string;
  lastname: string;
  roles: string[];
  adminTrials: AdministratorTrial[];
  muvUserId?: number;
}

export interface UpdateAdministratorTrialAssignmentRequest {
  id: string;
  projectManager: boolean;
  designatedContact: boolean;
  allCountries: boolean;
  assignedCountries: string;
}