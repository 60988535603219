<!-- Start: API Consumers -->
<div class="admin-users">

  <div class="row">
    <div class="col-12 text-right header-search">
      <a (click)="onShowAddConsumerModal()" class="btn btn-outline">+ Add
        API Consumer</a>

      <div class="admin-search">
        <app-search-input name="keywords" [parentForm]="searchForm"
                          (inputChange)="onSearchInputChanged($event)"></app-search-input>
      </div>
    </div>
  </div>

  <table class="with-heading-columns" aria-hidden="true">

    <thead>
    <tr>
      <th>Consumer Name</th>
      <th>Webhook URL</th>
      <th>Webhook Version</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngIf="dataLoaded">
    <tr *ngFor="let consumer of results.results">
      <td>
        <div class="name-state">
          <span class="badge badge-success" *ngIf="consumer.enabled">Enabled</span>
          <span class="badge badge-warning" *ngIf="!consumer.enabled">Disabled</span>
          {{ consumer.name }}
        </div>
      </td>
      <td>{{ consumer.webhookUrl }}</td>
      <td>{{ consumer.webhookVersion }}</td>
      <td class="btns">
        <a (click)="onShowEditConsumerModal(consumer)" class="btn btn-secondary btn-slim btn-auto-width">Edit</a>
        <a (click)="onRevealApiKey(consumer)" class="btn btn-outline btn-slim btn-auto-width">Reveal Keys</a>
      </td>
    </tr>
    </tbody>

    <tbody *ngIf="!dataLoaded">
    <tr *ngFor="let _ of loaderArray">
      <td style="width: 30%;"><ngx-skeleton-loader class="d-block" style="width: 50%;"></ngx-skeleton-loader></td>
      <td style="width: 30%;"><ngx-skeleton-loader class="d-block" style="width: 50%;"></ngx-skeleton-loader></td>
      <td style="width: 13%;"><ngx-skeleton-loader class="d-block" style="width: 90%;"></ngx-skeleton-loader></td>
      <td style="width: 14%;"><ngx-skeleton-loader class="d-block" style="width: 90%;"></ngx-skeleton-loader></td>
    </tr>
    </tbody>

  </table>

</div>

<app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
                (pageSelected)="onChangePage($event)"></app-pagination>
<!-- End: API Consumers -->

<!-- Start: Reveal Api Key Modal -->
<app-modal #revealApiKeyModal customClassName="api-key-modal" [size]="ModalSize.Md">
  <div class="modal-header">
    <h2 class="modal-title">API Keys</h2>
  </div>
  <div class="modal-body" *ngIf="currentApiConsumer">
    <div class="flex gap-4 justify-between mb-6">
      <div class="flex-1">
        <p style="margin-bottom: 0;">
          <strong>Primary API Key </strong><br>
          {{ currentApiConsumer.apiKey }}
        </p>
      </div>

      <div class="flex-1">
        <app-button text="Rotate Key" (clicked)="onHandleRotatePrimaryApiKey()" [isBusy]="rotatingPrimaryApiKey" [style]="ButtonStyle.Info" [size]="ButtonSize.Sm" />
      </div>
    </div>

    <div class="flex gap-4 justify-between mb-6">
      <div class="flex-1">
        <p style="margin-bottom: 0;">
          <strong>Seconary API Key </strong><br>
          {{ currentApiConsumer.secondaryApiKey }}
        </p>
      </div>

      <div class="flex-1">
        <app-button text="Rotate Key" (clicked)="onHandleRotateSecondaryApiKey()" [isBusy]="rotatingSecondaryApiKey" [style]="ButtonStyle.Info" [size]="ButtonSize.Sm" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-12">
        <a (click)="revealApiKeyModal.hide()" class="btn btn-primary">Done</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Reveal Api Key Modal -->

<app-add-api-consumer-modal #addApiConsumerModal (added)="onConsumerAddedOrUpdated()"></app-add-api-consumer-modal>
<app-edit-api-consumer-modal #editApiConsumerModal (updated)="onConsumerAddedOrUpdated()"></app-edit-api-consumer-modal>
