<div id="trialVisitSchedule" class="details-view">
  <div class="details-view-results">
    <form [formGroup]="form">
      <div class="details-view-results-head">
        <p>Add Visits</p>
        <input type="number" formControlName="visitCount">
        <button class="btn btn-primary" (click)="addVisits()" [disabled]="form.invalid">Add</button>

        <button class="btn btn-primary" style="margin-left: auto;" (click)="save()" *ngIf="saveBtnVisible" [disabled]="isBusy">Save Visit Schedule</button>
      </div>
    </form>

    <div *ngIf="visits.length" cdkDropList class="visit-list" (cdkDropListDropped)="drop($event)">
      <div class="visit-box" *ngFor="let visit of visits" cdkDrag>
        <i class="fa fa-grip-dots-vertical" cdkDragHandle></i>
        <span class="visit-title">{{ visit.title}}</span>
        <div class="button">
          <button class="btn btn-secondary btn-slim" (click)="onEditTemplate(visit)" [disabled]="!visit.editable">Edit</button>
          <button class="btn btn-outline btn-slim" (click)="removeVisit(visit)">Remove</button>
        </div>

        <div *cdkDragPreview class="visit-box-preview">{{visit.title}}</div>
      </div>
    </div>

  </div>
</div>

<app-visit-schedule-modal #visitScheduleModal (changed)="onVisitTranslationUpdated($event)"></app-visit-schedule-modal>
