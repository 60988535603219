<app-modal-v2 #modal>
  <div class="modal-content">
    <div class="modal-header">
      <div class="title-culture">
        <h2 class="modal-title">Edit Visit Schedule</h2>
        <div class="culture-select">
          <app-input-select #cultureSelect [items]="filteredCultures" [parentForm]="form" controlName="selectedCulture" [allowFiltering]="true" [allowClear]="true" (onFilter)="onCultureFiltered($event)" searchInputPlaceholder="Search..."></app-input-select>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <form *ngFor="let translationForm of translationForms" [formGroup]="translationForm" [class.visibility-hidden]="translationForm.get('culture').value !== form.get('selectedCulture').value">
        <div class="form-group">
          <label>Title</label>

          <div class="title-button">
            <input type="text" class="form-control" formControlName="title">

            <a (click)="onRemoveLanguage(translationForm)" class="btn btn-outline" *ngIf="form.get('selectedCulture').value !== 'en'">Remove Language</a>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control" formControlName="description"></textarea>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Notes</label>
              <textarea class="form-control" formControlName="notes"></textarea>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="form">

        <!-- Start: Maze and generic diary -->
        <div class="checkbox-wrap" *ngIf="form.get('selectedCulture').value === 'en' && (diaryEnabledOnTrial || genericDiaryEnabledOnTrial)">
          <div class="form-group">
            <label class="checkbox">
              <input type="checkbox" id="takeMedicationAtVisit" formControlName="takeMedicationAtVisit"
                     value="true">
              <span></span>
            </label>
            <label class="checkbox-label" for="takeMedicationAtVisit" *ngIf="diaryEnabledOnTrial">MAZE: Medication to be taken at visit</label>
            <label class="checkbox-label" for="takeMedicationAtVisit" *ngIf="genericDiaryEnabledOnTrial">Take Dosage at Visit</label>
          </div>
        </div>
        <!-- End: Maze and generic diary -->

        <!-- Start: Maze Diary -->
        <div [class.visibility-hidden]="this.form.get('selectedCulture').value !== 'en' || !diaryEnabledOnTrial">
          <div class="row">
            <div class="col-6">
              <div class="checkbox-wrap">
                <div class="form-group">
                  <label class="checkbox">
                    <input type="checkbox" id="urineSample3DaysBefore" formControlName="urineSample3DaysBefore"
                           value="true">
                    <span></span>
                  </label>
                  <label class="checkbox-label" for="urineSample3DaysBefore">MAZE: Urine sample (3 days before)</label>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="checkbox-wrap">
                <div class="form-group">
                  <label class="checkbox">
                    <input type="checkbox" id="urineSample10DaysBefore" formControlName="urineSample10DaysBefore"
                           value="true">
                    <span></span>
                  </label>
                  <label class="checkbox-label" for="urineSample10DaysBefore">MAZE: Urine sample (10+ days before)</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End: Maze Diary -->

      </form>
    </div>
    <div class="modal-footer">
      <div class="invalid-translations" *ngIf="invalidTranslationForms().length > 0">
        <span>One or more languages have invalid or missing information.</span>
        <span>
          <a (click)="onSelectLanguage(language)" *ngFor="let language of invalidTranslationForms()">{{ language }}</a>
        </span>
      </div>

      <div class="row">
        <div class="col-6">
          <a (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button (click)="onSubmit()" class="btn btn-primary"
                  [disabled]="form.get('isBusy').value === true || form.invalid || invalidTranslationForms().length > 0">
            <span *ngIf="form.get('isBusy').value === true">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="form.get('isBusy').value === false">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-v2>

<!-- Start: Confirmation modal -->
<app-modal #confirmationModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Update Visit?</h2>
    </div>
    <div class="modal-body">
      <p>This template visit has already been linked to some live visits.  Are you sure you want to change the template visit name?</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="onConfirmCancel()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <a (click)="onSubmit(true)" class="btn btn-primary">
            Yes
          </a>
        </div>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Confirmation modal -->
