import { Permissions } from './../../../core/constants/permissions';
import { AuthService } from './../../../core/services/auth.service';
import { AlertService } from './../../../shared/alert/alert.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '../../../shared/modal/modal.component';
import { AutoApprovalResult, ExpenseCategoryType, ExpenseClaimViewModel, ExpenseSubCategory } from '../../../core/models/expense.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExpenseService } from '../../../core/services/expense.service';
import { LogHelper } from '../../../core/helpers/log.helper';
import { CaregiverTravellingString, ExpenseCheck } from '../../../core/models/expense-check.model';
import { UpdateExpenseClaimCheck } from "../../../core/services/interfaces/update-expense-claim-check.interface";
import {
  ExpenseAutoApprovalResultComponent
} from '../expense-list/expense-auto-approval-result/expense-auto-approval-result.component';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { CaregiverTravellingOptions } from 'app/core/constants/caregiver-travelling-options';
import { VisitAttendanceOptions } from 'app/core/constants/visit-attendance-options';
import {
  OverBudgetCategoriesSelectModalComponent
} from '../over-budget-categories-select-modal/over-budget-categories-select-modal.component';
import {
  OverBudgetRequestModalComponent
} from '../expense-list/over-budget-request-modal/over-budget-request-modal.component';

@Component({
  selector: 'app-check-expense-modal',
  templateUrl: './check-expense-modal.component.html',
  styleUrls: ['./check-expense-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CheckExpenseModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('overBudgetCategoriesSelectModal') overBudgetCategoriesSelectModal: OverBudgetCategoriesSelectModalComponent;
  @ViewChild('overBudgetRequestModal') overBudgetRequestModal: OverBudgetRequestModalComponent;
  @ViewChild("autoApprovalResult") autoApprovalResult: ExpenseAutoApprovalResultComponent;
  @ViewChild('caregiverTravellingSelect') caregiverTravellingSelect: DropdownInputComponent;
  @ViewChild('attendanceSelect') attendanceSelect: DropdownInputComponent;

  ExpenseCategoryType = ExpenseCategoryType;
  ExpenseSubCategory = ExpenseSubCategory;
  CaregiverTravellingString = CaregiverTravellingString;
  Permissions = Permissions;

  @Input('expense') expense: ExpenseClaimViewModel;
  @Output() overBudgetRequestRaised: EventEmitter<any> = new EventEmitter<any>();

  @Output('approved') approved = new EventEmitter<ExpenseClaimViewModel>();
  @Output('rejected') rejected = new EventEmitter<ExpenseClaimViewModel>();
  @Output('onEdit') onEdit = new EventEmitter<ExpenseClaimViewModel>();
  @Output('saved') saved = new EventEmitter<ExpenseClaimViewModel>();
  @Output('suggested') suggested = new EventEmitter<any>();
  @Output('expenseClaimUpdated') expenseClaimUpdated = new EventEmitter();

  selectedTab: string = 'approvalSuggestion'

  caregiverTravellingOptions = CaregiverTravellingOptions.all();
  visitAttendanceOptions = VisitAttendanceOptions.all();
  expenseCheck: ExpenseCheck;
  form: UntypedFormGroup;
  overBudgetDetailsLoading: boolean = false;

  constructor(private expenseService: ExpenseService, private alertService: AlertService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      internalPolicyNotes: new UntypedFormControl({ value: '', disabled: true }),
      policyReminders: new UntypedFormControl(''),
      trialExpensePolicyReminders: new UntypedFormControl(''),
      notes: new UntypedFormControl(''),
      internalNotes: new UntypedFormControl(''),
      visitFlag: new UntypedFormControl(''),
      overspendTravel: new UntypedFormControl(false),
      overspendMileage: new UntypedFormControl(false),
      approvalNoReceipts: new UntypedFormControl(false),
      fixedFeePerVisit: new UntypedFormControl(false),
      bankFeesIncurred: new UntypedFormControl(false),
      overspendApproved: new UntypedFormControl(false),
      caregiverTravelling: new UntypedFormControl(),
      attendance: new UntypedFormControl(),
      visitTravelDays: new UntypedFormControl()
    });
  }

  show(expense: ExpenseClaimViewModel): void {
    this.expense = expense;

    this.expenseService.getExpenseCheck(expense.id).subscribe({
      next: (expenseCheck) => {
        this.expenseCheck = expenseCheck;

        this.form.patchValue({
          policyReminders: expenseCheck.policyReminders,
          notes: expenseCheck.notes,
          internalNotes: expenseCheck.internalNotes,
          overspendTravel: expenseCheck.overspendTravel,
          overspendMileage: expenseCheck.overspendMileage,
          approvalNoReceipts: expenseCheck.approvalNoReceipts,
          fixedFeePerVisit: expenseCheck.fixedFeePerVisit,
          bankFeesIncurred: expenseCheck.bankFeesIncurred,
          overspendApproved: expenseCheck.overspendApproved,
          trialExpensePolicyReminders: expenseCheck.trialExpensePolicyReminders,
          internalPolicyNotes: expenseCheck.internalPolicyNotes,
          visitTravelDays: expenseCheck.visitTravelDays
        });

        if (!this.authService.hasPermission(Permissions.ExpenseWrite)) {
          this.form.get("approvalNoReceipts").disable();
          this.form.get("overspendTravel").disable();
          this.form.get("overspendMileage").disable();
          this.form.get("bankFeesIncurred").disable();
          this.form.get("fixedFeePerVisit").disable();
          this.form.get("overspendApproved").disable();
        }

        this.autoApprovalResult?.setExpenseSuggestionData();

        setTimeout(() => {
          this.caregiverTravellingSelect.setValue(this.expenseCheck.caregiverTravelling);
          this.attendanceSelect.setValue(this.expenseCheck.visitAttendance);
        }, 10);

      },
      error: (error) => {
        LogHelper.log(error);
      }
    });

    this.modal.show();
  }

  hide(): void {
    this.selectedTab = 'approvalSuggestion';
    this.expenseCheck = null;
    this.modal.hide();
  }

  convertAmount(amount: number): string {
    return (amount / 100).toFixed(2);
  }

  /**
   * Called when the user has made changes to the expense claim, it will update the expense claim details in the view
   * @param changes
   */
  onExpenseUpdated(changes: any): void {
    this.expense = { ...this.expense, ...changes };
    this.expense.amount *= 100;
    this.expenseClaimUpdated.emit();
  }

  /**
   * Called when the user selects to approve the expense. If the form is not pristine, it will save the form first
   */
  onApprove(): void {
    if (!this.form.pristine) {
      this.onSave(true);
      return;
    }

    this.approved.emit(this.expense);
    this.hide();
  }

  /**
   * Called when the user selects to reject the expense. If the form is not pristine, it will save the form first
   */
  onReject(): void {
    if (!this.form.pristine) {
      this.onSave(false, true);
      return;
    }

    this.rejected.emit(this.expense);
    this.hide();
  }

  /**
   * Handles saving the form when the user clicks the save button
   */
  onSave(approveWhenSaved = false, rejectWhenSaved = false): void {
    this.form.patchValue({ processing: true });

    let travelDays = this.form.get('visitTravelDays').value;

    const dto: UpdateExpenseClaimCheck = {
      policyReminders: this.form.get('policyReminders').value,
      notes: this.form.get('notes').value,
      overspendApproved: this.form.get('overspendApproved').value,
      bankFeesIncurred: this.form.get('bankFeesIncurred').value,
      overspendTravel: this.form.get('overspendTravel').value,
      overspendMileage: this.form.get('overspendMileage').value,
      fixedFeePerVisit: this.form.get('fixedFeePerVisit').value,
      approvalNoReceipts: this.form.get('approvalNoReceipts').value,
      trialExpensePolicyReminders: this.form.get('trialExpensePolicyReminders').value,
      caregiverTravelling: this.form.get('caregiverTravelling').value,
      attendance: this.form.get('attendance').value,
      visitTravelDays: travelDays ? + travelDays : 0,
    };

    this.expenseService.updateExpenseCheck(this.expense.id, dto).subscribe({
      next: () => {
        this.form.patchValue({ processing: false });
        this.form.markAsPristine();

        if (approveWhenSaved) {
          this.onApprove();
        }

        if (rejectWhenSaved) {
          this.onReject();
        }

        if (!approveWhenSaved && !rejectWhenSaved) {
          this.saved.emit(this.expense);
        }

        this.hide();
      },
      error: (error) => {
        LogHelper.log(error);
        this.form.patchValue({ processing: false });
      }
    });
  }

  onTabSelect(tab: string) {
    this.selectedTab = tab;
  }

  updateRulesList(result: AutoApprovalResult) {
    if (this.expense.approvalSuggestion !== result) {
      this.suggested.emit();
    }
    this.expenseService.getExpenseCheck(this.expense.id).subscribe({
      next: result => {
        if (this.expense.amount !== result.amount) {
          this.suggested.emit();
        }

        this.expenseCheck = result;
        this.expense.amount = result.amount;
        this.expense.currency = result.currency;
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Failed to update rules list. Please check your input and try again or contact support.");
      }
    });
  }

  onRaiseOverBudget() {
    if (!this.expenseCheck.overBudgetRequestRuleExists) {
      this.overBudgetCategoriesSelectModal.subTotals = this.expenseCheck.subTotals.filter(ec => ec.categoryName !== 'Card Preload Redemption');
      this.overBudgetCategoriesSelectModal.selectedCategories = [];
      this.overBudgetCategoriesSelectModal.modal.show();
      return;
    }

    this.overBudgetDetailsLoading = true;

    this.expenseService.getOverBudgetRequestDetailsByExpenseClaim(this.expense.id).subscribe({
      next: response => {
        this.overBudgetDetailsLoading = false;
        this.overBudgetRequestModal.overBudgetRequestDetailsViewModel = response;
        this.overBudgetRequestModal.expenseClaimId = this.expense.id;
        this.overBudgetRequestModal.ruleExists = true;
        this.overBudgetRequestModal.show();
      },
      error: error => {
        this.overBudgetDetailsLoading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  onOverBudgetRequestRaised() {
    this.expense.overBudgetRequestRaised = true;
    this.expenseCheck.overBudgetRequestRaised = true;
    this.overBudgetCategoriesSelectModal.hideModal()
    this.overBudgetRequestRaised.emit();
  }

  raiseOverBudgetRequestByCategories(categories: string[]) {
    this.overBudgetCategoriesSelectModal.loading = true;
    this.expenseService.getOverBudgetRequestDetailsByCategories(categories, this.expense.visitId).subscribe({
      next: response => {
        this.overBudgetCategoriesSelectModal.loading = false;
        this.overBudgetRequestModal.overBudgetRequestDetailsViewModel = response;
        this.overBudgetRequestModal.expenseClaimId = this.expense.id;
        this.overBudgetRequestModal.visitId = this.expense.visitId;
        this.overBudgetRequestModal.ruleExists = false;
        this.overBudgetRequestModal.show();
      },
      error: error => {
        this.overBudgetCategoriesSelectModal.loading = false;
        this.alertService.showErrorResponse(error.error);
      }
    });
  }
}
