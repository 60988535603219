export interface CaxtonCardViewModel {
  id: string;
  name: string;
  delivery?: CaxtonCardDelivery;
  expiry?: Date;
  address: string;
  siteContact: string;
  dateSent?: Date;
  courierCost: string;
  trackingNumber: string;
  status?: CaxtonCardStatus;
  cardInfo: string;
  trialId: string;
  siteId: string;
  siteName: string;
  siteNumber: string;
  patientId: string;
  oracleCode: string;
}

export interface AddCaxtonCardRequest {
  numberOfCards: number;
  lastCardNo: number;
}

export interface UpdateCaxtonCardRequest {
  id: string;
  name: string;
  delivery?: CaxtonCardDelivery;
  trialId: string;
  siteId: string;
  siteCode: string;
  address: string;
  siteContact: string;
  dateSent: string;
  expiry: string;
  courierCost: string;
  trackingNumber: string;
  status?: CaxtonCardStatus;
  cardInfo: string;
}

export interface GetCaxtonCardsRequest {
  page: number;
  filter: string;
  cardStatus: string;
  trialId?: string;
  searchTerm?: string;
}

export interface UpdateCaxtonCardsExpiryDateRequest {
  cardIds: string[];
  expiryDate: Date;
}

export interface UpdateCaxtonCardsDeliveryStatusRequest {
  cardIds: string[];
  delivery: CaxtonCardDelivery;
  courierCost: string;
  trackingNumber: string;
}

export interface AssignCardsRequest {
  cardIds: string[];
  trialId: string;
  siteId: string;
  siteContact: string;
  patientDetails: string;
}

export interface ExportCaxtonCardsRequest {
  startDate: string;
  endDate: string;
  trialId: string;
}

export enum CaxtonCardTypeFilter {
  AllCards,
  Missing,
  Ordered,
  Received
}

export enum MarkCaxtonCardAs {
  Received,
  Sent,
  Missing
}

export enum CaxtonCardAction {
  Assign,
  Received,
  Sent,
  Missing,
  ExpiryDate
}

export enum CaxtonCardDelivery {
  Received,
  Sent,
  Missing,
  Ordered
}

export enum CaxtonCardStatus {
  Activated,
  Archived,
  Stolen,
  Lost,
  Blocked
}
