<div #dropdownParent
     [formGroup]="parentForm" [ngClass]="{'show': isFocused, disabled: disabled, 'has-placeholder': hasPlaceholder}"
     class="select-dropdown-container {{ className }}" [class.can-clear]="canClear && selectedValue">
  <button (click)="onOpen()" *ngIf="!loading" type="button">{{ selectedValueText }}</button>
  <button (click)="onOpen()" *ngIf="loading" type="button"><span aria-hidden="true"
                                                                 class="spinner-border spinner-border-sm"
                                                                 role="status"></span></button>

  <a (click)="reset()" class="clear-icon" *ngIf="canClear && selectedValue"><i class="fas fa-times"></i></a>

  <div class="select-dropdown-items" [ngClass]="{'many-items': options?.length >= 6}">
    <ul>
      <li *ngFor="let option of options; let i = index;" class="select-dropdown-item"><a
        (click)="onItemSelect(i)">{{ option.text }}</a></li>
    </ul>
  </div>

  <select #input [formControlName]="name" *ngIf="name" [disabled]="disabled">
    <option *ngFor="let option of options; let i = index;"
            [value]="option.value"
            [selected]="option.value == selectedValue">
      {{ option.text }}
    </option>
  </select>

  <select #input *ngIf="!name" [disabled]="disabled">
    <option *ngFor="let option of options; let i = index;"
            [value]="option.value"
            [selected]="option.value == selectedValue">
      {{ option.text }}
    </option>
  </select>
</div>
