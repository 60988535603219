import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ExpenseImage } from 'app/core/models/expense-image.model';
import { LogHelper } from '../../../../core/helpers/log.helper';
import { FileDownloadService } from "../../../../core/services/file-download.service";
import { ExpenseService } from 'app/core/services/expense.service';
import { AppendFileToExpenseClaimRequest, RemoveFileFromExpenseClaimRequest } from 'app/core/models/expense.model';
import { AlertService } from 'app/shared/alert/alert.service';
import { ModalComponent } from 'app/shared/modal/modal.component';

@Component({
  selector: 'app-expense-images',
  templateUrl: './expense-images.component.html',
  styleUrls: ['./expense-images.component.scss']
})
export class ExpenseImagesComponent {
  @ViewChild('uploadImageInput') uploadImageInput: ElementRef;
  @ViewChild('removeFileModal') removeFileModal: ModalComponent;

  @Input() expenseClaimId: string;
  @Input() enableImagesManagement: boolean;
  @Input() imagery: ExpenseImage[] = [];

  filenameToRemove: string;
  uploadingImage = false;
  processingRequest = false;

  constructor(
    private fileDownloadService: FileDownloadService,
    private expenseService: ExpenseService,
    private alertService: AlertService) { }

  public uploadIsPdf(uploadUrl: string) {
    let parts = uploadUrl.toLowerCase().split('?');
    return parts[0].endsWith('.pdf');
  }

  onDownloadFile(filename: string) {
    this.fileDownloadService.downloadExpenseFile(filename).subscribe({
      next: (blob) => {
        this.downloadBlob(blob, filename);
      },
      error: (err) => {
        LogHelper.log(err);
      }
    });
  }

  onAddImage() {
    this.uploadImageInput.nativeElement.click();
  }

  preview(files) {
    if (files.length === 0)
      return;

    if (!this.isFileTypeAllowed(files[0])) {
      this.alertService.showWarningAlert("Invalid file type.");
      return;
    }

    this.uploadingImage = true;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.expenseService.upload(files[0]).subscribe({
        next: result => {
          const request: AppendFileToExpenseClaimRequest = {
            expenseClaimId: this.expenseClaimId,
            fileName: result.fileName
          };

          this.expenseService.appendFileToExpenseClaim(request).subscribe({
            next: result => {
              this.imagery.push(result);
              this.uploadingImage = false;
              this.alertService.showSuccessAlert("Image successfully added.");
            },
            error: () => {
              this.alertService.showWarningAlert("Sorry, there was a problem uploading image. Please try again or contact support.");
              this.uploadingImage = false;
            }
          })
        },
        error: () => {
          this.alertService.showWarningAlert("Sorry, there was a problem uploading image. Please try again or contact support.");
          this.uploadingImage = false;
        }
      })
    };
  }

  private isFileTypeAllowed(file: File): boolean {
    const allowedTypes = /(image\/(jpeg|png|gif))|(application\/pdf)/;
    return allowedTypes.test(file.type);
  }

  private downloadBlob(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  onRemoveFile(filename: string) {
    this.filenameToRemove = filename;
    this.removeFileModal.show();
  }

  confirmFileRemove() {
    this.processingRequest = true;
    const request: RemoveFileFromExpenseClaimRequest = {
      expenseClaimId: this.expenseClaimId,
      fileName: this.filenameToRemove
    }

    this.expenseService.removeFileFromExpenseClaim(request).subscribe({
      next: () => {
        this.processingRequest = false;
        this.removeFileModal.hide();
        this.imagery = this.imagery.filter(i => i.filename !== this.filenameToRemove);
        this.alertService.showSuccessAlert("File successfully removed from an expense.");
      },
      error: () => {
        this.processingRequest = false;
        this.removeFileModal.hide();
        this.alertService.showWarningAlert("File failed to be removed. Please try again or contact an administrator.");
      }
    })
  }
}
