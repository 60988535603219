<div class="sidebar" *ngIf="userLoggedIn && rolesLoaded" [class.expanded]="sidebarExpanded">

  <div class="mobile-menu-icon">
    <a (click)="onToggleMobileMenu()" class="sidebar-expand"><i class="far fa-bars"></i></a>
    <a (click)="onToggleMobileMenu()" class="sidebar-contract"><i class="far fa-times"></i></a>
  </div>

  <div class="sidebar-logo">
    <img class="il-logo" src="/assets/img/illingworth-logo.svg" alt="PatientGo">
  </div>

  <div class="navigation-scroll">
    <nav class="sidebar-navigation">
      <ul>
        <ng-container *ngFor="let item of allNavigationItems">
          <li *ngIf="itemVisible(item.roles)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
            <a [routerLink]="item.routerLink" class="link link-icon">
              <i [class]="item.iconClassName" aria-hidden="true" *ngIf="item.iconClassName"></i>
              <img class="header-logo" [src]="item.iconImageUrl" [alt]="item.iconImageUrl" *ngIf="item.iconImageUrl">
              {{ item.text }}
            </a>
          </li>
        </ng-container>
      </ul>

      <!--
      <div class="sidebar-separator"></div>
        <ul>
          <ng-container *ngFor="let item of secondaryNavigationItems">
            <li *ngIf="itemVisible(item.roles)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
              <a [routerLink]="item.routerLink" class="link link-icon">
                <i [class]="item.iconClassName" aria-hidden="true" *ngIf="item.iconClassName"></i>
                <img class="header-logo" [src]="item.iconImageUrl" [alt]="item.iconImageUrl" *ngIf="item.iconImageUrl">
                {{ item.text }}
              </a>
            </li>
          </ng-container>
        </ul>
        -->
    </nav>
  </div>

  <div class="sidebar-footer">
    <ul class="admin">
      <li routerLinkActive="selected"
        *ngIf="adminTabVisible([RoleNames.ProductAdministrator, RoleNames.SystemAdministrator, RoleNames.ProjectCoordinatorExpenses, RoleNames.ProjectCoordinatorTravel])">
        <a routerLink="/admin" class="link link-icon"><i class="icon-people" aria-hidden="true"></i>
          <span class="text">Admin</span>
        </a>
      </li>
      <li routerLinkActive="selected"
          *ngIf="itemVisible([RoleNames.ProductAdministrator, RoleNames.SystemAdministrator])">
        <a routerLink="/settings" class="link link-icon link-settings"><i class="fas fa-cog" aria-hidden="true"></i>
          <span class="text">Settings</span>
        </a>
      </li>
      <li routerLinkActive="selected">
        <a (click)="onLogout()" class="link link-icon link-logout">
          <i class="fas fa-right-from-bracket" aria-hidden="true"></i> <span class="text">Logout</span>
        </a>
      </li>
    </ul>
  </div>
</div>
