<div class="view-head">
  <div class="row">

    <div class="col-12 d-none d-md-block col-md-3">
      <h2>Admin</h2>
    </div>

    <div class="col-12 col-md-9 text-right">
      <div class="button-container">
        <a (click)="onShowExportModal()" class="btn btn-outline" *ngIf="authService.hasPermission(Permissions.UserChangeLogExport)">Export Account Data</a>
        <a (click)="onShowExportUsersModal()" class="btn btn-outline" *ngIf="authService.hasPermission(Permissions.UserChangeLogExport)">Export Change Log</a>
      </div>
    </div>

  </div>
</div>

<div class="view-body admin">

  <div class="std-tabbed-content">
    <div class="tabs-wrap">
      <app-mobile-tabs #mobileTabs (onSelected)="onTabClick($event)">
        <ul>
          <li *ngIf="usersVisible()"><a class="selected" (click)="mobileTabs.onSelectItem('cms-users', $event)">CMS Users</a></li>
          <li><a (click)="mobileTabs.onSelectItem('site-contacts', $event)">Site Contacts</a></li>
          <li *ngIf="authService.hasPermission(Permissions.UserChangeLogRead)"><a (click)="mobileTabs.onSelectItem('cms-change-log', $event)">CMS User Change Log</a></li>
          <li *ngIf="authService.hasPermission(Permissions.SiteContactChangeLogRead)"><a (click)="mobileTabs.onSelectItem('contact-change-log', $event)">Site Contact Change Log</a></li>
        </ul>
      </app-mobile-tabs>

      <ul class="nav nav-tabs">
        <li *ngIf="usersVisible()" class="nav-item">
          <a (click)="onTabClick('cms-users')" [ngClass]="{'active': selectedTab == 'cms-users'}" class="nav-link">CMS Users</a>
        </li>
        <li class="nav-item">
          <a (click)="onTabClick('site-contacts')" [ngClass]="{'active': selectedTab == 'site-contacts'}" class="nav-link">Site Contacts</a>
        </li>
        <li *ngIf="authService.hasPermission(Permissions.UserChangeLogRead)" class="nav-item">
          <a (click)="onTabClick('cms-change-log')" [ngClass]="{'active': selectedTab == 'cms-change-log'}" class="nav-link">CMS User Change Log</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.SiteContactChangeLogRead)">
          <a (click)="onTabClick('contact-change-log')" [ngClass]="{'active': selectedTab == 'contact-change-log'}" class="nav-link">Site Contact Change Log</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasRole('ProductAdministrator') || authService.hasRole('SystemAdministrator')">
          <a (click)="onTabClick('api-consumers')" [ngClass]="{'active': selectedTab == 'api-consumers'}" class="nav-link">API Consumers</a>
        </li>
      </ul>
    </div>

    <div class="results-wrap">

      <div class="tab-content">

        <app-admin-users *ngIf="selectedTab === 'cms-users'"></app-admin-users>

        <app-admin-site-contacts *ngIf="selectedTab === 'site-contacts'"></app-admin-site-contacts>

        <app-admin-change-log *ngIf="selectedTab === 'cms-change-log'"></app-admin-change-log>

        <app-site-contact-change-log *ngIf="selectedTab === 'contact-change-log'"></app-site-contact-change-log>

        <app-admin-api-consumers *ngIf="selectedTab === 'api-consumers'"></app-admin-api-consumers>

      </div>

    </div>

  </div>

</div>

<!-- Start: Export change log modal -->
<app-modal #changeLogModal>
  <div class="modal-header">
    <h2 class="modal-title">Export Change Log</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="changeLogModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportChangeLog()" class="btn btn-primary" (mousemove)="updateFormValues()">
          <span *ngIf="exportIsProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!exportIsProcessing">Export</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export change log modal -->

<!-- Start: Export modal -->
<app-modal #exportModal>
  <div class="modal-header">
    <h2 class="modal-title">Export Account Data</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportUsersForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #usersDateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #usersDateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
      <div class="form-group">
        <label class="checkbox">
          <input formControlName="allData" id="all-data" type="checkbox" value="true">
          <span></span>
        </label>
        <label class="checkbox-label" for="all-data">All available data (ignore date range)</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportUsers()" class="btn btn-primary" (mousemove)="updateExportUsersFormValues()">
          <span *ngIf="exportUsersForm.get('processing').value">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
          <span *ngIf="!exportUsersForm.get('processing').value">Export</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export modal -->
