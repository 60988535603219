import { Permissions } from './../../../../core/constants/permissions';
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { SiteList } from "../../../../core/models/site-list.model";
import { SiteService } from "../../../../core/services/site.service";
import { TrialService } from "../../../../core/services/trial.service";
import { AuthService } from "../../../../core/services/auth.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { ButtonSize } from "../../../../shared/button/button-size.enum";
import { ButtonStyle } from "../../../../shared/button/button-style.enum";
import { ButtonWidth } from "../../../../shared/button/button-width.enum";
import { ButtonMargin } from "../../../../shared/button/button-margin.enum";

@Component({
  selector: 'app-trial-sites',
  templateUrl: './trial-sites.component.html',
  styleUrls: ['./trial-sites.component.scss']
})
export class TrialSitesComponent implements OnInit, OnChanges {
  @ViewChild('unassignSiteModel') unassignSiteModel: ModalComponent;

  @Input() trialId: string;
  @Input('apiEnabled') apiEnabled: boolean = false;

  results = new SiteList();
  siteIsUnassigning = false;
  unassignSiteId = '';
  Permissions = Permissions;

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonWidth = ButtonWidth;
  protected readonly ButtonMargin = ButtonMargin;

  constructor(public authService: AuthService, private readonly siteService: SiteService, private readonly alertService: AlertService, private readonly trialService: TrialService) { }

  ngOnInit() {
    this.loadSites();
  }

  loadSites(pageNo: number = 1) {
    if (this.trialId != null) {
      this.siteService.retrieveSites(pageNo, this.trialId, null, null).subscribe({
        next: siteList => {
          this.results = siteList;
        },
        error: () => {
          this.alertService.showWarningAlert('There was a problem loading sites!');
        }
      });
    }
  }

  mapStatus(int: number) {
    switch (int) {
      case 0:
        return "Pending"
      case 1:
        return "Approved"
      case 2:
        return "Declined"
      default:
        break;
    }
  }

  ngOnChanges() {
    this.loadSites();
  }

  onUnassignSite(siteId: string) {
    this.unassignSiteId = siteId;
    this.unassignSiteModel.show();
  }

  onConfirmUnassignSite() {
    this.trialService.unassignSite(this.trialId, this.unassignSiteId).subscribe({
      next: () => {
        this.unassignSiteId = '';
        this.unassignSiteModel.hide();
        this.loadSites(1);
      },
      error: error => {
        this.alertService.showWarningAlert(error.error ? error.error.title : 'Sorry there was a problem unassigning the site.');
      }
    });
  }
}
