<div class="tab-pane fade show active details-view-results" id="trial-patients" role="tabpanel">
  <div class="details-view-results-head">
    <div class="flex justify-end">
      <app-button
        text="Assign New Patient"
        faIcon="fas fa-plus"
        (clicked)="onShowAssignPatientModal()"
        [size]="ButtonSize.Sm"
        [margin]="ButtonMargin.Lg"
        [style]="ButtonStyle.Info"
        [disabled]="!addPatientAvailable"
        [visible]="authService.hasPermission(Permissions.TrialPatientAssign)" />
    </div>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr style="color: gray;">
      <th style="width: 10%;">Patient Code</th>
      <th>Patient Full Name</th>
      <th>Patient Address</th>
      <th>Status on Trial</th>
      <th style="width: 20%;"></th>
    </tr>
    </thead>
    <tbody>
    <tr (click)="onPatientClick(patient.patientTrialId)" *ngFor="let patient of results.results" data-toggle="link">
      <td>{{ patient.patientCode == null ? 'NA' : patient.patientCode }}</td>
      <td>{{ patient.firstname + ' ' + patient.lastname }}</td>
      <td>{{ patient.address != null ? patient.address : '' + ' ' + patient.country != null ? patient.country : '' }}</td>
      <td>{{ patient.stateOnTrial }}</td>
      <td class="details-view-btn"><a class="btn btn-circle more-btn more-btn"
        routerLink="/patient/{{ patient.patientTrialId }}"><i
        aria-hidden="true" class="icon-right"></i></a></td>
    </tr>
    </tbody>
  </table>

  <app-pagination (pageSelected)="loadPatients($event)" [currentPage]="results.currentPage"
                  [totalPageCount]="results.totalPageCount"></app-pagination>

  <div class="no-results inside" *ngIf="results.results.length == 0">
    <div class="inner">
      <div class="image">
        <img alt="No results found" src="assets/img/no-results-illustration.png"/>
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no patients on this trial.</p>
      </div>
    </div>
  </div>
</div>

<app-trial-assign-patient-modal #assignPatientModal
                                (patientAssigned)="onPatientAssigned()"></app-trial-assign-patient-modal>
