<div *ngIf="!hasSites">

  <div class="main-container std-results">
    <div class="container">
      <div class="row">

        <div class="no-results">
          <div class="inner">
            <div class="image">
              <img src="assets/img/no-sites.png" alt="No sites, yet" />
            </div>
            <div class="content">
              <h2>No Sites, Yet</h2>
              <p>Your created sites will appear here.</p>

              <a routerLink="/site/add" class="btn btn-primary" *ngIf="authService.hasPermission(Permissions.SiteAdd)">+
                Add
                New Site</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>

<div *ngIf="hasSites">

  <div class="view-head">
    <form [formGroup]="searchForm">
      <div class="row">

        <div class="col-12 d-none d-lg-block col-lg-4">
          <div class="title-country-filter">
            <h2>Sites</h2>

            <div class="country-filter">
              <app-autosuggest-dropdown-input className="small" placeholder="All Countries" [options]="countryOptions"
                [parentForm]="searchForm" controlName="country"></app-autosuggest-dropdown-input>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-8 text-right">
          <div class="button-container">
            <a (click)="exportSitesModal.show()" class="btn btn-outline"
              *ngIf="authService.hasPermission(Permissions.SiteExportCsv)">Export</a>
            <a routerLink="/site/add" class="btn btn-primary" *ngIf="authService.hasPermission(Permissions.SiteAdd)">+
              Add New Site</a>
          </div>

          <app-search-input name="keywords" [parentForm]="searchForm"
            (inputChange)="onSearchInputChanged($event)"></app-search-input>

          <div class="toggle-button-wrapper">
            <label class="switch mb-0">
              <input (click)="onHandleToggleApiConsumerSites()" type="checkbox" [checked]="false">
              <span class="slider round"></span>
            </label>
            <label class="switch-label">API-created sites</label>
          </div>
        </div>

      </div>
    </form>
  </div>

  <div class="view-body">
    <div class="content-panel">
      <table aria-hidden="true" class="std-results-list with-heading-columns">
        <thead>
          <tr>
            <th style="width: 15%;">Global Site Id</th>
            <th>Site Name</th>
            <th>Site Address</th>
            <th style="width: 25%;"></th>
          </tr>
        </thead>
        <tbody *ngIf="dataLoaded">
          <tr *ngFor="let site of results.results" data-toggle-class="more-btn">
            <td class="site-id"> {{ site.irgSiteId }} <span class="badge badge-info" *ngIf="site.apiConsumerId">{{ site.apiConsumerName }}</span></td>
            <td> {{ site.name }}</td>
            <td>{{ site.getFullAddress() }}</td>
            <td class="text-right">
              <div class="site-actions" style="width: unset">
                <a (click)="onDeleteSite(site.id)" *ngIf="authService.hasPermission(Permissions.SiteDelete)"
                  class="btn btn-outline btn-delete">Delete</a>
                <a *ngIf="authService.hasPermission(Permissions.SiteEdit)" class="btn btn-secondary btn-edit"
                  routerLink="/site/{{ site.id }}/edit">Edit</a>
                <a *ngIf="authService.hasPermission(Permissions.SiteTrialsRead) && authService.hasPermission(Permissions.TrialRead)"
                  class="btn btn-secondary btn-edit" routerLink="/site/{{ site.id }}/trials">Trials</a>
              </div>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!dataLoaded" class="sites-loader">
          <tr *ngFor="let _ of loaderArray">
            <td style="width: 10%;"><ngx-skeleton-loader class="d-block"></ngx-skeleton-loader></td>
            <td style="width: 20%;"><ngx-skeleton-loader class="d-block"></ngx-skeleton-loader></td>
            <td style="width: 45%;"><ngx-skeleton-loader class="d-block"></ngx-skeleton-loader></td>
            <td class="button-loaders">
              <ngx-skeleton-loader style="width: 95%;"></ngx-skeleton-loader>
              <ngx-skeleton-loader style="width: 95%;"></ngx-skeleton-loader>
              <ngx-skeleton-loader style="width: 95%;"></ngx-skeleton-loader>
            </td>
          </tr>
        </tbody>
      </table>

      <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
        (pageSelected)="loadSites($event, searchForm.get('keywords').value, searchForm.get('country').value)"></app-pagination>

      <div class="no-results inside"
        *ngIf="results.results.length == 0 && (keywords != null && keywords != '' || searchForm.get('country').value != null && searchForm.get('country').value != '')">
        <div class="inner">
          <div class="image">
            <img src="assets/img/no-results-illustration.png" alt="No results found" />
          </div>
          <div class="content">
            <h2>No Results Found</h2>
            <p>There are no sites matching your search.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- Start: Export sites modal -->
<app-modal #exportSitesModal (mousemove)="onExportMouseMouse()">
  <div class="modal-header">
    <h2 class="modal-title">Export</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
      <div class="form-group">
        <label class="checkbox">
          <input formControlName="allData" id="all-data" type="checkbox" value="true">
          <span></span>
        </label>
        <label class="checkbox-label" for="all-data">All available data (ignore date range)</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportSitesModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportSites()" (mousemove)="updateExportFormValues()" [class.disabled]="!exportForm.valid"
          class="btn btn-primary">
          <span *ngIf="exportIsProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!exportIsProcessing">Export</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export sites modal -->

<!-- Start: Delete site modal -->
<app-modal #deleteSiteModal customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Delete Site?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to delete this site?</p>

      <p>Only sites that are not allocated to trials can be deleted.</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="deleteSiteModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmDeleteSite()" class="btn btn-primary">
          <span *ngIf="siteIsDeleting">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!siteIsDeleting">Yes, delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export sites modal -->
