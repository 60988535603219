import { Component, Input, Renderer2 } from '@angular/core';
import { ModalSize } from "./modal-size.enum";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {
  visible = false;
  @Input() customClassName?= '';
  @Input() scrollable = false;
  @Input() size: ModalSize = ModalSize.Sm;

  @Input() deleteIndex = -1;
  @Input() deleteId: string;

  constructor(private renderer: Renderer2) {
  }

  hide() {
    this.visible = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  show() {
    this.visible = true;
    this.renderer.addClass(document.body, 'modal-open');
  }

  showUsingDeleteIndex(index: number) {
    this.deleteIndex = index;
    this.show();
  }

  showUsingDeleteId(id: string) {
    this.deleteId = id;
    this.show();
  }

  resetDeleteIndexAndId() {
    this.deleteIndex = -1;
    this.deleteId = null;
  }

  protected readonly ModalSize = ModalSize;
}
