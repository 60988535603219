import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import {
  AddCaxtonCardRequest,
  AssignCardsRequest,
  CaxtonCardViewModel,
  ExportCaxtonCardsRequest,
  GetCaxtonCardsRequest,
  UpdateCaxtonCardRequest,
  UpdateCaxtonCardsDeliveryStatusRequest,
  UpdateCaxtonCardsExpiryDateRequest
} from "../models/card-tracker.model";
import { ResponsePage } from "../models/search-page.model";

@Injectable({
  providedIn: 'root'
})
export class CaxtonCardService {
  private endpoint = '/caxtoncard';

  constructor(private http: HttpClient) { }

  getLastCardNumber(): Observable<{no: number}> {
    return this.http.get<{no: number}>(`${environment.apiUrl}${this.endpoint}/lastcard`);
  }

  addCards(request: AddCaxtonCardRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}${this.endpoint}`, request);
  }

  updateCard(request: UpdateCaxtonCardRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this.endpoint}`, request);
  }

  updateCardsExpiryDate(request: UpdateCaxtonCardsExpiryDateRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this.endpoint}/expiry`, request);
  }

  updateCardsDeliveryStatus(request: UpdateCaxtonCardsDeliveryStatusRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this.endpoint}/delivery`, request);
  }

  assignCards(request: AssignCardsRequest): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}${this.endpoint}/assign`, request);
  }

  getCaxtonCards(request: GetCaxtonCardsRequest): Observable<ResponsePage<CaxtonCardViewModel>> {
    let params = new HttpParams();
    params = params.set('page', request.page);
    params = params.set('filter', request.filter);
    params = params.set('cardStatus', request.cardStatus);
    params = params.set('trialId', request.trialId);
    params = params.set('searchTerm', request.searchTerm);

    return this.http.get<ResponsePage<CaxtonCardViewModel>>(`${environment.apiUrl}${this.endpoint}`, { params: params });
  }

  exportCards(request: ExportCaxtonCardsRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}${this.endpoint}/export`, request);
  }
}
