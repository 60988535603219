<app-modal #modal>
  <div class="modal-header">
    <h2 class="modal-title">{{ title }}</h2>
  </div>
  <div class="modal-body" style="text-align: center;">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="modal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmed()" class="btn btn-primary">{{ confirmBtnText }}</a>
      </div>
    </div>
  </div>
</app-modal>
