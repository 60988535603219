import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: '[table-cell-amount]',
  templateUrl: './table-cell-amount.component.html',
  styleUrls: ['./table-cell-amount.component.scss']
})
export class TableCellAmountComponent implements AfterViewInit, OnChanges {
  @ViewChild('input') input: ElementRef;
  @ViewChildren('td') tdElements: QueryList<ElementRef>;

  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('amount') amount: string;
  @Input('editable') editable: boolean = false;
  @Input('truncateLength') truncateLength: string = '0';
  @Input('minWidth') minWidth: string;
  @Input('maxWidth') maxWidth: string;
  @Input('negativeAmount') negativeAmount: boolean;

  editing = false;
  originalValue = '';
  renderedValue = '';

  ngAfterViewInit() {
    this.renderedValue = this.penceToPounds(this.amount);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.amount && !changes.amount.firstChange) {
      this.renderedValue = this.penceToPounds(this.amount);
    }
  }

  onEdit(): void {
    if (this.editable) {
      this.editing = true;
      this.originalValue = this.parentForm.get(this.controlName).value;
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 500);
    }
  }

  onBlur(): void {
    this.editing = false;

    // if the value has changed, then emit an event to let the parent component know about it
    if (this.parentForm.get(this.controlName).value != this.originalValue) {
      let val = this.parentForm.get(this.controlName).value;
      if (val !== '' && val !== '0') {
        this.amount = (+this.parentForm.get(this.controlName).value * 100).toString();
        this.renderedValue = this.penceToPounds(this.amount);
      }

      this.valueChanged.emit();
    }
  }

  penceToPounds(amount: string): string {
    if (amount === null || amount === undefined)
      return '';

    const pounds = +amount / 100;

    // Format the number to a string with commas as thousands separators and two decimal places
    return pounds.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

}
