<div class="tab-pane show active fade details-view-results" id="trial-contacts" role="tabpanel">
  <div class="trial-default-support-contacts">
    <div class="title">
      <h2>Default</h2>
    </div>

    <form [formGroup]="defaultContactForm" (submit)="onUpdateDefaultSupportContacts()">
      <div class="default-sc-container mb-4">
        <div class="default-cs-col">
          <label>Trial Support Email Address</label>
        </div>
        <div class="default-cs-col">
          <div class="form-group">
            <input type="text" formControlName="supportEmail">
          </div>
        </div>

        <div class="default-cs-col">
          <label>Trial Support Phone Number</label>
        </div>
        <div class="default-cs-col">
          <div class="form-group">
            <input type="text" formControlName="supportPhone">
          </div>
        </div>

        <div class="default-cs-col">
          <div class="form-group" *ngIf="authService.hasPermission(Permissions.TrialEditPublic)">
            <app-button
              text="Update"
              [type]="ButtonType.Submit"
              [isBusy]="defaultContactsFormProcessing"
              [disabled]="!defaultContactForm.valid || defaultContactsFormProcessing"
            />
          </div>
        </div>
      </div>

      <span class="validation-error" style="margin-bottom: 20px; text-align:center; display: block; margin-top: -20px;" *ngIf="!isValidPatientGoEmail(this.defaultContactForm.get('supportEmail').value)">Warning! Trial support email does not use an &#64;patientgo.com domain so NO emails will be sent to patients for this trial</span>
    </form>
  </div>

  <div class="details-view-results-head">
    <div class="flex justify-end">
      <app-button
        text="Add Support Contact"
        faIcon="fas fa-plus"
        (clicked)="onAddContact()"
        [size]="ButtonSize.Sm"
        [margin]="ButtonMargin.Lg"
        [style]="ButtonStyle.Info"
        [visible]="authService.hasPermission(Permissions.TrialSupportContactsAdd)" />
    </div>
  </div>

  <div class="support-contacts" *ngIf="trial.supportContacts.length > 0 || hasChanges">
    <table class="support-contacts-tbl">
      <tr>
        <th>Country</th>
        <th>Language</th>
        <th>Label</th>
        <th>Number</th>
        <th></th>
      </tr>
      <tr *ngFor="let contact of trial.supportContacts; let i = index">
        <td class="country-cell"><app-dropdown-input placeholder="Select country..." [options]="countryList" [selectedValue]="contact.country" (selectValueChanged)="onUpdateCountry(i, $event)"></app-dropdown-input></td>
        <td class="culture-cell"><app-dropdown-input placeholder="All" [options]="cultures" [selectedValue]="contact.culture" (selectValueChanged)="onUpdateCulture(i, $event)"></app-dropdown-input></td>
        <td class="label-cell"><input type="text" class="form-control" (keyup)="onUpdateLabel(i, $event)" [value]="contact.label"></td>
        <td><input type="text" class="form-control" (keyup)="onUpdateContact(i, $event)" [value]="contact.contact"></td>
        <td>
          <app-button
            text="Remove"
            (clicked)="onRemoveContact(i)"
            [style]="ButtonStyle.Info"
            [visible]="authService.hasPermission(Permissions.TrialSupportContactsDelete)" />
        </td>
      </tr>
    </table>

    <div class="button flex justify-end">
      <app-button
        text="Update Support Contacts"
        (clicked)="onUpdateSupportContacts()"
        [style]="ButtonStyle.Primary"
        [isBusy]="isSaving"
        [disabled]="!hasChanges"
        [visible]="authService.hasPermission(Permissions.TrialSupportContactsDelete)" />
    </div>
  </div>

  <div class="no-results inside" *ngIf="trial.supportContacts.length == 0 && !hasChanges">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no support contacts assigned to this trial.</p>
      </div>
    </div>
  </div>
</div>
