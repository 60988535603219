<div class="tab-pane active show fade details-view-results" id="trial-change-log" role="tabpanel">
  <app-change-log
    [dataLoaded]="dataLoaded"
    [results]="changeLogResults"
    (pageChanged)="onPageChanged($event)"
    (exportClicked)="exportChangeLogModal.show()">
</app-change-log>
</div>

<!-- Start: Export patient change log modal -->
<app-modal #exportChangeLogModal>
  <div class="modal-header">
    <h2 class="modal-title">Export Change Log</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <div class="form-group">
        <label>Start Date</label>
        <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
      </div>
      <div class="form-group">
        <label>End Date</label>
        <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
      </div>
    </form>
  </div>
  <div class="modal-footer" (mousemove)="updateExportFormValues()">
    <div class="row">
      <div class="col-6">
        <app-button
          text="Cancel"
          (clicked)="exportChangeLogModal.hide()"
          [width]="ButtonWidth.Fill"
          [style]="ButtonStyle.Secondary"
        />
      </div>

      <div class="col-6">
        <app-button
          text="Export"
          (clicked)="onExportChangeLog()"
          [width]="ButtonWidth.Fill"
        />
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export patient change log modal -->
