import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteService } from "../../../../../core/services/site.service";
import { Countries } from "../../../../../core/constants/countries";
import { LogHelper } from "../../../../../core/helpers/log.helper";
import { AlertService } from "../../../../../shared/alert/alert.service";
import { SelectOption } from "../../../../../core/models/select-option.model";
import { ApiConsumerService } from "../../../../../core/services/api-consumer.service";
import { ApiConsumerSimple } from "../../../../../core/models/api-consumer-simple.model";
import { TimezoneSelectComponent } from "../../../../../shared/timezone-select/timezone-select.component";

@Component({
  selector: 'app-trial-new-site',
  templateUrl: './trial-new-site.component.html'
})
export class TrialNewSiteComponent implements OnInit {
  @ViewChild('timezoneSelect') timezoneSelect: TimezoneSelectComponent;

  trialId: string;
  form: UntypedFormGroup;
  isFormProcessing = false;
  countries: { value: string, text: string }[] = [];
  apiConsumerOptions: SelectOption[] = [];

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly apiConsumerService: ApiConsumerService, private readonly siteService: SiteService, private readonly alertService: AlertService, private readonly router: Router) {
    for (const country of Countries.all()) {
      this.countries.push({ value: country.code, text: country.name });
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.trialId = params.id;
    });

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
      address: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      irgSiteId: new UntypedFormControl(''),
      siteAddress: new UntypedFormControl(null, Validators.required),
      apiConsumer: new UntypedFormControl(false),
      apiConsumerId: new UntypedFormControl(''),
      timezone: new UntypedFormControl()
    });

    this.loadAndSetApiConsumerOptions();
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;
      this.siteService.createSite(
        this.form.get('name').value,
        this.form.get('address').value,
        this.form.get('country').value,
        this.form.get('irgSiteId').value,
        this.form.get('siteAddress').value,
        this.form.get('apiConsumerId').value,
        this.form.get('timezone').value
      ).subscribe({
        next: site => {
          this.alertService.showSuccessAlert('Site Added Successfully');
          void this.router.navigate(['/trial', this.trialId, 'site', 'assign'], { queryParams: { siteId: site.id } });
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('Unable to create site, please try again!');
        }
      });
    }
  }

  /**
   * Retrieves and sets the api consumer options to be used in the api consumer dropdown
   */
  loadAndSetApiConsumerOptions(): void {
    this.apiConsumerService.getAllApiConsumers().subscribe({
      next: (apiConsumers: any) => {
        this.apiConsumerOptions = apiConsumers.map((apiConsumer: ApiConsumerSimple) => {
          const consumerName = apiConsumer.enabled ? apiConsumer.name : `${apiConsumer.name} (Temporarily disabled)`;
          return new SelectOption(apiConsumer.id, consumerName);
        });
      }
    });
  }
}
