<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="details-view-results-head">
    <div class="flex justify-end">
      <app-button
        text="Expense Policy"
        faIcon="fas fa-plus"
        href="/trial/{{trial.id}}/policy/add"
        [size]="ButtonSize.Sm"
        [margin]="ButtonMargin.Lg"
        [style]="ButtonStyle.Info"
        [visible]="authService.hasPermission(Permissions.TrialPolicyAdd)" />
    </div>
  </div>

  <table class="details-view-policies">
    <tr class="trial-policy-row" *ngFor="let policy of policies" [policy]="policy" (remove)="onRemovePolicy($event)" (edit)="onEdit($event)"></tr>
  </table>
</div>

<!-- Start: Remove policy modal -->
<app-modal #removePolicyModal>
  <div class="modal-header">
    <h2 class="modal-title">Remove Expense Policy</h2>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to remove this expense policy from the trial?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <app-button
          text="Cancel"
          (clicked)="removePolicyModal.hide()"
          [style]="ButtonStyle.Secondary"
          [width]="ButtonWidth.Fill"
        ></app-button>
      </div>

      <div class="col-6">
        <app-button
          text="Yes, remove"
          (clicked)="onConfirmRemovePolicy()"
          [width]="ButtonWidth.Fill"
        ></app-button>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Remove policy modal -->
