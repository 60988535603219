import { Permissions } from './../../../../core/constants/permissions';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrialService } from "../../../../core/services/trial.service";
import { TrialPatientList } from "../../../../core/models/trial-patient-list.model";
import { AlertService } from "../../../../shared/alert/alert.service";
import { AuthService } from '../../../../core/services/auth.service';
import { TrialAssignPatientModalComponent } from '../trial-assign-patient-modal/trial-assign-patient-modal.component';
import { TrialState, TrialStateString } from 'app/core/constants/trial-constants';
import { ButtonStyle } from "../../../../shared/button/button-style.enum";
import { ButtonMargin } from "../../../../shared/button/button-margin.enum";
import { ButtonSize } from "../../../../shared/button/button-size.enum";

@Component({
  selector: 'app-trial-patients',
  templateUrl: './trial-patients.component.html'
})
export class TrialPatientsComponent implements OnInit {
  @ViewChild('assignPatientModal') assignPatientModal: TrialAssignPatientModalComponent;

  @Input('apiEnabled') apiEnabled: boolean = false;

  results = new TrialPatientList();
  trialId: string;
  trialCode: string;
  addPatientAvailable: boolean = false;

  Permissions = Permissions;

  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonMargin = ButtonMargin;
  protected readonly ButtonSize = ButtonSize;

  constructor(public authService: AuthService, private readonly trialService: TrialService, private readonly activatedRoute: ActivatedRoute, private readonly alertService: AlertService,
    private readonly router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.trialId = params.id;

      this.loadTrial();
      this.loadPatients(1);
    });
  }

  onPatientClick(patientId: string) {
    void this.router.navigate(['/patient', patientId]);
  }

  onShowAssignPatientModal(): void {
    this.assignPatientModal.show(this.trialId, this.trialCode);
  }

  loadTrial(): void {
    this.trialService.retrieveTrial(this.trialId).subscribe({
      next: trial => {
        this.trialCode = trial.code;
        this.addPatientAvailable = trial.state === TrialStateString.Values[TrialState.Live];
      }
    });
  }

  loadPatients(pageNo: number) {
    this.trialService.retrievePatients(this.trialId, pageNo).subscribe({
      next: trialPatientList => {
        this.results = trialPatientList;
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem loading patients!');
      }
    });
  }

  onPatientAssigned(): void {
    this.loadPatients(1);
  }
}
