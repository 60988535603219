import { VisitAttendance } from './../../../../core/models/expense.model';
import { Permissions } from './../../../../core/constants/permissions';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Lightbox } from "ngx-lightbox";
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";
import {
  ApproveExpenseClaimResult,
  CaxtonIncompatibleData,
  ExpenseCategoryType,
  ExpenseClaimPaidBy
} from "../../../../core/models/expense.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { PatientService } from "../../../../core/services/patient.service";
import { AuthService } from "../../../../core/services/auth.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { ExpenseService } from "../../../../core/services/expense.service";
import { PatientExpensesList } from "../../../../core/models/patient-expenses-list.model";
import { TemplateService } from "../../../../core/services/template.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { StringHelper } from "../../../../core/helpers/string-helper";
import { EditExpenseModalComponent } from '../../../expense/edit-expense-modal/edit-expense-modal.component';
import { CreateExpenseModalComponent } from '../../../expense/create-expense-modal/create-expense-modal.component';
import { Currencies } from 'app/core/constants/currency';
import {
  CloseInvestigationModalComponent
} from 'app/features/expense/close-investigation-modal/close-investigation-modal.component';
import {
  StartInvestigationModalComponent
} from 'app/features/expense/start-investigation-modal/start-investigation-modal.component';
import { OverBudgetRequestDetailsStatus } from 'app/core/models/project-management.model';

@Component({
  selector: 'app-patient-detail-expenses',
  templateUrl: './patient-detail-expenses.component.html',
  styleUrls: ['./patient-detail-expenses.component.scss']
})
export class PatientDetailExpensesComponent implements OnInit {
  @ViewChild('createExpenseModal') createExpenseModal: CreateExpenseModalComponent;
  @ViewChild('editExpenseModal') editExpenseModal: EditExpenseModalComponent;
  @ViewChild('rejectExpenseModal') rejectExpenseModal: ModalComponent;
  @ViewChild('approveDistanceModal') approveDistanceModal: ModalComponent;
  @ViewChild('currencySelect') currencySelect: DropdownInputComponent;
  @ViewChild('startInvestigationModal') startInvestigationModal: StartInvestigationModalComponent;
  @ViewChild('closeInvestigationModal') closeInvestigationModal: CloseInvestigationModalComponent;
  @ViewChild('confirmExpenseApprove') confirmExpenseApproveModal: ModalComponent;
  @Input() patientTrialId: string;
  @Input() patientId: string;
  @Input() trialAllowsExpenses = false;

  rejectForm: UntypedFormGroup;
  approveDistanceForm: UntypedFormGroup;
  results: PatientExpensesList = new PatientExpensesList();
  approveFormIsProcessing = false;
  rejectFormIsProcessing = false;
  stringHelper = StringHelper;
  VisitAttendance = VisitAttendance;

  investigationFormProcessing = false;
  investigationForm: UntypedFormGroup;

  currencyOptions: { value: string, text: string }[] = [];
  ExpenseClaimPaidBy = ExpenseClaimPaidBy;
  caxtonIncompatibleData: CaxtonIncompatibleData;
  confirmExpenseClaimApprovalProcessing = false;
  Permissions = Permissions;
  ExpenseCategoryType = ExpenseCategoryType;
  OverBudgetRequestDetailsStatus = OverBudgetRequestDetailsStatus;
  page: number;

  constructor(public authService: AuthService, private lightbox: Lightbox, private templateService: TemplateService, private alertService: AlertService, private activatedRoute: ActivatedRoute, private router: Router, private patientService: PatientService, private expenseService: ExpenseService) { }

  ngOnInit() {
    this.loadPatientExpenses(1);

    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    this.rejectForm = new UntypedFormGroup({
      expenseId: new UntypedFormControl('', Validators.required),
      reason: new UntypedFormControl('', Validators.required)
    });

    this.approveDistanceForm = new UntypedFormGroup({
      expenseId: new UntypedFormControl('', Validators.required),
      currency: new UntypedFormControl('', Validators.required),
      amount: new UntypedFormControl('', Validators.required)
    });

    this.investigationForm = new UntypedFormGroup({
      id: new UntypedFormControl('', Validators.required),
      reasonForInvestigation: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
    });
  }

  viewReceipt(imageUrl: string) {
    const album: { src: string, caption: string, thumb: string, downloadUrl: string }[] = [];
    album.push({ src: imageUrl, caption: '', thumb: imageUrl, downloadUrl: '' });

    this.lightbox.open(album, 0);
  }

  onApproveDistanceExpenseClaim() {
    this.approveFormIsProcessing = true;
    let expenseId = this.approveDistanceForm.get('expenseId').value;
    let currency = this.currencySelect.selectedValue;
    let amount = this.approveDistanceForm.get('amount').value;

    if (this.approveDistanceForm.valid) {
      this.expenseService.approveExpenseClaim(expenseId, this.caxtonIncompatibleData?.caxtonIncompatibleReason, currency, amount).subscribe({
        next: result => {
          this.approveFormIsProcessing = false;
          this.confirmExpenseClaimApprovalProcessing = false;
          this.approveDistanceModal.hide();

          this.handleApproveExpenseResponse(result, expenseId, ExpenseCategoryType.Distance);
        },
        error: error => {
          this.alertService.showWarningAlert('Unable to approve expense claim!');
          this.approveFormIsProcessing = false;
          this.confirmExpenseClaimApprovalProcessing = false;
        }
      })
    }
  }

  onApproveExpenseClaim(expenseId: string, type: ExpenseCategoryType) {
    if (type === ExpenseCategoryType.Distance) {
      this.currencySelect.reset();

      let expense = this.results.results.find(e => e.id == expenseId);

      this.approveDistanceForm.patchValue({
        expenseId: expenseId,
        currency: expense?.currency,
        amount: expense?.amount
      });

      if (expense) {
        this.currencySelect.setValue(expense.currency);
      }

      this.approveDistanceModal.show();
    } else {
      this.expenseService.approveExpenseClaim(expenseId, null, null, null).subscribe({
        next: result => {
          this.handleApproveExpenseResponse(result, expenseId, type);
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to approve expense claim!');
        }
      })
    }
  }

  /**
   * Called when the user selects to edit a patient expense
   * @param expenseId
   */
  onEditExpense(expenseId: string) {
    this.editExpenseModal.show(expenseId);
  }

  /**
   * Called when an expense claim has been updated, will refresh the current list of expenses
   */
  reloadExpenses() {
    this.loadPatientExpenses(this.results.currentPage);
  }

  confirmExpenseClaimApproval() {
    this.confirmExpenseClaimApprovalProcessing = true;

    if (this.caxtonIncompatibleData.type === ExpenseCategoryType.Distance) {
      this.onApproveDistanceExpenseClaim();
    } else {
      this.expenseService.approveExpenseClaim(this.caxtonIncompatibleData.expenseClaimId, this.caxtonIncompatibleData.caxtonIncompatibleReason, null, null).subscribe({
        next: result => {
          this.handleApproveExpenseResponse(result, this.caxtonIncompatibleData.expenseClaimId, this.caxtonIncompatibleData.type);
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert('Unable to approve expense claim!');
        },
        complete: () => {
          this.confirmExpenseClaimApprovalProcessing = false;
        }
      })
    }
  }

  handleApproveExpenseResponse(result: ApproveExpenseClaimResult, expenseId: string, type: ExpenseCategoryType) {
    if (!result.approved) {
      this.caxtonIncompatibleData = {
        caxtonIncompatibleReason: result.caxtonIncompatibleMessage,
        expenseClaimId: expenseId,
        type: type
      }

      this.confirmExpenseApproveModal.show();
      return;
    }

    this.caxtonIncompatibleData = null;
    this.alertService.showSuccessAlert('Expense Claim Successfully Approved.');
    this.loadPatientExpenses(this.results.currentPage);
    this.confirmExpenseApproveModal.hide();
  }

  loadPatientExpenses(page: number) {
    this.page = page;
    this.patientService.retrievePatientExpensesForPatientTrial(this.patientTrialId, page).subscribe({
      next: patientList => {
        console.log(patientList);
        this.results = patientList;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to load patient expenses!');
      }
    });
  }

  onCurrencySelectChanged(value: string) {
    this.approveDistanceForm.patchValue({ currency: value });
  }

  onConfirmRejectExpense() {
    this.rejectFormIsProcessing = true;
    this.expenseService.rejectExpenseClaim(this.rejectForm.get('expenseId').value, this.rejectForm.get('reason').value).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Expense Claim Successfully Rejected.');
        this.rejectExpenseModal.hide();
        this.loadPatientExpenses(this.results.currentPage);
        this.rejectFormIsProcessing = false;
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to reject expense claim!');
        this.rejectExpenseModal.hide();
      }
    });
  }

  onRejectModal(expenseId: string) {
    this.rejectForm.patchValue({
      expenseId: expenseId,
      reason: ''
    });
    this.rejectExpenseModal.show();
  }

  onMarkAsPaid(expenseId: string) {
    this.expenseService.markExpenseClaimAsPaid(expenseId).subscribe({
      next: () => {
        this.loadPatientExpenses(this.results.currentPage);
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Sorry, there was a problem!');
      }
    });
  }

  onCloseInvestigation(claimId: string) {
    this.closeInvestigationModal.expenseClaimId = claimId;
    this.closeInvestigationModal.modal.show();
  }

  onStartInvestigation(claimId: string) {
    this.startInvestigationModal.expenseClaimId = claimId;
    this.startInvestigationModal.modal.show();
  }

  getBadgeLabel(expenseState: string) {
    switch (expenseState) {
      case 'CaxtonApproved':
        return 'Caxton Approved';
      case 'Processing':
        return 'Payment Processing';
      case 'CaxtonFailed':
        return 'Caxton Failed';
      default:
        return expenseState;
    }
  }

  closeConfirmExpenseApproveModal() {
    this.caxtonIncompatibleData = null;
    this.confirmExpenseApproveModal.hide();
  }

  public uploadIsPdf(uploadUrl: string) {
    let parts = uploadUrl.toLowerCase().split('?');
    return parts[0].endsWith('.pdf');
  }
}
