import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeLog } from "./change-log.model";
import { Permissions } from "../../core/constants/permissions";
import { ButtonStyle } from "../button/button-style.enum";
import { ButtonMargin } from "../button/button-margin.enum";
import { ButtonSize } from "../button/button-size.enum";

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html'
})
export class ChangeLogComponent {
  @Output() exportClicked = new EventEmitter<void>();
  @Output() pageChanged = new EventEmitter<number>();

  @Input() results: ChangeLog
  @Input() hideExportBtn = false;
  @Input() dataLoaded: boolean = false;

  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  protected readonly Permissions = Permissions;
  protected readonly ButtonStyle = ButtonStyle;
  protected readonly ButtonMargin = ButtonMargin;
  protected readonly ButtonSize = ButtonSize;

  onChangePage(page: number) {
    this.pageChanged.emit(page);
  }
}
