import { AutoApprovalResult } from "app/core/models/expense.model";
import { VisitScheduleViewModel } from "./trial-visit-schedule.model";
import { ExpensePolicyTranslation } from "app/core/models/expense-policy-translation";
import { SiteListItem } from "app/core/models/site-list.model";

export class TrialPolicy {
  public id: string;
  public isDefault: boolean = false;
  public countryCode: string;
  public sites: SiteListItem[];
  public siteCount: number;
  public allowExpenseRequests: boolean;
  public allowTravelRequests: boolean;
  public internalPolicyNotes: string;
  public policyCurrency: string;

  public carHireIncluded: boolean;
  public carHirePatientBudget?: number;
  public carHireCaregiverBudget?: number;
  public carHireAdditionalInformation: string;

  public accommodationIncluded: boolean;
  public accommodationPatientBudget?: number;
  public accommodationCaregiverBudget?: number;
  public accommodationBudgetType: AccommodationBudgetType;
  public accommodationAdditionalInformation: string;

  public airTravelIncluded: boolean;
  public airTravelPatientBudget?: number;
  public airTravelCaregiverBudget?: number;
  public airTravelReturnTripIncluded: boolean;
  public airTravelAdditionalInformation: string;

  public railTravelIncluded: boolean;
  public railTravelPatientBudget?: number;
  public railTravelCaregiverBudget?: number;
  public railTravelReturnTripIncluded: boolean;
  public railTravelAdditionalInformation: string;

  public homeToAirportTravelIncluded: boolean;
  public homeToAirportTravelPatientBudget?: number;
  public homeToAirportTravelCaregiverBudget?: number;
  public homeToAirportTravelReturnTripIncluded: boolean;
  public homeToAirportTravelAdditionalInformation: string;

  public airportToHotelTravelIncluded: boolean;
  public airportToHotelTravelPatientBudget?: number;
  public airportToHotelTravelCaregiverBudget?: number;
  public airportToHotelTravelReturnTripIncluded: boolean;
  public airportToHotelTravelAdditionalInformation: string;

  public hotelToSiteTravelIncluded: boolean;
  public hotelToSiteTravelPatientBudget?: number;
  public hotelToSiteTravelCaregiverBudget?: number;
  public hotelToSiteTravelReturnTripIncluded: boolean;
  public hotelToSiteTravelAdditionalInformation: string;

  public homeToSiteTravelIncluded: boolean;
  public homeToSiteTravelPatientBudget?: number;
  public homeToSiteTravelCaregiverBudget?: number;
  public homeToSiteTravelReturnTripIncluded: boolean;
  public homeToSiteTravelAdditionalInformation: string;

  public airportToSiteTravelIncluded: boolean;
  public airportToSiteTravelPatientBudget?: number;
  public airportToSiteTravelCaregiverBudget?: number;
  public airportToSiteTravelReturnTripIncluded: boolean;
  public airportToSiteTravelAdditionalInformation: string;

  public expenseCategories: CategoryListItemViewModel[];
  public cmsOnlyCategories: CategoryListItemViewModel[];
  public allSites: boolean;
  public translations: ExpensePolicyTranslation[];
}

export interface KeyVisitPreloadPaymentsRequest {
  preloadBeforeVisitDays?: number;
  preloadPaymentCurrency: string;
  preloadType: PreloadPaymentType;
  keyVisits: KeyVisitRequest[];
}

export interface KeyVisitAutoPaymentsRequest {
  autoPaymentType: AutoPaymentType;
  assumeVisitAttendance: boolean;
  assumeVisitAttendanceAfterDays?: number;
  autoPaymentCurrency: string;
  keyVisits: KeyVisitRequest[];
}

export interface KeyVisitRequest {
  visitTitle: string;
  keyVisit: boolean;
  amount?: number;
  caregiverAmount?: number;
}

export interface KeyVisitAutoPaymentsViewModel {
  autoPaymentType: AutoPaymentType;
  assumeVisitAttendance: boolean;
  assumeVisitAttendanceAfterDays?: number;
  autoPaymentCurrency: string;
  visits: VisitScheduleViewModel[];
}

export interface KeyVisitPreloadPaymentsViewModel {
  preloadBeforeVisitDays?: number;
  preloadPaymentCurrency: string;
  preloadType: PreloadPaymentType;
  visits: VisitScheduleViewModel[];
}

export interface CategoryListItemViewModel {
  id: string;
  name: string;
}

export enum AutoPaymentType {
  AutoStipend = 0,
  AutoFixedFee = 1
}

export enum PreloadPaymentType {
  Visit,
  Day
}

export class AutoPaymentTypeString {
  public static Values = {
    0: "Auto Stipend",
    1: "Auto Fixed Fee"
  }
}

export enum ExpenseAutoApprovalRuleType {
  Visit = 0,
  Day = 1
}

export class ExpenseAutoApprovalRuleTypeString {
  static Values: { [key in ExpenseAutoApprovalRuleType]: string } = {
    [ExpenseAutoApprovalRuleType.Visit]: "Visit",
    [ExpenseAutoApprovalRuleType.Day]: "Day"
  };
}

export interface CreateTrialPolicyExpenseRuleRequest {
  policyId: string;
  name: string;
  note: string;
  receiptRequired: boolean;
  receiptRequiredValue: number;
  allCategories: boolean;
  amount: number;
  caregiverAmount?: number;
  currency: string;
  type: ExpenseAutoApprovalRuleType;
  categories: string[];
  overBudgetRequestInformation: string;
}

export interface UpdateTrialPolicyExpenseRuleRequest {
  id: string;
  name: string;
  note: string;
  receiptRequired: boolean;
  receiptRequiredValue: number;
  allCategories: boolean;
  amount: number;
  caregiverAmount?: number;
  currency: string;
  type: ExpenseAutoApprovalRuleType;
  categories: string[];
  overBudgetRequestInformation: string;
}

export interface ExpenseRulesViewModel {
  defaultPolicyCurrency: string;
  rules: ExpenseRuleListItemViewModel[];
}

export interface ExpenseRuleListItemViewModel {
  id: string;
  ruleName: string;
  categories: CategoryListItemViewModel[];
  allCategories: boolean;
  amount: number;
  caregiverAmount?: number;
  type: ExpenseAutoApprovalRuleType;
  receiptRequired: boolean;
  receiptRequiredValue: number;
  note: string;
  currency: string;
  result: AutoApprovalResult;
  reason: string;
  overBudgetRequestInformation: string;
}

export interface SetDefaultExpenseCurrencyRequest {
  policyId: string;
  currency: string;
}

export interface SetDefaultPolicyDistanceUnitRequest {
  policyId: string;
  distanceUnit: DistanceUnit;
}

export interface CreateTrialPolicyMileageRateRequest {
  trialPolicyId: string;
  currency: string;
  note: string;
  engineSize: string;
  distanceUnit: DistanceUnit;
  distanceLimits: MileageRateDistanceLimitRequest[];
}

export interface UpdateTrialPolicyMileageRateRequest {
  id: string;
  currency: string;
  note: string;
  engineSize: string;
  distanceUnit: DistanceUnit;
  distanceLimits: MileageRateDistanceLimitRequest[];
}

export interface MileageRateDistanceLimitRequest {
  id: string;
  distanceFrom: number;
  distanceTo?: number;
  amountPerDistance: number;
  extraAmount: number;
}

export enum DistanceUnit {
  Km,
  Mile,
}

export class DistanceUnitString {
  static Values: { [key in DistanceUnit]: string } = {
    [DistanceUnit.Km]: "Km",
    [DistanceUnit.Mile]: "Miles",
  };
}

export interface MileageRateDistanceLimitViewModel {
  id: string;
  distanceFrom: number;
  distanceTo?: number;
  amountPerDistance: number;
  extraAmount: number;
}

export interface MileageRateListItemViewModel {
  id: string;
  engineSize: string;
  distanceUnit: DistanceUnit;
  currency: string;
  note: string;
  distanceLimits: MileageRateDistanceLimitViewModel[];
}

export interface MileageRatesViewModel {
  defaultDistanceUnit?: DistanceUnit;
  defaultCurrency: string;
  rates: MileageRateListItemViewModel[];
}

export interface CreateTrialPolicyRequest {
  trialId: string;
  country: string;
  sites: string[];
  allowTravelRequests: boolean;
  allowExpenseRequests: boolean;
  translations: TrialPolicyTranslationRequest[];
  categories: string[];
  cmsOnlyCategories: string[];
  internalPolicyNotes: string;
  policyCurrency: string;
  airTravelIncluded: boolean;
  airTravelPatientBudget?: number;
  airTravelCaregiverBudget?: number;
  airTravelReturnTripIncluded: boolean;
  railTravelIncluded: boolean;
  railTravelPatientBudget?: number;
  railTravelCaregiverBudget?: number;
  railTravelReturnTripIncluded: boolean;
  homeToAirportTravelIncluded: boolean;
  homeToAirportTravelPatientBudget?: number;
  homeToAirportTravelCaregiverBudget?: number;
  homeToAirportTravelReturnTripIncluded: boolean;
  airportToHotelTravelIncluded: boolean;
  airportToHotelTravelPatientBudget?: number;
  airportToHotelTravelCaregiverBudget?: number;
  airportToHotelTravelReturnTripIncluded: boolean;
  hotelToSiteTravelIncluded: boolean;
  hotelToSiteTravelPatientBudget?: number;
  hotelToSiteTravelCaregiverBudget?: number;
  hotelToSiteTravelReturnTripIncluded: boolean;
  homeToSiteTravelIncluded: boolean;
  homeToSiteTravelPatientBudget?: number;
  homeToSiteTravelCaregiverBudget?: number;
  homeToSiteTravelReturnTripIncluded: boolean;
  carHireIncluded: boolean;
  carHirePatientBudget?: number;
  carHireCaregiverBudget?: number;
  accommodationIncluded: boolean;
  accommodationPatientBudget?: number;
  accommodationCaregiverBudget?: number;
  accommodationBudgetType?: AccommodationBudgetType;
}

export interface UpdateTrialPolicyRequest extends CreateTrialPolicyRequest {
  policyId: string;
}

export interface TrialPolicyTranslationRequest {
  translationId: string;
  expensePolicy: string;
  culture: string;
}

export enum AccommodationBudgetType {
  Visit,
  Night
}
