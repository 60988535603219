import { DateHelper } from "../../../../../core/helpers/date-helper";
import { CustomData } from "../../../../../core/models/custom-data.model";
import { PatientTripMuvRide } from "../../../../../core/models/patient-trip-muv-ride.model";
import { Address } from "../../../../../shared/input-address/address.model";
import { FileAttachment } from "../../../../../core/models/file-attachment.model";
import { GroundTransportTravelType, PatientTripDirection } from "app/core/models/patient-trip.model";
import { OverBudgetRequestDetailsStatus } from "app/core/models/project-management.model";

export class PatientTrip {
  public id: string;
  public departureDate: string;
  public departureLocation: string;
  public departureTimeMinutes: number;
  public arrivalDate: string;
  public arrivalLocation: string;
  public arrivalTimeMinutes: string;
  public accommodationLocation: string;
  public notes: string;
  public internalNotes: string;
  public type: string;
  public ticketId: string;
  public ticketUrl: string;
  public ticketOriginalFilename: string;
  public addedBy: string;
  public addedByDate: Date;
  public rideHealthStatus: string;
  public rideHealtStatusUpdatedAt: Date;
  public rideHealthState: string;
  public customDataFields: CustomData[] = [];
  public carrier: string;
  public bookingProvider: string;
  public paymentCardUsed: string;
  public reference: string;
  public ticketOrConfirmationNo: string;
  public bookingStatus: string;
  public invoiceNo: string;
  public visitId: string;
  public trialMuvEnabled: boolean;
  public rideHealthEnabled: boolean;
  public muvRides: PatientTripMuvRide[] = [];
  public caregiverTravelling: string;
  public patientHomeAddress: Address;
  public patientSiteName: string;
  public patientSiteAddress: Address;
  public patientTelephone: string;
  public attachments: FileAttachment[] = [];
  public visitTime: string;
  public quotedAmount: number;
  public quotedCurrency: string;
  public pdfGenerating: boolean;
  public trialBaseCurrency: string;
  public quotedAmountBC: number;
  public patientTrialId: string;
  public patientId: string;
  public groundTransportTravelType: GroundTransportTravelType;
  public overBudget: boolean;
  public overBudgetRequestRaised: boolean;
  public overBudgetRequestStatus?: OverBudgetRequestDetailsStatus;
  public overBudgetLabel: string;
  public updateSucceeded?: boolean;
  public direction?: PatientTripDirection;

  public static fromObject(obj: any) {
    let trip = new PatientTrip();

    trip.id = obj.id;
    trip.departureDate = obj.departureDate;
    trip.departureTimeMinutes = obj.departureTimeMinutes;
    trip.arrivalDate = obj.arrivalDate;
    trip.arrivalLocation = obj.arrivalLocation;
    trip.arrivalTimeMinutes = obj.arrivalTimeMinutes;
    trip.departureLocation = obj.departureLocation;
    trip.accommodationLocation = obj.accommodationLocation;
    trip.notes = obj.notes;
    trip.internalNotes = obj.internalNotes;
    trip.type = obj.type;
    trip.ticketId = obj.ticketId;
    trip.ticketOriginalFilename = obj.ticketOriginalFilename;
    trip.ticketUrl = obj.ticketUrl;
    trip.addedBy = obj.addedBy;
    trip.addedByDate = obj.addedByDate;
    trip.rideHealthStatus = obj.rideHealthStatus;
    trip.rideHealtStatusUpdatedAt = DateHelper.dateUTCToLocal(obj.rideHealtStatusUpdatedAt);
    trip.carrier = obj.carrier;
    trip.bookingProvider = obj.bookingProvider;
    trip.paymentCardUsed = obj.paymentCardUsed;
    trip.reference = obj.reference;
    trip.ticketOrConfirmationNo = obj.ticketOrConfirmationNo;
    trip.bookingStatus = obj.bookingStatus;
    trip.invoiceNo = obj.invoiceNo;
    trip.visitId = obj.visitId;
    trip.trialMuvEnabled = obj.trialMuvEnabled;
    trip.rideHealthEnabled = obj.rideHealthEnabled;
    trip.caregiverTravelling = obj.caregiverTravelling;
    trip.patientTelephone = obj.patientTelephone;
    trip.attachments = obj.attachments;
    trip.visitTime = obj.visitTime;
    trip.quotedAmount = obj.quotedAmount;
    trip.quotedCurrency = obj.quotedCurrency;
    trip.pdfGenerating = obj.pdfGenerating;
    trip.trialBaseCurrency = obj.trialBaseCurrency;
    trip.quotedAmountBC = obj.quotedAmountBC;
    trip.patientTrialId = obj.patientTrialId;
    trip.patientId = obj.patientId;
    trip.groundTransportTravelType = obj.groundTransportTravelType;
    trip.direction = obj.direction;
    trip.overBudget = obj.overBudget;
    trip.overBudgetRequestStatus = obj.overBudgetRequestStatus;
    trip.overBudgetRequestRaised = obj.overBudgetRequestRaised;
    trip.overBudgetLabel = obj.overBudgetLabel;
    trip.updateSucceeded = obj.updateSucceeded;
    trip.patientSiteName = obj.patientSiteName;

    if (obj.hasOwnProperty('customFields') && obj.customFields !== null) {
      obj.customFields.forEach(customField => {
        trip.customDataFields.push(CustomData.map(customField));
      });
    }

    if (obj.hasOwnProperty('muvRides') && obj.muvRides !== null) {
      trip.muvRides = obj.muvRides.map(r => new PatientTripMuvRide(r));
    }

    if (obj.patientHomeAddress)
      trip.patientHomeAddress = new Address(obj.patientHomeAddress);

    if (obj.patientSiteAddress)
      trip.patientSiteAddress = new Address(obj.patientSiteAddress);

    switch (trip.rideHealthStatus) {
      case 'Arranged':
        trip.rideHealthState = 'Pending';
        break;
      case 'Scheduling':
        trip.rideHealthState = 'Pending';
        break;
      case 'Confirmed':
        trip.rideHealthState = 'Active';
        break;
      case 'Unavailable':
        trip.rideHealthState = 'Unavailable';
        break;
      case 'Rejected':
        trip.rideHealthState = 'Rejected';
        break;
      case 'Cancelled':
        trip.rideHealthState = 'Cancelled';
        break;
      case 'Failed':
        trip.rideHealthState = 'Failed';
        break;
    }

    return trip;
  }
}

export class PatientVisit {
  public id: string;
  public shortId: string;
  public title: string;
  public date: Date;
  public timeMinutes: number;
  public endDate: Date;
  public endTimeMinutes: number;
  public siteName: string;
  public siteAddress: string;
  public siteCountry: string;
  public siteId: string;
  public department: string;
  public description: string;
  public notes: string;
  public type: string;
  public typeDescription: string;
  public trialId: string;
  public trips: PatientTrip[] = [];
  public hasExpenses = false;
  public travelRequested: string;
  public caregiverTravelling: string;
  public attendance: string;
  public designatedContacts: string[] = [];
  public bookingStatus: string;
  public publishedStatus: string;
  public templateId: string;
  public templatePaymentCurrency: string;
  public trialVisitTemplates: VisitTemplateResponse[] = []
  public muvEnabled: boolean;
  public travelDays: number;
  public takeMedicationAtVisit: boolean;
  public urineSample3DaysBefore: boolean;
  public urineSample10DaysBefore: boolean;

  public static fromObject(obj: any) {
    let visit = new PatientVisit();
    visit.shortId = obj.hasOwnProperty('shortId') ? obj.shortId : '';
    visit.date = obj.date;
    visit.endDate = obj.endDate;
    visit.timeMinutes = obj.timeMinutes;
    visit.endTimeMinutes = obj.endTimeMinutes;
    visit.id = obj.id;
    visit.title = obj.title;
    visit.department = obj.department;
    visit.siteName = obj.date;
    visit.siteName = obj.siteName;
    visit.siteCountry = obj.siteCountry;
    visit.siteId = obj.siteId;
    visit.siteAddress = obj.siteAddress;
    visit.description = obj.description;
    visit.notes = obj.notes;
    visit.type = obj.type;
    visit.trialId = obj.trialId;
    visit.hasExpenses = obj.hasExpenses;
    visit.travelRequested = obj.travelRequested;
    visit.caregiverTravelling = obj.careGiverTravelling;
    visit.attendance = obj.attendance;
    visit.templateId = obj.templateId;
    visit.templatePaymentCurrency = obj.templatePaymentCurrency;
    visit.muvEnabled = obj.trialMuvEnabled;
    visit.travelDays = obj.travelDays;

    visit.designatedContacts = obj.hasOwnProperty('designatedContacts') ? obj.designatedContacts : [];
    visit.bookingStatus = obj.hasOwnProperty('bookingStatus') ? obj.bookingStatus : '';
    visit.publishedStatus = obj.hasOwnProperty('publishedStatus') ? obj.publishedStatus : '';
    visit.trialVisitTemplates = obj.trialVisitTemplates;
    visit.takeMedicationAtVisit = obj.takeMedicationAtVisit;
    visit.urineSample3DaysBefore = obj.urineSample3DaysBefore;
    visit.urineSample10DaysBefore = obj.urineSample10DaysBefore;

    for (const t of obj.trips) {
      visit.trips.push(PatientTrip.fromObject(t));
    }

    switch (obj.type) {
      case 'Home':
        visit.typeDescription = 'Home Visit';
        break;
      case 'OnSite':
        visit.typeDescription = 'On-Site';
        break;
      case 'Virtual':
        visit.typeDescription = 'Virtual';
        break;
      case 'Telephone':
        visit.typeDescription = 'Telephone';
        break;
    }

    return visit;
  }
}

export class VisitTemplateResponse {
  id: string;
  templateText: string;
}
