import { Permissions } from './../../../core/constants/permissions';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PatientApprovedComponent } from './patient-approved/patient-approved.component';
import { PatientPendingComponent } from './patient-pending/patient-pending.component';
import { PatientRejectedComponent } from './patient-rejected/patient-rejected.component';
import { PatientDeletionRequestComponent } from './patient-deletion-request/patient-deletion-request.component';
import { Observable } from 'rxjs';
import { SiteAutocompleteComponent } from 'app/shared/site-autocomplete/site-autocomplete.component';
import { SiteAutocomplete } from 'app/shared/site-autocomplete/site-autocomplete.model';
import { PatientFeedbackComponent } from './patient-feedback/patient-feedback.component';
import { Store } from "@ngrx/store";
import { Location } from '@angular/common';
import { SelectOption } from "../../../core/models/select-option.model";
import { Countries } from "../../../core/constants/countries";
import { PatientService } from "../../../core/services/patient.service";
import { NavigationState } from "../../../core/state/navigation.state";
import { LogHelper } from "../../../core/helpers/log.helper";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { PatientListItem } from "../../../core/models/patient-list.model";
import { UpdateLastRouteAction } from "../../../core/actions/navigation.action";
import { TemplateService } from "../../../core/services/template.service";
import { ModalComponent } from "../../../shared/modal/modal.component";
import {
  AutosuggestDropdownInputComponent
} from "../../../shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { PatientRegistrationsComponent } from './patient-registrations/patient-registrations.component';
import { PatientAddModalComponent } from "../../../shared/patient-add-modal/patient-add-modal.component";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { PatientPreApprovalCheck } from "../../../core/models/patient-pre-approval-check.model";

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit, AfterViewInit {
  // Export
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('exportPatientsModal') exportPatientsModal: ModalComponent;
  @ViewChild('mergePatientModal') mergePatientModal: ModalComponent;

  @ViewChild('countryDropdown') countryDropdown: AutosuggestDropdownInputComponent;
  @ViewChild('approvePatientModal') approvePatientModal: ModalComponent;
  @ViewChild('approvedTab') approvedTab: PatientApprovedComponent;
  @ViewChild('pendingTab') pendingTab: PatientPendingComponent;
  @ViewChild('rejectedTab') rejectedTab: PatientRejectedComponent;
  @ViewChild('deletionRequestTab') deletionRequestTab: PatientDeletionRequestComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('appFeedbackTab') appFeedbackTab: PatientFeedbackComponent;
  @ViewChild('appPatientRegistrationsTab') patientRegistrationsTab: PatientRegistrationsComponent;
  @ViewChild('addPatientModal') addPatientModal: PatientAddModalComponent;
  @ViewChild('orderBySelect') orderBySelect: DropdownInputComponent;

  exportIsProcessing = false;
  exportForm: UntypedFormGroup;

  searchForm: UntypedFormGroup;
  keywords: string;
  orderBy: number = 0; // Trial
  selectedTab = 'approved';
  pendingPatientsCount = 0;
  approvePatientForm: UntypedFormGroup;
  approvingPatient: PatientListItem;
  isApprovalFormProcessing = false;

  selectedCountry = '';
  countryOptions: SelectOption[] = [];
  filteredCountries: Observable<SelectOption[]>;
  Permissions = Permissions;
  orderByOptions: SelectOption[] = [];

  patientApprovalCheck: PatientPreApprovalCheck;

  constructor(public authService: AuthService, private readonly templateService: TemplateService,
    private readonly patientService: PatientService, private readonly alertService: AlertService, private location: Location, private store: Store<NavigationState>) {
    for (const country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    // Export sites form
    this.exportForm = new UntypedFormGroup({
      anonymisePatientData: new UntypedFormControl(false),
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl()
    });

    // Search form
    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl(''),
      country: new UntypedFormControl('')
    });

    // Approve patient form (in modal)
    this.approvePatientForm = new UntypedFormGroup({
      patientCode: new UntypedFormControl('', Validators.required),
      siteId: new UntypedFormControl('', Validators.required),
      patientConsent: new UntypedFormControl(false, Validators.requiredTrue),
      showPatientConsentCheckbox: new UntypedFormControl(true),
      patientConsentDate: new UntypedFormControl(''),
      patientConsentType: new UntypedFormControl('')
    });

    this.searchForm.get('country').valueChanges.subscribe(country => {
      this.selectedCountry = country;
      this.patientService.filterCountry = country;
      this.reloadTabs();
    });

    // Apply filter from service
    setTimeout(() => {
      if (this.patientService.filterKeywords !== null) {
        this.searchForm.patchValue({ keywords: this.patientService.filterKeywords });
        this.keywords = this.patientService.filterKeywords;
      }

      if (this.patientService.filterCountry !== null) {
        this.countryDropdown.setSelectedOption(this.patientService.filterCountry);
        this.selectedCountry = this.patientService.filterCountry;
        this.searchForm.patchValue({ country: this.patientService.filterCountry });
      }

      this.reloadTabs();
    }, 500);

    this.store.dispatch(new UpdateLastRouteAction(this.location.path()));

  }

  ngAfterViewInit(): void {
    this.siteAutocomplete.minChars = 0;

    this.initOrderBySelection();
    this.orderBySelect.setValue("0");

    this.orderBySelect.selectValueChanged.subscribe((value: string) => {
      this.orderBy = +value;
      this.reloadTabs();
    });
  }

  initOrderBySelection() {
    this.orderByOptions.push({ value: "0", text: 'Trial' });
    this.orderByOptions.push({ value: "1", text: 'Patient' });
    this.orderByOptions.push({ value: "2", text: 'Newest' });
    this.orderByOptions.push({ value: "3", text: 'Oldest' });
  }

  onSelectTab(selectedTab: string) {
    this.selectedTab = selectedTab;

    switch (selectedTab) {
      case 'approved':
        this.approvedTab.loadPatients(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        break;
      case 'pending':
        this.pendingTab.loadPatients(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        break;
      case 'rejected':
        this.rejectedTab.loadPatients(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        this.rejectedTab.loadPatients(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        break;
      case 'deletion-requests':
        this.deletionRequestTab.loadPatients(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        break;
      case 'app-feedback':
        this.appFeedbackTab.loadFeedback(1, this.searchForm.get('keywords').value, this.selectedCountry, this.orderBy);
        break;
      case 'patient-registrations':
        this.patientRegistrationsTab.loadPatientRegistrations(1, this.searchForm.get('keywords').value, this.orderBy);
        break;
    }
  }
  onPendingPatientCountChanged(patientCount: number) {
    this.pendingPatientsCount = patientCount;
  }

  /**
   * Called when the value of the search input changes
   */
  onSearchInputChanged(keywords: string) {
    this.keywords = keywords === '' ? null : keywords;
    this.patientService.filterKeywords = this.keywords;
    this.searchForm.patchValue({ keywords: this.keywords });

    this.reloadTabs();
  }

  /**
   * Called when the user has created a new patient via the add patient modal.
   * Forces an update of the patient lists to reflect the new patient
   */
  onPatientCreated(): void {
    // Reselect the current tab to force reload of patients
    this.onSelectTab(this.selectedTab);
  }

  reloadTabs() {
    // Pass updated keyword to all tabs
    if (this.approvedTab !== undefined) this.approvedTab.updateFilter(this.keywords, this.selectedCountry, this.orderBy);
    if (this.pendingTab !== undefined) this.pendingTab.updateFilter(this.keywords, this.selectedCountry, this.orderBy);
    if (this.rejectedTab !== undefined) this.rejectedTab.updateFilter(this.keywords, this.selectedCountry, this.orderBy);
    if (this.deletionRequestTab !== undefined) this.deletionRequestTab.updateFilter(this.keywords, this.selectedCountry, this.orderBy);
    if (this.appFeedbackTab !== undefined) this.appFeedbackTab.updateFilter(this.keywords, this.selectedCountry, this.orderBy);
    if (this.patientRegistrationsTab !== undefined) this.patientRegistrationsTab.updateFilter(this.keywords, this.orderBy);
  }

  /**
   * Merges and approves a patient - generally called after the pre-approved patient has been completed and the user has chosen to merge the patient
   * @param patientTrialId
   * @param patientCode
   * @param siteId
   * @param patientConsent
   */
  mergeAndApprovePatient(patientTrialId: string, patientCode: string, siteId: string, patientConsent: boolean) {
    this.patientService.mergeAndApprovePatient(
      patientTrialId,
      patientCode,
      siteId,
      patientConsent
    ).subscribe({
      next: () => {
        // Tell the pending/rejected tab to reload patients, stay on the current page though (passing NULL for the page)
        this.pendingTab.loadPatients(null, this.keywords, this.searchForm.get('country').value, this.orderBy);
        this.rejectedTab.loadPatients(null, this.keywords, this.searchForm.get('country').value, this.orderBy);

        this.isApprovalFormProcessing = false;

        this.siteAutocomplete.ngOnInit();
        this.siteAutocomplete.ngAfterViewInit();
        this.siteAutocomplete.onClearSelection();

        this.approvePatientModal.hide();
        this.mergePatientModal.hide();
      },
      error: (error) => {
        LogHelper.log(error);
        this.isApprovalFormProcessing = false;
        this.alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Approved a patient - generally called after the pre-approved patient has been completed and the patient code isnt in use on the trial
   * @param patientTrialId
   * @param patientCode
   * @param siteId
   * @param patientConsent
   * @private
   */
  approvePatient(patientTrialId: string, patientCode: string, siteId: string, patientConsent: boolean) {
    this.patientService.approvePatient(
      patientTrialId,
      patientCode,
      siteId,
      patientConsent
    ).subscribe({
      next: () => {
        // Tell the pending/rejected tab to reload patients, stay on the current page though (passing NULL for the page)
        this.pendingTab.loadPatients(null, this.keywords, this.searchForm.get('country').value, this.orderBy);
        this.rejectedTab.loadPatients(null, this.keywords, this.searchForm.get('country').value, this.orderBy);

        this.isApprovalFormProcessing = false;

        this.siteAutocomplete.ngOnInit();
        this.siteAutocomplete.ngAfterViewInit();
        this.siteAutocomplete.onClearSelection();

        this.approvePatientModal.hide();
        this.mergePatientModal.hide();
      },
      error: (error) => {
        LogHelper.log(error);
        this.isApprovalFormProcessing = false;
        this.alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Approves a patient after the admin has chosen to approve the patient without merging
   */
  approvePatientNoMerge() {
    this.isApprovalFormProcessing = true;
    this.mergePatientModal.hide();
    this.patientApprovalCheck = null;
    this.approvePatient(
      this.approvingPatient.patientTrialId,
      this.approvePatientForm.get('patientCode').value,
      this.approvePatientForm.get('siteId').value,
      this.approvePatientForm.get('patientConsent').value
    );
  }

  mergePatient() {
    this.isApprovalFormProcessing = true;
    this.patientApprovalCheck = null;
    this.mergeAndApprovePatient(
      this.approvingPatient.patientTrialId,
      this.approvePatientForm.get('patientCode').value,
      this.approvePatientForm.get('siteId').value,
      this.approvePatientForm.get('patientConsent').value
    );
  }

  /**
   * Cancels the merge and closes the merge patient popup
   */
  cancelMergePatient() {
    this.isApprovalFormProcessing = false;
    this.mergePatientModal.hide();
    this.patientApprovalCheck = null;
  }

  /**
   * Executed when the form on the approve patient modal is submitted, and actions the patient approval process
   */
  onApprovePatientFormSubmit() {
    if (this.approvePatientForm.valid && this.approvingPatient != null) {
      this.isApprovalFormProcessing = true;

      // Pre-approve the patient to make sure the patient code isn't in use on the trial
      this.patientService.preApprovePatient(
        this.approvingPatient.patientTrialId,
        this.approvePatientForm.get('patientCode').value,
        this.approvePatientForm.get('siteId').value,
        this.approvePatientForm.get('patientConsent').value
      ).subscribe({
        next: (rsp) => {
          if (rsp.patientExists) {
            // Patient code is in use, does the admin want to merge the patients?
            this.patientApprovalCheck = rsp;
            this.mergePatientModal.show();
            this.isApprovalFormProcessing = false;
          } else {
            // Approve the patient, the patient code isn't being used on the trial
            this.approvePatient(this.approvingPatient.patientTrialId,
              this.approvePatientForm.get('patientCode').value,
              this.approvePatientForm.get('siteId').value,
              this.approvePatientForm.get('patientConsent').value);
          }
        },
        error: (error) => {
          LogHelper.log(error);
          this.isApprovalFormProcessing = false;
          this.alertService.showErrorAlert(error);
        }
      });
    }
  }

  /**
   * Method shows the approve patient modal and is called when a child component tells us that a patient has been
   * selected for approval
   * @param patient
   */
  onPatientSelectedForApproval(patient: PatientListItem) {
    this.approvingPatient = patient;
    this.approvePatientForm.reset();

    this.siteAutocomplete.setTrialId(patient.trialId);
    if (patient.siteId) {
      const text = patient.siteName + ' / ' + patient.siteAddress;
      const option = new SiteAutocomplete(patient.siteId, '', text.substring(0, 30) + ' ...', patient.siteNumber + ' ' + patient.siteName, patient.siteAddress + ' ' + patient.siteCountry);
      this.siteAutocomplete.onSelectOption(option)
    }

    this.approvePatientForm.patchValue({
      patientCode: patient.patientCode,
      patientConsent: patient.consent,
      showPatientConsentCheckbox: !patient.consent,
      patientConsentDate: patient.consentDate,
      patientConsentType: patient.consentType.toLowerCase()
    });

    this.approvePatientModal.show();
  }

  onPatientSelectedForRejection(patient: PatientListItem) {
    this.patientService.rejectPatient(patient.patientTrialId).subscribe({
      next: () => {
        // Tell the pending tab to reload patients, stay on the current page though (passing NULL for the page)
        this.pendingTab.loadPatients(null, this.keywords, this.searchForm.get('country').value);

        this.alertService.clearAll();
        this.alertService.showSuccessAlert('Patient Rejected Successfully.');
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Sorry, there was a problem performing that action.');
      }
    });
  }

  /**
   * Updates the angular form when you mouse over the export button, it pulls the dates from the fields and puts them
   * into the form. It's a bodge to fix an issue with the jQuery datepicker and angular
   */
  updateExportFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  onExportPatientData() {
    this.exportIsProcessing = true;
    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;
    const anonymisePatientData = this.exportForm.get('anonymisePatientData').value;

    this.patientService.exportPatients(from, to, anonymisePatientData).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportPatientsModal.hide();
        this.exportIsProcessing = false;
        this.exportForm.patchValue({
          dateFrom: '',
          dateTo: '',
          anonymisePatientData: false
        });
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.exportPatientsModal.hide();
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }

  hideApproveModal() {
    this.siteAutocomplete.ngOnInit();
    this.siteAutocomplete.ngAfterViewInit();
    this.siteAutocomplete.onClearSelection();
    this.approvePatientModal.hide();
  }
}
