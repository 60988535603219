import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit {
  @Output() selectValueChanged = new EventEmitter();
  @ViewChild('input') input: ElementRef;
  @ViewChild('dropdownParent') dropdownParent: ElementRef;

  @Input() options = Array<{ value: string, text: string }>();
  @Input() selectedValue = '';
  @Input() placeholder = '';
  @Input() name: string;
  @Input() parentForm = new UntypedFormGroup({});
  @Input() className = '';
  @Input() disabled = false;
  @Input() loading = false;
  @Input() canClear = false;

  selectedValueText = '';
  isFocused = false;
  hasPlaceholder = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!(this.input.nativeElement.contains(event.target) || this.dropdownParent.nativeElement.contains(event.target))) {
      this.isFocused = false;
    }
  }

  ngOnInit(): void {
    if (this.selectedValue !== '') {
      for (const option of this.options) {
        if (this.selectedValue === option.value) {
          this.selectedValueText = option.text;
        }
      }
    } else {
      this.setPlaceholder();
    }
  }

  reset() {
    this.selectedValue = '';
    this.setPlaceholder();
  }

  setPlaceholder() {
    this.hasPlaceholder = true;
    this.selectedValueText = this.placeholder;
  }

  onItemSelect(index: number) {
    const option = this.options[index];
    this.selectedValue = option.value;
    this.selectedValueText = option.text;
    this.hasPlaceholder = false;
    this.input.nativeElement.value = option.value;
    this.onClose();

    this.parentForm.markAsDirty();
    this.parentForm.patchValue({ [this.name]: option.value }, { emitEvent: true });

    this.selectValueChanged.emit(option.value);
  }

  setValue(value: string) {
    let i = 0;
    for (const option of this.options) {
      if (option.value?.toLowerCase() === value?.toLowerCase()) {
        this.onItemSelect(i);
      }
      i++;
    }
  }

  onBlur() {
    this.isFocused = false;
  }

  onOpen() {
    if (!this.disabled)
      this.isFocused = !this.isFocused;
  }

  onClose() {
    if (!this.disabled)
      this.isFocused = false;
  }
}
