import { enumToText } from 'app/core/helpers/enum-to-text.function';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalV2Component } from "../../../../shared/modal-v2/modal-v2.component";
import { ModalViewport } from "../../../../shared/modal-v2/modal-viewport";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectOption } from "../../../../core/models/select-option.model";
import { TripBookingStatus } from "../../../../core/constants/trip-booking-status";
import { ListAutocompleteComponent } from "../../../../shared/list-autocomplete/list-autocomplete.component";
import { IllyTime } from "../../../../core/helpers/date-helper";
import { PatientService } from "../../../../core/services/patient.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import moment from "moment";
import { CreateTripDto } from "../../../../core/services/interfaces/create-trip.interface";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { PatientDetail } from "../../../../core/models/patient-detail.model";
import { Currencies } from "../../../../core/constants/currency";
import { TabulatedContentComponent } from "../../../../shared/tabulated-content/tabulated-content.component";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { TabItem } from "../../../../shared/tabulated-content/tab-item.model";
import { StringHelper } from "../../../../core/helpers/string-helper";
import { ComponentBase } from "../../../../core/component-base";
import { ManageTripTicketsComponent } from "../manage-trip-tickets/manage-trip-tickets.component";
import { InputSelectComponent } from "../../../../shared/input-select/input-select.component";
import { InputCurrencyComponent } from 'app/shared/input-currency/input-currency.component';
import { ExchangeRateService } from 'app/core/services/exchange-rate.service';
import { ExchangeRateRequest } from 'app/core/models/exchange-rate.model';
import {
  VisitPatientDetailModalComponent
} from "../../visits/visit-patient-detail-modal/visit-patient-detail-modal.component";
import { NumberHelper } from "../../../../core/helpers/number.helper";
import { GroundTransportTravelType, PatientTripDirection } from 'app/core/models/patient-trip.model';

@Component({
  selector: 'app-add-trip-v2-modal',
  templateUrl: './add-trip-v2-modal.component.html',
  styleUrls: ['./add-trip-v2-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddTripV2ModalComponent extends ComponentBase implements OnInit {
  @ViewChild('ticketManager') ticketManager: ManageTripTicketsComponent;
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;
  @ViewChild('carrierList') carrierList: ListAutocompleteComponent;
  @ViewChild('bookedThroughList') bookedThroughList: ListAutocompleteComponent;
  @ViewChild('paymentCardUsedList') paymentCardUsedList: ListAutocompleteComponent;
  @ViewChild('bookingStatusSelect') bookingStatusSelect: InputSelectComponent;
  @ViewChild('departureDate') departureDate: ElementRef;
  @ViewChild('arrivalDate') arrivalDate: ElementRef;
  @ViewChild('muvEnabledModal') muvEnabledModal: ModalComponent;
  @ViewChild('amountQuotedControl') amountQuotedControl: InputCurrencyComponent;
  @ViewChild('amountQuotedBCControl') amountQuotedBCControl: InputCurrencyComponent;
  @ViewChild('visitPatientDetailModal') visitPatientDetailModal: VisitPatientDetailModalComponent;

  @Output('tripAdded') tripAdded = new EventEmitter<{ visitId: string, tripId: string, addMuvRides: boolean }>();

  @Input('muvEnabled') muvEnabled = false;

  //Fx rates
  transformedFxRateForm: UntypedFormGroup;
  transformedCurrencyProcessing: boolean = false;
  showFxTransformError: boolean = false;

  viewPort: ModalViewport = ModalViewport.Medium;

  lastAddedTripId: string = null;
  patient: PatientDetail;
  form: UntypedFormGroup;
  visitType: string;
  visitId: string;
  selectedFilename: string;
  selectedFileBlob: Blob;
  tabs: TabItem[] = [
    new TabItem({ title: 'Trip Details', visible: true }),
    new TabItem({ title: 'Booking Info', visible: false }),
    new TabItem({ title: 'Notes', visible: false })
  ];
  tripTabForm: UntypedFormGroup;
  bookingTabForm: UntypedFormGroup;
  noteTabForm: UntypedFormGroup;

  tripTypeOptions: SelectOption[] = [
    { value: '0', text: 'Ground Transport' },
    { value: '1', text: 'Flight' },
    { value: '2', text: 'Train' },
    { value: '3', text: 'Accommodation' },
    { value: '4', text: 'Rental Car' },
  ];
  tripBookingStatusOptions = TripBookingStatus.all();
  groundTransportTravelTypeOptions: SelectOption[] = [
    { value: GroundTransportTravelType.HomeToAirport.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HomeToAirport) },
    { value: GroundTransportTravelType.AirportToHotel.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.AirportToHotel) },
    { value: GroundTransportTravelType.HotelToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HotelToSite) },
    { value: GroundTransportTravelType.HomeToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HomeToSite) },
    { value: GroundTransportTravelType.AirportToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.AirportToSite) },
  ];

  tripDirectionOptions: SelectOption[] = [
    { value: PatientTripDirection.Outbound.toString(), text: enumToText(PatientTripDirection, PatientTripDirection.Outbound) },
    { value: PatientTripDirection.Return.toString(), text: enumToText(PatientTripDirection, PatientTripDirection.Return) }
  ];

  currencyOptions: SelectOption[] = [];
  numberHelper = NumberHelper;

  get hasQuotedCurrency(): boolean {
    let quotedCurrency = this.bookingTabForm.get('quotedCurrency').value;
    return quotedCurrency !== undefined && quotedCurrency !== '';
  }

  get canTransformRate(): boolean {
    let baseCurrency = this.patient.trialBaseCurrency;
    let amount = this.bookingTabForm.get('quotedAmount').value;
    let currency = this.bookingTabForm.get('quotedCurrency').value;
    return baseCurrency !== '' && baseCurrency !== null && baseCurrency !== undefined
      && amount !== '' && amount !== null && amount !== undefined
      && currency !== '' && currency !== null && currency !== undefined;
  }

  constructor(
    private readonly patientService: PatientService,
    private readonly alertService: AlertService,
    private readonly exchangeRateService: ExchangeRateService,
    private readonly cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initiateCurrencies();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      tripTab: new UntypedFormGroup({
        tripType: new UntypedFormControl('', Validators.required),
        groundTransportTravelType: new UntypedFormControl(null),
        tripDirection: new UntypedFormControl(null),
        departureDate: new UntypedFormControl('', Validators.required),
        departureTime: new UntypedFormControl('', Validators.required),
        departureLocation: new UntypedFormControl(''),
        arrivalDate: new UntypedFormControl(''),
        arrivalTime: new UntypedFormControl(''),
        arrivalLocation: new UntypedFormControl(''),
        accommodationLocation: new UntypedFormControl(),
      }),
      noteTab: new UntypedFormGroup({
        notes: new UntypedFormControl(),
        internalNotes: new UntypedFormControl(),
      }),
      bookingTab: new UntypedFormGroup({
        carrier: new UntypedFormControl(),
        ticketConfirmationNo: new UntypedFormControl(),
        bookingProvider: new UntypedFormControl(),
        reference: new UntypedFormControl(),
        quotedAmount: new UntypedFormControl(),
        quotedCurrency: new UntypedFormControl(),
        paymentCardUsed: new UntypedFormControl(),
        invoiceNo: new UntypedFormControl(),
        bookingStatus: new UntypedFormControl('NotStarted'),
        ticketsChanged: new UntypedFormControl(false),
        overBudget: new UntypedFormControl(false),
      }),
    });

    this.tripTabForm = this.form.get('tripTab') as UntypedFormGroup;
    this.bookingTabForm = this.form.get('bookingTab') as UntypedFormGroup;
    this.noteTabForm = this.form.get('noteTab') as UntypedFormGroup;

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({ value: null }),
      transformedCurrency: new UntypedFormControl({ value: null, disabled: true }),
    });

    // Update validation on trip type change
    this.tripTabForm.get('tripType').valueChanges.subscribe(value => this.onTripTypeChange(value));

    this.bookingTabForm.get('quotedAmount').valueChanges.subscribe(value => this.onQuotedAmountChange(value));
    this.bookingTabForm.get('bookingStatus').valueChanges.subscribe(value => this.onBookingStatusChange(value));

    this.form.valueChanges.subscribe(changes => {
      if (this.visitType !== undefined && this.visitType !== null) {
        if (this.tripTabForm.invalid) {
          this.tabulatedContent.addTabClass('Trip Details', 'invalid');
        } else {
          this.tabulatedContent.removeTabClass('Trip Details', 'invalid');
        }

        if (this.bookingTabForm.invalid) {
          this.tabulatedContent.addTabClass('Booking Info', 'invalid');
        } else {
          this.tabulatedContent.removeTabClass('Booking Info', 'invalid');
        }
      }
    });
  }

  /**
   * Called when the user has made a change to the tickets - forces the form to be dirty
   * @param hasChanged
   */
  onTicketsChanged(hasChanged: boolean): void {
    this.bookingTabForm.patchValue({ 'ticketsChanged': hasChanged });
    this.form.markAsDirty();
  }

  /**
   * This method initializes the currency options.
   *
   * @return {void}
   */
  initiateCurrencies(): void {
    this.currencyOptions = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  /**
   * Resets the form
   */
  resetForm(): void {
    this.visitId = null;

    this.tripTabForm.patchValue({
      tripType: '',
      groundTransportTravelType: null,
      tripDirection: null,
      departureDate: '',
      departureTime: '',
      departureLocation: '',
      arrivalDate: '',
      arrivalTime: '',
      arrivalLocation: '',
      accommodationLocation: '',
      currency: null,
    });

    this.noteTabForm.patchValue({
      notes: ''
    });

    this.form.reset();
    this.form.updateValueAndValidity();

    this.bookingTabForm.patchValue({
      ticket: null,
      ticketConfirmationNo: '',
      bookingProvider: '',
      reference: '',
      quotedAmount: '',
      quotedCurrency: '',
      paymentCardUsed: '',
      invoiceNo: '',
      bookingStatus: 'NotStarted',
      overBudget: false
    });

    this.selectedFilename = null;
    this.selectedFileBlob = null;

    this.carrierList.reset();
    this.bookedThroughList.reset();
    this.paymentCardUsedList.reset();

    this.visitType = null;
  };

  onClickShowPatientDetails() {
    this.visitPatientDetailModal.show(this.patient.id, this.patient.patientTrialId, this.visitId);
  }

  /**
   * Shows the modal
   */
  show(visitId: string): void {
    // Hide all but the first tab
    this.tabulatedContent.switchTab(0);
    let tabIndex = 0;
    this.tabs.forEach(tab => {
      if (tabIndex > 0)
        tab.visible = false;

      tabIndex++;
    });

    this.initForm();

    this.lastAddedTripId = null;
    this.resetForm();
    this.modal.show();
    this.visitId = visitId;
    this.loadPatientDetails(visitId);
    this.ticketManager.reset();
  }

  /**
   * Hides the modal
   */
  hide(): void {
    this.modal.hide();
  }

  /**
   * Loads the patient details from the visit
   * @param visitId
   */
  loadPatientDetails(visitId: string): void {
    this.patientService.retrievePatientDetailForVisit(visitId).subscribe({
      next: patient => {
        this.patient = patient;
        if (this.patient.trialBaseCurrency !== null && this.patient.trialBaseCurrency !== undefined) {
          this.transformedFxRateForm.patchValue({ transformedCurrency: this.patient.trialBaseCurrency });
          this.amountQuotedBCControl.currencySelect.setSelectedOption(this.patient.trialBaseCurrency);
          this.bookingTabForm.patchValue({ quotedCurrency: this.patient.trialBaseCurrency });
          this.amountQuotedControl.setCurrency(this.patient.trialBaseCurrency);
        }
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to load patient information!');
      }
    });
  }

  /**
   * Called when the trip type is changed. It will update validation etc on the form based on the user selection
   * @param value
   */
  onTripTypeChange(value: string) {
    if (value) {
      this.tabs.forEach(tab => {
        tab.visible = true;
      });
      this.tabulatedContent.updateTabNavButtonStates();
    }

    // Set departure date and time as required
    this.tripTabForm.get('departureDate').setValidators(Validators.required);
    this.tripTabForm.get('departureDate').updateValueAndValidity();
    this.tripTabForm.get('departureTime').setValidators(Validators.required);
    this.tripTabForm.get('departureTime').updateValueAndValidity();

    // Set arrival date and time as required
    this.tripTabForm.get('arrivalDate').setValidators(Validators.required);
    this.tripTabForm.get('arrivalDate').updateValueAndValidity();
    this.tripTabForm.get('arrivalTime').setValidators(Validators.required);
    this.tripTabForm.get('arrivalTime').updateValueAndValidity();

    if (+value >= 0 && +value <= 2) {
      // Travel Request (Ground Transport, Flight or Train)
      this.visitType = 'Travel';

      this.tripTabForm.patchValue({
        arrivalDate: '',
        departureDate: '',
      });

      this.tripTabForm.get('departureLocation').setValidators(Validators.required);
      this.tripTabForm.get('departureLocation').updateValueAndValidity();

      this.tripTabForm.get('accommodationLocation').clearValidators();
      this.tripTabForm.get('accommodationLocation').setErrors(null);

      if (+value >= 1 && +value <= 2) {
        // Flight or train
        // Arrival date/time is not required
        this.tripTabForm.get('arrivalTime').setValidators(Validators.required);
        this.tripTabForm.get('arrivalTime').updateValueAndValidity();
        this.tripTabForm.get('arrivalDate').setValidators(Validators.required);
        this.tripTabForm.get('arrivalDate').updateValueAndValidity();
        this.tripTabForm.get('arrivalLocation').setValidators(Validators.required);
        this.tripTabForm.get('arrivalLocation').updateValueAndValidity();
      } else {
        // Ground transport
        // Arrival date and time isn't required for ground transport
        this.tripTabForm.get('arrivalDate').clearValidators();
        this.tripTabForm.get('arrivalDate').setErrors(null);
        this.tripTabForm.get('arrivalTime').clearValidators();
        this.tripTabForm.get('arrivalTime').setErrors(null);
        this.tripTabForm.get('arrivalLocation').clearValidators();
        this.tripTabForm.get('arrivalLocation').setErrors(null);
      }
    } else if (+value === 3) {
      // Accommodation Request
      this.visitType = 'Accommodation';

      this.tripTabForm.get('accommodationLocation').setValidators(Validators.required);
      this.tripTabForm.get('accommodationLocation').updateValueAndValidity();
      this.tripTabForm.get('departureLocation').clearValidators();
      this.tripTabForm.get('departureLocation').setErrors(null);
      this.tripTabForm.get('arrivalLocation').clearValidators();
      this.tripTabForm.get('arrivalLocation').setErrors(null);
      this.tripTabForm.get('departureTime').clearValidators();
      this.tripTabForm.get('departureTime').setErrors(null);
      this.tripTabForm.get('arrivalTime').clearValidators();
      this.tripTabForm.get('arrivalTime').setErrors(null);

      this.tripTabForm.patchValue({
        arrivalDate: '',
        departureTime: '',
        departureDate: '',
      });
    } else if (+value === 4) {
      // Rental Car
      this.visitType = 'Rental Car';

      this.tripTabForm.get('departureLocation').setValidators(Validators.required);
      this.tripTabForm.get('departureLocation').updateValueAndValidity();

      this.tripTabForm.get('arrivalLocation').setValidators(Validators.required);
      this.tripTabForm.get('arrivalLocation').updateValueAndValidity();

      this.tripTabForm.get('accommodationLocation').clearValidators();
      this.tripTabForm.get('accommodationLocation').setErrors(null);
    }
  }

  /**
   * Called when user wants to see quoted amount in transformed FX currency.
   */
  onShowTransformedFxRate() {
    let quotedAmount = this.bookingTabForm.get('quotedAmount').value;
    let quotedCurrency = this.bookingTabForm.get('quotedCurrency').value;
    let toCurrency = this.transformedFxRateForm.get('transformedCurrency').value;

    if (!this.canTransformRate) {
      this.showFxTransformError = true;
      return;
    }
    this.transformedCurrencyProcessing = true;

    let request = {
      fromCurrencyCode: quotedCurrency,
      toCurrencyCode: toCurrency,
      quotedAmount: quotedAmount
    } as ExchangeRateRequest

    this.exchangeRateService.getTransformedFxRate(request).subscribe({
      next: result => {
        this.transformedCurrencyProcessing = false;
        if (result.result !== null) {
          this.transformedFxRateForm.patchValue({ transformedAmount: result.result / 100 });
        }
        else if (result.destinationCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for the base currency set on connected trial (${toCurrency}).`);
        }
        else if (result.sourceCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for this currency (${quotedCurrency})`);
        }
      },
      error: error => {
        this.transformedCurrencyProcessing = false;
        this.showFxTransformError = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to retrieve transformed FX rate!');
      }
    });
  }

  resetFxFields() {
    this.transformedCurrencyProcessing = false;
    this.showFxTransformError = false;
  }

  /**
   * Updates the departure and arrival date in the form when you move your mouse around,
   * a bit of a bodge fix because angular doesn't automatically detect changes
   */
  onMouseEnterForm() {
    if (this.departureDate.nativeElement.value !== this.form.get('departureDate')) {
      this.tripTabForm.patchValue({ departureDate: this.departureDate.nativeElement.value });
    }
    if (this.arrivalDate.nativeElement.value !== this.form.get('arrivalDate')) {
      this.tripTabForm.patchValue({ arrivalDate: this.arrivalDate.nativeElement.value });
    }
  }

  /**
   * Opens the file browser when the user clicks the upload button
   * @param event
   */
  onOpenFileBrowser(event: any) {
    event.preventDefault();

    const element: HTMLElement = document.getElementById('file-upload-input');
    element.click();
  }

  /**
   * Removes the ticket from the form
   */
  onRemoveTicket() {
    this.selectedFilename = null;
    this.bookingTabForm.patchValue({ ticket: null });
  }

  /**
   * Called when the user selects a file
   * @param event
   */
  onTicketChange(event) {
    if (event.target?.files?.length) {
      let file = event.target.files[0];
      let fr = new FileReader();

      fr.onload = (fileLoadEvent: any) => {
        let pdf = fileLoadEvent.target.result.split(',')[1];
        this.selectedFileBlob = new Blob(this.convertToByteArray(window.atob(pdf)), { type: 'application/pdf' });

        // Set the form value just for validation purposes
        this.form.patchValue({
          ticket: 'file selected'
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();

        this.selectedFilename = file.name;
      };
      fr.readAsDataURL(file);
    }
  }

  /**
   * Converts a string to a byte array
   * @param input
   */
  convertToByteArray(input) {
    const sliceSize = 512;
    const bytes: Uint8Array[] = [];

    for (let offset = 0; offset < input.length; offset += sliceSize) {
      const slice = input.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      bytes.push(byteArray);
    }

    return bytes;
  }

  /**
   * Returns the date format based on the validity of the given IllyTime object.
   *
   * @param {IllyTime} time - The IllyTime object used to determine the date format.
   * @return {string} The date format string.
   */
  private getDateFormat(time: IllyTime): string {
    return time?.isValid ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD';
  }

  /**
   * Formats a date and time string into a moment object
   * @param date
   * @param time
   * @param isArrivalDate
   * @private
   */
  private formatDateTimeString(date: string, time: IllyTime, isArrivalDate = false): moment.Moment {
    let response = null;

    if (date !== '' && time?.isValid) {
      // Both date and time were supplied
      response = moment(date + ' ' + time.to24HourString(), 'DD/MM/YYYY HH:mm');
    } else if (date !== '' && (time == null || !time.isValid)) {
      // Only a date was provided
      if (isArrivalDate) {
        // Set time to end of day as the date doesn't have a time
        response = moment(date + ' 23:59', 'DD/MM/YYYY HH:mm');
      } else {
        response = moment(date, 'DD/MM/YYYY');
      }
    }

    return response;
  }

  onQuotedAmountChange(value: any) {
    if (!value) {
      this.bookingTabForm.get('quotedCurrency').clearValidators();
      this.bookingTabForm.get('quotedCurrency').updateValueAndValidity();
    } else {
      // If value is not empty, set the currencyControlName input to be required
      this.bookingTabForm.get('quotedCurrency').setValidators([Validators.required]);
      this.bookingTabForm.get('quotedCurrency').updateValueAndValidity();
    }
  }

  onBookingStatusChange(status: string) {
    if (status === 'Booked' && this.tripTabForm.get('tripType').value === '0') {
      this.tripTabForm.get('groundTransportTravelType').setValidators(Validators.required);
    } else {
      this.tripTabForm.get('groundTransportTravelType').clearValidators();
    }

    this.tripTabForm.get('groundTransportTravelType').updateValueAndValidity();
  }

  /**
   * Called when the user clicks the submit button
   */
  onFormSubmit() {
    if (!this.form.valid)
      return;

    let validationError = '';

    const departureTime = !StringHelper.isNullOrEmpty(this.tripTabForm.get('departureTime').value) ? IllyTime.parseString(this.tripTabForm.get('departureTime').value) : null;
    const departureDate = this.formatDateTimeString(this.tripTabForm.get('departureDate').value, departureTime);
    const arrivalTime = !StringHelper.isNullOrEmpty(this.tripTabForm.get('arrivalTime').value) ? IllyTime.parseString(this.tripTabForm.get('arrivalTime').value) : null;
    const arrivalDate = this.formatDateTimeString(this.tripTabForm.get('arrivalDate').value, arrivalTime, true);

    let formIsValid = true;

    if (arrivalDate && arrivalDate.valueOf() < departureDate.valueOf()) {
      validationError = 'Arrival date is before departure date.';
      formIsValid = false;
    }

    if (this.tripTabForm.get('tripType').value === '0' && this.noteTabForm.get('notes').value !== null && this.noteTabForm.get('notes').value.length > 1000) {
      validationError = 'Notes for ground transport trips must be no more than 1000 characters in length.';
      formIsValid = false;
    }

    if (!formIsValid) {
      this.alertService.showWarningAlert(validationError);
      return;
    }

    this.form.patchValue({ processing: true });

    const dto: CreateTripDto = {
      tripType: +this.tripTabForm.get('tripType').value,
      groundTransportTravelType: this.tripTabForm.get('groundTransportTravelType').value,
      tripDirection: this.tripTabForm.get('tripDirection').value,
      departureDate: departureDate ? departureDate.format(this.getDateFormat(departureTime)) : null,
      departureLocation: this.tripTabForm.get('departureLocation').value,
      departureTimeMinutes: departureTime !== null ? departureTime.totalMinutes() : null,
      arrivalDate: arrivalDate ? arrivalDate.format(this.getDateFormat(arrivalTime)) : null,
      arrivalLocation: this.tripTabForm.get('arrivalLocation').value,
      arrivalTimeMinutes: arrivalTime !== null ? arrivalTime.totalMinutes() : null,
      accommodationLocation: this.tripTabForm.get('accommodationLocation').value,
      notes: this.noteTabForm.get('notes').value,
      internalNotes: this.noteTabForm.get('internalNotes').value,
      carrier: this.bookingTabForm.get('carrier').value,
      bookingProvider: this.bookingTabForm.get('bookingProvider').value,
      paymentCardUsed: this.bookingTabForm.get('paymentCardUsed').value,
      ticketConfirmationNo: this.bookingTabForm.get('ticketConfirmationNo').value,
      reference: this.bookingTabForm.get('reference').value,
      bookingStatus: this.bookingTabForm.get('bookingStatus').value,
      invoiceNo: this.bookingTabForm.get('invoiceNo').value,
      quotedAmount: this.bookingTabForm.get('quotedAmount').value,
      quotedAmountBC: this.transformedFxRateForm.get('transformedAmount').value,
      quotedCurrency: this.bookingTabForm.get('quotedCurrency').value,
      overBudget: this.bookingTabForm.get('overBudget').value,
    };

    this.patientService.addTrip(this.patient.id, this.visitId, dto).subscribe({
      next: trip => {
        // Calculate how a delay based on the number of attachments added to the ticket.
        // This is a bit of a bodge, but will help for now until a more in-depth solution can be implemented
        let attachmentCount = this.ticketManager.attachments.length;
        let timeout = 3000 + (attachmentCount * 650);

        this.ticketManager.uploadTicketsForTrip(trip.id).then(() => {
          if (this.bookingTabForm.get('ticketsChanged').value) {
            // Set the order of the attachments
            this.patientService.setTripTicketOrder(trip.id, this.ticketManager.attachments.map(a => a.originalFileName)).subscribe({
              next: () => {
                // Delay to allow the attachments to be merged by the queue handler
                setTimeout(() => {
                  this.saveComplete(trip.id);
                }, timeout);
              },
              error: (error) => {
                this.form.patchValue({ processing: false });
                this.alertService.showErrorAlert(error);
              }
            });
          } else {
            this.saveComplete(trip.id);
          }


        }).catch(error => {
          this.form.patchValue({ processing: false });
          this.alertService.showWarningAlert('There was a problem uploading the ticket!');
          this.hide();
        });
      },
      error: error => {
        this.form.patchValue({ processing: false });
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  /**
   * Saves the completed trip and adds MUV rides.
   *
   * @return {void} - Returns nothing.
   */
  saveCompletedAndAddMuvRides(): void {
    this.muvEnabledModal.hide();
    this.tripAdded.emit({ visitId: this.visitId, tripId: this.lastAddedTripId, addMuvRides: true });
    this.hide();
    this.alertService.showSuccessAlert('Trip Successfully Added, you can now add MUV Rides');
  }

  /**
   * Saves the completed trip with the given trip ID.
   * If MUV is enabled and the trip type is ground transport, a modal is shown.
   * Otherwise, it calls the method saveCompletedNoMuvRides().
   *
   * @param {string} tripId - The ID of the trip being saved.
   * @return {void}
   */
  saveComplete(tripId: string): void {
    this.lastAddedTripId = tripId;
    this.resetFxFields();

    // If muv enabled and trip type is ground transport, show modal
    if (this.muvEnabled && this.tripTabForm.get('tripType').value === '0') {
      this.muvEnabledModal.show();
    } else {
      this.saveCompletedNoMuvRides()
    }
  }

  /**
   * Method to save completed no muv rides.
   *
   * @return {void} - Returns nothing.
   */
  saveCompletedNoMuvRides(): void {
    this.tripAdded.emit({ visitId: this.visitId, tripId: this.lastAddedTripId, addMuvRides: false });
    this.hide();
    this.muvEnabledModal.hide();
    this.alertService.showSuccessAlert('Trip Successfully Added');
  }

  getDepartureDateLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-in Date';
      case 'Rental Car':
        return 'Pick-up Date';
    }

    return 'Departure Date';
  }

  getArrivalDateLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-out Date';
      case 'Rental Car':
        return 'Drop-off Date';
    }

    return 'Arrival Date';
  }

  getDepartureTimeLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-in Time';
      case 'Rental Car':
        return 'Pick-up Time';
    }

    return 'Departure Time';
  }

  getArrivalTimeLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-out Time';
      case 'Rental Car':
        return 'Drop-off Time';
    }

    return 'Arrival Time';
  }

}
