<app-modal #modal class="assign-patient-modal">
  <div class="modal-header">
    <h2 class="modal-title">Assign Patient to Trial</h2>
  </div>
  <div class="modal-body">
    <form (submit)="onFormSubmit()" [formGroup]="form">

      <table aria-hidden="true" class="patient-trial">
        <tr>
          <th style="width: 115px;"><label>Trial</label></th>
          <td>
            <div class="form-group">
              <span class="fake-field disabled">{{ form.get('trialCode').value }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Site</label></th>
          <td>
            <div class="form-group">
              <app-site-autocomplete #siteAutocomplete [allowAllSites]="false"
                                     [form]="form"
                                     [showApprovedOnly]="true"
                                     [showSiteNumbers]="true"
                                     [showConsumerSites]="trial.apiEnabled"
                                     controlName="siteId"
                                     placeholder="Select a site..."></app-site-autocomplete>
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Patient</label></th>
          <td>
            <div class="form-group">
              <app-patient-autocomplete #patientAutocomplete [parentForm]="form"
                                        placeholder="Start typing to find patient..."
                                        [globalOnly]="!trial.apiEnabled"
                                        controlName="patientId"></app-patient-autocomplete>
            </div>
            <a (click)="addPatientModal.show(form.get('trialId').value)" class="btn btn-outline btn-outline-slim">+
              Add New Patient</a>
          </td>
        </tr>
        <tr *ngIf="patient !== undefined && patient !== null">
          <th><label>First Name</label></th>
          <td>
            <div class="form-group">
              <span class="fake-field disabled">{{ patient.firstname }}</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="patient !== undefined && patient !== null">
          <th><label>Last Name</label></th>
          <td>
            <div class="form-group">
              <span class="fake-field disabled">{{ patient.lastname }}</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="patient !== undefined && patient !== null">
          <th><label>Email</label></th>
          <td>
            <div class="form-group">
              <span class="fake-field disabled">{{ patient.email }}</span>
            </div>
          </td>
        </tr>
        <tr *ngIf="patient !== undefined && patient !== null">
          <th><label>Country</label></th>
          <td>
            <div class="form-group">
              <span class="fake-field disabled">{{ patient.country }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="patientCode">Patient Id</label></th>
          <td>
            <div class="form-group">
              <input class="form-control" formControlName="patientCode" id="patientCode" type="text">
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="checkbox-wrap last">
              <div class="form-group">
                <label class="checkbox">
                  <input formControlName="consent" id="consent" type="checkbox">
                  <span></span>
                </label>
                <label class="checkbox-label" for="consent">I confirm that I have seen and verified patient
                  consent</label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td class="buttons" style="text-align: right;">
            <div class="row">
              <div class="col-6">
                <button (click)="hide()" class="btn btn-secondary" type="button">Cancel</button>
              </div>
              <div class="col-6">
                <button [disabled]="!form.valid" class="btn btn-primary" type="submit">
                  <span *ngIf="form.get('processing').value">
                            <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                  <span *ngIf="!form.get('processing').value">Add Patient</span>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </table>

    </form>
  </div>
</app-modal>

<app-patient-add-modal #addPatientModal (patientCreated)="onHandlePatientCreated($event)"></app-patient-add-modal>
