import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdministratorTrial } from 'app/core/models/administrator-trial.model';
import { ModalV2Component } from '../modal-v2/modal-v2.component';
import { AdminUser } from 'app/core/models/admin-user.model';
import { Countries } from 'app/core/constants/countries';
import { SelectOption } from 'app/core/models/select-option.model';

@Component({
  selector: 'app-edit-trial-assignment',
  templateUrl: './edit-trial-assignment.component.html',
  styleUrls: ['./edit-trial-assignment.component.scss']
})
export class EditTrialAssignmentComponent implements OnInit {
  @ViewChild('editTrialAssignment') editTrialAssignment: ModalV2Component;

  @Input("administrator") administrator: AdminUser;

  @Output('assignmentUpdated') assignmentUpdated = new EventEmitter<AdministratorTrial>();

  administratorTrial: AdministratorTrial;
  editTrialAssignmentForm = new FormGroup({
    trialId: new FormControl(''),
    countries: new FormControl([]),
    assignToAllCountries: new FormControl(false),
    projectManager: new FormControl(false),
    designatedContact: new FormControl(false)
  })

  allCountryOptions: SelectOption[] = Countries.all().map(c => new SelectOption(c.code, c.name));
  filteredCountries: SelectOption[] = this.allCountryOptions;

  constructor() { }

  ngOnInit() {
    this.editTrialAssignmentForm.controls.assignToAllCountries.valueChanges.subscribe(value => {
      if (value) {
        this.editTrialAssignmentForm.controls.countries.setValue([]);
        this.editTrialAssignmentForm.controls.countries.disable();
      } else {
        this.editTrialAssignmentForm.controls.countries.enable();
      }
    })
  }

  onCountryFiltered(value: string) {
    if (value && value !== '') {
      this.filteredCountries = this.allCountryOptions.sort((a, b) => {
        const aMatches = a.text.toLowerCase().includes(value.toLowerCase());
        const bMatches = b.text.toLowerCase().includes(value.toLowerCase());
        if (aMatches && !bMatches) {
          return -1; // Move `a` before `b`
        } else if (!aMatches && bMatches) {
          return 1; // Move `b` before `a`
        }
      });
      return;
    }

    this.filteredCountries = this.allCountryOptions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
  }

  showEditModal(trial: AdministratorTrial) {
    this.editTrialAssignmentForm.patchValue({
      trialId: trial.id,
      countries: trial.assignedCountries?.split(","),
      assignToAllCountries: trial.allCountries,
      designatedContact: trial.designatedContact,
      projectManager: trial.projectManager
    })

    this.editTrialAssignment.show();
  }

  saveTrialAssignmentChanges() {
    if (this.administrator) {
      const trial = this.administrator.trials.find(t => t.id === this.editTrialAssignmentForm.controls.trialId.value)

      trial.allCountries = this.editTrialAssignmentForm.controls.assignToAllCountries.value;
      trial.projectManager = this.editTrialAssignmentForm.controls.projectManager.value;
      trial.designatedContact = this.editTrialAssignmentForm.controls.designatedContact.value;
      trial.assignedCountries = this.editTrialAssignmentForm.controls.countries.value?.join(',');
    }

    this.assignmentUpdated.emit(
      {
        allCountries: this.editTrialAssignmentForm.controls.assignToAllCountries.value,
        projectManager: this.editTrialAssignmentForm.controls.projectManager.value,
        designatedContact: this.editTrialAssignmentForm.controls.designatedContact.value,
        assignedCountries: this.editTrialAssignmentForm.controls.countries.value?.join(','),
        id: this.editTrialAssignmentForm.controls.trialId.value,
        code: ''
      }
    );
    this.editTrialAssignment.hide();
  }

  hideTrialAssignmentModal() {
    this.editTrialAssignmentForm.controls.countries.setValue([]);
    this.editTrialAssignmentForm.reset();
    this.editTrialAssignment.hide();
  }
}
