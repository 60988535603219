import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminUser } from "../../../../core/models/admin-user.model";
import { TrialAutocompleteComponent } from "../../../../shared/trial-autocomplete/trial-autocomplete.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { SelectOption } from "../../../../core/models/select-option.model";
import { TrialService } from "../../../../core/services/trial.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { AdministratorTrial } from "../../../../core/models/administrator-trial.model";
import { Countries } from "../../../../core/constants/countries";
import { InputMultiSelectComponent } from 'app/shared/input-multi-select/input-multi-select.component';
import { EditTrialAssignmentComponent } from 'app/shared/edit-trial-assignment/edit-trial-assignment.component';

@Component({
  selector: 'app-manage-admin-trials',
  templateUrl: './manage-admin-trials.component.html',
  styleUrls: ['./manage-admin-trials.component.scss']
})
export class ManageAdminTrialsComponent implements OnInit {
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('unassignModel') unassignModel: ModalComponent;
  @ViewChild('unassignAllTrialsModel') unassignAllTrialsModel: ModalComponent;
  @ViewChild('countrySelect') countrySelect: InputMultiSelectComponent;
  @ViewChild('editCountriesSelect') editCountriesSelect: InputMultiSelectComponent;
  @ViewChild('editTrialAssignment') editTrialAssignment: EditTrialAssignmentComponent;

  @Output('assignedTrialChanged') assignedTrialChanged = new EventEmitter<AdministratorTrial[]>();
  @Output('hideFormButtons') hideFormButtons = new EventEmitter<boolean>();
  @Output('assignmentUpdated') assignmentUpdated = new EventEmitter();

  @Input("administrator") administrator: AdminUser = new AdminUser();

  showAssignForm = false;
  assignTrialForm = new FormGroup({
    trialIds: new FormControl([], Validators.required),
    countries: new FormControl([]),
    allCountries: new FormControl(false),
    dropdownTrialIds: new FormControl(), // Do not use this value
    assignToAllTrials: new FormControl(false),
    projectManager: new FormControl(false),
    designatedContact: new FormControl(false)
  });

  trialOptions: SelectOption[] = [];
  filteredTrials: SelectOption[] = [];
  assignButtonLabel: string = "Assign";
  pageSize = 25;
  currentPage = 1;
  pageCount = 1;
  originalAssignedTrialCount = 0;
  allCountryOptions: SelectOption[] = Countries.all().map(c => new SelectOption(c.code, c.name));
  filteredCountries: SelectOption[] = this.allCountryOptions;

  administratorTrials: AdministratorTrial[] = [];

  constructor(private trialService: TrialService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.assignTrialForm.get('assignToAllTrials').valueChanges.subscribe(allTrials => {
      if (allTrials) {
        this.assignTrialForm.get('dropdownTrialIds').disable();
        const currentTrials = this.assignTrialForm.get('trialIds').value;
        const allTrialIds = this.trialOptions.filter(t => !currentTrials.includes(t.value)).map(t => t.value);
        this.assignTrialForm.patchValue({ trialIds: allTrialIds, dropdownTrialIds: allTrialIds });
      } else {
        this.assignTrialForm.get('dropdownTrialIds').enable();
        this.assignTrialForm.patchValue({ trialIds: [], dropdownTrialIds: [] });
      }

      this.updateButtonLabel();
    });

    this.assignTrialForm.controls.allCountries.valueChanges.subscribe(value => {
      if (value) {
        this.assignTrialForm.controls.countries.disable();
        this.assignTrialForm.controls.countries.setValue([]);
      } else {
        this.assignTrialForm.controls.countries.enable();
      }
    })

    this.loadTrials();
  }

  onCountryFiltered(value: string) {
    if (value && value !== '') {
      this.filteredCountries = this.allCountryOptions.sort((a, b) => {
        const aMatches = a.text.toLowerCase().includes(value.toLowerCase());
        const bMatches = b.text.toLowerCase().includes(value.toLowerCase());
        if (aMatches && !bMatches) {
          return -1; // Move `a` before `b`
        } else if (!aMatches && bMatches) {
          return 1; // Move `b` before `a`
        }
      });
      return;
    }

    this.filteredCountries = this.allCountryOptions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
  }

  /**
   * Called when editing an administrator, it sets the administrator and assigned trials ready for editing
   * @param administrator
   */
  setAdministrator(administrator: AdminUser): void {
    this.administrator = administrator;
    const currentTrialIds = administrator.trials.map(t => t.id);

    this.assignTrialForm.patchValue({
      trialIds: currentTrialIds,
      dropdownTrialIds: currentTrialIds
    });

    this.originalAssignedTrialCount = administrator.trials.length;

    this.sortAdminTrials();
    this.recalculatePageCount();

    this.isAdminAssignedAllTrials();

  }

  loadTrials(): void {
    this.trialService.getTrialsPublic(null, 9999).subscribe({
      next: trials => {
        trials.forEach(trial => {
          this.trialOptions.push(new SelectOption(trial.id, trial.label));
        });
      },
      error: error => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  /**
   * Returns the trials for the specified page
   * @param page
   */
  getTrialsForPage(page = 1): AdministratorTrial[] {
    const start = (page - 1) * this.pageSize;
    const end = start + this.pageSize;

    return this.administrator.trials.slice(start, end);
  }

  onHandlePageChanged(page: number): void {
    this.currentPage = page;
  }

  onHandleAssignTrials(): void {
    this.showAssignForm = false;

    this.hideFormButtons.emit(false);

    if (this.administrator === undefined || this.administrator === null)
      this.administrator = new AdminUser();

    if (!Array.isArray(this.administrator.trials))
      this.administrator.trials = [];

    // We need to populate the admin user with each trial Id and code
    let selectedTrials = this.assignTrialForm.get('trialIds').value;
    let isProjectManager = this.assignTrialForm.get('projectManager').value == true;
    let isDesignatedContact = this.assignTrialForm.get('designatedContact').value == true;
    let allCountries = this.assignTrialForm.get('allCountries').value == true;
    let assignedCountries = this.assignTrialForm.get('countries').value?.join(',');

    selectedTrials.forEach((trialId) => {
      if (!this.administrator.trials.find((trial) => trial.id === trialId)) {
        let trial = this.trialOptions.find((trial) => trial.value === trialId);
        this.administrator.trials.push(
          {
            id: trialId,
            code: trial.text,
            projectManager: isProjectManager,
            designatedContact: isDesignatedContact,
            allCountries: allCountries,
            assignedCountries: assignedCountries
          });
      }
    });

    this.sortAdminTrials();
    this.recalculatePageCount();
    this.isAdminAssignedAllTrials();

    this.assignedTrialChanged.emit(this.administrator.trials);
  }

  private sortAdminTrials(): void {
    this.administrator.trials.sort((a, b) => {
      let codeA = a.code.toLowerCase();
      let codeB = b.code.toLowerCase();

      if (codeA < codeB) {
        return -1;
      }
      if (codeA > codeB) {
        return 1;
      }
      return 0;
    });

  }

  private isAdminAssignedAllTrials() {
    let matchedTrials = 0;
    matchedTrials = this.trialOptions.filter(trial => this.administrator.trials.find(t => t.id === trial.value)).length;

    return matchedTrials === this.trialOptions.length;
  }

  onHandleTrialClicked(trialId: string): void {
    if (!Array.isArray(this.assignTrialForm.get('trialIds').value))
      this.assignTrialForm.patchValue({ trialIds: [] });

    let selectedTrialIds = this.assignTrialForm.get('trialIds').value;

    if (selectedTrialIds.includes(trialId)) {
      selectedTrialIds.splice(selectedTrialIds.indexOf(trialId), 1);
    } else {
      selectedTrialIds.push(trialId);
    }

    this.assignTrialForm.patchValue({
      trialIds: selectedTrialIds,
      dropdownTrialIds: selectedTrialIds // Update the dropdown so it shows selected items
    });

    this.updateButtonLabel();
  }

  private updateButtonLabel(): void {
    let assignedTrialsCount = this.assignTrialForm.get('trialIds').value.length - this.originalAssignedTrialCount;
    if (assignedTrialsCount < 0)
      assignedTrialsCount = 0;

    if (assignedTrialsCount === 0)
      this.assignButtonLabel = "Assign";
    else if (assignedTrialsCount === 1)
      this.assignButtonLabel = "Assign 1 Trial";
    else
      this.assignButtonLabel = `Assign ${assignedTrialsCount} Trials`;
  }

  onHandleShowAssignTrial() {
    this.showAssignForm = true;
    this.hideFormButtons.emit(true);
    this.administratorTrials = [];
    this.assignTrialForm.reset();
    this.assignTrialForm.controls.allCountries.setValue(true);

    // If the administrator is assigned to trials, add them into the form
    this.setAdministrator(this.administrator);

    this.filterTrials();
  }

  onHandleReturnToForm() {
    this.showAssignForm = false;
    this.hideFormButtons.emit(false);
  }

  onHandleAssignTrial(): void {
    this.hideFormButtons.emit(false);
  }

  onHandleDeleteConfirmed(index: number) {
    // calculate true index based on page and index
    const trueIndex = (this.currentPage - 1) * this.pageSize + index;

    this.administrator.trials.splice(trueIndex, 1);

    const currentIds = this.administrator.trials.map((trial) => trial.id);
    this.assignTrialForm.patchValue({ trialIds: currentIds, dropdownTrialIds: currentIds });

    // Reset the unassigning Site flag
    this.unassignModel.deleteIndex = -1;

    // Hide the modal window
    this.unassignModel.hide();

    this.hideFormButtons.emit(false);

    this.isAdminAssignedAllTrials();

    this.assignedTrialChanged.emit(this.administrator.trials);
  }

  public onToggleProjectManager(trialId: string) {
    let trial = this.administrator.trials.find(t => t.id === trialId);
    trial.projectManager = !trial.projectManager;

    this.assignedTrialChanged.emit(this.administrator.trials);
  }

  public onToggleDesignatedContact(trialId: string) {
    let trial = this.administrator.trials.find(t => t.id === trialId);
    trial.designatedContact = !trial.designatedContact;

    this.assignedTrialChanged.emit(this.administrator.trials);
  }

  private recalculatePageCount(): void {
    this.pageCount = Math.ceil(this.administrator.trials.length / this.pageSize);

    if (this.getTrialsForPage(this.currentPage).length === 0) {
      this.currentPage--;

      if (this.currentPage < 1)
        this.currentPage = 1;
    }
  }

  filterTrials(searchInput: string = ''): void {
    if (this.administrator && this.administrator.trials) {
      let trialIds = this.administrator.trials.map((trial) => trial.id);
      this.filteredTrials = this.trialOptions.filter((trialId) => !trialIds.includes(trialId.value));
    } else {
      this.filteredTrials = this.trialOptions;
    }

    if (searchInput !== null && searchInput !== '')
      this.filteredTrials = this.filteredTrials.filter((trial) => trial.text.toLowerCase().includes(searchInput.toLowerCase()));
  }

  onConfirmUnassignAllTrials(): void {
    this.administrator.trials = [];
    this.assignTrialForm.patchValue({ trialIds: [], dropdownTrialIds: [] });
    this.unassignAllTrialsModel.hide();

    this.recalculatePageCount();

    this.isAdminAssignedAllTrials();

    this.assignedTrialChanged.emit([]);
  }

  showEditTrialAssignmentModal(trial: AdministratorTrial) {
    this.editTrialAssignment.showEditModal(trial);
  }
}
