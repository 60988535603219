import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalV2Component } from "../../../../shared/modal-v2/modal-v2.component";
import { TabulatedContentComponent } from "../../../../shared/tabulated-content/tabulated-content.component";
import { ListAutocompleteComponent } from "../../../../shared/list-autocomplete/list-autocomplete.component";
import { ModalViewport } from "../../../../shared/modal-v2/modal-viewport";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SelectOption } from "../../../../core/models/select-option.model";
import { TripBookingStatus } from "../../../../core/constants/trip-booking-status";
import { PatientService } from "../../../../core/services/patient.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { Currencies } from "../../../../core/constants/currency";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { IllyTime } from "../../../../core/helpers/date-helper";
import moment from "moment/moment";
import {
  PatientTrip
} from "../../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { UpdateTripDto } from "../../../../core/services/interfaces/update-trip.interface";
import { StringHelper } from "../../../../core/helpers/string-helper";
import { TabItem } from "../../../../shared/tabulated-content/tab-item.model";
import { ManageTripTicketsComponent } from "../manage-trip-tickets/manage-trip-tickets.component";
import { ComponentBase } from "../../../../core/component-base";
import { InputCurrencyComponent } from "../../../../shared/input-currency/input-currency.component";
import { ExchangeRateRequest } from 'app/core/models/exchange-rate.model';
import { ExchangeRateService } from 'app/core/services/exchange-rate.service';
import { NumberHelper } from "../../../../core/helpers/number.helper";
import {
  VisitPatientDetailModalComponent
} from "../../visits/visit-patient-detail-modal/visit-patient-detail-modal.component";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import { GroundTransportTravelType, PatientTripDirection } from 'app/core/models/patient-trip.model';

@Component({
  selector: 'app-edit-trip-v2-modal',
  templateUrl: './edit-trip-v2-modal.component.html',
  styleUrls: ['./edit-trip-v2-modal.component.scss']
})
export class EditTripV2ModalComponent extends ComponentBase implements OnInit {
  @ViewChild('ticketManager') ticketManager: ManageTripTicketsComponent;
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('recalculateAmountBcModal') recalculateAmountBcModal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;
  @ViewChild('carrierList') carrierList: ListAutocompleteComponent;
  @ViewChild('bookedThroughList') bookedThroughList: ListAutocompleteComponent;
  @ViewChild('paymentCardUsedList') paymentCardUsedList: ListAutocompleteComponent;
  @ViewChild('departureDate') departureDate: ElementRef;
  @ViewChild('arrivalDate') arrivalDate: ElementRef;
  @ViewChild('amountQuotedControl') amountQuotedControl: InputCurrencyComponent;
  @ViewChild('amountQuotedBCControl') amountQuotedBCControl: InputCurrencyComponent;
  @ViewChild('visitPatientDetailModal') visitPatientDetailModal: VisitPatientDetailModalComponent;
  @ViewChild('deleteTripModal') deleteTripModal: ModalComponent;

  @Output('tripUpdated') tripUpdated = new EventEmitter<string>();

  //Fx rates
  transformedFxRateForm: UntypedFormGroup;
  transformedCurrencyProcessing: boolean = false;
  showFxTransformError: boolean = false;

  viewPort: ModalViewport = ModalViewport.Medium;

  formInitialising = false;
  isRideHealthTrip = false;
  tripId: string;
  trip: PatientTrip;
  form: UntypedFormGroup;
  visitType: string;
  visitId: string;
  selectedFilename: string;
  selectedFileBlob: Blob;
  ticketRemoved = false;
  ticketOriginalFilename: string;
  tripHasObRequest: boolean = false;
  tabs: TabItem[] = [
    new TabItem({ title: 'Trip Details', visible: true }),
    new TabItem({ title: 'Booking Info', visible: true }),
    new TabItem({ title: 'Notes', visible: true }),
    new TabItem({ title: 'RideHealth', visible: false }),
    new TabItem({ title: 'MUV Rides', visible: false })
  ];
  tripTabForm: UntypedFormGroup;
  bookingTabForm: UntypedFormGroup;
  noteTabForm: UntypedFormGroup;

  tripTypeOptions: SelectOption[] = [
    { value: '0', text: 'Ground Transport' },
    { value: '1', text: 'Flight' },
    { value: '2', text: 'Train' },
    { value: '3', text: 'Accommodation' },
    { value: '4', text: 'Rental Car' }
  ];
  tripBookingStatusOptions = TripBookingStatus.all();
  groundTransportTravelTypeOptions: SelectOption[] = [
    { value: GroundTransportTravelType.HomeToAirport.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HomeToAirport) },
    { value: GroundTransportTravelType.AirportToHotel.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.AirportToHotel) },
    { value: GroundTransportTravelType.HotelToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HotelToSite) },
    { value: GroundTransportTravelType.HomeToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.HomeToSite) },
    { value: GroundTransportTravelType.AirportToSite.toString(), text: enumToText(GroundTransportTravelType, GroundTransportTravelType.AirportToSite) },
  ];

  tripDirectionOptions: SelectOption[] = [
    { value: PatientTripDirection.Outbound.toString(), text: enumToText(PatientTripDirection, PatientTripDirection.Outbound) },
    { value: PatientTripDirection.Return.toString(), text: enumToText(PatientTripDirection, PatientTripDirection.Return) }
  ];

  currencyOptions: SelectOption[] = [];
  muvRideFormVisible = false;
  numberHelper = NumberHelper;

  isDeleteProcessing = false;
  deleteTrip: PatientTrip;
  isUpdateProcessing: boolean = false;

  get canTransformRate(): boolean {
    let baseCurrency = this.trip.trialBaseCurrency;
    let amount = this.bookingTabForm.get('quotedAmount').value;
    let currency = this.bookingTabForm.get('quotedCurrency').value;
    return baseCurrency !== '' && baseCurrency !== null && baseCurrency !== undefined
      && amount !== '' && amount !== null && amount !== undefined && amount > 0
      && currency !== '' && currency !== null && currency !== undefined;
  }

  constructor(
    private readonly patientService: PatientService,
    private readonly alertService: AlertService,
    private readonly exchangeRateService: ExchangeRateService) {
    super();
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      visitId: new UntypedFormControl('', Validators.required),
      tripTab: new UntypedFormGroup({
        tripType: new UntypedFormControl('', Validators.required),
        groundTransportTravelType: new UntypedFormControl(null),
        tripDirection: new UntypedFormControl(null),
        departureDate: new UntypedFormControl('', Validators.required),
        departureTime: new UntypedFormControl('', Validators.required),
        departureLocation: new UntypedFormControl(''),
        arrivalDate: new UntypedFormControl(''),
        arrivalTime: new UntypedFormControl(''),
        arrivalLocation: new UntypedFormControl(''),
        accommodationLocation: new UntypedFormControl(),
      }),
      noteTab: new UntypedFormGroup({
        notes: new UntypedFormControl(),
        internalNotes: new UntypedFormControl(),
      }),
      bookingTab: new UntypedFormGroup({
        carrier: new UntypedFormControl(),
        ticketConfirmationNo: new UntypedFormControl(),
        bookingProvider: new UntypedFormControl(),
        reference: new UntypedFormControl(),
        amountQuoted: new UntypedFormControl(),
        paymentCardUsed: new UntypedFormControl(),
        invoiceNo: new UntypedFormControl(),
        bookingStatus: new UntypedFormControl('Pending'),
        ticketsChanged: new UntypedFormControl(false),
        quotedAmount: new UntypedFormControl(),
        quotedCurrency: new UntypedFormControl(),
        overBudget: new UntypedFormControl(false)
      }),
    });

    this.tripTabForm = this.form.get('tripTab') as UntypedFormGroup;
    this.bookingTabForm = this.form.get('bookingTab') as UntypedFormGroup;
    this.noteTabForm = this.form.get('noteTab') as UntypedFormGroup;

    this.transformedFxRateForm = new UntypedFormGroup({
      transformedAmount: new UntypedFormControl({ value: null }),
      transformedCurrency: new UntypedFormControl({ value: null, disabled: true }),
    });

    // Update validation on trip type change
    this.tripTabForm.get('tripType').valueChanges.subscribe(value => this.onTripTypeChange(value));

    this.bookingTabForm.get('quotedAmount').valueChanges.subscribe(value => this.onQuotedAmountChange(value));
    this.bookingTabForm.get('bookingStatus').valueChanges.subscribe(value => this.onBookingStatusChange(value));

    this.form.valueChanges.subscribe(changes => {
      if (this.visitType !== undefined && this.visitType !== null) {
        if (this.tripTabForm.invalid) {
          this.tabulatedContent.addTabClass('Trip Details', 'invalid');
        } else {
          this.tabulatedContent.removeTabClass('Trip Details', 'invalid');
        }

        if (this.bookingTabForm.invalid) {
          this.tabulatedContent.addTabClass('Booking Info', 'invalid');
        } else {
          this.tabulatedContent.removeTabClass('Booking Info', 'invalid');
        }
      }
    });

    this.initiateCurrencies();
  }

  /**
   * Called when the user has made a change to the tickets - forces the form to be dirty
   * @param hasChanged
   */
  onTicketsChanged(hasChanged: boolean): void {
    this.bookingTabForm.patchValue({ 'ticketsChanged': hasChanged });
    this.form.markAsDirty();
  }

  onClickShowPatientDetails() {
    this.visitPatientDetailModal.show(this.trip.patientId, this.trip.patientTrialId, this.trip.visitId);
  }

  initiateCurrencies() {
    this.currencyOptions = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  /**
   * Resets the form
   */
  resetForm(): void {
    this.visitId = null;

    this.tripTabForm.patchValue({
      tripType: '',
      groundTransportTravelType: null,
      tripDirection: null,
      departureDate: '',
      departureTime: '',
      departureLocation: '',
      arrivalDate: '',
      arrivalTime: '',
      arrivalLocation: '',
      accommodationLocation: '',
      currency: null,
    });

    this.noteTabForm.patchValue({
      notes: ''
    });

    this.bookingTabForm.patchValue({
      ticket: null,
      ticketConfirmationNo: '',
      bookingProvider: '',
      reference: '',
      amountQuoted: '',
      paymentCardUsed: '',
      invoiceNo: '',
      bookingStatus: 'Pending',
      overBudget: false
    });

    this.form.reset();
    this.form.updateValueAndValidity();

    this.selectedFilename = null;
    this.selectedFileBlob = null;

    this.carrierList.reset();
    this.bookedThroughList.reset();
    this.paymentCardUsedList.reset();

    this.visitType = null;

    this.bookingTabForm.get('bookingStatus').setValue('Pending');

    this.tabs.find(t => t.title === 'RideHealth').visible = false;
    this.tabs.find(t => t.title === 'MUV Rides').visible = false;

    // Reset the ticket manager
    this.ticketManager.reset();
  };

  show(tripId: string, defaultTab = null): void {
    this.resetForm();
    this.modal.show();
    this.tripId = tripId;
    this.loadPatientTrip(tripId);
    this.tabulatedContent.switchTab(0);

    if (defaultTab !== null) {
      // Delay to allow tabs to load before switching
      setTimeout(() => {
        const tabIndex = this.tabs.indexOf(this.tabs.find(t => t.title == defaultTab));
        if (tabIndex > -1)
          this.tabulatedContent.switchTab(tabIndex);
      }, 1500);
    }
  }

  /**
   * Hides the modal
   */
  hide(): void {
    this.modal.hide();
  }

  loadPatientTrip(tripId: string) {
    this.patientService.retrievePatientTrip(tripId).subscribe({
      next: trip => {
        this.trip = trip;

        this.tripHasObRequest = trip.bookingStatus === 'ObAwaitingSponsorApproval';

        let tripTypeInt: number;
        switch (trip.type) {
          case 'Ground Transport':
            tripTypeInt = 0;
            break;
          case 'Flight':
            tripTypeInt = 1;
            break;
          case 'Train':
            tripTypeInt = 2;
            break;
          case 'Accommodation':
            tripTypeInt = 3;
            break;
          case 'Rental Car':
            tripTypeInt = 4;
            break;
          default:
            this.alertService.showWarningAlert('There was a problem loading the trip!');
            return;
        }

        // Disable some inputs
        if (trip.rideHealthStatus !== '' && trip.rideHealthStatus !== 'NotApplicable') {
          this.isRideHealthTrip = true;
          this.tripTabForm.controls['tripType'].disable();
          this.tripTabForm.controls['departureDate'].disable();
          this.tripTabForm.controls['departureTime'].disable();
          this.tripTabForm.controls['departureLocation'].disable();
          this.tripTabForm.controls['arrivalDate'].disable();
          this.tripTabForm.controls['arrivalTime'].disable();
          this.tripTabForm.controls['arrivalLocation'].disable();
        }

        // Populate the form
        this.formInitialising = true;

        this.form.patchValue({ visitId: trip.visitId });

        this.onTripTypeChange(tripTypeInt.toString());

        this.tripTabForm.patchValue({
          tripType: tripTypeInt.toString(),
          groundTransportTravelType: trip.groundTransportTravelType?.toString(),
          tripDirection: trip.direction?.toString(),
          departureDate: !StringHelper.isNullOrEmpty(trip.departureDate) ? moment(trip.departureDate).format('DD/MM/YYYY') : null,
          departureTime: !StringHelper.isNullOrEmpty(trip.departureDate) && trip.departureTimeMinutes !== null ? moment(trip.departureDate).format('HH:mm') : null,
          departureLocation: trip.departureLocation,
          arrivalDate: !StringHelper.isNullOrEmpty(trip.arrivalDate) ? moment(trip.arrivalDate).format('DD/MM/YYYY') : null,
          arrivalTime: !StringHelper.isNullOrEmpty(trip.arrivalDate) && trip.arrivalTimeMinutes !== null ? moment(trip.arrivalDate).format('HH:mm') : null,
          arrivalLocation: trip.arrivalLocation,
          accommodationLocation: trip.accommodationLocation,
        });

        this.noteTabForm.patchValue({
          notes: trip.notes,
          internalNotes: trip.internalNotes
        });

        this.bookingTabForm.patchValue({
          bookingStatus: trip.bookingStatus,
          ticket: trip.ticketId != null ? 'file selected' : '',
          reference: trip.reference,
          ticketConfirmationNo: trip.ticketOrConfirmationNo,
          carrier: trip.carrier,
          bookingProvider: trip.bookingProvider,
          paymentCardUsed: trip.paymentCardUsed,
          invoiceNo: trip.invoiceNo,
          quotedAmount: trip.quotedAmount ? (trip.quotedAmount / 100).toFixed(2) : '',
          quotedCurrency: trip.quotedCurrency,
          overBudget: trip.overBudget,
        });

        let presetCurrency = trip.quotedCurrency != null ? trip.quotedCurrency : trip.trialBaseCurrency;
        this.amountQuotedControl.setCurrency(presetCurrency);

        this.transformedFxRateForm.patchValue({ transformedAmount: trip.quotedAmountBC / 100 });
        if (trip.trialBaseCurrency !== null && trip.trialBaseCurrency !== undefined) {
          this.transformedFxRateForm.patchValue({ transformedCurrency: this.trip.trialBaseCurrency });
        }
        this.amountQuotedBCControl.setCurrency(trip.trialBaseCurrency);

        this.ticketOriginalFilename = trip.ticketOriginalFilename;
        this.selectedFilename = trip.ticketOriginalFilename;

        // Enable RideHealth tab
        if (this.trip.rideHealthEnabled && trip.type === 'Ground Transport')
          this.tabs.find(t => t.title === 'RideHealth').visible = true;

        if (this.trip.trialMuvEnabled && trip.type === 'Ground Transport')
          this.tabs.find(t => t.title === 'MUV Rides').visible = true;

        // Tell the ticket manager component about any existing ticket attachments on this trip
        this.ticketManager.initialiseAttachments(trip.attachments);

        this.formInitialising = false;
      },
      error: error => {
        LogHelper.log(error);

        // Pause for a second and then double check that the trip hasn't loaded before showing an error toast
        setTimeout(() => {
          if (this.trip === null || this.trip === undefined) {
            this.alertService.showWarningAlert('There was a problem loading the trip!');
          }
        }, 1000);
      }
    });
  }

  /**
   * Called when the trip type is changed. It will update validation etc on the form based on the user selection
   * @param value
   */
  onTripTypeChange(value: string) {
    if (+value === 0 && this.trip?.trialMuvEnabled) {
      this.tabs.find(t => t.title === 'MUV Rides').visible = true;
    } else {
      this.tabs.find(t => t.title === 'MUV Rides').visible = false;
    }

    if (+value >= 0 && +value <= 2) {
      // Travel Request (Ground Transport, Flight or Train)
      this.visitType = 'Travel';

      this.tripTabForm.patchValue({
        arrivalDate: '',
        departureDate: '',
      });

      this.tripTabForm.get('departureLocation').setValidators(Validators.required);
      this.tripTabForm.get('departureLocation').updateValueAndValidity();
      this.tripTabForm.get('departureDate').setValidators(Validators.required);
      this.tripTabForm.get('departureDate').updateValueAndValidity();
      this.tripTabForm.get('accommodationLocation').clearValidators();
      this.tripTabForm.get('accommodationLocation').setErrors(null);

      if (+value >= 1 && +value <= 2) {
        // Flight or train
        this.tripTabForm.get('arrivalTime').setValidators(Validators.required);
        this.tripTabForm.get('arrivalDate').setValidators(Validators.required);
        this.tripTabForm.get('arrivalTime').updateValueAndValidity();
        this.tripTabForm.get('arrivalDate').updateValueAndValidity();
        this.tripTabForm.get('arrivalLocation').setValidators(Validators.required);
        this.tripTabForm.get('arrivalLocation').updateValueAndValidity();
      } else {
        // Ground transport
        this.tripTabForm.get('arrivalDate').clearValidators();
        this.tripTabForm.get('arrivalDate').setErrors(null);
        this.tripTabForm.get('arrivalTime').clearValidators();
        this.tripTabForm.get('arrivalTime').setErrors(null);
        this.tripTabForm.get('arrivalLocation').clearValidators();
        this.tripTabForm.get('arrivalLocation').setErrors(null);
      }
    } else if (+value === 3) {
      // Accommodation Request
      this.visitType = 'Accommodation';

      this.tripTabForm.get('accommodationLocation').setValidators(Validators.required);
      this.tripTabForm.get('departureLocation').clearValidators();
      this.tripTabForm.get('departureLocation').setErrors(null);
      this.tripTabForm.get('arrivalLocation').clearValidators();
      this.tripTabForm.get('arrivalLocation').setErrors(null);
      this.tripTabForm.get('arrivalDate').clearValidators();
      this.tripTabForm.get('arrivalDate').setErrors(null);
      this.tripTabForm.get('departureDate').clearValidators();
      this.tripTabForm.get('departureDate').setErrors(null);
      this.tripTabForm.get('departureTime').clearValidators();
      this.tripTabForm.get('departureTime').setErrors(null);

      this.tripTabForm.patchValue({
        arrivalDate: '',
        departureTime: '',
        departureDate: '',
      });
    } else if (+value === 4) {
      // Rental Car
      this.visitType = 'Rental Car';

      this.tripTabForm.get('departureLocation').setValidators(Validators.required);
      this.tripTabForm.get('departureLocation').updateValueAndValidity();

      this.tripTabForm.get('arrivalLocation').setValidators(Validators.required);
      this.tripTabForm.get('arrivalLocation').updateValueAndValidity();

      this.tripTabForm.get('accommodationLocation').clearValidators();
      this.tripTabForm.get('accommodationLocation').setErrors(null);
    }
  }

  /**
   * Called when user wants to see quoted amount in transformed FX currency.
   */
  onShowTransformedFxRate() {
    let quotedAmount = this.bookingTabForm.get('quotedAmount').value;
    let quotedCurrency = this.bookingTabForm.get('quotedCurrency').value;
    let toCurrency = this.transformedFxRateForm.get('transformedCurrency').value;

    if (!this.canTransformRate) {
      this.showFxTransformError = true;
      return;
    }
    this.showFxTransformError = false;
    this.transformedCurrencyProcessing = true;

    let request = {
      fromCurrencyCode: quotedCurrency,
      toCurrencyCode: toCurrency,
      quotedAmount: quotedAmount
    } as ExchangeRateRequest

    this.exchangeRateService.getTransformedFxRate(request).subscribe({
      next: result => {
        this.transformedCurrencyProcessing = false;
        if (result.result !== null) {
          this.transformedFxRateForm.patchValue({ transformedAmount: result.result / 100 });
        }
        else if (result.destinationCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for the base currency set on connected trial (${toCurrency}).`);
        }
        else if (result.sourceCurrencyNotAvailable) {
          this.alertService.showWarningAlert(`FX rate information is not available for this currency (${quotedCurrency})`);
        }
      },
      error: error => {
        this.transformedCurrencyProcessing = false;
        this.showFxTransformError = true;
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to retrieve transformed FX rate!');
      }
    });
  }

  resetFxFields() {
    this.transformedCurrencyProcessing = false;
    this.showFxTransformError = false;
  }

  /**
   * Updates the departure and arrival date in the form when you move your mouse around,
   * a bit of a bodge fix because angular doesn't automatically detect changes
   */
  onMouseEnterForm() {
    if (this.departureDate.nativeElement.value !== this.form.get('departureDate')) {
      this.tripTabForm.patchValue({ departureDate: this.departureDate.nativeElement.value });
    }
    if (this.arrivalDate.nativeElement.value !== this.form.get('arrivalDate')) {
      this.tripTabForm.patchValue({ arrivalDate: this.arrivalDate.nativeElement.value });
    }
  }

  private getDateFormat(time: IllyTime): string {
    return time?.isValid ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD'
  }

  /**
   * Formats a date and time string into a moment object
   * @param date
   * @param time
   * @param isArrivalDate
   * @private
   */
  private formatDateTimeString(date: string, time: IllyTime, isArrivalDate = false): moment.Moment {
    let response = null;

    if (date !== '' && time?.isValid) {
      // Both date and time were supplied
      response = moment(date + ' ' + time.to24HourString(), 'DD/MM/YYYY HH:mm');
    } else if (date !== '' && (time == null || !time.isValid)) {
      // Only a date was provided
      if (isArrivalDate) {
        // Set time to end of day as the date doesn't have a time
        response = moment(date + ' 23:59', 'DD/MM/YYYY HH:mm');
      } else {
        response = moment(date, 'DD/MM/YYYY');
      }
    }

    return response;
  }

  /**
   * Called when a trip has been successfully updated
   * @private
   */
  private handleSuccessfulUpdate() {
    this.isUpdateProcessing = false;
    this.form.patchValue({ processing: false });
    this.tripUpdated.emit(this.trip.visitId);
    this.resetFxFields();
    this.recalculateAmountBcModal.hide();
    this.hide();
    this.alertService.showSuccessAlert('Trip successfully updated');
  }

  private handleSuccessfulDelete() {
    this.form.patchValue({ processing: false });
    this.tripUpdated.emit(this.trip.visitId);
    this.resetFxFields();
    this.hide();
  }

  updateMuvRideFormVisibility(visible: boolean): void {
    this.muvRideFormVisible = visible;
    this.loadPatientTrip(this.tripId);
  }

  onQuotedAmountChange(value: any) {
    if (!value) {
      this.bookingTabForm.get('quotedCurrency').clearValidators();
      this.bookingTabForm.get('quotedCurrency').updateValueAndValidity();
    } else {
      // If value is not empty, set the currencyControlName input to be required
      this.bookingTabForm.get('quotedCurrency').setValidators([Validators.required]);
      this.bookingTabForm.get('quotedCurrency').updateValueAndValidity();
    }
  }

  onBookingStatusChange(status: string) {
    if (status === 'Booked' && this.tripTabForm.get('tripType').value === '0') {
      this.tripTabForm.get('groundTransportTravelType').setValidators(Validators.required);
    } else {
      this.tripTabForm.get('groundTransportTravelType').clearValidators();
    }

    this.tripTabForm.get('groundTransportTravelType').updateValueAndValidity();
  }

  getTripTypeText() {
    const type = this.tripTypeOptions.find(o => o.text === this.trip.type);
    return type.text;
  }

  getGroundTransportTypeText() {
    if (!this.trip.groundTransportTravelType)
      return '';

    const type = this.groundTransportTravelTypeOptions.find(o => o.value === this.trip.groundTransportTravelType.toString());
    return type.text;
  }

  getDirectionText() {
    if (!this.trip.direction)
      return '';

    const type = this.tripDirectionOptions.find(o => o.value === this.trip.direction.toString());
    return type.text;
  }

  getAmountAsText(amount: number): string {
    if (!amount)
      return '';

    return (amount / 100).toFixed(2).toString();
  }

  getBookingStatusText() {
    if (!this.trip.bookingStatus)
      return '';

    const type = this.tripBookingStatusOptions.find(o => o.value === this.trip.bookingStatus.toString());
    return type.text;
  }

  /**
   * Called when the user clicks the submit button
   */
  onFormSubmit(recalculateAmountBc?: boolean) {
    this.isUpdateProcessing = true;
    if (!this.form.valid)
      return;

    if (!this.form.dirty) {
      this.hide();
      return;
    }

    let validationError = '';

    const departureTime = !StringHelper.isNullOrEmpty(this.tripTabForm.get('departureTime').value) ? IllyTime.parseString(this.tripTabForm.get('departureTime').value) : null;
    const departureDate = this.formatDateTimeString(this.tripTabForm.get('departureDate').value, departureTime);
    const arrivalTime = !StringHelper.isNullOrEmpty(this.tripTabForm.get('arrivalTime').value) ? IllyTime.parseString(this.tripTabForm.get('arrivalTime').value) : null;
    const arrivalDate = this.formatDateTimeString(this.tripTabForm.get('arrivalDate').value, arrivalTime, true);

    let formIsValid = true;

    if (arrivalDate && arrivalDate.valueOf() < departureDate.valueOf()) {
      validationError = 'Arrival date is before departure date.';
      formIsValid = false;
    }

    if (this.tripTabForm.get('tripType').value === '0' && this.noteTabForm.get('notes').value !== null && this.noteTabForm.get('notes').value.length > 1000) {
      validationError = 'Notes for ground transport trips must be no more than 1000 characters in length.';
      formIsValid = false;
    }

    if (!formIsValid) {
      this.alertService.showWarningAlert(validationError);
      return;
    }

    this.form.patchValue({ processing: true });

    const dto: UpdateTripDto = {
      tripType: +this.tripTabForm.get('tripType').value,
      tripDirection: this.tripTabForm.get('tripDirection').value,
      groundTransportTravelType: this.tripTabForm.get('groundTransportTravelType').value,
      departureDate: departureDate ? departureDate.format(this.getDateFormat(departureTime)) : null,
      departureLocation: this.tripTabForm.get('departureLocation').value,
      departureTimeMinutes: departureTime !== null ? departureTime.totalMinutes() : null,
      arrivalDate: arrivalDate ? arrivalDate.format(this.getDateFormat(arrivalTime)) : null,
      arrivalLocation: this.tripTabForm.get('arrivalLocation').value,
      arrivalTimeMinutes: arrivalTime !== null ? arrivalTime.totalMinutes() : null,
      accommodationLocation: this.tripTabForm.get('accommodationLocation').value,
      notes: this.noteTabForm.get('notes').value,
      internalNotes: this.noteTabForm.get('internalNotes').value,
      ticketRemoved: this.ticketRemoved,
      carrier: this.bookingTabForm.get('carrier').value,
      bookingProvider: this.bookingTabForm.get('bookingProvider').value,
      paymentCardUsed: this.bookingTabForm.get('paymentCardUsed').value,
      ticketConfirmationNo: this.bookingTabForm.get('ticketConfirmationNo').value,
      reference: this.bookingTabForm.get('reference').value,
      bookingStatus: this.bookingTabForm.get('bookingStatus').value,
      invoiceNo: this.bookingTabForm.get('invoiceNo').value,
      quotedAmount: this.bookingTabForm.get('quotedAmount').value,
      quotedAmountBC: this.transformedFxRateForm.get('transformedAmount').value,
      quotedCurrency: this.bookingTabForm.get('quotedCurrency').value,
      overBudget: this.bookingTabForm.get('overBudget').value,
      recalculateAmountBc: recalculateAmountBc
    };

    // Update the trip and upload any attachments (if any)
    this.patientService.updateTrip(this.trip.id, dto).subscribe({
      next: trip => {
        if (!trip.updateSucceeded) {
          this.form.get('processing').setValue(false);
          this.isUpdateProcessing = false;
          this.recalculateAmountBcModal.show();
          return;
        }

        // Calculate how a delay based on the number of attachments added to the ticket.
        // This is a bit of a bodge, but will help for now until a more in-depth solution can be implemented
        let attachmentCount = this.ticketManager.attachments.length;
        let timeout = 3000 + (attachmentCount * 650);

        this.ticketManager.uploadTicketsForTrip(trip.id).then(() => {
          if (this.bookingTabForm.get('ticketsChanged').value) {
            // Set the order of the attachments
            this.patientService.setTripTicketOrder(this.trip.id, this.ticketManager.attachments.map(a => a.originalFileName)).subscribe({
              next: () => {
                // Delay to allow the attachments to be merged by the queue handler
                setTimeout(() => {
                  this.handleSuccessfulUpdate();
                }, timeout);
              },
              error: (error) => {
                this.alertService.showErrorAlert(error);
              }
            });
          } else {
            this.handleSuccessfulUpdate();
          }


        }).catch(error => {
          this.form.patchValue({ processing: false });
          this.isUpdateProcessing = false;
          this.alertService.showWarningAlert('There was a problem uploading the ticket!');
          this.hide();
        });
      },
      error: error => {
        this.isUpdateProcessing = false;
        this.form.patchValue({ processing: false });
        this.alertService.showErrorResponse(error.error);
      }
    });
  }

  getDepartureDateLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-in Date';
      case 'Rental Car':
        return 'Pick-up Date';
    }

    return 'Departure Date';
  }

  getArrivalDateLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-out Date';
      case 'Rental Car':
        return 'Drop-off Date';
    }

    return 'Arrival Date';
  }

  getDepartureTimeLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-in Time';
      case 'Rental Car':
        return 'Pick-up Time';
    }

    return 'Departure Time';
  }

  getArrivalTimeLabel(tripType: string) {
    switch (tripType) {
      case 'Accommodation':
        return 'Check-out Time';
      case 'Rental Car':
        return 'Drop-off Time';
    }

    return 'Arrival Time';
  }

  onDeleteTrip(trip: PatientTrip) {
    this.deleteTrip = trip;
    this.deleteTripModal.show();
  }

  onConfirmDeleteTrip() {
    this.isDeleteProcessing = true;
    this.alertService.clearAll();
    this.patientService.deleteTrip(this.deleteTrip.id).subscribe({
      next: () => {
        this.deleteTripModal.hide();
        this.alertService.showSuccessAlert('Trip successfully removed');
        this.isDeleteProcessing = false;
        this.deleteTrip = null;

        this.handleSuccessfulDelete();
      },
      error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem removing the trip!');
        this.deleteTripModal.hide();
        this.isDeleteProcessing = false;
      }
    });
  }
}
