<div class="details-view">

  <div class="details-view-head">
    <div class="details-view-row-top">
      <div alternativeBackRoute="/trial" backBtn class="back-btn"></div>
      <div class="details-view-trial-info">
        <span class="badge-reference-wrap">
          <app-badge-trial [state]="trial.state"></app-badge-trial>
          <span class="field-value details-view-trial-reference">{{ trial.label }}</span>
        </span>
        <span class="field-value details-view-trial-visits">{{ trial.visitCount }} Visits</span>
      </div>
      <div class="btn-wrap flex-row gap-x-2">
        <app-button text="Export" [style]="ButtonStyle.Info" (clicked)="onShowTripExportModal()" [visible]="authService.hasPermission(Permissions.TrialExportTripData)"></app-button>
        <app-button text="Edit" href="/trial/{{ trial.id }}/edit" [visible]="authService.hasPermission(Permissions.TrialEditPublic)"></app-button>
      </div>
    </div>

    <div class="details-view-row-desc">
      <p class="added-by">Added by {{ trial.createdBy }} on {{ trial.created | date: 'd MMM yyyy' }} at {{ trial.created | date: 'h:mm aaa' }}</p>
    </div>
  </div>

  <div class="tabs-wrap">
    <app-mobile-tabs #mobileTabs (onSelected)="onSelectTab($event)" selectedValue="sites">
      <ul>
        <li><a class="selected" (click)="mobileTabs.onSelectItem('sites', $event)">Sites</a>
        <li><a (click)="mobileTabs.onSelectItem('patients', $event)" *ngIf="authService.hasPermission(Permissions.TrialPatientsRead)">Patients</a></li>
        <li><a (click)="mobileTabs.onSelectItem('policies', $event)">Policies</a></li>
        <li><a (click)="mobileTabs.onSelectItem('contacts', $event)">Support Contacts</a></li>
        <li><a (click)="mobileTabs.onSelectItem('change-log', $event)" *ngIf="authService.hasPermission(Permissions.TrialChangeLogRead)">Change Log</a></li>
        <li><a (click)="mobileTabs.onSelectItem('change-log', $event)" *ngIf="authService.hasPermission(Permissions.TrialPaymentsRead)">Payments</a></li>
        <li><a (click)="mobileTabs.onSelectItem('bookings', $event)" *ngIf="trial.bookingEnabled">Bookings</a></li>
        <li *ngIf="authService.hasRole(roleNames.ProductAdministrator) || authService.hasRole(roleNames.SystemAdministrator)"><a (click)="mobileTabs.onSelectItem('CMS Users', $event)">CMS Users</a></li>
        <li *ngIf="trial.apiEnabled"><a (click)="mobileTabs.onSelectItem('trial-integration', $event)">Integration</a></li>
      </ul>
    </app-mobile-tabs>


    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'sites'}" data-toggle="tab" id="trial-sites-anchor" (click)="onSelectTab('sites')">Sites</a>
      </li>
      <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialPatientsRead)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'patients'}" data-toggle="tab" id="trial-patients-anchor" (click)="onSelectTab('patients')">Patients</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'policies'}" data-toggle="tab" id="trial-policies-anchor" (click)="onSelectTab('policies')">Policies</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'contacts'}" data-toggle="tab" id="trial-support-contacts-anchor" (click)="onSelectTab('contacts')">Support Contacts</a>
      </li>
      <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialChangeLogRead)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'change-log'}" data-toggle="tab" id="trial-change-log-anchor" (click)="onSelectTab('change-log')">Change Log</a>
      </li>
      <li class="nav-item" *ngIf="trialInfoTabEnabled">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'trial-info'}" data-toggle="tab" id="trial-info-anchor" (click)="onSelectTab('trial-info')">Trial Info</a>
      </li>
      <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialPaymentsRead)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'payments'}" data-toggle="tab" id="trial-payments" (click)="onSelectTab('payments')">Payments</a>
      </li>
      <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialAutoStipend)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'visit-schedule'}" data-toggle="tab" id="trial-visit-schedule" (click)="onSelectTab('visit-schedule')">Visit Schedule</a>
      </li>
      <li class="nav-item" *ngIf="trial.bookingEnabled && authService.hasPermission(Permissions.TrialBooking)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'bookings'}" data-toggle="tab" id="trial-bookings-anchor" (click)="onSelectTab('bookings')">Bookings</a>
      </li>
      <!--<li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'mailbox'}" data-toggle="tab" id="trial-mailbox-anchor" (click)="onSelectTab('mailbox')">Study Mailbox</a>
      </li>-->
      <li class="nav-item" *ngIf="authService.hasPermission(Permissions.TrialCmsUsers)">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'cms-users'}" data-toggle="tab" id="cms-users-anchor" (click)="onSelectTab('cms-users')">CMS Users</a>
      </li>
      <li class="nav-item" *ngIf="trial.apiEnabled">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'trial-integration'}" data-toggle="tab" id="integration-anchor" (click)="onSelectTab('trial-integration')">Integration</a>
      </li>
      <li class="nav-item" *ngIf="trial.genericDiaryEnabled">
        <a class="nav-link" [ngClass]="{'active': selectedTab == 'trial-generic-diary'}" data-toggle="tab" id="trial-generic-diary" (click)="onSelectTab('trial-generic-diary')">E-Diary</a>
      </li>
    </ul>
  </div>

  <div class="tab-content" id="details-view-tab-content">

    <app-trial-sites [trialId]="trial.id" [apiEnabled]="trial.apiEnabled" *ngIf="selectedTab == 'sites'"></app-trial-sites>

    <app-trial-patients *ngIf="selectedTab == 'patients'" [apiEnabled]="trial.apiEnabled"></app-trial-patients>

    <app-trial-policies *ngIf="selectedTab == 'policies'" [trial]="trial"></app-trial-policies>

    <app-trial-change-log *ngIf="selectedTab == 'change-log'" [trialId]="trial.id"></app-trial-change-log>

    <app-trial-contacts #contactsComponent *ngIf="selectedTab == 'contacts'" [trial]="trial"></app-trial-contacts>

    <app-trial-info *ngIf="selectedTab == 'trial-info'" [trialId]="trial.id" [startDate]="trial.startDate" [endDate]="trial.endDate" [timelineEnabled]="trial.timelineEnabled" [trialInfoEnabled]="trial.trialInfoEnabled" (updated)="loadTrial(trial.id)"></app-trial-info>

    <app-trial-payments *ngIf="selectedTab == 'payments'" [trialId]="trial.id"></app-trial-payments>

    <app-trial-visit-schedule *ngIf="selectedTab == 'visit-schedule'" [trialId]="trial.id" [diaryEnabled]="trial.diaryEnabled" [genericDiaryEnabled]="trial.genericDiaryEnabled"></app-trial-visit-schedule>

    <app-trial-bookings *ngIf="selectedTab == 'bookings'" [trialId]="trial.id"></app-trial-bookings>

    <!--<app-trial-mailbox *ngIf="selectedTab == 'mailbox'" [trialId]="trial.id"></app-trial-mailbox>-->

    <app-trial-users *ngIf="selectedTab === 'cms-users'" [trialId]="trial.id" [trialUsers]="trial.trialUsers" (userRoleChanged)="onUserRoleChanged($event)"></app-trial-users>

    <app-trial-integration [apiConsumers]="trial.apiConsumers" *ngIf="selectedTab === 'trial-integration'"></app-trial-integration>

    <app-trial-generic-diary *ngIf="trial.genericDiaryEnabled && selectedTab === 'trial-generic-diary'"></app-trial-generic-diary>
  </div>

</div>

<!-- Start: Contact changes modal -->
<app-modal #contactModal>
  <div class="modal-header">
    <h2 class="modal-title">Unsaved Changes</h2>
  </div>
  <div class="modal-body">
    <p>You have unsaved support contact changes, are you sure you want to continue and lose your changes</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="contactModal.hide()" class="btn btn-secondary" data-dismiss="modal">No, go back</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmSelectTab(preSelectedTab)" class="btn btn-primary">Yes, continue</a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Contact modal changes -->

<!-- Start: Export patient change log modal -->
<app-modal #exportTripsModal>
  <div class="modal-header">
    <h2 class="modal-title">Export</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="exportForm">
      <ng-container *ngIf="exportForm.get('allData').value === false">
        <div class="form-group">
          <label>Start Date</label>
          <input type="text" #dateFrom class="date" data-toggle="datepicker" formControlName="dateFrom">
        </div>

        <div class="form-group">
          <label>End Date</label>
          <input type="text" #dateTo class="date" data-toggle="datepicker" formControlName="dateTo">
        </div>
      </ng-container>

      <div class="form-group" *ngIf="trial.diaryEnabled">
        <label>What to Export</label>
        <mat-select formControlName="type" placeholder="Select...">
          <mat-option *ngFor="let option of exportOptions" [value]="option.value">{{ option.text }}</mat-option>
        </mat-select>
      </div>

      <div [class.visibility-hidden]="this.exportForm.get('type').value !== 'diary'">
        <div class="form-group" [class.visibility-hidden]="exportForm.get('allSites').value === true">
          <label>Site</label>
          <app-site-autocomplete #siteAutocomplete [form]="exportForm" [trialFilter]="trial.id"
                                 [allowAllSites]="false" controlName="site"
                                 placeholder="Start typing to find a site..."></app-site-autocomplete>
        </div>

        <div class="form-group">
          <label class="checkbox">
            <input formControlName="allSites" id="all-sites" type="checkbox" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="all-sites">All sites</label>
        </div>

        <div class="form-group">
          <label class="checkbox">
            <input formControlName="allData" id="all-data" type="checkbox" value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="all-data">All available data (ignore date range)</label>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="exportTripsModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onExportChangeLog()" class="btn btn-primary" (mousemove)="updateExportFormValues()">
          <span *ngIf="exportIsProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!exportIsProcessing">Export</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Export patient change log modal -->
