import { AuthService } from 'app/core/services/auth.service';
import { Permissions } from './../../../../../core/constants/permissions';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccommodationBudgetType,
  TrialPolicy,
  TrialPolicyTranslationRequest,
  UpdateTrialPolicyRequest
} from '../../trial-policy.model';
import { DropdownInputComponent } from "../../../../../shared/dropdown-input/dropdown-input.component";
import { Countries } from "../../../../../core/constants/countries";
import { LogHelper } from "../../../../../core/helpers/log.helper";
import { SiteService } from "../../../../../core/services/site.service";
import { TrialService } from "../../../../../core/services/trial.service";
import { AlertService } from "../../../../../shared/alert/alert.service";
import { ExpenseService } from "../../../../../core/services/expense.service";
import { TemplateService } from "../../../../../core/services/template.service";
import {
  AutosuggestDropdownInputComponent
} from "../../../../../shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component";
import { ModalComponent } from "../../../../../shared/modal/modal.component";
import { Cultures } from 'app/core/constants/cultures';
import { Currencies } from 'app/core/constants/currency';
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import { VisitAutoPaymentsComponent } from "../../visit-auto-payments/visit-auto-payments.component";
import { TabItem } from "../../../../../shared/tabulated-content/tab-item.model";
import { TabulatedContentComponent } from "../../../../../shared/tabulated-content/tabulated-content.component";
import { VisitPreloadPaymentsComponent } from "../../visit-preload-payments/visit-preload-payments.component";

@Component({
  selector: 'app-trial-policy-edit',
  templateUrl: './trial-policy-edit.component.html',
  styleUrls: ['./trial-policy-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrialPolicyEditComponent implements OnInit, AfterViewInit {
  @ViewChild('countrySelect') countrySelect: AutosuggestDropdownInputComponent;
  @ViewChild('languageSelect') languageSelect: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('translationVersionDropdown') translationVersionDropdown: DropdownInputComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;
  @ViewChild('currencySelect') currencySelect: DropdownInputComponent;
  @ViewChild('policyCurrencySelect') policyCurrencySelect: DropdownInputComponent;
  @ViewChild('accommodationBudgetTypeSelect') accommodationBudgetTypeSelect: DropdownInputComponent;
  @ViewChild('autoPaymentTypeSelect') autoPaymentTypeSelect: DropdownInputComponent;
  @ViewChild('visitAutoPayments') visitAutoPayments: VisitAutoPaymentsComponent;
  @ViewChild('autoPaymentTabulatedContent') autoPaymentTabulatedContent: TabulatedContentComponent;
  @ViewChild('visitPreloadPayments') visitPreloadPayments: VisitPreloadPaymentsComponent;

  trialId: string = null;
  policyId: string = null;
  policy: TrialPolicy;

  expenseCategories: { id: string, value: string }[] = [];
  cmsOnlyCategories: { id: string, value: string }[] = [];
  languageSelectForm: UntypedFormGroup;
  defaultForm: FormGroup;

  forms: UntypedFormGroup[] = [];
  formIsProcessing = false;
  countryOptions: { value: string, text: string }[] = [];
  allCultures: { value: string, text: string }[] = [];
  culturesInUse: { value: string, text: string }[] = [{ value: 'en', text: 'English (GB)' }];
  siteOptions: { value: string, text: string }[] = [];
  currencies: { value: string, text: string }[] = [];
  accommodationBudgetTypes: { value: string, text: string }[] = [];
  currentCulture = 'en';
  selectedTab = 'details';
  Permissions = Permissions;
  autoPaymentTabs: TabItem[] = [
    new TabItem({ title: 'Stipend/Fixed', visible: true }),
    new TabItem({ title: 'Preloads', visible: true })
  ];

  constructor(private activatedRoute: ActivatedRoute, private trialService: TrialService,
    private alertService: AlertService, private expenseService: ExpenseService, private router: Router,
    private templateService: TemplateService, private siteService: SiteService,
    public authService: AuthService) {
    for (const country of Countries.all()) {
      this.countryOptions.push({ value: country.code, text: country.name });
    }

    for (const culture of Cultures.all()) {
      this.allCultures.push({
        value: culture.culture,
        text: culture.name
      });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.activatedRoute.params.subscribe(params => {
      this.trialId = params['id'];
      this.policyId = params['policyId'];
    });

    // Build the add new trial form
    this.defaultForm = new UntypedFormGroup({
      translationId: new UntypedFormControl(''),
      culture: new UntypedFormControl('en'),
      country: new UntypedFormControl('', Validators.required),
      sites: new UntypedFormControl([]),
      expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      categories: new UntypedFormControl([]),
      cmsOnlyCategories: new UntypedFormControl([]),
      allowTravelRequests: new UntypedFormControl(false),
      allowExpenseRequests: new UntypedFormControl(false),
      internalPolicyNotes: new UntypedFormControl(''),
      policyCurrency: new UntypedFormControl(''),

      carHireIncluded: new UntypedFormControl(false),
      carHirePatientBudget: new UntypedFormControl(null),
      carHireCaregiverBudget: new UntypedFormControl(null),
      carHireAdditionalInformation: new UntypedFormControl(),

      accommodationIncluded: new UntypedFormControl(false),
      accommodationPatientBudget: new UntypedFormControl(null),
      accommodationCaregiverBudget: new UntypedFormControl(null),
      accommodationBudgetType: new UntypedFormControl(null),
      accommodationAdditionalInformation: new UntypedFormControl(),

      airTravelIncluded: new UntypedFormControl(false),
      airTravelPatientBudget: new UntypedFormControl(null),
      airTravelCaregiverBudget: new UntypedFormControl(null),
      airTravelReturnTripIncluded: new UntypedFormControl(false),
      airTravelAdditionalInformation: new UntypedFormControl(),

      railTravelIncluded: new UntypedFormControl(false),
      railTravelPatientBudget: new UntypedFormControl(null),
      railTravelCaregiverBudget: new UntypedFormControl(null),
      railTravelReturnTripIncluded: new UntypedFormControl(false),
      railTravelAdditionalInformation: new UntypedFormControl(),

      homeToAirportTravelIncluded: new UntypedFormControl(false),
      homeToAirportTravelPatientBudget: new UntypedFormControl(null),
      homeToAirportTravelCaregiverBudget: new UntypedFormControl(null),
      homeToAirportTravelReturnTripIncluded: new UntypedFormControl(false),
      homeToAirportTravelAdditionalInformation: new UntypedFormControl(),

      airportToHotelTravelIncluded: new UntypedFormControl(false),
      airportToHotelTravelPatientBudget: new UntypedFormControl(null),
      airportToHotelTravelCaregiverBudget: new UntypedFormControl(null),
      airportToHotelTravelReturnTripIncluded: new UntypedFormControl(false),
      airportToHotelTravelAdditionalInformation: new UntypedFormControl(),

      hotelToSiteTravelIncluded: new UntypedFormControl(false),
      hotelToSiteTravelPatientBudget: new UntypedFormControl(null),
      hotelToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      hotelToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      hotelToSiteTravelAdditionalInformation: new UntypedFormControl(),

      homeToSiteTravelIncluded: new UntypedFormControl(false),
      homeToSiteTravelPatientBudget: new UntypedFormControl(null),
      homeToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      homeToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      homeToSiteTravelAdditionalInformation: new UntypedFormControl(),

      airportToSiteTravelIncluded: new UntypedFormControl(false),
      airportToSiteTravelPatientBudget: new UntypedFormControl(null),
      airportToSiteTravelCaregiverBudget: new UntypedFormControl(null),
      airportToSiteTravelReturnTripIncluded: new UntypedFormControl(false),
      airportToSiteTravelAdditionalInformation: new UntypedFormControl(),
    });

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });


    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });

    this.defaultForm.get('country').valueChanges.subscribe(() => {
      this.loadSites();
    });

    // Start loading expense categories
    this.loadExpenseCategories();

    this.initiateCurrencies();
    this.initiateAccommodationBudgetTypes();
  }

  ngAfterViewInit() {
    this.loadPolicy();
  }

  initiateCurrencies() {
    this.currencies = Currencies.all().map((x) => ({ value: x.cc, text: `${x.name} (${x.cc})` }));
  }

  initiateAccommodationBudgetTypes() {
    this.accommodationBudgetTypes.push({ value: AccommodationBudgetType.Visit.toString(), text: 'Per ' + enumToText(AccommodationBudgetType, AccommodationBudgetType.Visit) });
    this.accommodationBudgetTypes.push({ value: AccommodationBudgetType.Night.toString(), text: 'Per ' + enumToText(AccommodationBudgetType, AccommodationBudgetType.Night) });
  }

  loadPolicy() {
    this.trialService.getPolicy(this.trialId, this.policyId).subscribe({
      next: (policy: TrialPolicy) => {
        this.policy = policy;
        this.populateFormWithTranslations(policy);

        if (policy.isDefault) {
          this.defaultForm.get('country').setValidators(null);
          this.defaultForm.get('country').updateValueAndValidity();
        } else {
          this.defaultForm.get('country').setValidators([Validators.required]);
          this.defaultForm.get('country').updateValueAndValidity();
        }
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  loadSites() {
    this.siteOptions = [];
    this.siteService.retrieveSites(1, this.trialId, '', this.defaultForm.get('country').value, false, null, 9999).subscribe({
      next: (sites: any) => {
        this.siteOptions = [];
        sites.results.forEach(site => {
          this.siteOptions.push({ text: site.name, value: site.id });
        });
      },
      error: (error) => {
        this.alertService.showErrorAlert(error);
      }
    });
  }

  populateFormWithTranslations(policy: TrialPolicy) {
    const categories: string[] = [];
    policy.expenseCategories.forEach(c => {
      categories.push(c.id);
    });

    const cmsOnlyCategories: string[] = [];
    policy.cmsOnlyCategories.forEach(c => {
      cmsOnlyCategories.push(c.id);
    });

    const sites: string[] = [];
    policy.sites.forEach(s => {
      sites.push(s.id);
    });

    this.defaultForm.patchValue({
      country: policy.countryCode,
      allowExpenseRequests: policy.allowExpenseRequests,
      allowTravelRequests: policy.allowTravelRequests,
      policyCurrency: policy.policyCurrency,
      internalPolicyNotes: policy.internalPolicyNotes,

      carHireIncluded: policy.carHireIncluded,
      carHirePatientBudget: policy.carHirePatientBudget,
      carHireCaregiverBudget: policy.carHireCaregiverBudget,
      carHireAdditionalInformation: policy.carHireAdditionalInformation,

      accommodationIncluded: policy.accommodationIncluded,
      accommodationPatientBudget: policy.accommodationPatientBudget,
      accommodationCaregiverBudget: policy.accommodationCaregiverBudget,
      accommodationBudgetType: policy.accommodationBudgetType,
      accommodationAdditionalInformation: policy.accommodationAdditionalInformation,

      airTravelIncluded: policy.airTravelIncluded,
      airTravelPatientBudget: policy.airTravelPatientBudget,
      airTravelCaregiverBudget: policy.airTravelCaregiverBudget,
      airTravelReturnTripIncluded: policy.airTravelReturnTripIncluded,
      airTravelAdditionalInformation: policy.airTravelAdditionalInformation,

      railTravelIncluded: policy.railTravelIncluded,
      railTravelPatientBudget: policy.railTravelPatientBudget,
      railTravelCaregiverBudget: policy.railTravelCaregiverBudget,
      railTravelReturnTripIncluded: policy.railTravelReturnTripIncluded,
      railTravelAdditionalInformation: policy.railTravelAdditionalInformation,

      homeToAirportTravelIncluded: policy.homeToAirportTravelIncluded,
      homeToAirportTravelPatientBudget: policy.homeToAirportTravelPatientBudget,
      homeToAirportTravelCaregiverBudget: policy.homeToAirportTravelCaregiverBudget,
      homeToAirportTravelReturnTripIncluded: policy.homeToAirportTravelReturnTripIncluded,
      homeToAirportTravelAdditionalInformation: policy.homeToAirportTravelAdditionalInformation,

      airportToHotelTravelIncluded: policy.airportToHotelTravelIncluded,
      airportToHotelTravelPatientBudget: policy.airportToHotelTravelPatientBudget,
      airportToHotelTravelCaregiverBudget: policy.airportToHotelTravelCaregiverBudget,
      airportToHotelTravelReturnTripIncluded: policy.airportToHotelTravelReturnTripIncluded,
      airportToHotelTravelAdditionalInformation: policy.airportToHotelTravelAdditionalInformation,

      hotelToSiteTravelIncluded: policy.hotelToSiteTravelIncluded,
      hotelToSiteTravelPatientBudget: policy.hotelToSiteTravelPatientBudget,
      hotelToSiteTravelCaregiverBudget: policy.hotelToSiteTravelCaregiverBudget,
      hotelToSiteTravelReturnTripIncluded: policy.hotelToSiteTravelReturnTripIncluded,
      hotelToSiteTravelAdditionalInformation: policy.hotelToSiteTravelAdditionalInformation,

      homeToSiteTravelIncluded: policy.homeToSiteTravelIncluded,
      homeToSiteTravelPatientBudget: policy.homeToSiteTravelPatientBudget,
      homeToSiteTravelCaregiverBudget: policy.homeToSiteTravelCaregiverBudget,
      homeToSiteTravelReturnTripIncluded: policy.homeToSiteTravelReturnTripIncluded,
      homeToSiteTravelAdditionalInformation: policy.homeToSiteTravelAdditionalInformation,

      airportToSiteTravelIncluded: policy.airportToSiteTravelIncluded,
      airportToSiteTravelPatientBudget: policy.airportToSiteTravelPatientBudget,
      airportToSiteTravelCaregiverBudget: policy.airportToSiteTravelCaregiverBudget,
      airportToSiteTravelReturnTripIncluded: policy.airportToSiteTravelReturnTripIncluded,
      airportToSiteTravelAdditionalInformation: policy.airportToSiteTravelAdditionalInformation,

      categories: categories,
      cmsOnlyCategories: cmsOnlyCategories,
      sites: sites
    });

    if (!policy.isDefault) {
      setTimeout(() => {
        this.countrySelect.setSelectedOption(policy.countryCode);
      }, 10);
    }

    setTimeout(() => {
      this.accommodationBudgetTypeSelect.setValue(policy.accommodationBudgetType?.toString());
    }, 10);

    setTimeout(() => {
      this.policyCurrencySelect.setValue(policy.policyCurrency);
    }, 10);


    this.culturesInUse = [];
    policy.translations.forEach(translation => {
      this.allCultures.forEach(culture => {
        if (culture.value.toLowerCase() === translation.culture.toLowerCase()) {
          this.culturesInUse.push({ value: culture.value, text: culture.text });
        }
      });

      if (translation.culture === 'en') {
        this.defaultForm.patchValue({ translationId: translation.id, expensePolicy: translation.expensePolicy });
      } else {
        const form = new UntypedFormGroup({
          translationId: new UntypedFormControl(translation.id),
          culture: new UntypedFormControl(translation.culture),
          expensePolicy: new UntypedFormControl(translation.expensePolicy)
        });
        this.forms.push(form);
      }

      this.culturesInUse.sort((a, b) => a.text.localeCompare(b.text));
    });
  }

  formsValid(): boolean {
    return this.defaultForm.valid && this.forms.every((group) => group.valid);
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    for (const culture of this.culturesInUse) {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
        break;
      }
    }

    return inUse;
  }

  /**
   * Makes a call to the API, gets a list of all expense categories and stores them locally
   */
  loadExpenseCategories() {
    this.expenseCategories = []; // reset array
    this.cmsOnlyCategories = []; // reset array
    this.expenseService.retrieveExpenseCategories(1, '', '', false, 9999).subscribe({
      next: (expenseCategoryList) => {
        for (const category of expenseCategoryList.results) {
          this.expenseCategories.push({ id: category.id, value: category.name });
          this.cmsOnlyCategories.push({ id: category.id, value: category.name });
        }
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was problem loading categories!');
      }
    });
  }

  /**
   * Called when the add trial form is submitted
   */
  onFormSubmit() {
    this.formIsProcessing = true;

    // Build translations
    const translations: TrialPolicyTranslationRequest[] = [];
    let englishTranslation: TrialPolicyTranslationRequest = {
      translationId: this.defaultForm.get('translationId').value,
      culture: 'en',
      expensePolicy: this.defaultForm.get('expensePolicy').value
    }
    translations.push(englishTranslation);

    this.forms.forEach(form => {
      if (form.get('expensePolicy').value) {
        translations.push(form.value as TrialPolicyTranslationRequest);
      }
    });

    let updateTrialPolicyRequest: UpdateTrialPolicyRequest = this.defaultForm.value as UpdateTrialPolicyRequest;

    updateTrialPolicyRequest.translations = translations;
    updateTrialPolicyRequest.policyId = this.policyId;

    this.trialService.updatePolicy(updateTrialPolicyRequest).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('Expense Policy Updated Successfully');
        this.router.navigate(['/trial', this.trialId], { fragment: 'policies' }).then();
      },
      error: (error) => {
        this.formIsProcessing = false;
        this.alertService.showErrorAlert(error);
      }
    });
  }

  onAutoPaymentTabChanged(tabIndex: number) {
    switch (tabIndex) {
      case 0:
        this.visitAutoPayments.loadAutoPayments();
        break;
      case 1:
        this.visitPreloadPayments.loadPreloadPayments();
        break;
    }
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
      this.languageSelect.setValue('en');
    }

    this.removeLanguageModal.hide();
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
      const form = new UntypedFormGroup({
        culture: new UntypedFormControl(culture.value),
        expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      });
      this.forms.push(form);
      this.currentCulture = culture.value;
      this.languageSelectForm.patchValue({ culture: culture.value });
      this.languageSelect.setValue(culture.value);
      this.addLanguageModal.hide();
    }
  }

  onTabClick(selection: string) {
    this.selectedTab = selection;
    if (this.selectedTab === 'auto-payments') {
      // Reset sub tab to the first tab
      this.autoPaymentTabulatedContent.switchTab(0);

      // Load both auto and preload payments
      this.visitAutoPayments.loadAutoPayments();
      this.visitPreloadPayments.loadPreloadPayments();
    }
  }
}
