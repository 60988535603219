import { Component, Input } from '@angular/core';
import { BadgeStyle } from "./badge-style.enum";
import { BadgeStyleMapper } from "./badge-style.mapper";
import { BadgeSize } from "./badge-size.enum";

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrl: './badge.component.scss'
})
export class BadgeComponent {
  @Input() badgeStyle: BadgeStyle = BadgeStyle.Primary;
  @Input() size: BadgeSize = BadgeSize.Sm;

  protected readonly BadgeStyleMapper = BadgeStyleMapper;
  protected readonly BadgeSize = BadgeSize;
}
