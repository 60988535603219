<div class="suggestion-icons">
  <div class="suggestion-title" [style.border-color]="borderColor">
    <span>Recommended Action</span>
  </div>
  <div class="suggestion-content" [class]="suggestionClass">
    <mat-icon class="suggestion-icon">{{expenseSuggestionData.icon}}</mat-icon>
    <p>{{expenseSuggestionData.text}}</p>

    <app-button text="Re-check" (clicked)="suggestApproval()" faIcon="fas fa-rotate" [isBusy]="loading" [style]="ButtonStyle.Info" [size]="ButtonSize.Sm" />
  </div>
</div>
