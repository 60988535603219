<div class="payment-info-container view-body">
  <form [formGroup]="paymentInfoForm" [hidden]="!formInitialized">
    <div class="container">
      <div class="row">

        <div class="col-md-6">
          <table class="simple-form">

            <tr>
              <th><label for="bankTransferMethod">Bank Transfer Method</label></th>
              <td>
                <div class="form-group">
                  <app-dropdown-input #bankTransferMethodSelect id="bankTransferMethod" [options]="bankTransferMethods"
                    [parentForm]="paymentInfoForm" name="bankTransferMethod"
                    placeholder="Select Preferred Bank Transfer Method..."></app-dropdown-input>
                </div>
              </td>
            </tr>

            <tr>
              <th><label for="defaultPaymentMethod">Default Payment Method</label></th>
              <td>
                <div class="form-group">
                  <app-dropdown-input #defaultPaymentMethodSelect id="defaultPaymentMethod"
                    [options]="defaultPaymentMethods" [parentForm]="paymentInfoForm" name="defaultPaymentMethod"
                    placeholder="Select Default Payment Method..."></app-dropdown-input>
                </div>
              </td>
            </tr>

            <tr *ngIf="defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString()">
              <th><label for="caxtonWalletName">Caxton Wallet Name</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="caxtonWalletName" class="form-control" formControlName="caxtonWalletName">
                </div>
              </td>
            </tr>

            <tr *ngIf="defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString()">
              <th><label for="assignWallet">Available Wallets</label></th>
              <td>
                <div class="form-group">
                  <app-autosuggest-dropdown-input #assignWalletSelect id="assignWalletSelect"
                    [options]="assignWalletOptions" [parentForm]="paymentInfoForm"
                    placeholder="Select Wallet"></app-autosuggest-dropdown-input>
                </div>
              </td>
            </tr>

            <tr
              *ngIf="defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() && paymentInfoForm.controls.caxtonWalletId.value">
              <th><label for="caxtonWalletId">Assigned Wallet</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="caxtonWalletId" class="form-control" formControlName="caxtonWalletId">
                </div>
              </td>
            </tr>

            <tr
              *ngIf="defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() && authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails)">
              <th></th>
              <td>
                <button class="btn btn-primary test-connection-btn w-100" (click)="showWalletAssignmentPopup()">
                  <span>Assign and move wallet</span>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() && authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) && paymentInfoForm.get('caxtonWalletId').value">
              <th></th>
              <td>
                <button class="btn btn-secondary test-connection-btn w-100" [disabled]="linkingCardToTracker" (click)="linkCardToTracker()">
                  <span>Link to Card Tracker</span>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="(cardsActivated || caxtonOnlineAccessGranted) &&
                defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() &&
                authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) &&
                paymentInfoForm.controls.caxtonWalletId.value">
              <th></th>
              <td>
                <div class="cards-activated-wrapper">
                  <span *ngIf="cardsActivated" class="badge badge-success cards-activateds">Cards Activated</span>
                  <span *ngIf="caxtonOnlineAccessGranted" class="badge badge-success online-access-granted">Online Access Granted</span>
                </div>
              </td>
            </tr>

            <tr
              *ngIf="cardsActivated &&
                !caxtonOnlineAccessGranted &&
                defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() &&
                authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) &&
                paymentInfoForm.controls.caxtonWalletId.value">
              <th></th>
              <td>
                <button class="btn btn-outline btn-outline-slim test-connection-btn w-100" (click)="showGrantCaxtonAccessPopup()">
                  <span>Grant online access to Caxton</span>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="!cardsActivated &&
                defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() &&
                authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) &&
                paymentInfoForm.controls.caxtonWalletId.value">
              <th></th>
              <td>
                <button class="btn btn-outline btn-outline-slim test-connection-btn w-100" (click)="activateCards()">
                  <span *ngIf="isCardActivationProcessing">
                    <span class="spinner-border spinner-border-sm mb-0" role="status" aria-hidden="true"></span>
                  </span>
                  <span *ngIf="!isCardActivationProcessing">Activate Cards</span>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="
                cardsActivated &&
                defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() &&
                itemVisible([RoleNames.ProductAdministrator, RoleNames.ProjectCoordinatorExpenses]) &&
                paymentInfoForm.controls.caxtonWalletId.value">
              <th></th>
              <td>
                <button class="btn btn-outline btn-outline-slim test-connection-btn w-100" (click)="viewCardPin()">
                  <span *ngIf="isCardPinProcessing">
                    <span class="spinner-border spinner-border-sm mb-0" role="status" aria-hidden="true"></span>
                  </span>
                  <span *ngIf="!isCardPinProcessing">View Card Pin</span>
                </button>
              </td>
            </tr>

            <tr
              *ngIf="cardsActivated &&
                defaultPaymentMethodSelect?.selectedValue === PaymentMethod.Card.toString() &&
                authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) &&
                paymentInfoForm.controls.caxtonWalletId.value">
              <th><label class="account-balance account-balance-label">Balance</label></th>
              <td>
                <div class="account-balance">
                  <span *ngIf="isWalletBalanceProcessing">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                  <span *ngIf="!isWalletBalanceProcessing">{{walletBalance}}</span>
                </div>
              </td>
            </tr>

            <ng-container *ngIf="authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails)">
              <tr
                [hidden]="!componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) || !trialCaxtonEnabled">
                <th><label for="title">Title</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input [disabled]="!updateBeneficiary" #titleSelect id="title" [options]="titleOptions"
                      [parentForm]="paymentInfoForm" name="title" placeholder="Select title">
                    </app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) && trialCaxtonEnabled">
                <th><label for="firstName">First Name</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="firstName" class="form-control" formControlName="firstName">
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) && trialCaxtonEnabled">
                <th><label for="lastName">Last Name</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="lastName" class="form-control" formControlName="lastName">
                  </div>
                </td>
              </tr>
              <tr
                *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) && trialCaxtonEnabled">
                <th><label for="beneficiaryId">Caxton Beneficiary ID</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="beneficiaryId" class="form-control" formControlName="beneficiaryId">
                  </div>
                </td>
              </tr>
              <tr
                [hidden]="!componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated)  || !trialCaxtonEnabled">
                <th><label for="country">Country</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input [disabled]="!updateBeneficiary" #countrySelect id="country"
                      [options]="countries" [parentForm]="paymentInfoForm" name="country"
                      placeholder="Select country..."></app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr
                [hidden]="!componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) || !trialCaxtonEnabled">
                <th><label for="currency">Currency</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input [disabled]="!updateBeneficiary" #currencySelect id="currency"
                      [options]="currencies" [parentForm]="paymentInfoForm" name="currency"
                      placeholder="Select currency">
                    </app-dropdown-input>
                  </div>
                </td>
              </tr>
            </ng-container>

          </table>
        </div>

        <div class="col-md-6" *ngIf="authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails)">

          <table class="bank-fee-covers"
            [ngClass]="{'bottom-separator': componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated)}">
            <tr>
              <th><label class="separator-label">Bank Fee Covers</label></th>
            </tr>
            <tr
              [hidden]="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated)  && trialCaxtonEnabled">
              <th class="bank-fee-currency-dropdown"><label for="bankCoverFeeCurrency">Currency</label></th>
              <td>
                <div class="form-group">
                  <app-dropdown-input #bankCoverFeeCurrencySelect id="bankCoverFeeCurrency" [options]="currencies"
                    [parentForm]="paymentInfoForm" name="bankCoverFeeCurrency" placeholder="Select currency">
                  </app-dropdown-input>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="initial-bank-fee-cover">
                  <p>Add <input formControlName="initialBankCoverFee" type="number"><strong>{{currency}}</strong> to
                    each bank
                    transfer.
                  </p>
                  <button class="btn btn-primary" (click)="loadBankCoverFeeComponent()">+</button>
                </div>
              </td>
            </tr>

            <tr>
              <td colspan="2">
                <div class="other-bank-fee-covers">
                  <div class="fee" *ngFor="let fee of bankFeeCoverData">
                    <patient-bank-cover-fee [data]="fee" [currency]="currency"
                      (removeComponentEmitter)="removeBankFeeCoverComponent($event)"
                      (componentDataEmitter)="populateBankCoverFeeData($event)"></patient-bank-cover-fee>
                  </div>
                </div>
              </td>
            </tr>

          </table>

          <table class="simple-form"
            *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) && trialCaxtonEnabled">
            <tr *ngIf="bankRequirements.usesIban">
              <th><label for="iban">IBAN</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="iban" class="form-control" formControlName="iban">
                </div>
              </td>
            </tr>

            <tr *ngIf="bankRequirements.usesSortCode">
              <th><label for="sortCode">Sort Code</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="sortCode" class="form-control" placeholder="123456" formControlName="sortCode">
                </div>
              </td>
            </tr>

            <tr *ngIf="bankRequirements.usesSortCode || bankRequirements.usesSwift">
              <th><label for="accountNumber">Account Number</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="accountNumber" class="form-control" formControlName="accountNumber">
                </div>
              </td>
            </tr>

            <tr *ngIf="bankRequirements.usesSwift">
              <th><label for="swift">SWIFT</label></th>
              <td>
                <div class="form-group">
                  <input type="text" id="swift" class="form-control" formControlName="swift">
                </div>
              </td>
            </tr>

            <tr>
              <th><label for="swift">Account Owner Address</label></th>
              <td>
                <div class="form-group">
                  <textarea type="text" id="accountOwnerAddress" class="form-control"
                    formControlName="accountOwnerAddress"></textarea>
                  <span class="mandatory-label">*Mandatory for Canada</span>
                </div>
              </td>
            </tr>
          </table>

          <button class="btn btn-secondary"
            *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated) && trialCaxtonEnabled"
            [disabled]="updateBeneficiary" (click)="editBeneficiary()">Change Bank Details
          </button>
        </div>

        <div class="col-md-12"
          *ngIf="authService.hasPermission(Permissions.PatientPaymentInfoReadBankDetails) && trialCaxtonEnabled">
          <button *ngIf="componentVisible(PaymentMethod.Bank, BankTransferMethod.CaxtonAutomated)"
            (click)="togglePreviousBeneficiaries()" class="btn btn-secondary">Previous Beneficiary IDs</button>
          <ul *ngIf="showPreviousBeneficiaries && previousBeneficiaryIds.length !== 0">
            <li *ngFor="let id of previousBeneficiaryIds">
              {{id}}
            </li>
          </ul>
          <p *ngIf="showPreviousBeneficiaries && previousBeneficiaryIds.length === 0">No previous beneficiary IDs.</p>
        </div>

        <div *ngIf="bankAccount.bankAccountEnabled" class="col-12 bank-account">
          <div class="row">
            <div class="col-12">
              <h2>Patient Bank Details</h2>
            </div>

            <div class="col-3">
              <p>
                <strong>Account Holder and Address</strong><br>
                <span [innerHtml]="bankAccount.bankNameAddress | replaceNewLines"></span>
              </p>
            </div>
            <div class="col-3">
              <p>
                <strong>Account Holder's Name</strong><br>
                {{ bankAccount.accountHolderName }}
              </p>
            </div>
            <div class="col-2">
              <p>
                <strong>Account Number</strong><br>
                {{ bankAccount.accountNumber }}
              </p>
            </div>
            <div class="col-2">
              <p>
                <strong>BIC/SWIFT Code</strong><br>
                {{ bankAccount.bicSwiftCode }}
              </p>
            </div>
            <div class="col-2">
              <p>
                <strong>Routing Number</strong><br>
                {{ bankAccount.routingNumber }}
              </p>
            </div>
          </div>

        </div>

        <div class="col-md-12" *ngIf="authService.hasPermission(Permissions.PatientPaymentInfoWrite)">
          <div class="payment-info-buttons">
            <button class="btn btn-secondary" [disabled]="!updateBeneficiary" (click)="cancel()">Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="updatePatient()"
              [disabled]="paymentInfoForm.invalid || !paymentInfoForm.dirty || invalidForm">
              <span *ngIf="isFormProcessing">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </span>
              <span *ngIf="!isFormProcessing">Save</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<app-modal #viewCardPinModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Card pin number</h2>
    </div>
    <div class="modal-body text-center ">
      <span *ngIf="isCardPinProcessing">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </span>
      <img *ngIf="!isCardPinProcessing" class="card-pin-image" [src]="cardPinImageUrl"/>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-12">
          <a (click)="viewCardPinModal.hide()" class="btn btn-secondary"
            data-dismiss="modal">Close</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #confirmGrantOnlineAccessToCaxtonModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Do you want to grant access?</h2>
    </div>
    <div class="modal-body">
      <p>
        You should only grant access to patients who are on trials
        that do not support caxton card management in the app.
      </p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="onConfirmGrantAccess()" class="btn btn-primary">
            <span>Continue</span>
          </a>
        </div>
        <div class="col-6">
          <a (click)="confirmGrantOnlineAccessToCaxtonModal.hide()" class="btn btn-secondary"
            data-dismiss="modal">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #grantOnlineAccessToCaxtonFormModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Grant patient online access to caxton</h2>
    </div>
    <div class="modal-body">
      <form [formGroup]="caxtonOnlineAccessForm">
        <table aria-hidden="true" class="simple-form">
          <tr>
            <th><label for="email">Email Address</label></th>
            <td>
              <div class="form-group">
                <input class="form-control" formControlName="email" id="email" type="text">
              </div>
            </td>
          </tr>
          <tr>
            <th><label for="firstname">First Name</label></th>
            <td>
              <div class="form-group">
                <input class="form-control" formControlName="firstName" id="firstName" type="text">
              </div>
            </td>
          </tr>
          <tr>
            <th><label for="lastname">Last Name</label></th>
            <td>
              <div class="form-group">
                <input class="form-control" formControlName="lastName" id="lastName" type="text">
              </div>
            </td>
          </tr>
          <tr>
            <th><label for="title">Gender</label></th>
            <td>
              <div class="form-group">
                <app-dropdown-input #genderSelect id="gender" [options]="genderOptions"
                  [parentForm]="caxtonOnlineAccessForm" name="gender" placeholder="Select gender">
                </app-dropdown-input>
              </div>
            </td>
          </tr>
          <tr>
            <th><label>DOB</label></th>
            <td>
              <div class="form-group">
                <input type="text" #dob class="date form-control" data-toggle="datepicker"
                  formControlName="dateOfBirth">
              </div>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="onGrantOnlineAccessToCaxtonFormSubmit()" class="btn btn-primary"
            [ngClass]="{'disabled': isCaxtonAccessProcessing}">
            <span *ngIf="isCaxtonAccessProcessing">
              <span class="caxton-access-processing-spinner spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
            </span>
            <span *ngIf="!isCaxtonAccessProcessing">Continue</span>
          </a>
        </div>

        <div class="col-6">
          <a (click)="grantOnlineAccessToCaxtonFormModal.hide()" class="btn btn-secondary"
            data-dismiss="modal">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #confirmWalletAssignmentModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Confirm</h2>
    </div>
    <div class="modal-body">
      <p>Please confirm that you want to continue to assign and move this wallet.</p>
      <p>Once you select 'Confirm' , you will not be able to reverse the action.</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="onConfirmWalletAssignment()" class="btn btn-primary"
            [ngClass]="{'disabled': isAssignmentProcessing}">
            <span *ngIf="isAssignmentProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isAssignmentProcessing">Confirm</span>
          </a>
        </div>

        <div class="col-6">
          <a (click)="confirmWalletAssignmentModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #cardAlreadyActiveModal>
  <div class="modal-content">
    <div class="modal-header">
      <h2 class="modal-title">Warning!</h2>
    </div>
    <div class="modal-body">
      <p>Warning! The card in this wallet is already active.</p>
      <p>If you continue, the wallet will be linked to this patient but cannot be moved to the Caxton trial subsidiary
        account so will remain on the main Illingworth account. </p>
      <p>Please check you have selected the correct wallet before continuing.</p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="onContinueMoveActiveCard()" class="btn btn-primary"
            [ngClass]="{'disabled': isAssignmentProcessing}">
            <span *ngIf="isAssignmentProcessing">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
            <span *ngIf="!isAssignmentProcessing">Continue</span>
          </a>
        </div>

        <div class="col-6">
          <a (click)="cardAlreadyActiveModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-beneficiaries-list-modal #beneficiariesListModal (beneficiaryUpdated)="updateBeneficiaryId($event)"></app-beneficiaries-list-modal>
