import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ComponentBase } from "../../core/component-base";
import { ModalV2Component } from "../modal-v2/modal-v2.component";
import { TabulatedContentComponent } from "../tabulated-content/tabulated-content.component";
import { SiteAutocompleteComponent } from "../site-autocomplete/site-autocomplete.component";
import { TrialAutocompleteComponent } from "../trial-autocomplete/trial-autocomplete.component";
import { CreateSiteModalComponent } from "../create-site-modal/create-site-modal.component";
import { ModalViewport } from "../modal-v2/modal-viewport";
import { TabItem } from "../tabulated-content/tab-item.model";
import { TrialService } from "../../core/services/trial.service";
import { AlertService } from "../alert/alert.service";
import { PatientService } from "../../core/services/patient.service";
import { optionalEmailValidator } from "../../core/validators/optional-email.validator";
import { Address } from "../input-address/address.model";
import { TrialAutocomplete } from "../trial-autocomplete/trial-autocomplete.model";
import { SiteListItem } from "../../core/models/site-list.model";
import { SiteAutocomplete } from "../site-autocomplete/site-autocomplete.model";
import { CreatePatientDto } from "../../core/services/interfaces/create-patient.interface";
import { PatientCreated } from "./patient-created.model";
import { SelectOption } from "../../core/models/select-option.model";
import { Countries } from "../../core/constants/countries";
import { LanguageCultureOptions } from "../../core/constants/language-culture-options";
import { LogHelper } from "../../core/helpers/log.helper";
import { ModalComponent } from "../modal/modal.component";
import { InputAddressComponent } from "../input-address/input-address.component";
import { InputSelectComponent } from "../input-select/input-select.component";

@Component({
  selector: 'app-patient-add-modal',
  templateUrl: './patient-add-modal.component.html',
  styleUrl: './patient-add-modal.component.scss'
})
export class PatientAddModalComponent extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('createSiteModal') createSideModal: CreateSiteModalComponent;
  @ViewChild('sendInviteModal') sendInviteModal: ModalComponent;
  @ViewChild('homeAddress') homeAddress: InputAddressComponent;
  @ViewChild('languageSelect') languageSelect: InputSelectComponent;

  @Output('patientCreated') patientCreated = new EventEmitter<PatientCreated>();

  viewPort: ModalViewport = ModalViewport.Medium;

  form: FormGroup;
  trialInfoForm: FormGroup;
  patientInfoForm: FormGroup;
  extraInfoForm: FormGroup;

  tabs: TabItem[] = [
    new TabItem({title: 'Trial Info', visible: true}),
    new TabItem({title: 'Patient Info', visible: true}),
    new TabItem({title: 'Extra Info', visible: true})
  ];
  selectedTabIndex = 0;
  languageOptions: SelectOption[] = [];
  countries: { value: string; text: string }[] = [];

  // Invitation related properties
  sendingInvite = false;
  createdPatientId: string;
  createdPatientEmail: string;
  lastTrialCode = '';
  apiEnabled = false;

  constructor(private _trialService: TrialService, private _alertService: AlertService, private _patientService: PatientService) {
    super();

    for (const country of Countries.all()) {
      this.countries.push({ value: country.code, text: country.name });
    }

    const languageCultures = LanguageCultureOptions.all();
    languageCultures.forEach(culture => {
      this.languageOptions.push({ value: culture.cultureInfoCode.toLowerCase(), text: culture.language + ' (' + culture.cultureInfoCode.toLowerCase() + ')' });
    });
    this.languageOptions.sort((a, b) => a.text.localeCompare(b.text));
  }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.siteAutocomplete.minChars = 1;
    this.trialAutocomplete.minChars = 0;
    this.siteAutocomplete.disabled = true;

    this.listenToTrialSelection();
  }

  initForm(): void {
    this.form = new FormGroup({
      processing: new FormControl<boolean>(false),
      trialInfo: new FormGroup({
        trialId: new FormControl<string>('', Validators.required),
        trialCode: new FormControl<string>('', Validators.required),
        patientCode: new FormControl<string>('', [Validators.required, Validators.maxLength(16)]),
        site: new FormControl<string>('', Validators.required),
      }),
      patientInfo: new FormGroup({
        firstname: new FormControl<string>('', Validators.required),
        lastname: new FormControl<string>('', Validators.required),
        email: new FormControl<string>('', [Validators.required, optionalEmailValidator]),
        country: new FormControl<string>(''),
        address: new FormControl<Address>(null),
        homeAddress: new FormControl(null),
        culture: new FormControl<string>('en-gb')
      }),
      extraInfo: new FormGroup({
        notes: new FormControl<string>(''),
        policyReminders: new FormControl<string>(''),
        patientConsent: new FormControl<boolean>(false, Validators.requiredTrue),
        patientNotOnApp: new FormControl<boolean>(false, Validators.required)
      })
    });

    this.trialInfoForm = this.form.get('trialInfo') as FormGroup;
    this.patientInfoForm = this.form.get('patientInfo') as FormGroup;
    this.extraInfoForm = this.form.get('extraInfo') as FormGroup;

    // Show exclamation mark on tabs is form is invalid
    this.form.valueChanges.subscribe(changes => {
      if (this.trialInfoForm.invalid) {
        this.tabulatedContent.addTabClass('Trial Info', 'invalid');
      } else {
        this.tabulatedContent.removeTabClass('Trial Info', 'invalid');
      }

      if (this.patientInfoForm.invalid) {
        this.tabulatedContent.addTabClass('Patient Info', 'invalid');
      } else {
        this.tabulatedContent.removeTabClass('Patient Info', 'invalid');
      }

      if (this.extraInfoForm.invalid) {
        this.tabulatedContent.addTabClass('Extra Info', 'invalid');
      } else {
        this.tabulatedContent.removeTabClass('Extra Info', 'invalid');
      }
    });

    // Set a default patient email address when the patient code is updated
    this.form.get('trialInfo').get('patientCode').valueChanges.subscribe(patientCode => {
      if (patientCode !== null && patientCode !== '') {
        let email = this.form.get('patientInfo').get('email').value;
        let trialCode = this.form.get('trialInfo').get('trialCode').value;
        if (email === '' || email.includes('@' + trialCode)) {
          this.form.get('patientInfo').patchValue({ email: patientCode + '@' + trialCode });
          this.form.updateValueAndValidity();
        }
      }
    });

    // If a default email has been set, update it with the new trial code when the trial is changed
    this.form.get('trialInfo').get('trialCode').valueChanges.subscribe(trialCode => {
      let email = this.form.get('patientInfo').get('email').value;
      if (email !== '' && email.includes('@' + this.lastTrialCode)) {
        if (trialCode !== '') {
          email = email.replace('@' + this.lastTrialCode, '@' + trialCode);
          this.form.get('patientInfo').patchValue({ email: email });
        } else {
          this.form.get('patientInfo').patchValue({ email: '' });
        }
        this.form.updateValueAndValidity();
      }

      this.lastTrialCode = trialCode;
    });
  }

  reset(): void {
    this.form.reset();
    this.initForm();
    this.patientInfoForm.patchValue({culture: 'en-gb'});

    this.homeAddress.reset();
    this.trialAutocomplete.reset();
    this.siteAutocomplete.clear();
    this.languageSelect.onClear();
  }

  listenToTrialSelection(): void {
    this.trialAutocomplete.valueChanged.subscribe((option: TrialAutocomplete) => {
      this.onTrialSelected(option);
    });
  }

  onTrialSelected(option: TrialAutocomplete) {
    if (option === null) {
      this.siteAutocomplete.clear();
      this.siteAutocomplete.disabled = true;
      return;
    }

    // Load the trial details so we can enable showing consumer trials in the site dropdown if enabled on the trial
    this.loadTrial(option.id);

    this.siteAutocomplete.setTrialId(option.id);
    this.trialInfoForm.patchValue({trialId: option.id});
    this.siteAutocomplete.disabled = false;
    this.siteAutocomplete.clear();
  }

  onHandleTabChanged(index: number): void {
    this.selectedTabIndex = index;
  }

  /**
   * Shows the modal
   */
  show(trialId: string = null): void {
    this.reset();

    // Switch to the first tab
    this.tabulatedContent.switchTab(0);
    this.trialInfoForm.get('trialCode').enable();

    if (trialId !== null && trialId !== '') {
      this.trialInfoForm.patchValue({trialId: trialId});
      this.loadTrial(trialId)
    }

    // Show modal
    this.modal.show();
  }

  loadTrial(trialId: string) {
    this._trialService.retrieveTrial(trialId).subscribe({
      next: trial => {
        this.apiEnabled = trial.apiEnabled;
        this.trialInfoForm.patchValue({trialCode: trial.code, trialId: trial.id});
        this.trialAutocomplete.setInitialValue(trial.id, trial.code);
        this.siteAutocomplete.setTrialId(trial.id);

        // Disable trial selection
        this.trialInfoForm.get('trialCode').disable();
      }
    });
  }

  /**
   * Hides the modal
   */
  hide(): void {
    this.modal.hide();
  }

  onNewSiteCreated(site: SiteListItem) {
    const text = site.name + ' / ' + site.address;
    const option = new SiteAutocomplete(site.id, site.irgSiteId, text.substring(0, 30) + ' ...', site.name, site.address + ' ' + site.country);
    this.siteAutocomplete.onAddAndSelectOption(option);
  }

  onDoNotSendInvitation() {
    this.createdPatientId = null;
    this.createdPatientEmail = null;


    this.sendInviteModal.hide();
    this.hide();
  }

  /**
   * Sends patient invitation email
   */
  onSendPatientInvitation() {
    const patientId = this.createdPatientId;
    const patientEmail = this.createdPatientEmail;

    if (this.extraInfoForm.get('patientNotOnApp').value) {
      this._alertService.showWarningAlert('Patient is not on the app, unable to send invitation.');
      return;
    }

    this.hide();
    this.sendingInvite = true;
    this._patientService.sendClaimAccountEmail(patientId).subscribe({
      next: () => {
        this._alertService.showSuccessAlert(`Email sent to ${patientEmail}`);
        this.sendingInvite = false;

        this.sendInviteModal.hide();
      },
      error: error => {
        LogHelper.log(error);
        this.sendingInvite = false;
        this._alertService.showWarningAlert('Sorry, there was a problem sending an email to the patient.');
      }
    });
  }

  /**
   * Called when the form is submitted
   */
  onFormSubmit(): void {
    if (!this.form.valid) {
      this._alertService.showWarningAlert('Please complete all required fields.');
      return;
    }

    const dto: CreatePatientDto = {
      trialCode: this.trialInfoForm.get('trialCode').value,
      site: this.trialInfoForm.get('site').value,
      patientCode: this.trialInfoForm.get('patientCode').value,
      firstname: this.patientInfoForm.get('firstname').value,
      lastname: this.patientInfoForm.get('lastname').value,
      email: this.patientInfoForm.get('email').value,
      country: this.patientInfoForm.get('country').value,
      address: this.patientInfoForm.get('address').value,
      notes: this.extraInfoForm.get('notes').value,
      state: '0', // Live/Approved
      policyReminders: this.extraInfoForm.get('policyReminders').value,
      patientConsent: this.extraInfoForm.get('patientConsent').value,
      patientNotOnApp: this.extraInfoForm.get('patientNotOnApp').value,
      homeAddress: this.patientInfoForm.get('homeAddress').value,
      culture: this.patientInfoForm.get('culture').value,
    };

    this.form.patchValue({processing: true});
    this._patientService.create(dto).subscribe({
      next: patientCreatedResponse => {
        this.form.patchValue({processing: false});

        this.patientCreated.emit({
          patientId: patientCreatedResponse.patientId,
          patientTrialId: patientCreatedResponse.patientTrialId,
          patientFirstname: this.patientInfoForm.get('firstname').value,
          patientLastname: this.patientInfoForm.get('lastname').value,
          trialId: this.trialInfoForm.get('trialId').value,
          trialCode: this.trialInfoForm.get('trialCode').value
        });

        const patientNotOnTheApp = this.extraInfoForm.get('patientNotOnApp').value;
        if (patientNotOnTheApp) {
          this.hide();

          return;
        }

        this.createdPatientId = patientCreatedResponse.patientId;
        this.createdPatientEmail = this.patientInfoForm.get('email').value;
        this.sendInviteModal.show();
      },
      error: error => {
        LogHelper.log(error);
        this.form.patchValue({processing: false});
        this._alertService.showWarningAlert(error.error.title);
      }
    });
  }
}
