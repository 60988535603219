import { BadgeStyle } from "./badge-style.enum";

export class BadgeStyleMapper {
  static getClass(style: BadgeStyle): string {
    const classMap: { [key in BadgeStyle]: string } = {
      [BadgeStyle.Success]: 'badge-success',
      [BadgeStyle.Warning]: 'badge-warning',
      [BadgeStyle.Danger]: 'badge-danger',
      [BadgeStyle.Secondary]: 'badge-secondary',
      [BadgeStyle.Primary]: 'badge-primary'
    };

    return classMap[style];
  }
}
