import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MomentModule } from 'ngx-moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxEditorModule } from 'ngx-editor';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { HomeComponent } from './features/home/home.component';
import { navigationReducer } from './core/reducers/navigation.reducer';
import { AppRoutingModule } from './core/app-routing.module';
import { AuthModule } from './features/auth/auth.module';
import { AdminModule } from './features/admin/admin.module';
import { TrialModule } from './features/trial/trial.module';
import { PatientModule } from './features/patient/patient.module';
import { SiteModule } from './features/site/site.module';
import { ExpenseModule } from './features/expense/expense.module';
import { TravelModule } from './features/travel/travel.module';
import { SettingModule } from './features/settings/setting.module';
import { ExpenseCategoryModule } from './features/expense-category/expense-category.module';
import { CaxtonModule } from './features/caxton/caxton.module';
import { AuthGuard } from './core/guards/auth-guard.service';
import { RoleAuthGuard } from './core/guards/role-auth-guard.service';
import { AllRolesAuthGuard } from './core/guards/all-roles-auth-guard.service';
import { SettingsGuard } from './core/guards/settings-guard.service';
import { AutoLogoutService } from './core/services/autologout.service';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './core/MsalInstance';
import { CoreModule } from './core/core.module';
import { NgxMaskModule } from 'ngx-mask';
import { VisitModule } from "./features/visit/visit.module";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { APP_DATE_FORMATS, AppDateAdapter } from "./core/AppDateAdapter";
import { Platform } from "@angular/cdk/platform";
import { ProjectManagementModule } from "./features/project-manager/project-manager.module";
import { AdminRoleAuthGuard } from './core/guards/admin-role-auth-guard.service';
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { CardTrackerModule } from './features/card-tracker/card-tracker.module';
import { ButtonComponent } from "./shared/button/button.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
    imports: [
        BrowserModule.withServerTransition({appId: 'ng-cli-universal'}),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        StoreModule.forRoot({navigation: navigationReducer}),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: false, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        NgxMaskModule.forRoot(),
        AppRoutingModule,
        SharedModule,
        AuthModule,
        AdminModule,
        TrialModule,
        PatientModule,
        SiteModule,
        ExpenseModule,
        TravelModule,
        SettingModule,
        ExpenseCategoryModule,
        MomentModule,
        MsalModule,
        CaxtonModule,
        CoreModule,
        VisitModule,
        ProjectManagementModule,
        CardTrackerModule,
        MatNativeDateModule,
        NgxMatInputTelComponent,
        NgxEditorModule,
        ButtonComponent
    ],
  providers: [
    AuthGuard,
    RoleAuthGuard,
    AdminRoleAuthGuard,
    AllRolesAuthGuard,
    SettingsGuard,
    AutoLogoutService,
    DeviceDetectorService,
    MsalGuard,
    MsalService,
    {provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE, Platform]},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    {provide: LOCALE_ID, useValue: 'en-gb'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    }
  ],
  exports: [

  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
