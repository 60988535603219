<div *ngIf="dataLoaded">
  <div class="title-cost-wrap" *ngIf="expense.categoryType == ExpenseCategoryType.Distance">
    <span class="result-heading title">{{ expense.categoryName }}</span>
    <span class="result-heading cost">
      {{ expense.distanceAmount }} {{ expense.distanceUnit }}
      <span *ngIf="expense.amount > 0">/ {{ expense.currency}} {{ expense.amount / 100 | number:'1.2-2' }}</span>
    </span>
  </div>

  <div class="title-cost-wrap" *ngIf="expense.categoryType == ExpenseCategoryType.Value">
    <span class="result-heading title">
      {{ expense.categoryName }}
      <span *ngIf="expense.subCategory !== null && expense.subCategory !== ExpenseSubCategory.None">
        - {{ expense.subCategory | enumToText:ExpenseSubCategory }}
      </span>
    </span>
    <span class="result-heading cost">{{ expense.currency }} <span class="negative-amount"
        *ngIf="expense.subCategory === ExpenseSubCategory.CardPreloadRedemption">-</span> {{ expense.amount / 100 |
      number:'1.2-2' }}</span>
  </div>
</div>

<div *ngIf="!dataLoaded" class="title-cost-wrap">
  <div class="loaders">
    <ngx-skeleton-loader style="width: 12%;"></ngx-skeleton-loader>
    <ngx-skeleton-loader style="width: 11%;"></ngx-skeleton-loader>
  </div>
</div>