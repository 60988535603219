import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterToggleOption } from "./filter-toggle-option.model";

@Component({
  selector: 'app-filter-toggle',
  templateUrl: './filter-toggle.component.html',
  styleUrl: './filter-toggle.component.scss'
})
export class FilterToggleComponent {
  @Output() toggled = new EventEmitter<FilterToggleOption[]>();

  @Input('options') options: FilterToggleOption[] = [];

  isOpen: boolean = false;

  onToggle(option: FilterToggleOption) {
    this.options.find(o => o.id === option.id).checked = !option.checked;
    this.toggled.emit(this.options);
  }

  getSelectedCount(): number {
    return this.options.filter(o => o.checked && o.visible).length;
  }

  open(): void {
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }

  clear(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.options.forEach(option => option.checked = false);
    this.toggled.emit(this.options);
  }
}
