<div class="auto-payments">
  <form [formGroup]="visitAutoPaymentsForm">

    <div class="auto-payments-header">
      <div class="form-group" style="width: 210px">
        <label for="currency">Auto-payment type</label>
        <app-dropdown-input #autoPaymentTypeSelect id="autoPaymentType" [options]="autoPaymentTypes"
                            [parentForm]="visitAutoPaymentsForm" name="autoPaymentType"
                            placeholder="Select Auto Payment Type"></app-dropdown-input>
      </div>

      <div class="checkbox-wrap">
        <div class="form-group checkbox-input-field">
          <label class="checkbox input-checkbox">
            <input type="checkbox" id="assumeVisitAttendance" formControlName="assumeVisitAttendance"
                   value="true">
            <span></span>
          </label>
          <label class="checkbox-label" for="assumeVisitAttendance">Assume visit attendance after <input
            formControlName="assumeVisitAttendanceAfterDays" class="days-input" type="number"> days</label>
        </div>
      </div>

      <div class="form-group" style="width: 210px">
        <label for="currency">Auto-payment currency</label>
        <app-dropdown-input #currencySelect id="autoPaymentCurrency" [options]="currencies"
                            [parentForm]="visitAutoPaymentsForm" name="autoPaymentCurrency" placeholder="Select currency">
        </app-dropdown-input>
      </div>
    </div>

    <div>
      <div class="visit-headings">
        <span class="visit-key-visit">Key Visit</span>
        <span class="visit-name">Visit</span>
        <span class="visit-amount">Patient<br>Amount</span>
        <span class="visit-caregiver-amount">Caregiver<br>Amount</span>
      </div>
      <div class="visit-box" *ngFor="let visit of visits">
        <span class="visit-key-visit">
          <label class="checkbox">
            <input type="checkbox" id="keyVisit" (change)="onKeyVisitChecked(visit, $event)"
                   [checked]="visit.keyVisit">
            <span></span>
          </label>
        </span>
        <span class="visit-name">{{ visit.title }}</span>
        <span class="visit-amount">
          <input [disabled]="!visit.keyVisit" #amount class="amount-input" type="number" placeholder="0.00"
                 (blur)="onAmountSet(visit, amount.value)" [value]="visit.amount ? visit.amount.toFixed(2) : ''">
        </span>
        <span class="visit-caregiver-amount">
          <input [disabled]="!visit.keyVisit" #caregiverAmount class="amount-input" type="number" placeholder="0.00"
                 (blur)="onCaregiverAmountSet(visit, caregiverAmount.value)" [value]="visit.caregiverAmount ? visit.caregiverAmount.toFixed(2) : ''">
        </span>
      </div>
    </div>

  </form>

  <div class="auto-payments-footer">
    <a routerLink="/trial/{{trialId}}" fragment="policies" class="btn btn-secondary">Cancel</a>
    <button class="btn btn-primary" type="submit" [disabled]="visitAutoPaymentsForm.invalid"
            (click)="onSave()">
      <span *ngIf="visitAutoPaymentsForm.get('isBusy').value">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
      <span *ngIf="!visitAutoPaymentsForm.get('isBusy').value">Save</span>
    </button>
  </div>
</div>
